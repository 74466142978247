import React, { useState, useMemo } from 'react';
import { DragDropContext } from 'react-dnd';
import HTML5Backend from 'react-dnd-html5-backend';
import styles from './SelectSignalDialog.module.scss';
import Dialog from '../../../../shared/dialog/Dialog';
import selectGroups, { GROUP_TYPES } from '../../models/group';
import { signalsToSeries } from '../../models/serie';
import { List } from '../SortableList';
import Button from '../../../../shared/button/Button';

function SelectSignalDialog(props) {
  const {
    selected,
    available,
    recordID,
    applySelection,
    open,
    closeDialog,
    headbandVersion,
    viewerVersion,
  } = props;

  const allSignals = available.map((s) => s.path);
  const isSupported = (headbandVersion === null || (headbandVersion === 6 && viewerVersion === 'external'))

  const ignoreList = useMemo(() => ['eeg3/filtered', 'eeg7/filtered', 'eeg3/raw', 'eeg7/raw'], [])
  const selectedSignals = useMemo(() => {
    if (isSupported) {
      return selected.filter((sig) => !ignoreList.includes(sig));
    }
    return selected
  }, [ignoreList, isSupported, selected]);

  const availableSignals = useMemo(() => {
    const remainingSignals = allSignals.filter((s) => !selected.includes(s));
    if (isSupported) {
      return remainingSignals.filter((sig) => !ignoreList.includes(sig));
    }
    return remainingSignals;
  }, [allSignals, ignoreList, isSupported, selected]);

  const [selectedArray, setSelectedArray] = useState(selectedSignals);
  const [nonSelectedArray, setNonSelectedArray] = useState(availableSignals);

  function onSelectedChange(selectedSignals) {
    setSelectedArray(selectedSignals);
    setNonSelectedArray(availableSignals.filter((s) => !selectedSignals.includes(s)));
  }

  function onRemovedChange(unselectedSignals) {
    setNonSelectedArray(unselectedSignals);
    setSelectedArray(selectedSignals.filter((s) => !unselectedSignals.includes(s)));
  }

  function selectSignalHandler(type) {
    return () => {
      const groups = selectGroups(type);
      const signals = selectedArray.map(
        (s) => available.find((sg) => sg.path === s),
      );
      const series = signalsToSeries(recordID, signals);
      switch (type) {
        case GROUP_TYPES.SINGLEPLOT:
          groups.mapGroup(series);
          break;
        case GROUP_TYPES.COMPARISON:
        case GROUP_TYPES.MULTIPLOT:
        default:
          groups.addGroup(series);
          break;
      }
      applySelection(series, groups);
    };
  }

  const footer = [
    <Button
      type="button"
      onClick={() => closeDialog()}
      className={styles.button}
      text="Cancel"
      cancel
    />,
    <Button
      disabled
      onClick={selectSignalHandler(GROUP_TYPES.SINGLEPLOT)}
      className={styles.button}
      text="SinglePlot"
    />,
    <Button
      onClick={selectSignalHandler(GROUP_TYPES.COMPARISON)}
      className={styles.button}
      text="Compare"
    />,
    <Button
      onClick={selectSignalHandler(GROUP_TYPES.MULTIPLOT)}
      className={styles.button}
      text="MultiPlot"
    />,
  ];

  const render = (
    <div className={styles.content}>
      <div className={styles.container}>
        <List
          title="Selected"
          elements={selectedArray}
          onSelectionChange={onSelectedChange}
        />
        <List
          title="Available"
          elements={nonSelectedArray}
          onSelectionChange={onRemovedChange}
        />
      </div>
    </div>
  );

  return (
    <Dialog
      display={open}
      title="Select signals"
      content={render}
      footer={footer}
      handleCloseDialog={() => closeDialog()}
    />
  );
}

export default DragDropContext(HTML5Backend)(SelectSignalDialog);
