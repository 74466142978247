import React from 'react';
import { useLocation, useHistory } from 'react-router-dom';
import styles from './Nav.module.scss';
import Item from './Item';
import { ReactComponent as Logo } from '../../../assets/images/logos/logo.svg';
import { ReactComponent as Headband } from '../../../assets/images/icons/headband.svg';
import { ReactComponent as Logout } from '../../../assets/images/icons/logout.svg';
import { logout } from '../../features/auth/actions';

export default function Nav() {
  const { pathname } = useLocation();

  const history = useHistory();

  return (
    <div className={styles.container}>
      <div className={styles.topMenu}>
        <Logo width="45px" style={{ margin: 'auto auto 4.2125rem' }} />
        {/* <Item
          logo={TODO}
          title="Subjects"
          route="/subjects"
          selected={pathname === '/subjects'}
        /> */}
        <Item
          logo={<Headband width="20px" height="20px" fill="#fff" />}
          title="Records"
          route="/records"
          selected={pathname === '/records'}
        />
      </div>
      <div className={styles.bottomMenu}>
        <Item
          logo={<Logout width="20px" height="20px" fill="#C0BAEE" />}
          title="Log out"
          onClick={() => { logout(); history.push('/login'); }}
        />
      </div>
    </div>
  );
}