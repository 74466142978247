/* eslint-disable react/no-access-state-in-setstate */
/* eslint-disable react/no-deprecated */
/* eslint-disable react/prop-types */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable max-classes-per-file */
import React, { Component, Fragment } from 'react';
import _ from 'lodash';
import { createPortal } from 'react-dom';
import Draggable from 'react-draggable';
import {
  Button, Form,
} from 'antd';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import { connect } from 'react-redux';
import Dialog from '../../../../shared/dialog/Dialog';
import {
  formatLabelOption,
  parseLabelOption,
  labelOptions,
} from '../../models/label';
import { addLabel } from '../../actions/labels';
import { timeSeriesConfig } from '../../constants/globals';
import { Keys, keybind } from '../utils';
import { createOptions } from '../../utils/formUtils';
import { ReactComponent as PlusIcon } from '../../../../../assets/images/icons/plus.svg';

const ButtonGroup = Button.Group;
const FormItem = Form.Item;

const containerStyle = {
  position: 'fixed',
  top: '0',
  left: '0',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  background: 'whitesmoke',
  padding: '5px 10px',
  zIndex: '9999',
  cursor: 'move',
  border: '1px lightgray solid',
};

const configButtonStyle = {
  marginRight: '20px',
};

const popoverContentStyle = {
  width: '150px',
  textAlign: 'center',
};

class LabelToolboxWrapper extends Component {
  constructor(props) {
    super(props);
    this.el = document.createElement('div');
  }

  componentDidMount() {
    document.body.appendChild(this.el);
  }

  componentWillUnmount() {
    document.body.removeChild(this.el);
  }

  render() {
    return createPortal(<LabelToolbox {...this.props} />, this.el);
  }
}

function filterValueOptions(input, option) {
  return option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0;
}

function labelTemplateKey(lt) {
  return `${lt.value}-${lt.type}-[${_(lt.signals)
    .sortBy()
    .join(';')}]`;
}

const AddDialog = Form.create()(
  class extends Component {
    constructor(props) {
      super(props);
      this.state = {
        value: null,
        signals: [],
      };
      this.onSubmit = this.onSubmit.bind(this);
    }

    onSubmit(e) {
      e.preventDefault();
      const values = {
        value: this.state.value,
        signals: this.state.signals,
      };
      const labelInfo = parseLabelOption(values.value);
      this.props.addLabel({
        signals: values.signals,
        value: labelInfo.value,
        type: labelInfo.type,
        state: true,
      });
      this.props.form.resetFields();
    }

    render() {
      return (
        <div style={{ position: 'fixed', inset: '0' }}>
          <Dialog
            display
            title="Add label template"
            handleCloseDialog={this.props.closeDialog}
            content={(
              <Form onSubmit={this.onSubmit}>
                <FormItem>
                  <Select
                    placeholder="Value"
                    filterOption={filterValueOptions}
                    value={this.state.value}
                    onChange={(e) => (
                      this.setState({ value: e.target.value })
                    )}
                  >
                    {labelOptions.map((l) => (
                      <MenuItem key={l.value} value={l.value}>{l.value}</MenuItem>
                    ))}
                  </Select>
                </FormItem>
                <FormItem>
                  <Select
                    multiple
                    placeholder="Signals"
                    value={this.state.signals}
                    onChange={(e) => (
                      this.setState({ signals: typeof e.target.value === 'string' ? e.target.value.split(',') : e.target.value })
                    )}
                  >
                    {createOptions(this.props.signals.map((s) => s.path))}
                  </Select>
                </FormItem>
                <button type="submit" onClick={this.onSubmit}>Save</button>
              </Form>
            )}
          />
        </div>
      );
    }
  },
);

function saveTemplates(lt) {
  window.localStorage.setItem(timeSeriesConfig.labelToolbox, JSON.stringify(lt));
}

// TODO: Check signals are available on the current record
function loadTemplates() {
  try {
    const stored = window.localStorage.getItem(timeSeriesConfig.labelToolbox);
    return stored ? JSON.parse(stored) : [];
  } catch (e) {
    return [];
  }
}

class LabelToolbox extends Component {
  constructor(props) {
    super(props);
    this.state = {
      labelTemplates: loadTemplates(),
      addDialog: false,
    };
    this.keys = Keys();
    this.kb = keybind(
      ['1', '2', '3', '4', '5', '6', '7', '8', '9', '0'],
      this.onKeyPressed,
      'labels-keys',
    );
    if (!props.stagingEnabled) {
      this.keys.addToggled(this.kb);
    }
  }

  componentWillReceiveProps(nextProps) {
    if (this.props.stagingEnabled !== nextProps.stagingEnabled) {
      if (nextProps.stagingEnabled) {
        this.keys.removeToggled(this.kb);
      } else {
        this.keys.addToggled(this.kb);
      }
    }
  }

  onKeyPressed(e, keystr) {
    e.preventDefault();
    const key = +keystr;
    const i = key > 0 ? key - 1 : this.state.labelTemplates.length - 1;
    if (!i.isNaN() && i >= 0 && i < this.state.labelTemplates.length) {
      this.onLabelClick(this.state.labelTemplates[i]);
    }
  }

  onLabelClick(lt) {
    if (!this.props.selection.hasSelection()) {
      return;
    }
    const s = this.props.selection.current;
    const label = {
      ...lt,
      start: s.start,
      end: s.end,
    };
    this.props.dispatch(addLabel(this.props.recordID, label, false));
  }

  removeLabelTemplate(ltKey) {
    const newTemplates = this.state.labelTemplates.filter(
      (lt) => labelTemplateKey(lt) !== ltKey,
    );
    saveTemplates(newTemplates);
    this.setState({
      labelTemplates: newTemplates,
    });
  }

  renderAddDialog() {
    if (!this.state.addDialog) {
      return null;
    }
    return (
      <AddDialog
        signals={this.props.signals}
        addLabel={(nlt) => {
          const nltKey = labelTemplateKey(nlt);
          const exists = this.state.labelTemplates.some(
            (lt) => labelTemplateKey(lt) === nltKey,
          );
          const newTemplates = exists
            ? this.state.labelTemplates
            : this.state.labelTemplates.concat(nlt);
          saveTemplates(newTemplates);
          this.setState({
            labelTemplates: newTemplates,
            addDialog: false,
          });
        }}
        closeDialog={() => this.setState({ addDialog: false })}
      />
    );
  }

  render() {
    return (
      <>
        <Draggable defaultPosition={{ x: 450, y: 0 }}>
          <div style={containerStyle}>
            <button
              type="button"
              style={configButtonStyle}
              onClick={() => this.setState({ addDialog: true })}
            >
              <PlusIcon />
            </button>
            {this.state.labelTemplates.length > 0 ? (
              <ButtonGroup>
                {this.state.labelTemplates.map((lt) => {
                  const ltKey = labelTemplateKey(lt);
                  return (
                    <Button
                      size="small"
                      onClick={() => this.onLabelClick(lt)}
                    >
                      <div style={popoverContentStyle}>
                        {lt.value}
                        <h5>{formatLabelOption(lt)}</h5>
                        <p>{lt.signals.join(', ')}</p>
                        <Button
                          onClick={() => this.removeLabelTemplate(ltKey)}
                        >
                          Remove
                        </Button>
                      </div>
                    </Button>
                  );
                })}
              </ButtonGroup>
            ) : (
              <span>No label configured</span>
            )}
          </div>
        </Draggable>
        {this.renderAddDialog()}
      </>
    );
  }
}

export default connect((state) => {
  const ts = state.timeSeries;
  return {
    recordID: _.get(ts, 'recordInfo.id', ''),
    selectedSignals: ts.selectedSignals,
    selection: ts.selection,
    stagingEnabled: ts.staging.enabled,
    signals: ts.signals,
  };
})(LabelToolboxWrapper);
