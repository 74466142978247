/* eslint-disable prefer-rest-params */
import Immutable from 'immutable';

export const defaultWindow = 30000;
const defaultThreshold = 1000;

function Range(
  start = 0,
  end = 0,
  window = defaultWindow,
  threshold = defaultThreshold,
  current = 0,
) {
  if (arguments.length === 1 && typeof arguments[0] === 'object') {
    // eslint-disable-next-line prefer-destructuring
    this.r = arguments[0];
  } else {
    const l = end - start;
    this.r = Immutable.Map({
      start,
      end,
      window: Math.min(l, window),
      threshold: Math.min(l, threshold),
      current: Math.max(current, start),
    });
  }
}

function simpleRange(start, end) {
  return {
    start,
    end,
  };
}

function limitNext(value, start, end, window) {
  return Math.trunc(Math.min(Math.max(value, start), end - window));
}

Range.prototype = {
  get start() {
    return this.r.get('start');
  },

  get end() {
    return this.r.get('end');
  },

  get length() {
    return this.end - this.start;
  },

  get window() {
    return this.r.get('window');
  },

  get threshold() {
    return this.r.get('threshold');
  },

  get current() {
    return this.r.get('current');
  },

  range() {
    const {
      current,
    } = this;
    return simpleRange(current, current + this.window);
  },

  update(p) {
    const nextValue = limitNext(p, this.start, this.end, this.window);
    return new Range(this.r.set('current', nextValue));
  },

  diff(p) {
    const {
      current,
    } = this;
    const {
      window,
    } = this;
    const nextValue = limitNext(p, this.start, this.end, window);
    if (nextValue === current) {
      return {
        removed: simpleRange(0, 0),
        added: simpleRange(0, 0),
      };
    }
    if (nextValue > current) {
      const currentEnd = current + window;
      return {
        removed: simpleRange(current, Math.min(currentEnd, nextValue)),
        added: simpleRange(Math.max(nextValue, currentEnd), nextValue + window),
      };
    }
    const nextEnd = nextValue + window;
    return {
      removed: simpleRange(Math.max(current, nextEnd), current + window),
      added: simpleRange(nextValue, Math.min(current, nextEnd)),
    };
  },

  updateThreshold(t) {
    return new Range(this.r.set('threshold', Math.min(t, this.length)));
  },

  updateWindow(nextPosition, w) {
    const r = this.r.withMutations((ra) => ra.set('current', nextPosition).set('window', w));
    return new Range(r);
  },

  currentTime() {
    return new Date(this.current);
  },

  currentPosition() {
    return (this.current - this.start) / (this.end - this.start);
  },

  computePosition(p) {
    const nextPosition = Math.min(Math.max(p, 0), 1);
    return this.start + nextPosition * (this.end - this.start);
  },

  inRange(p) {
    return p >= this.current && p < this.current + this.window;
  },

  centerOn(p) {
    return new Range(
      this.r.set('current', Math.max(this.start, p - this.window / 2)),
    );
  },
};

export default function range(start, end, window, threshold, current) {
  return new Range(start, end, window, threshold, current);
}