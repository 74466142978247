/* eslint-disable react/prop-types */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable max-classes-per-file */
import React, { Component } from 'react';
import _ from 'lodash';
import { Form } from 'antd';
import { v4 as uuidv4 } from 'uuid';
import Dialog from '../../../../shared/dialog/Dialog';
import styles from './ScaleConfigDialog.module.scss';
import Button from '../../../../shared/button/Button';
import ColorPicker from '../../../../shared/colorPicker/ColorPicker';

const scaleInputFieldOptions = {
  valuePropName: 'value',
  trigger: 'onUpdate',
  validateTrigger: 'onUpdate',
  getValueFromEvent: _.identity,
};

class ScaleInput extends Component {
  constructor(props) {
    super(props);
    this.updateMax = this.updateMax.bind(this);
    this.updateMin = this.updateMin.bind(this);
    this.updateColor = this.updateColor.bind(this);
    this.applyToDomain = this.applyToDomain.bind(this);
  }

  applyToDomain() {
    if (this.props.applyToDomain) {
      this.props.applyToDomain(this.props.value);
    }
  }

  updateMax(v) {
    if (this.props.onUpdate) {
      const val = this.props.value;
      this.props.onUpdate({
        ...val,
        domain: { ...val.domain, max: v.target.value },
      });
    }
  }

  updateMin(v) {
    if (this.props.onUpdate) {
      const val = this.props.value;
      this.props.onUpdate({
        ...val,
        domain: { ...val.domain, min: v.target.value },
      });
    }
  }

  updateColor(e) {
    if (this.props.onUpdate) {
      this.props.onUpdate({
        ...this.props.value,
        color: e,
      });
    }
  }

  render() {
    const v = this.props.value;
    const d = v.domain;
    return (
      <div className={styles.scaleInput}>
        <h3>{this.props.label}</h3>
        <div>
          <input
            type="number"
            step="0.1"
            value={d.min}
            onChange={this.updateMin}
          />
          <input
            type="number"
            step="0.1"
            value={d.max}
            onChange={this.updateMax}
          />
          <button type="button" onClick={this.applyToDomain}>Apply to domain</button>
          <ColorPicker
            value={_.get(v, 'color', '#000')}
            onChange={this.updateColor}
          />
        </div>
      </div>
    );
  }
}

class ScaleConfigDialog extends Component {
  constructor(props) {
    super(props);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.applyToDomain = this.applyToDomain.bind(this);
  }

  handleSubmit(e) {
    e.preventDefault();
    this.props.form.validateFields((err, fields) => {
      if (!err) {
        const scaleConfig = {};
        const colorConfig = {};
        Object.keys(fields).forEach((k) => {
          const value = fields[k];
          scaleConfig[k] = value.domain;
          colorConfig[k] = value.color;
        });
        this.props.applyScaleConfig(scaleConfig, colorConfig);
      }
    });
  }

  applyToDomain(d) {
    const currentFields = this.props.form.getFieldsValue();
    const nextFields = {};
    Object.keys(currentFields).forEach((k) => {
      const c = currentFields[k];
      if (c.domain.name === d.domain.name) {
        nextFields[k] = { ...c, domain: { ...d.domain } };
      } else {
        nextFields[k] = c;
      }
    });
    this.props.form.setFieldsValue(nextFields);
  }

  contentRender() {
    return (
      <div className={styles.container}>
        <form onSubmit={this.handleSubmit}>{this.renderItems()}</form>
      </div>
    );
  }

  renderItems() {
    const { getFieldDecorator } = this.props.form;
    return this.props.signals.map((signal) => (
      <div key={`${signal.path}_${uuidv4}`}>
        {getFieldDecorator(signal.path, scaleInputFieldOptions)(
          <ScaleInput label={signal.path} applyToDomain={this.applyToDomain} />,
        )}
      </div>
    ));
  }

  render() {
    const footer = [
      <Button
        type="button"
        onClick={this.props.closeDialog}
        text="Cancel"
        className={styles.footerButton}
        cancel
      />,
      <Button
        type="button"
        onClick={this.handleSubmit}
        text="Apply"
        className={styles.footerButton}
      />,
    ];
    return (
      <Dialog
        display
        title="Configure Scales"
        content={this.contentRender()}
        handleCloseDialog={() => this.props.closeDialog()}
        footer={footer}
      />
    );
  }
}

function mapPropsToFields(props) {
  const res = {};
  const cfg = props.configuration;
  _.forEach(props.signals, (signal) => {
    const p = signal.path;
    const color = _.get(cfg, ['colorConfig', p], '#000');
    const domain = _.get(cfg, ['scaleConfig', p]);
    res[signal.path] = Form.createFormField({
      value: {
        color,
        domain: domain != null ? domain : { ...signal.domain },
      },
    });
  });
  return res;
}

export default Form.create({ mapPropsToFields })(ScaleConfigDialog);
