/* eslint-disable no-shadow */
import Immutable from 'immutable';

export const lt = {
  NEURO: 'Neuro',
  CARDIO: 'Cardio',
  EOG: 'EOG',
  LOW_FREQUENCY: 'Low frequency',
  PERIODIC: 'Periodic',
  BURSTS: 'Bursts',
  ENVELOPE: 'Envelope',
  EVENTS: 'Events',
  PATTERNS: 'Patterns',
  QUALITY: 'Quality',
  SLEEP_APNEA: 'Sleep Apnea',
  OTHER: 'Other',
  RELAX: 'Relax',
  MARKER: 'Marker',
  ALGO: 'Algo',
  SIGNAL: 'Signal',
};

export const labelTypes = Object.keys(lt).map((k) => lt[k]);

function label(value, type) {
  return {
    value,
    type,
  };
}

export const labelsList = [
  // Neuro
  label('Alpha', lt.NEURO),
  label('Theta', lt.NEURO),
  label('SO', lt.NEURO),
  label('Spindle', lt.NEURO),
  label('K Complex', lt.NEURO),
  label('Saw', lt.NEURO),
  label('Beta', lt.NEURO),
  // Cardio
  label('Pulse artefacts', lt.CARDIO),
  label('Small spikes', lt.CARDIO),
  label('Big spikes', lt.CARDIO),
  label('Harmonics only', lt.CARDIO),
  // EOG
  label('REM', lt.EOG),
  label('Blinks', lt.EOG),
  label('Eyes fluttering', lt.EOG),
  label('Slow roll', lt.EOG),
  label('Lateral eyes movements', lt.EOG),
  label('Eyes opened', lt.EOG),
  label('Eyes closed', lt.EOG),
  // Low frequency
  label('GSR', lt.LOW_FREQUENCY),
  label('Respiration', lt.LOW_FREQUENCY),
  label('Baseline shift', lt.LOW_FREQUENCY),
  label('Electrostatic influence', lt.LOW_FREQUENCY),
  label('Other', lt.LOW_FREQUENCY),
  // Periodic
  label('Harmonics', lt.PERIODIC),
  label('No harmonics', lt.PERIODIC),
  // Bursts
  label('Irregular spikes', lt.BURSTS),
  label('Electrode popping', lt.BURSTS),
  label('Codec ON', lt.BURSTS),
  label('Codec OFF', lt.BURSTS),
  label('BC', lt.BURSTS),
  // Envelope
  label('5s', lt.ENVELOPE),
  label('100Hz', lt.ENVELOPE),
  label('50Hz', lt.ENVELOPE),
  label('Bulb', lt.ENVELOPE),
  // Events
  label('Quality', lt.EVENTS),
  label('Bad quality', lt.EVENTS),
  label('Detachment', lt.EVENTS),
  label('Short circuit', lt.EVENTS),
  label('HB Off', lt.EVENTS),
  label('Reading', lt.EVENTS),
  label('Pause pipi', lt.EVENTS),
  label('Bad contact', lt.EVENTS),
  label('Bad wiring', lt.EVENTS),
  label('Movement', lt.EVENTS),
  label('Off Head', lt.EVENTS),
  label('Stim Waking Up', lt.EVENTS),
  label('Want to Sleep', lt.EVENTS),
  label('Fall asleep', lt.EVENTS),
  label('Before Bed', lt.EVENTS),
  label('Wake At Night', lt.EVENTS),
  label('Wake up', lt.EVENTS),
  label('Micro Arousals', lt.EVENTS),
  label('Hardware artefact', lt.EVENTS),
  // Patterns
  label('Square', lt.PATTERNS),
  label('Triangle', lt.PATTERNS),
  label('EMG', lt.PATTERNS),
  label('Noisy', lt.PATTERNS),
  // Quality
  label('Good Quality', lt.QUALITY),
  label('Saturation', lt.QUALITY),
  label('Low frequency', lt.QUALITY),
  label('Cardio', lt.QUALITY),
  label('Peaks', lt.QUALITY),
  label('Harmonics', lt.QUALITY),
  label('Stabilization', lt.QUALITY),
  label('Fpz detachment', lt.QUALITY),
  label('F7 detachment', lt.QUALITY),
  label('F8 detachment', lt.QUALITY),
  label('O1 detachment', lt.QUALITY),
  label('O2 detachment', lt.QUALITY),
  label('Fp1 detachment', lt.QUALITY),
  label('Fp2 detachment', lt.QUALITY),
  label('M1 detachment', lt.QUALITY),
  label('M2 detachment', lt.QUALITY),
  label('Off-head', lt.QUALITY),
  label('ppg_infrared_quality', lt.QUALITY),
  label('Other', lt.QUALITY),
  // Other
  label('Unknown', lt.OTHER),
  label('Fake N3', lt.OTHER),
  label('New', lt.OTHER),
  label('Cardio', lt.OTHER),
  // Sleep Apnea
  label('Obstructive', lt.SLEEP_APNEA),
  label('Central', lt.SLEEP_APNEA),
  label('Mixed', lt.SLEEP_APNEA),
  label('Hypopnea', lt.SLEEP_APNEA),
  label('Effort Related Arousal', lt.SLEEP_APNEA),
  // Relax
  label('S1', lt.RELAX),
  label('S2', lt.RELAX),
  label('S3', lt.RELAX),
  label('S4', lt.RELAX),
  label('Focused', lt.RELAX),
  label('Not focused', lt.RELAX),
  label('Meditating', lt.RELAX),
  // Algo
  label('N1', lt.ALGO),
  label('N2', lt.ALGO),
  label('N3', lt.ALGO),
  label('REM', lt.ALGO),
  label('Wake', lt.ALGO),
  // Signals
  label('0', lt.SIGNAL),
  label('1', lt.SIGNAL),
  label('2', lt.SIGNAL),
  label('3', lt.SIGNAL),
];

export const labelValues = labelsList.map((l) => l.value);

const labelOptionSeparator = '|';

export function formatLabelOption(l) {
  return `${l.type}${labelOptionSeparator}${l.value}`;
}

export const labelOptions = labelsList.map((l) => ({
  value: formatLabelOption(l),
}));

export function parseLabelOption(value) {
  const splits = value.split(labelOptionSeparator);
  if (splits.length !== 2) {
    console.error('Invalid label option');
    return {
      type: 'Invalid',
      value: 'Invalid',
    };
  }
  return {
    type: splits[0],
    value: splits[1],
  };
}

function Position(x, x2, y) {
  this.x = x;
  this.x2 = x2;
  this.y = y;
}

Position.prototype = {
  length() {
    return Math.max(0, this.x2 - this.x);
  },
  offset(value) {
    this.x += value;
    this.x2 += value;
  },
};

function intersect(r1, r2) {
  return r1.start < r2.end && r2.start < r1.end;
}

function newPosition(l, y, xScale) {
  return Object.assign(
    new Position(xScale(new Date(l.start)), xScale(new Date(l.end)), y),
    l,
  );
}

function Labels(...args) {
  if (arguments.length === 2) {
    const labels = args[0] != null ? args[0].sort((a) => a.start) : [];
    this.s = Immutable.Map({
      labels,
      filters: args[1] != null ? args[1] : {},
      selectedID: labels.length > 0 ? labels[0].id : '',
      lastAdded: null,
      edit: false,
      commitQueue: [],
      channelLayout: false,
    });
  } else {
    // eslint-disable-next-line prefer-destructuring
    this.s = args[0];
  }
}

function getRangeFilter(range) {
  if (range == null) {
    return true.constant();
  }
  // eslint-disable-next-line func-names
  return function (l) {
    return intersect(l, range);
  };
}

function singleSignalFilter(l) {
  return l.signals.length === 1;
}

let editingID = 1;

function copyLabel(source, newStart, newEnd, state) {
  return {
    // eslint-disable-next-line no-plusplus
    editingID: editingID++,
    ...source,
    start: newStart,
    end: newEnd,
    state,
  };
}

function createIntersectGroups(visible) {
  const res = [];
  // eslint-disable-next-line no-plusplus
  for (let i = 0; i < visible.length; i++) {
    const v = visible[i];
    const r = res.find((r) => intersect(v, r));
    if (r != null) {
      r.start = Math.min(r.start, v.start);
      r.end = Math.max(r.end, v.end);
      r.group.push(v);
    } else {
      res.push({
        start: v.start,
        end: v.end,
        group: [v],
      });
    }
  }
  return res;
}

function comparator(c1, c2) {
  if (c1.editingID != null && c2.editingID == null) {
    return 1;
  }
  if (c1.editingID == null && c2.editingID != null) {
    return -1;
  }
  if (c1.editingID == null && c2.editingID == null) {
    return c1.updated > c2.updated ? 1 : -1;
  }
  if (c1.editingID != null && c2.editingID != null) {
    return c1.editingID > c2.editingID ? 1 : -1;
  }
  return null;
}

export function cleanDuplicates(visible) {
  if (visible.length < 2) {
    return visible;
  }
  const groups = createIntersectGroups(visible);
  return groups.flatMap((g) => {
    let lastUpdated = g.group[0];
    // eslint-disable-next-line no-plusplus
    for (let i = 1; i < g.group.length; i++) {
      const c = comparator(lastUpdated, g.group[i]);
      if (c === -1) {
        lastUpdated = g.group[i];
      }
    }
    return g.group.filter(
      (l) => l === lastUpdated || !intersect(lastUpdated, l),
    );
  });
}

Labels.prototype = {
  get labels() {
    return this.s.get('labels');
  },

  get filters() {
    return this.s.get('filters');
  },

  get edit() {
    return this.s.get('edit');
  },

  toggleEdit() {
    return new Labels(this.s.set('edit', !this.s.get('edit')));
  },

  get selectedID() {
    return this.s.get('selectedID');
  },

  get selectedLabel() {
    const id = this.selectedID;
    return this.labels.find((l) => l.id === id);
  },

  get lastAdded() {
    return this.s.get('lastAdded');
  },

  get commitQueue() {
    return this.s.get('commitQueue');
  },

  get channelLayout() {
    return this.s.get('channelLayout');
  },

  toggleChannelLayout() {
    return new Labels(
      this.s.set('channelLayout', !this.s.get('channelLayout')),
    );
  },

  update(ul) {
    const {
      labels,
    } = this;
    const i = labels.findIndex((l) => l.id === ul.id);
    if (i === -1) {
      labels.push(ul);
      const newLabels = labels.sortBy((l) => l.start);
      const updated = this.s.withMutations((s) => s
        .set('labels', newLabels)
        .set('selectedID', ul.id)
        .set('lastAdded', ul));
      return new Labels(updated);
    }
    const updated = labels.slice(0);
    updated[i] = ul;
    return new Labels(this.s.set('labels', updated));
  },

  insert(l) {
    if (l.length === 0) {
      return this;
    }
    const newLabels = this.labels.concat(l).sort((l) => l.start);
    return new Labels(this.s.set('labels', newLabels));
  },

  remove(dl) {
    const {
      labels,
    } = this;
    const i = labels.findIndex((l) => l.id === dl.id);
    if (i !== -1) {
      const updated = labels.slice(0);
      updated.splice(i, 1);
      const newSelected = updated[Math.min(updated.length - 1, i)];
      return new Labels(
        this.s.withMutations((s) => s
          .set('labels', updated)
          .set('selectedID', newSelected != null ? newSelected.id : '')),
      );
    }
    return this;
  },

  selectNext() {
    const currentSelected = this.selectedID;
    const {
      labels,
    } = this;
    if (labels.length === 0) {
      return this;
    }
    const s = labels.findIndex((l) => l.id === currentSelected);
    const currentLabel = labels[s];
    if (
      this.channelLayout
      && currentLabel.signals.length === 1
      && s < labels.length - 1
    ) {
      const currentChannel = currentLabel.signals[0];
      const nextOnChannel = labels.find(
        (l) => l.signals.length === 1 && l.signals[0] === currentChannel,
        s + 1,
      );
      return nextOnChannel != null
        ? new Labels(this.s.set('selectedID', nextOnChannel.id))
        : this;
    }
    if (s < 0) {
      return new Labels(this.s.set('selectedID', labels[0].id));
    }
    if (s < labels.length - 1) {
      return new Labels(this.s.set('selectedID', labels[s + 1].id));
    }
    return this;
  },

  selectPrevious() {
    const currentSelected = this.selectedID;
    const {
      labels,
    } = this;
    if (labels.length === 0) {
      return this;
    }
    const s = labels.findIndex((l) => l.id === currentSelected);
    const currentLabel = labels[s];
    if (this.channelLayout && currentLabel.signals.length === 1 && s > 0) {
      const currentChannel = currentLabel.signals[0];
      const previousOnChannel = labels.findLast(
        (l) => l.signals.length === 1 && l.signals[0] === currentChannel,
        s - 1,
      );
      return previousOnChannel != null
        ? new Labels(this.s.set('selectedID', previousOnChannel.id))
        : this;
    }
    if (s <= 0) {
      return new Labels(this.s.set('selectedID', labels[0].id));
    }
    if (s > 0) {
      return new Labels(this.s.set('selectedID', labels[s - 1].id));
    }
    return this;
  },

  select(id) {
    return new Labels(this.s.set('selectedID', id));
  },

  enqueueCommit(l) {
    return new Labels(
      this.s.set(
        'commitQueue',
        // eslint-disable-next-line no-plusplus
        this.commitQueue.concat({
          // eslint-disable-next-line no-plusplus
          editingID: editingID++,
          ...l,
        }),
      ),
    );
  },

  clearCommitQueue() {
    return new Labels(this.s.set('commitQueue', []));
  },

  getLastCommited() {
    const g = this.commitQueue.groupBy((l) => l.id);
    return Object.keys(g).map((k) => g[k].last());
  },

  commit() {
    if (this.commitQueue.length === 0) {
      return [];
    }
    const allEdited = this.getLastCommited();
    return allEdited.flatMap((edited) => {
      const res = [];
      const pr = this.labels.find((l) => l.id === edited.id);
      if (edited.start > pr.end || edited.end < pr.start) {
        res.push(copyLabel(pr, pr.start, pr.end, !pr.state));
        res.push(copyLabel(pr, edited.start, edited.end, pr.state));
        return res;
      }
      const endDiff = edited.end - pr.end;
      const startDiff = edited.start - pr.start;
      if (startDiff === 0 && endDiff === 0) {
        return res;
      }
      if (startDiff === 0) {
        if (endDiff > 0) {
          res.push(copyLabel(pr, pr.start, edited.end, pr.state));
        } else {
          res.push(copyLabel(pr, pr.start, edited.end, pr.state));
          res.push(copyLabel(pr, edited.end, pr.end, !pr.state));
        }
      }
      if (endDiff === 0) {
        if (startDiff > 0) {
          res.push(copyLabel(pr, pr.start, edited.start, !pr.state));
          res.push(copyLabel(pr, edited.start, pr.end, pr.state));
        } else {
          res.push(copyLabel(pr, edited.start, pr.end, pr.state));
        }
      }
      if (startDiff > 0 && endDiff < 0) {
        res.push(copyLabel(pr, pr.start, edited.start, !pr.state));
        res.push(copyLabel(pr, edited.start, edited.end, pr.state));
        res.push(copyLabel(pr, edited.end, pr.end, !pr.state));
      }
      if (startDiff < 0 && endDiff < 0) {
        res.push(copyLabel(pr, edited.start, edited.end, pr.state));
        res.push(copyLabel(pr, edited.end, pr.end, !pr.state));
      }
      if (startDiff > 0 && endDiff > 0) {
        res.push(copyLabel(pr, pr.start, edited.start, !pr.state));
        res.push(copyLabel(pr, edited.start, edited.end, pr.state));
      }
      if (startDiff < 0 && endDiff > 0) {
        res.push(copyLabel(pr, edited.start, edited.end, pr.state));
      }
      return res;
    });
  },

  labelsForDisplay(range) {
    const visible = this.labels.filter(getRangeFilter(range));
    const lastEdited = this.getLastCommited();
    const placeholder = [];
    lastEdited.forEach((l) => {
      const index = visible.findIndex((v) => v.id === l.id);
      if (index !== -1) {
        // eslint-disable-next-line no-param-reassign
        l.edited = true;
        placeholder.push(visible[index]);
        visible[index] = l;
      }
    });

    const c = this.commit();
    // eslint-disable-next-line no-plusplus
    for (let i = 0; i < c.length; i++) {
      const original = lastEdited.find((l) => l.id === c[i].id);
      if (c[i].state !== original.state) {
        visible.push(c[i]);
      }
    }
    return {
      visible: visible.sortBy((l) => l.start),
      placeholder: placeholder.sortBy((l) => l.start),
    };
  },

  labelsForChannelDisplay(range, series) {
    const res = series.map((s) => ({
      signal: s.signal.path,
      visible: [],
      placeholder: [],
    }));
    this.labels
      .filter(getRangeFilter(range))
      .filter(singleSignalFilter)
      .forEach((l) => res.find((r) => r.signal === l.signals[0]).visible.push(l));

    const lastEdited = this.getLastCommited();
    lastEdited.forEach((l) => {
      res.forEach((r) => {
        const index = r.visible.findIndex((v) => v.id === l.id);
        if (index !== -1) {
          // eslint-disable-next-line no-param-reassign
          l.edited = true;
          r.placeholder.push(r.visible[index]);
          // eslint-disable-next-line no-param-reassign
          r.visible[index] = l;
          return false;
        }
        return true;
      });
    });

    const c = this.commit();
    // eslint-disable-next-line no-plusplus
    for (let i = 0; i < c.length; i++) {
      const original = lastEdited.find((l) => l.id === c[i].id);
      if (c[i].state !== original.state) {
        res.find((r) => r.signal === c[i].signals[0]).visible.push(c[i]);
      }
    }
    res.forEach((r) => {
      // eslint-disable-next-line no-param-reassign
      r.visible = cleanDuplicates(r.visible.sortBy((l) => l.start));
      // eslint-disable-next-line no-param-reassign
      r.placeholder = cleanDuplicates(r.placeholder.sortBy((l) => l.start));
    });
    return res;
  },

  getUsers() {
    return this.labels
      .uniqBy((l) => l.user)
      .map((l) => l.userInfo);
  },
};

export function labelsLayout(visible, placeholder, xScale, baseY, labelHeight) {
  if (visible.length === 0) {
    return {
      visible: [],
      placeholder: [],
    };
  }
  const bins = [{
    y: baseY,
    content: [visible[0]],
  }];
  // eslint-disable-next-line no-plusplus
  for (let i = 1; i < visible.length; i++) {
    let placed = false;
    // eslint-disable-next-line no-plusplus
    for (let j = 0; j < bins.length; j++) {
      if (visible[i].start > bins[j].content.last().end) {
        bins[j].content.push(visible[i]);
        placed = true;
        break;
      }
    }
    if (!placed) {
      bins.push({
        y: bins.last().y - labelHeight - 1,
        content: [visible[i]],
      });
    }
  }
  return {
    visible: bins.flatMap((b) => b.content.map((l) => newPosition(l, b.y, xScale))),
    placeholder: placeholder.map((l) => newPosition(l, 0, xScale)),
  };
}

export default function labels(l = [], filters = {}) {
  return new Labels(l, filters);
}