import moment from 'moment-timezone';

const defaultTimeFormat = 'HH:mm:ss ZZ';
const defaultTimestampFormat = 'HH:mm:ss';

export function formatTime(time, timezone, format = defaultTimeFormat) {
  const m = moment(time);
  if (timezone != null) {
    if (timezone.includes(':')) {
      m.utcOffset(timezone);
    } else {
      m.tz(timezone);
    }
  }
  return m.format(format);
}

export function timestampFormatter(timezone, format = defaultTimestampFormat) {
  return function (t) {
    return formatTime(t, timezone, format);
  };
}

function formatWithUnit(t, ext) {
  if (t === 0) {
    return '';
  }
  return t < 10 ? `0${t}${ext}` : `${t}${ext}`;
}

function absFloor(n) {
  return n < 0 ? Math.ceil(n) || 0 : Math.floor(n);
}

export function formatSimpleDuration(dur) {
  if (dur < 1000) {
    return '< 1s';
  }
  const s = absFloor(dur / 1000);
  const m = absFloor(s / 60);
  const h = absFloor(m / 60);
  return (
    formatWithUnit(h % 24, 'h')
    + formatWithUnit(m % 60, 'm')
    + formatWithUnit(s % 60, 's')
  );
}
