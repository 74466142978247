export const GROUP_TYPES = {
  SINGLEPLOT: 'SinglePlot',
  COMPARISON: 'Comparison',
  MULTIPLOT: 'MultiPlot',
};

const Group = {
  findSeries(series) {
    return series.filter((s) => this.ids.includes(s.id));
  },
};

function group(series) {
  const res = Object.create(Group);
  if (Array.isArray(series)) {
    const signals = series.map((s) => s.signal);
    const ids = series.map((s) => s.id);
    const id = series.reduce((sid, s) => `${sid}${s}`);
    return Object.assign(res, {
      signals,
      ids,
      id,
    });
  }
  const serie = series;
  return Object.assign(res, {
    signals: [serie.signal],
    ids: [serie.id],
    id: serie.id,
  });
}

export default function selectGroups(type = GROUP_TYPES.MULTIPLOT) {
  const groups = [];
  return {
    addGroup(series) {
      groups.push(group(series));
    },
    mapGroup(series) {
      series.forEach((s) => groups.push(group(s)));
    },
    get list() {
      return groups;
    },
    type,
  };
}