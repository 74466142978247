import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment-timezone';
import styles from './Records.module.scss';
import LoadingIcon from '../../../shared/loadingIcon/LoadingIcon';
import { fetchDevices, fetchRecords, fetchUser } from './actions';
import Table from './table/Table';
// import greenDot from '../../../../assets/images/icons/green_dot.png';
// import yellowDot from '../../../../assets/images/icons/yellow_dot.png';
// import orangeDot from '../../../../assets/images/icons/orange_dot.png';
// import redDot from '../../../../assets/images/icons/red_dot.png';
import { ReactComponent as Chevron } from '../../../../assets/images/icons/chevron.svg';

const defaultDateFormatNoTz = 'DD/MM/YY HH[H]mm';

function Records(props) {
  const {
    selected,
  } = props;

  const [records, setRecords] = useState([]);
  const [user, setUser] = useState(null);
  const [devices, setDevices] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    async function setupUser() {
      const r = await (fetchRecords(selected));
      if (r.results && r.results.length) {
        setRecords(r.results);
        const devicesToFetch = [];
        r.results.forEach((record) => {
          if (!devicesToFetch.includes(record.device)) devicesToFetch.push(record.device);
        });
        const d = await (fetchDevices(devicesToFetch));
        setDevices(d);
      }
      const u = await (fetchUser(selected));
      if (u) setUser(u);
    }
    setRecords([]);
    setDevices([]);
    setUser(null);
    setLoading(true);
    if (selected) {
      setupUser()
    }
    setLoading(false);
  }, [selected]);

  const devicesMap = devices.length && devices.map((d, i) => (
    <span key={d.id}>{`${i === 0 ? 'Device : ' : ''}${d.nickname}${i < devices.length - 1 ? ', ' : ''}`}</span>
  ));

  const columns = [
    {
      title: 'uuid short',
      render: (r) => {
        if (r.record) {
          const uuidSplited = r.record.split('-');
          if (uuidSplited[0]) {
            return uuidSplited[0];
          }
        }
        return 'N/A';
      },
    },
    {
      title: 'Duration',
      render: (r) => {
        if (r.endpoints && r.endpoints.record_duration) {
          let hours = parseInt(Number(r.endpoints.record_duration) / 60 / 60, 10);
          let minutes = parseInt(
            (Number(r.endpoints.record_duration) - hours * 60 * 60) / 60,
            10,
          );
          if (hours < 10) hours = `0${hours}`;
          if (minutes < 10) minutes = `0${minutes}`;
          return `${hours}H${minutes}`;
        }
        return 'N/A';
      },
    },
    {
      title: 'Start',
      render: (r) => {
        if (
          (r.endpoints && r.endpoints.record_start_iso)
        ) {
          const originalDate = r.endpoints.record_start_iso;
          const dateRender = moment(originalDate.slice(0, -6)).format(
            defaultDateFormatNoTz,
          );
          if (originalDate.includes('+') || originalDate.includes('-')) {
            return `${dateRender} ${originalDate.slice(-6).replace(':', '')}`;
          }
          return dateRender;
        }
        return 'N/A';
      },
    },
    {
      title: 'End',
      render: (r) => {
        if (r.endpoints && r.endpoints.record_start_iso) {
          const originalDate = r.endpoints.record_start_iso;
          const dateRender = moment(
            new Date(originalDate.slice(0, -6)).setTime(
              new Date(originalDate.slice(0, -6)).getTime()
              + r.endpoints.record_duration * 1000,
            ),
          ).format(defaultDateFormatNoTz);
          if (originalDate.slice(-6)) {
            return `${dateRender} ${originalDate.slice(-6).replace(':', '')}`;
          }
          return dateRender;
        }
        return 'N/A';
      },
    },
    {
      title: 'Alerts',
      render: (r) => {
        function durationAlert() {
          if (r
            && r.endpoints
            && r.endpoints.record_duration
            && r.endpoints.record_duration / 60 < 60) {
            return (
              // <img src={yellowDot} alt="" />
              'yellowDot'
            );
          }
          return null;
        }
        function noDataAlert() {
          if (Math.random() < 0.5) {
            return (
              // <img src={orangeDot} alt="" />
              'orangeDot'
            );
          }
          return null;
        }
        function qualityAlert() {
          if (Math.random() < 0.5) {
            return (
              // <img src={redDot} alt="" />
              'redDot'
            );
          }
          return null;
        }
        function counter() {
          let count = 0;
          if (durationAlert()) count += 1;
          if (noDataAlert()) count += 1;
          if (qualityAlert()) count += 1;
          if (count) {
            return count;
          }
          return 'None';
        }
        return (
          <div className={styles.alertsColumn}>
            {durationAlert()}
            {noDataAlert()}
            {qualityAlert()}
            {!durationAlert() && !noDataAlert() && !qualityAlert() && ('greenDot')}
            <span>{counter()}</span>
          </div>
        );
      },
    },
    {
      render: () => (
        <div className={styles.fullRecordColumn}>
          <span>Full record</span>
          <Chevron
            width="16px"
            fill="#C0BAEE"
            style={{ marginRight: '1rem' }}
            transform=""
          />
        </div>
      ),
    },
  ];

  return (
    <div>
      {loading ? (
        <div style={{
          height: '100vh',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}
        >
          <LoadingIcon />
        </div>
      ) : (
        <div className={styles.container}>
          <div className={styles.header}>
            <h3 className={styles.email}>
              {user && user.email ? user.email : user && user.pseudo && user.pseudo}
            </h3>
            <div className={styles.badgeRow}>
              {devices.length && <span className={styles.deviceBadge}>{devicesMap}</span>}
            </div>
          </div>
          <Table
            columns={columns}
            rows={records}
          />
        </div>
      )}
    </div>
  );
}

Records.defaultProps = {
  selected: null,
};

Records.propTypes = {
  selected: PropTypes.string,
};

export default Records;
