export const storageKey = {
  username: 'dreem-viewer-username',
  token: 'dreem-viewer-token',
  user: 'dreem-viewer-user',
  role: 'dreem-viewer-role',
};

export const adminRoles = ['team', 'study_admin'];

export const timeSeriesConfig = {
  toolbarToolboxes: 'dreem-viewer-toolbar-toolboxes',
  labelToolbox: 'dreem-viewer-label-toolbox-v2',
  profile: 'dreem-viewer-profile',
};

export const backendURL = () => window.env.REACT_APP_API_URI;
export const headbandURL = () => window.env.REACT_APP_HEADBAND_URI;
export const loginURL = () => window.env.REACT_APP_LOGIN_URI;

export const algorythmUser = '948db4f0-e242-4456-9cdb-bf71705f896d';

const stagingPrefix = `${backendURL()}/algorythm`;

export const defaultAPI = {
  report: `${backendURL()}/record/report/details`,
  label: `${backendURL()}/algorythm/label`,
  sample: `${backendURL()}/datasample`,
  staging: {
    hypnogram: `${backendURL()}/record/record`,
    eeg: `${stagingPrefix}/hypnogram`,
    sleepOnset: `${stagingPrefix}/sleep_onset_scoring`,
  },
  record: `${backendURL()}/algorythm/record`,
  user: `${backendURL()}/dreemer/dreemer`,
  headband: `${headbandURL()}/headband/headband`,
};

export const defaultFeaturesConfig = {
  labelThumbnail: true,
  positionogram: true,
  spectrogram: false,
  stimulations: true,
  recordDetailsLink: true,
  algorythmOverview: true,
  seriesFilters: false,
  windowRead: false,
  physioMetrics: false,
};
