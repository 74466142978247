import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import styles from './DownloadMenu.module.scss';
import { ReactComponent as Chevron } from '../../../assets/images/icons/chevron.svg';
import {
  downloadH5,
  downloadRAW,
  downloadEDF,
  downloadHypnogram,
  openURL,
  downloadReportCSV,
} from './actions';
import LoadingIcon from '../loadingIcon/LoadingIcon';

function DownloadMenu(props) {
  const {
    disabled,
    selected,
    getRecord,
    disabledButton,
  } = props;
  const componentRef = useRef();
  const inputBarRef = useRef();
  const [focus, setFocus] = useState(false);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    function handleClick(e) {
      if (componentRef && componentRef.current) {
        const ref = componentRef.current;
        if (!ref.contains(e.target)) {
          setFocus(false);
        }
      }
    }
    document.addEventListener('click', handleClick, { capture: true });
    return () => document.removeEventListener('click', handleClick);
  }, []);

  const contentWidth = () => {
    if (inputBarRef && inputBarRef.current && inputBarRef.current.offsetWidth) {
      return `calc(${inputBarRef.current.offsetWidth}px)`;
    }
    return 'unset';
  };

  function Row(rowProps) {
    const {
      rTitle,
      rKey,
      rDisabled,
    } = rowProps;
    const rowClick = () => {
      setLoading(true);
      switch (rKey) {
        case 'handleDownloadH5':
          downloadH5(getRecord(selected)).then((content) => {
            openURL(content);
            setLoading(false);
          });
          break;
        case 'handleDownloadRAW':
          downloadRAW(getRecord(selected)).then(() => {
            setLoading(false);
          });
          break;
        case 'handleDownloadEDF':
          downloadEDF(getRecord(selected)).then(() => {
            setLoading(false);
          });
          break;
        case 'handleDownloadHypnogram':
          downloadHypnogram(getRecord(selected)).then(() => {
            setLoading(false);
          });
          break;
        case 'handleDownloadReportCsv':
          downloadReportCSV(getRecord(selected)).then(() => {
            setLoading(false);
          });
          break;
        default:
          break;
      }
    };
    return (
      <div
        onClick={() => rowClick()}
        onKeyPress={null}
        role="button"
        tabIndex={0}
        className={`${styles.rowButton} ${rDisabled ? styles.disabled : ''}`}
      >
        <span>{rTitle}</span>
      </div>
    );
  }

  return (
    <div className={styles.container} ref={componentRef}>
      <div
        className={`${styles.inputBar} ${focus ? styles.focus : ''} ${disabled || !selected ? styles.disabled : ''}`}
        ref={inputBarRef}
        onClick={() => !disabled && selected && setFocus(!focus)}
        onKeyPress={null}
        role="button"
        tabIndex={0}
      >
        <span>Download</span>
        <Chevron fill="#C0BAEE" transform={focus ? 'rotate(90)' : 'rotate(-90)'} />
      </div>
      {focus && (
        <div className={styles.content} style={{ width: contentWidth() }}>
          <Row rTitle="H5" rKey="handleDownloadH5" rDisabled={disabledButton.h5} />
          <Row rTitle="RAW" rKey="handleDownloadRAW" rDisabled={disabledButton.raw} />
          <Row rTitle="EDF" rKey="handleDownloadEDF" rDisabled={disabledButton.edf} />
          <Row rTitle="Hypnogram text" rKey="handleDownloadHypnogram" rDisabled={disabledButton.hypnogram} />
          <Row rTitle="Report csv" rKey="handleDownloadReportCsv" rDisabled={disabledButton.csv} />
          {loading && (
            <div className={styles.loadingContainer}>
              <LoadingIcon />
            </div>
          )}
        </div>
      )}
    </div>
  );
}

DownloadMenu.defaultProps = {
  disabled: false,
  selected: null,
  getRecord: null,
  disabledButton: {},
};

DownloadMenu.propTypes = {
  disabled: PropTypes.bool,
  selected: PropTypes.string,
  getRecord: PropTypes.func,
  disabledButton: PropTypes.instanceOf(Array),
};

export default DownloadMenu;