import {
  storageKey,
  loginURL,
  backendURL,
} from '../../../constants/globals';

export function parseToken(token) {
  if (token == null || token === '') {
    return null;
  }
  const tokenParts = token.split('.');
  const userInfo = JSON.parse(window.atob(tokenParts[1]));
  const permissions = new Map();
  userInfo.permissions
    .split(';')
    .forEach((p) => {
      const v = p.split('=');
      permissions.set(v[0], v[1]);
    });
  return {
    ...userInfo,
    permissions,
  };
}

function responseHandler(resp) {
  if (!resp.ok) {
    return resp.text().then((msg) => JSON.parse(msg));
  }
  return resp.json();
}

function loginRequest(credentials) {
  return fetch(`${loginURL()}/token/`, {
    method: 'POST',
    headers: {
      Authorization: `Basic ${window.btoa(`${credentials.username}:${credentials.password}`)}`,
    },
  }).then(responseHandler);
}

function clusterRequest(user) {
  return fetch(`${backendURL()}/dreemer/dreemer/${user.id}/pharma_config/`, {
    method: 'GET',
    headers: {
      Authorization: `bearer ${user.token}`,
    },
  }).then(responseHandler);
}

export function login(credentials) {
  return loginRequest(credentials).then((u) => {
    if (u.detail) {
      return {
        status: false,
        msg: u.detail,
      };
    }
    const user = {
      username: credentials.username,
      token: u.token,
      id: u.user_id,
    };
    window.localStorage.setItem(storageKey.username, credentials.username);
    window.localStorage.setItem(storageKey.token, user.token);
    window.localStorage.setItem(storageKey.user, user.id);
    clusterRequest(user).then((uc) => {
      window.localStorage.setItem(storageKey.role, window.btoa(uc.type));
    });
    return {
      status: true,
    };
  });
}

export function logout() {
  Object.keys(storageKey).forEach((k) => window.localStorage.removeItem(storageKey[k]));
}

export function getUserMail() {
  return window.localStorage.getItem('dreem-comisa-username');
}
