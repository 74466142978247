/* eslint-disable import/prefer-default-export */
import {
  backendURL,
  headbandURL,
  jwt,
  getHeaders,
  responseHandler,
  user,
} from '../../../../constants/globals';

export function fetchUser(uuid) {
  const headers = getHeaders(jwt());
  return fetch(`${backendURL()}/dreemer/dreemer/${uuid}/`, {
    headers,
    method: 'GET',
    mode: 'cors',
  }).then(responseHandler);
}

export function fetchDevices(devices) {
  const headers = getHeaders(jwt());
  return fetch(`${headbandURL()}/headband/headband/details/`, {
    headers,
    method: 'POST',
    mode: 'cors',
    body: JSON.stringify({
      id: devices,
    }),
  }).then(responseHandler);
}

export function fetchRecords(uuid) {
  const headers = getHeaders(jwt());
  return fetch(`${backendURL()}/record/restricted_report/?restricted_list_user=${user()}&user=${uuid}`, {
    headers,
    method: 'GET',
    mode: 'cors',
  }).then(responseHandler);
}
