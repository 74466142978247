import React from 'react';
import PropTypes, { func } from 'prop-types';
import styles from './TextField.module.scss';

function TextField(props) {
  const {
    className,
    placeholderClassName,
    inputClassName,
    style,
    placeholderStyle,
    inputStyle,
    type,
    name,
    disabled,
    onChange,
    value,
    label,
    placeholder,
    required,
    onFocus,
    onBlur,
    icon,
    autoComplete,
    onKeyPress,
    ref,
  } = props;

  return (
    <div className={`${styles.container} ${className}`} style={style}>
      <label
        htmlFor={name || 'TextField'}
        className={`${styles.title} ${placeholderClassName}`}
        style={placeholderStyle}
      >
        {label}
        {required && label ? '*' : null}
      </label>
      <div style={{ position: 'relative' }}>
        <input
          id={name || 'TextField'}
          placeholder={placeholder}
          className={`${styles.input} ${inputClassName}`}
          type={type}
          style={inputStyle}
          name={name || 'TextField'}
          disabled={disabled}
          onChange={onChange}
          value={value || ''}
          required={required}
          onFocus={onFocus}
          onBlur={onBlur}
          autoComplete={autoComplete || 'off'}
          ref={ref}
          onKeyPress={onKeyPress}
        />
        {icon ? (
          <div className={styles.icon}>
            {icon}
          </div>
        ) : null}
      </div>
    </div>
  );
}

TextField.defaultProps = {
  className: '',
  placeholderClassName: '',
  inputClassName: '',
  style: {},
  placeholderStyle: {},
  inputStyle: {},
  type: '',
  name: '',
  disabled: false,
  onChange: null,
  value: '',
  label: '',
  placeholder: '',
  required: false,
  onFocus: null,
  onBlur: null,
  icon: null,
  autoComplete: '',
  ref: null,
  onKeyPress: null,
};

TextField.propTypes = {
  className: PropTypes.string,
  placeholderClassName: PropTypes.string,
  inputClassName: PropTypes.string,
  style: PropTypes.instanceOf(Object),
  placeholderStyle: PropTypes.instanceOf(Object),
  inputStyle: PropTypes.instanceOf(Object),
  type: PropTypes.string,
  name: PropTypes.string,
  disabled: PropTypes.bool,
  onChange: PropTypes.func,
  value: PropTypes.string,
  label: PropTypes.string,
  placeholder: PropTypes.string,
  required: PropTypes.bool,
  onFocus: PropTypes.func,
  onBlur: PropTypes.func,
  icon: PropTypes.element,
  autoComplete: PropTypes.string,
  ref: PropTypes.instanceOf(Object),
  onKeyPress: PropTypes.instanceOf(func),
};

export default TextField;