/* eslint-disable react/no-deprecated */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable react/prop-types */
/* eslint-disable no-return-assign */
/* eslint-disable import/no-extraneous-dependencies */
import React, { useCallback, useState, useEffect, useRef } from 'react';
import { select, event } from 'd3-selection';
import { scaleLinear } from 'd3-scale';
import { axisBottom, axisLeft } from 'd3-axis';
import { max } from 'd3-array';
import { line as svgLine, curveBasis } from 'd3-shape';
import { zoom } from 'd3-zoom';
import { format } from 'd3-format';
import { colorPalette, transform, getMargin } from './plot/utils';
import styles from './FFTPlot.module.scss';
import { ReactComponent as Check } from '../../../../assets/images/icons/check.svg';
import { ReactComponent as Close } from '../../../../assets/images/icons/close.svg';
import { useMemo } from 'react';

export default function FFTPlot(props) {
  const {
    series,
    onClose,
  } = props;

  const fftDomain = useMemo(() => [0, 15], []);
  const fftFormat = format('.2s');

  function findFFTMax(v) {
    return max(v, (se) => max(se.values, (ss) => ss.value));
  }

  function computeColorPalette(s) {
    const m = {};
    s.forEach((se, i) => (m[se.signal.path] = colorPalette[i]));
    return m;
  }

  const createFFTPlot = useCallback((el) => {
    const margin = getMargin();
    const width = el.offsetWidth - margin.hMargin();
    const height = el.offsetHeight - margin.vMargin();
    let seriesCache = series;
    let fftColors;

    const svgContainer = select(el)
      .append('svg')
      .attr('width', '100%')
      .attr('height', '100%');

    const svg = svgContainer
      .append('g')
      .attr('transform', transform(margin.left, margin.top));

    const xScale = scaleLinear()
      .range([0, width])
      .domain(fftDomain);
    const xScaleReference = xScale.copy();
    const xAxis = axisBottom(xScale).ticks(20);
    const xAxisElem = svg
      .append('g')
      .classed('x-axis axis', true)
      .attr('transform', transform(0, height))
      .call(xAxis);

    const yScale = scaleLinear().range([height, 0]);
    const yAxis = axisLeft(yScale)
      .ticks(5)
      .tickFormat(fftFormat);
    const yAxisElem = svg
      .append('g')
      .classed('y-axis axis', true)
      .call(yAxis);

    const line = svgLine()
      .x((d) => xScale(d.frequency))
      .y((d) => yScale(d.value))
      .curve(curveBasis);

    let rid = null;

    function redraw() {
      const path = svg.selectAll('.chart-line').data(seriesCache);
      path.exit().remove();
      path
        .enter()
        .append('path')
        .classed('chart-line', true)
        .merge(path)
        .attr('stroke', (d) => fftColors[d.signal.path])
        .attr('d', (d) => line(d.values));
      xAxisElem.call(xAxis);
      yAxisElem.call(yAxis);
      rid = null;
    }

    const z = zoom()
      .scaleExtent([0.1, 1])
      .on('zoom', () => {
        const d = event.transform.rescaleX(xScaleReference).domain();
        xScale.domain([
          Math.max(fftDomain[0], d[0]),
          Math.max(2, Math.min(d[1], 125)),
        ]);
        if (rid == null) {
          rid = window.requestAnimationFrame(redraw);
        }
      });

    svg
      .append('rect')
      .attr('width', width)
      .attr('height', height)
      .style('fill', 'none')
      .style('pointer-events', 'all')
      .call(z);

    return {
      update(s, colors) {
        seriesCache = s;
        fftColors = colors;
        yScale.domain([0, findFFTMax(s)]);
        redraw();
      },
      destroy() {
        svgContainer.remove();
      },
    };
  }, [fftDomain, fftFormat, series])

  const [selected, setSelected] = useState(series.map((s) => s.signal.path));
  const container = useRef(null);
  const palette = useRef(computeColorPalette(series))
  const [d3Chart, setd3Chart] = useState(null);


  useEffect(() => {
    const nextd3Chart = createFFTPlot(container.current, props);
    setd3Chart(nextd3Chart);
    nextd3Chart.update(series, palette);
  }, [createFFTPlot, palette, props, series]);

  useEffect(() => {
    if (d3Chart) {
      palette.current = computeColorPalette(series);
      d3Chart.update(
        series.filter((s) => selected.includes(s.signal.path)),
        palette.current,
      );
    }
  }, [d3Chart, series, selected]);

  useEffect(() => () => {
    if (d3Chart) {
      d3Chart.destroy();
      setd3Chart(null);
    }
  }, [d3Chart]);

  const selectOptions = series.map((s) => {
    function onItemClick(v) {
      if (selected.includes(v)) {
        setSelected(selected.filter((i) => i !== v));
      } else {
        setSelected((prevItems) => [...prevItems, v]);
      }
    }
    const isDisplayed = (v) => selected.find((i) => i === v);
    return (
      <button
        type="button"
        onClick={() => onItemClick(s.signal.path)}
        className={`${!isDisplayed(s.signal.path) ? styles.notSselected : ''} `}
        style={{ borderColor: palette.current[s.signal.path] }}
      >
        <div
          className={styles.dot}
          style={{ backgroundColor: palette.current[s.signal.path] }}
        >
          {isDisplayed(s.signal.path) && <Check fill="#fff" width="7px" />}
        </div>
        <span>{s.signal.name}</span>
      </button>
    );
  });

  return (
    <div className={styles.container}>
      <div className={styles.plotContainer} ref={container} />
      <div className={styles.signalSelect}>
        {selectOptions}
      </div>
      <Close className={styles.closeIcon} fill="#7A71A6" width="9px" height="9px" onClick={onClose} />
    </div>
  );
}
