/* eslint-disable react/no-access-state-in-setstate */
/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable react/prop-types */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable max-classes-per-file */
import React, { Component } from 'react';
import _ from 'lodash';
import {
  Form, Select, Button, InputNumber, Input,
} from 'antd';
import styles from './SeriesFilterDialog.module.scss';
import Dialog from '../../../../shared/dialog/Dialog';

const bandTypes = ['lowpass', 'highpass', 'bandpass', 'bandstop'];

function checkFreqCutsForBandType(values, bt) {
  switch (bt) {
    case 'lowpass':
    case 'highpass':
      if (values.length !== 1) {
        return 'Lowpass/Highpass must have a single frequency cut';
      }
      break;
    case 'bandpass':
    case 'bandstop':
      if (values.length !== 2) {
        return 'Bandpass/Bandstop must have two frequency cuts';
      }
      break;
    default:
      break;
  }
  return values.some((v) => +_.isNaN(v)) ? 'Invalid value' : null;
}

const FormItem = Form.Item;
const { Option } = Select;

function FilterConfig(props) {
  return (
    <dl className={styles.config}>
      <div
        className={styles.remove}
        onClick={() => props.removeFilter(props.path)}
      >
        X
      </div>
      <dt>Path</dt>
      <dd>{props.path}</dd>
      <dt>Order</dt>
      <dd>{props.order}</dd>
      <dt>Band type</dt>
      <dd>{props.bandType.toUpper()}</dd>
      <dt>Filter type</dt>
      <dd>Butterworth</dd>
      <dt>Freq cuts</dt>
      <dd>
        {props.frequencyCuts.length === 1
          ? props.frequencyCuts[0]
          : `[${props.frequencyCuts.join(',')}]`}
      </dd>
    </dl>
  );
}

class _FilterForm extends Component {
  handleSubmit(e) {
    e.preventDefault();
    this.props.form.validateFields((err, values) => {
      if (!err) {
        const freqCuts = values.frequencyCuts
          .split(',')
          .map((v) => +v)
          .sortBy()
          .value();
        const filters = values.signals.map((v) => ({
          path: v,
          order: values.order,
          frequencyCuts: freqCuts,
          bandType: values.bandType,
        }));
        this.props.addFilters(filters);
      }
    });
  }

  onBandTypeChange() {
    setTimeout(
      () => this.props.form.validateFields(['frequencyCuts'], { force: true }),
      100,
    );
  }

  validateFrequencyCuts(rule, value, cb) {
    if (!value) {
      cb('Required');
      return;
    }
    const values = value.split(',');
    const bandType = this.props.form.getFieldValue('bandType');
    const check = checkFreqCutsForBandType(values, bandType);
    if (check != null) {
      cb(check);
    } else {
      cb();
    }
  }

  render() {
    const { getFieldDecorator } = this.props.form;
    const itemLayout = { labelCol: { span: 6 }, wrapperCol: { span: 14 } };
    return (
      <Form onSubmit={this.handleSubmit} hideRequiredMark>
        <FormItem label="Signals" {...itemLayout}>
          {getFieldDecorator('signals', {
            rules: [
              { required: true, message: 'Please assign one or more signals' },
            ],
          })(
            <Select mode="multiple">
              {this.props.signals.map((s) => (
                <Option key={s.path}>{s.name}</Option>
              ))}
            </Select>,
          )}
        </FormItem>
        <FormItem label="Order" {...itemLayout}>
          {getFieldDecorator('order', {
            initialValue: 2,
            rules: [{ required: true }],
          })(<InputNumber min={1} max={24} />)}
        </FormItem>
        <FormItem label="Band type" {...itemLayout}>
          {getFieldDecorator('bandType', {
            initialValue: 'lowpass',
            rules: [{ required: true }],
          })(
            <Select onChange={this.onBandTypeChange}>
              {bandTypes.map((b) => <Option key={b}>{b.toUpperCase()}</Option>)}
            </Select>,
          )}
        </FormItem>
        <FormItem label="Frequency cuts" {...itemLayout}>
          {getFieldDecorator('frequencyCuts', {
            rules: [{ required: true, validator: this.validateFrequencyCuts }],
          })(<Input placeholder="Frequency Cuts" />)}
        </FormItem>
        <FormItem style={{ textAlign: 'center' }}>
          <Button type="primary" htmlType="submit">
            Add filter
          </Button>
        </FormItem>
      </Form>
    );
  }
}

const FilterForm = Form.create()(_FilterForm);

class FilterDialog extends Component {
  constructor(props) {
    super(props);
    this.state = {
      filters: props.filtersConfig || [],
    };
  }

  handleSubmit() {
    this.props.updateFilters(this.state.filters);
  }

  addFilters(f) {
    const filters = f
      .concat(this.state.filters)
      .uniqBy((v) => v.path)
      .value();
    this.setState({ filters });
  }

  removeFilter(path) {
    this.setState({
      filters: this.state.filters.filter((f) => f.path !== path),
    });
  }

  render() {
    return (
      <Dialog
        title="Filters"
        display
        handleCloseDialog={this.props.closeDialog}
        content={(
          <div style={{ height: '400px', overflow: 'auto' }}>
            <div>
              <FilterForm
                signals={this.props.signals}
                selectedSignals={this.props.selectedSignals}
                addFilters={this.addFilters}
              />
            </div>
            <div>
              <h3>Filters list</h3>
              {this.state.filters.length > 0 ? (
                this.state.filters.map((f) => (
                  <FilterConfig
                    key={f.path}
                    removeFilter={this.removeFilter}
                    {...f}
                  />
                ))
              ) : (
                <span>No configured filter</span>
              )}
            </div>
            <button
              type="button"
              onClick={this.handleSubmit}
            >
              Submit
            </button>
          </div>
        )}
      />
    );
  }
}

export default FilterDialog;
