import React from 'react';
import PropTypes from 'prop-types';
import { v4 as uuidv4 } from 'uuid';
import styles from './Table.module.scss';

function Table(props) {
  const {
    columns,
    rows,
  } = props;

  const headers = columns.length && columns.map((elem) => (
    <th
      className={styles.headers}
      key={`column_${elem.title}}`}
    >
      <span>{elem.title}</span>
    </th>
  ));

  const rowsRender = rows.length && rows.map((row) => {
    const cols = columns.map((col) => (
      <td style={{ width: col.width || 'auto' }} key={`cols_${uuidv4()}_${col.id}`}>
        {col.render ? col.render(row) : row[col.field]}
      </td>
    ));
    return (
      <tr className={styles.headers} key={`row_${uuidv4()}`}>
        {cols}
      </tr>
    );
  });

  return (
    <div className={styles.container}>
      <div className={styles.tableContainer}>
        <table className={styles.table}>
          <thead>
            <tr>
              {headers}
            </tr>
          </thead>
          <tbody>
            {rows.length ? rowsRender : (
              <span className={styles.noDataContainer}>No data</span>
            )}
          </tbody>
        </table>
      </div>
    </div>
  );
}

Table.defaultProps = {
  columns: [],
  rows: [],
};

Table.propTypes = {
  columns: PropTypes.instanceOf(Array),
  rows: PropTypes.instanceOf(Array),
};

export default Table;