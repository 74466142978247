/* eslint-disable no-return-assign */
/* eslint-disable react/prop-types */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable import/no-extraneous-dependencies */
import React from 'react';
import { scaleTime } from 'd3-scale';
import { axisTop } from 'd3-axis';
import { select, mouse, event } from 'd3-selection';
import { drag } from 'd3-drag';
import styles from './SignalNavigation.module.scss';
import { transform, colorPalette } from './plot/utils';
import { timestampFormatter } from '../utils/timeUtils';

function newTimeFormatter(props) {
  return timestampFormatter(props.recordInfo.timezone, 'HH:mm');
}

function initNavigation(el, props) {
  let data = props;
  const width = el.offsetWidth;
  const height = el.offsetHeight;
  const xScale = scaleTime().range([0, width]);

  const xAxis = axisTop(xScale).tickFormat(newTimeFormatter(props));

  const svg = select(el)
    .append('svg')
    .attr('width', '100%')
    .attr('height', '100%');

  svg
    .append('rect')
    .attr('height', '100%')
    .attr('width', '100%')
    .attr('fill', 'none')
    .style('pointer-events', 'all')
    .on('click', () => {
      data.onRangeChange(xScale.invert(mouse(el.querySelector('svg>rect'))[0]).getTime());
    });

  const xAxisElem = svg
    .append('g')
    .classed('x-axis axis', true)
    .attr('transform', transform(0, height))
    .call(xAxis);

  const position = svg
    .append('rect')
    .attr('fill', 'deeppink')
    .attr('y', 5)
    .attr('height', height / 2)
    .style('opacity', 0.8);

  function getPositionX() {
    return +position.attr('x');
  }

  const d = drag()
    .on('drag', () => {
      position.attr(
        'x',
        Math.min(
          Math.max(0, getPositionX() + event.dx),
          width - +position.attr('width'),
        ),
      );
    })
    .on('end', () => {
      data.onRangeChange(xScale.invert(getPositionX()).getTime());
    });
  position.call(d);

  function update() {
    const r = data.range;
    xScale.domain([r.start, r.end]);
    const range = r.range();
    const start = xScale(range.start);
    position
      .attr('x', start)
      .attr('width', Math.max(1, xScale(range.end) - start));
    xAxis.tickFormat(newTimeFormatter(data));
    xAxisElem.call(xAxis);
  }

  update();

  return {
    setData(updatedData) {
      data = updatedData;
    },
    update,
    destroy() {
      svg.remove();
    },
  };
}

const overviewPalette = ['white'].concat(colorPalette);

const dreemnogramColors = [
  '#dc5640',
  '#47a0e2',
  '#1590e2',
  '#a967ef',
  '#21c19e',
];

function getPalette(title) {
  switch (title) {
    case 'dreemnogram':
      return dreemnogramColors;
    case 'index_channel':
      return colorPalette;
    default:
      return overviewPalette;
  }
}

function getValues(data) {
  if (data.title.startsWith('quality')) {
    return data.values.map((v) => ({ ...v, value: v.value >= 0.6 ? 1 : 0 }));
  }
  return data.values;
}

class SignalNavigation extends React.Component {
  componentDidMount() {
    this.navigation = initNavigation(this.container, this.props);
    this.drawAlgoOverview(this.props.algoOverview, this.props.range);
  }

  shouldComponentUpdate(nextProps) {
    if (nextProps.range !== this.props.range) {
      this.navigation.setData(nextProps);
      this.navigation.update();
    }
    if (
      nextProps.algoOverview !== this.props.algoOverview
      || nextProps.range !== this.props.range
      || nextProps.selectedAlgo !== this.props.selectedAlgo
    ) {
      this.drawAlgoOverview(
        nextProps.algoOverview,
        nextProps.range,
        nextProps.selectedAlgo,
      );
    }
    return false;
  }

  componentWillUnmount() {
    this.navigation.destroy();
    this.navigation = null;
  }

  drawAlgoOverview(algoOverview, range, selectedAlgo) {
    if (
      algoOverview == null
      || algoOverview.length === 0
      || range.start === 0
      || selectedAlgo === ''
    ) {
      return;
    }
    const data = algoOverview.find((a) => a.title === selectedAlgo);
    if (data == null) {
      return;
    }
    const { canvas } = this;
    const width = this.container.offsetWidth;
    const height = this.container.offsetHeight / 2;
    canvas.width = width;
    canvas.height = height;
    const xScale = scaleTime()
      .range([0, width])
      .domain([range.start, range.end]);
    const ctx = canvas.getContext('2d');
    ctx.globalAlpha = 0.5;
    const palette = getPalette(data.title);
    getValues(data).forEach((v) => {
      const s = xScale(v.start);
      ctx.fillStyle = v.value < 0 ? '#fff' : palette[v.value];
      ctx.fillRect(s, 0, xScale(v.end) - s, height);
    });
  }

  render() {
    return (
      <div className={styles.container} ref={(e) => (this.container = e)}>
        <canvas className={styles.canvas} ref={(e) => (this.canvas = e)} />
      </div>
    );
  }
}

export default SignalNavigation;
