/* eslint-disable no-return-assign */
/* eslint-disable react/no-access-state-in-setstate */
/* eslint-disable react/prop-types */
/* eslint-disable react/destructuring-assignment */
import React, {
  Component,
} from 'react';
import _ from 'lodash';
import Tooltip from '@mui/material/Tooltip';
import { v4 as uuidv4 } from 'uuid';
import {
  Input,
} from 'antd';
import {
  scaleReadWrite,
} from '../../models/signal';
import styles from './plot.module.scss';

function InfoButton(props) {
  return (
    <Tooltip title={props.tooltip}>
      <button onClick={props.onClick} type="button" className={props.className}>
        {props.text}
      </button>
    </Tooltip>
  );
}

class InfoToolbar extends Component {
  constructor(props) {
    super(props);
    this.state = {
      scaleConfig: false,
    };
    this.scaleInput = null;
    this.onScaleEdit = this.onScaleEdit.bind(this);
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.state.scaleConfig && !prevState.scaleConfig) {
      if (this.scaleInput != null) {
        this.scaleInput.focus();
      }
    }
  }

  onScaleEdit(e) {
    const newDomain = scaleReadWrite.read(e.target.value);
    const domain = this.getScaleConfig();
    if (
      newDomain != null
      && (newDomain.min !== domain.min || newDomain.max !== domain.max)
    ) {
      this.props.onScaleEdit(this.props.signal.path, newDomain);
    }
    this.setState(prev => ({
      scaleConfig: false,
    }));
  }

  getScaleConfig() {
    const {
      signal,
      configuration,
    } = this.props;
    return configuration.scaleConfig[signal.path] != null
      ? configuration.scaleConfig[signal.path]
      : signal.domain;
  }

  doRender() {
    if (this.state.scaleConfig) {
      return (
        <Input
          ref={
            (e) => (this.scaleInput = e)
          }
          size="small"
          placeholder="-50;50"
          defaultValue={
            scaleReadWrite.write(this.getScaleConfig())
          }
          onPressEnter={
            this.onScaleEdit
          }
          onBlur={
            this.onScaleEdit
          }
          style={{
            margin: "5.5px"
          }}
        />
      );
    }
    const numbersHidden = () => !!this.props.numberDisplay.find(
      (n) => n === this.props.signal.path,
    );
    return (
      <>
        <InfoButton
          text="n"
          tooltip="Show numbers"
          onClick={
            () => this.props.onToggleNumberDisplay(this.props.signal.path)
          }
          className={`${numbersHidden() ? styles.active : ''}`}
        />
        <InfoButton
          text="s"
          tooltip="Edit scale"
          onClick={
            () => this.setState(prev => ({
              scaleConfig: !this.state.scaleConfig,
            }))
          }
        />
        <InfoButton
          text="h"
          tooltip={
            this.props.hiddenSeries.includes(this.props.signal.path)
              ? 'Show'
              : 'Hide'
          }
          onClick={
            () => {
              this.props.onToggleSerieHidden(this.props.signal.path);
            }
          }
          className={`${this.props.hiddenSeries.includes(this.props.signal.path) ? styles.active : ''}`}
        />
      </>
    );
  }

  render() {
    return (
      <div className={styles.signalInfoToolbar}>
        {this.doRender()}
      </div>
    );
  }
}

export default function SignalInfo(props) {
  return (
    <div className={styles.signalInfo}>
      {
        _.map(props.series, (s) => {
          const { signal } = s;

          function onClick(e) {
            if (e.altKey) {
              props.onSerieRemove(signal.path);
            } else {
              const res = props.selectedSignals != null && e.shiftKey
                ? _.uniq(props.selectedSignals.concat(signal.path))
                : [signal.path];
              props.onSignalSelect(res);
            }
          }
          return (
            <div
              className={styles.signalInfoElement}
              variant="contained"
              aria-describedby={signal.path}
              onKeyPress={null}
              role="button"
              tabIndex={0}
              key={`${signal.name}_${uuidv4()}`}
            >
              <div
                onClick={onClick}
                onKeyPress={null}
                role="button"
                tabIndex={0}
              >
                <span>
                  {signal.name}
                </span>
                <span className={styles.signalUnits}>
                  {signal.domain.units ? ` (${signal.domain.units})` : ''}
                </span>
              </div>
              <InfoToolbar
                signal={
                  signal
                }
                configuration={
                  props.configuration
                }
                hiddenSeries={
                  props.hiddenSeries
                }
                numberDisplay={props.numberDisplay}
                onToggleNumberDisplay={
                  props.onToggleNumberDisplay
                }
                onToggleSerieHidden={
                  props.onToggleSerieHidden
                }
                onScaleEdit={
                  props.onScaleEdit
                }
              />
            </div>
          );
        })
      }
    </div>
  );
}