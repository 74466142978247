/* eslint-disable no-return-assign */
/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable react/prop-types */
/* eslint-disable react/destructuring-assignment */
import React, { Component, Fragment } from 'react';
import _ from 'lodash';
import { mouse } from 'd3-selection';
import Tooltip from './Tooltip';
import Brush from './Brush';
import EventListener from './EventListener';

function shiftHandler() {
  let shiftKey = false;
  let handlers = [];
  function handleShift(e) {
    shiftKey = e.shiftKey;
    _.forEach(handlers, (h) => h(shiftKey));
  }
  window.addEventListener('keydown', handleShift, false);
  window.addEventListener('keyup', handleShift, false);
  return {
    addHandler(handler) {
      handlers.push(handler);
    },
    clear() {
      handlers = null;
      window.removeEventListener('keydown', handleShift, false);
      window.removeEventListener('keyup', handleShift, false);
    },
    shift() {
      return shiftKey;
    },
  };
}

export default class Interaction extends Component {
  constructor(props) {
    super(props);
    this.shiftHandler = shiftHandler();
    this.onMouseDown = this.onMouseDown.bind(this);
    this.onMouseMove = this.onMouseMove.bind(this);
  }

  componentWillUnmount() {
    this.shiftHandler.clear();
  }

  onMouseMove(event, element) {
    if (this.marker != null) {
      this.marker.updateCursor(element);
    }
  }

  onMouseDown(event, element) {
    if (this.brush != null) {
      this.brush.clearBrush();
    }
    if (!this.props.measureMode) {
      if (event.ctrlKey) {
        this.props.onSerieMark({
          signals: _.map(this.props.series, (s) => s.signal),
          location: this.props.xScale.invert(mouse(element)[0]).getTime(),
        });
      } else {
        this.props.onSerieSelection({});
      }
    }
  }

  render() {
    return (
      <>
        <EventListener
          shiftHandler={this.shiftHandler}
          onMouseDown={this.onMouseDown}
          onMouseMove={this.onMouseMove}
          {...this.props}
        />
        <Tooltip
          ref={(m) => (this.marker = m)}
          xScale={this.props.xScale}
          series={this.props.series}
          yScales={this.props.yScales}
          size={this.props.size}
          selection={this.props.selection}
          range={this.props.range}
          measureMode={this.props.measureMode}
          colorConfig={this.props.colorConfig}
        />
        <Brush
          ref={(b) => (this.brush = b)}
          shiftHandler={this.shiftHandler}
          series={this.props.series}
          selection={this.props.selection}
          onSerieSelection={this.props.onSerieSelection}
          xScale={this.props.xScale}
          size={this.props.size}
        />
      </>
    );
  }
}
