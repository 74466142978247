import _ from 'lodash';
import {
  TIMESERIES,
} from '../constants/actionTypes';
import newRange, {
  defaultWindow,
} from '../models/range';
import {
  defaultSeries,
  signalsToSeries,
} from '../models/serie';
import selectGroups from '../models/group';
import {
  ensureDomain,
} from '../models/signal';
import {
  updateAllContent,
  closeContent,
} from './timeseriesContent';
import {
  contentTypes,
} from '../models/content';
import {
  getConfig,
  validateConfiguration,
} from '../models/profile';
import {
  newDefaultRecordInfo,
  newRecordInfo,
} from '../models/recordInfo';
import {
  catchErrorMessage,
  toggleProgressBar,
} from './app';
import {
  authFetch,
  authFetchOptional,
  authFetchFunc,
} from './utils';
import queryParams from '../utils/queryParams';

export const timeseriesQueryParams = queryParams('timeseries');

function updateTimestampQuery(ts) {
  timeseriesQueryParams.updateQuery('timestamp', ts).writeHistory();
}

function pathQuery(series) {
  return _(series)
    .map((s) => s.signal)
    .join(',');
}

export function updateSeries(series) {
  return {
    type: TIMESERIES.UPDATE_SERIE,
    series,
  };
}

function countRemoved(series, r) {
  return series[0].count(r.removed.start, r.removed.end);
}

function dispatchConcatSeries(dispatch, getState, cancel) {
  return (responses) => {
    if (cancel != null) {
      cancel();
    }
    const {
      series,
    } = getState().timeSeries;
    const rng = getState().timeSeries.range;
    const newSeries = _.map(responses, (res, i) => series[i].merge(res.values, rng));
    dispatch(updateSeries(newSeries));
  };
}

function dispatchUpdateSeries(dispatch, series, cancel) {
  return (responses) => {
    if (cancel != null) {
      cancel();
    }
    const newSeries = _.map(responses, (res, i) => series[i].update(res.values));
    dispatch(updateSeries(newSeries));
  };
}

function limitProgress(rng, p) {
  const min = rng.start;
  const max = rng.end - rng.window;
  return Math.min(Math.max(min, p), max);
}

function getSamples(requestedSeries, r, threshold, getState, api) {
  if (requestedSeries.length === 0) {
    return Promise.resolve([]);
  }
  return authFetch(
    `${api.sample}/series/${requestedSeries[0].recordID}?from=${r.start}&to=${
      r.end
    }&threshold=${threshold}&series=${pathQuery(requestedSeries)}`,
    getState,
  );
}

function doUpdateProgress(dispatch, getState, api, p, callback) {
  const ts = getState().timeSeries;
  const current = limitProgress(ts.range, p);
  const r = ts.range.diff(current);
  if (r.added.start === r.added.end) {
    return Promise.resolve();
  }
  const threshold = countRemoved(ts.series, r);
  // If nothing to remove, either the window has changed or some request is
  // still pending due async changes between the range and the series. In both
  // cases it is safer to bail out of the diff optimization and ask for the
  // entire window
  const req = threshold === 0
    ? getSamples(
      ts.series, {
        start: current,
        end: current + ts.range.window,
      },
      ts.range.threshold,
      getState,
      api,
    )
    : getSamples(ts.series, r.added, threshold, getState, api);
  callback(current);
  const cancel = toggleProgressBar(dispatch);
  updateTimestampQuery(current);
  updateAllContent(dispatch, getState, api);
  return req
    .then(dispatchConcatSeries(dispatch, getState, cancel))
    .catch(catchErrorMessage(dispatch, cancel));
}

export function updateProgress(p) {
  return (dispatch, getState, api) => doUpdateProgress(dispatch, getState, api, p, (current) => {
    dispatch({
      type: TIMESERIES.UPDATE_PROGRESS,
      current,
    });
  });
}

/** Advances the range to the next window. */
export function progressForward() {
  return (dispatch, getState) => {
    const {
      staging,
    } = getState().timeSeries;
    if (staging.enabled) {
      // If staging is enabled we need to handle the
      // highlight cursor progression
      // This should probably be moved into a reducer
      const rng = getState().timeSeries.range.range();
      const nextCursor = staging.stages.nextCursor();
      const nextEnd = nextCursor + staging.stages.config.window;
      if (nextEnd >= rng.end) {
        dispatch(updateProgress(nextCursor)).then(() => {
          if (staging.stages.config.highlight) {
            dispatch({
              type: TIMESERIES.SET_HIGHLIGHTS,
              highlights: [{
                start: nextCursor,
                end: nextEnd,
              }],
            });
          }
        });
      } else {
        dispatch({
          type: TIMESERIES.UPDATE_STAGING_CURSOR,
          cursor: nextCursor,
          config: staging.stages.config,
        });
      }
    } else {
      const rng = getState().timeSeries.range;
      dispatch(updateProgress(rng.current + rng.window));
    }
  };
}

/** Advances the range to the previous window. */
export function progressBackward() {
  return (dispatch, getState) => {
    const {
      staging,
    } = getState().timeSeries;
    if (staging.enabled) {
      // If staging is enabled we need to handle the
      // highlight cursor progression
      // This should probably be moved into a reducer
      const rng = getState().timeSeries.range;
      const prev = staging.stages.prevCursor();
      if (prev < rng.current) {
        dispatch(updateProgress(prev)).then(() => {
          if (staging.stages.config.highlight) {
            dispatch({
              type: TIMESERIES.SET_HIGHLIGHTS,
              highlights: [{
                start: prev,
                end: prev + staging.stages.config.window,
              }],
            });
          }
        });
      } else {
        dispatch({
          type: TIMESERIES.UPDATE_STAGING_CURSOR,
          cursor: prev,
          config: staging.stages.config,
        });
      }
    } else {
      const rng = getState().timeSeries.range;
      dispatch(updateProgress(rng.current - rng.window));
    }
  };
}

/** Handles mouse wheel scroll progress */
export function scrollProgress(p) {
  return (dispatch, getState, api) => {
    const ts = getState().timeSeries;
    if (p === ts.range.current) {
      return Promise.resolve();
    }
    const current = limitProgress(ts.range, p);
    dispatch({
      type: TIMESERIES.UPDATE_PROGRESS,
      current,
    });
    const cancel = toggleProgressBar(dispatch, 1000);
    updateTimestampQuery(current);
    updateAllContent(dispatch, getState, api);
    return ts.seriesCache
      .getSignalPart({
        from: current,
      })
      .then(dispatchUpdateSeries(dispatch, ts.series, cancel))
      .catch(catchErrorMessage(dispatch));
  };
}

/** Updates window data content */
export function updateWindow(rng) {
  return (dispatch, getState, api) => {
    const ts = getState().timeSeries;
    const currentWindow = ts.range.window;
    if (currentWindow === rng.window) {
      return doUpdateProgress(dispatch, getState, api, rng.current, () => {
        dispatch({
          type: TIMESERIES.UPDATE_WINDOW,
          range: rng,
        });
      });
    }
    const req = getSamples(ts.series, rng.range(), rng.threshold, getState, api);
    dispatch({
      type: TIMESERIES.UPDATE_WINDOW,
      range: rng,
    });
    const cancel = toggleProgressBar(dispatch);
    updateTimestampQuery(rng.current);
    updateAllContent(dispatch, getState, api);
    return req
      .then(dispatchUpdateSeries(dispatch, ts.series, cancel))
      .catch(catchErrorMessage(dispatch, cancel));
  };
}

export function setSerieSelection(selection) {
  return {
    type: TIMESERIES.SET_SELECTION,
    selection,
  };
}

/** Sets the window to match the selection */
export function windowMatchSelection() {
  return (dispatch, getState) => {
    const ts = getState().timeSeries;
    const sel = ts.selection.current;
    if (sel != null && sel.start > 0 && sel.end - sel.start > 0) {
      dispatch(setSerieSelection(ts.selection.updateSelection()));
      dispatch(
        updateWindow(ts.range.updateWindow(sel.start, sel.end - sel.start)),
      );
    }
  };
}

/**
 * Changes the number of samples displayed.
 * This requires to refetch the data
 */
export function updateThreshold(threshold) {
  return (dispatch, getState, api) => {
    const ts = getState().timeSeries;
    if (ts.range.threshold === threshold) {
      return Promise.resolve();
    }
    const req = getSamples(
      ts.series,
      ts.range.range(),
      threshold,
      getState,
      api,
    );
    dispatch({
      type: TIMESERIES.UPDATE_THRESHOLD,
      threshold,
    });
    const cancel = toggleProgressBar(dispatch);
    return req
      .then(dispatchUpdateSeries(dispatch, ts.series, cancel))
      .catch(catchErrorMessage(dispatch, cancel));
  };
}

/**
 * Get initial record metadata from the server.
 * This triggers the download of the file on the viewer
 * backend if not present. This information is retrieved
 * from the attributes inside the h5 file and therefore
 * in some buggy cases may differ from the content of the
 * report present on the algorythm service.
 */
function getInfo(dispatch, getState, api, recordID) {
  const request = `${api.sample}/files/${recordID}`;
  let cancel = toggleProgressBar(dispatch);

  function handler(resp) {
    if (!resp.ok) {
      return resp.json().then((r) => {
        throw new Error(JSON.stringify(r));
      });
    }
    if (resp.status === 204) {
      return new Promise((resolve) => {
        setTimeout(() => {
          resolve(authFetch(request, getState, null, handler));
        }, 5000);
      });
    }
    if (cancel != null) {
      cancel();
      cancel = null;
    }
    return resp.json();
  }
  return authFetch(request, getState, null, handler);
}

function startCaching(series, rang, dispatch, getState, api) {
  const {
    recordID,
  } = series[0];
  dispatch({
    type: TIMESERIES.START_SERIES_CACHE,
    config: {
      recordID,
      authFetch: authFetchFunc(getState),
      url: `${api.sample}/series/${recordID}?series=${pathQuery(
        series,
      )}&threshold=${rang.threshold}`,
      range: {
        start: rang.start,
        end: rang.end,
      },
      start: rang.current,
      signalWindow: rang.window,
      windowCount: 10,
    },
  });
}

/** Initial load of the record */
function doLoadRecord({
  dispatch,
  getState,
  api,
  requestedSeries,
  rang,
  groups,
  signals,
  recordInfo,
  filtersConfig,
  selectedProfile,
}) {
  const cancel = toggleProgressBar(dispatch);
  return getSamples(
    requestedSeries,
    rang.range(),
    rang.threshold,
    getState,
    api,
  )
    .then((resp) => {
      cancel();
      const series = _.map(resp, (res, i) => requestedSeries[i].update(res.values));
      dispatch({
        type: TIMESERIES.LOAD_RECORD,
        range: rang,
        series,
        groups,
        signals,
        recordInfo,
        selectedProfile,
        filtersConfig,
      });
      if (!getState().timeSeries.zoomMode) {
        startCaching(series, rang, dispatch, getState, api);
      }
    })
    .catch(catchErrorMessage(dispatch, cancel));
}

function timezoneGuesser(report) {
  if (report && report.endpoints && report.endpoints.record_start_iso) {
    return report.endpoints.record_start_iso.slice(-6)
  }
  return null
}

/** Fetches record/user/device metadata from server */
function getRecordInfo(recordID, getState, api) {
  if (!api.record) {
    return Promise.resolve(newDefaultRecordInfo(recordID));
  }
  return authFetch(`${api.record}/${recordID}/`, getState)
    .then((d) => Promise.all([
      authFetch(`${api.user}/${d.user}/`, getState).catch(() => ({
        email: 'N/A',
      })),
      authFetch(`${api.headband}/${d.device}/`, getState).catch(() => ({
        nickname: 'N/A',
      })),
      authFetch(`${api.report}/`, getState, {
        method: 'POST',
        body: JSON.stringify({
          id: [recordID],
          latest: true,
        }),
      }).catch((err) => ({
        err,
      })),
    ]).then((resp) => { 
      const user = resp[0]
      const device = resp[1]
      const report = resp[2][0]

      return newRecordInfo({
        id: recordID,
        record: d,
        reference: d.reference,
        user: user.email != null ? user.email : user.pseudo,
        device: device.nickname,
        timezone: d.report !== null ? d.report.timezone : timezoneGuesser(report),
      })
  }))
    .catch(() => newDefaultRecordInfo(recordID));
}

function getRecordFilters(recordID, getState, api) {
  return authFetch(`${api.sample}/series/${recordID}/filter`, getState).catch(
    _.constant([]),
  );
}

function postFilterConfig({
  recordID,
  getState,
  api,
  added,
  removed,
  removeAll,
}) {
  const removedReq = removeAll ? [
    authFetch(`${api.sample}/series/${recordID}/filter`, getState, {
      method: 'DELETE',
    }),
  ]
    : _(removed)
      .filter((f) => f.id != null)
      .map((f) => authFetch(
        `${api.sample}/series/${recordID}/filter/${f.id}`,
        getState, {
          method: 'DELETE',
        },
      ))
      .value();
  return Promise.all(removedReq)
    .then(() => Promise.all(
      _(added)
        .filter((f) => f.id == null)
        .map((f) => authFetch(`${api.sample}/series/${recordID}/filter`, getState, {
          method: 'POST',
          body: JSON.stringify(f),
        }))
        .value(),
    ))
    .then(() => getRecordFilters(recordID, getState, api))
    .catch(_.noop);
}

function loadFromProfile({
  recordID,
  info,
  getState,
  api,
}) {
  const signals = _(info.signals)
    .map(ensureDomain)
    .sortBy((s) => s.domain.name, 'path')
    .value();
  const configToLoad = getConfig(getState);
  const currentConfig = validateConfiguration(configToLoad, signals)
    ? configToLoad
    : getConfig(getState, 'default');
  const requestedSeries = defaultSeries(recordID, signals, currentConfig);
  const groups = selectGroups();
  groups.addGroup(requestedSeries);
  const rang = newRange(
    info.start,
    info.end,
    currentConfig.windowRange ? currentConfig.windowRange : defaultWindow,
  );
  return postFilterConfig({
    recordID,
    getState,
    api,
    added: _.map(currentConfig.filtersConfig, (fc) => ({
      ...fc,
      id: null,
    })),
    removeAll: true,
  }).then((resp) => ({
    requestedSeries,
    rang,
    groups,
    signals,
    filtersConfig: resp,
    selectedProfile: currentConfig.name,
  }));
}

export function fetchRecord(recordID, currentTimestamp) {
  return (dispatch, getState, api) => {
    timeseriesQueryParams
      .updateResource(recordID)
      .updateQuery('timestamp', currentTimestamp);
    return getInfo(dispatch, getState, api, recordID)
      .then((info) => Promise.all([
        loadFromProfile({
          recordID,
          info,
          getState,
          api,
        }),
        getRecordInfo(recordID, getState, api),
      ]))
      .then((resp) => {
        const cfg = resp[0];
        if (currentTimestamp != null && !_.isNaN(currentTimestamp)) {
          cfg.rang = cfg.rang.update(currentTimestamp);
        }
        return doLoadRecord({
          ...cfg,
          recordInfo: resp[1],
          dispatch,
          getState,
          api,
        });
      })
      .catch(catchErrorMessage(dispatch));
  };
}

export function stopCaching() {
  return {
    type: TIMESERIES.STOP_SERIES_CACHE,
  };
}

function validateFilterOptions(fopts, signals) {
  const {
    added,
    removed,
  } = fopts;
  return (
    _.every(added, (fc) => _.find(signals, (s) => s.path === fc.path) != null)
    && _.every(removed, (fc) => _.find(signals, (s) => s.path === fc.path) != null)
  );
}

/** Refresh all function */
export function reloadSeries({
  dispatch,
  getState,
  api,
  args,
}) {
  dispatch(closeContent(contentTypes.FFT));
  dispatch(stopCaching());
  const {
    recordInfo: {
      id: recordID,
    },
    series,
    signals,
    range: rang,
  } = getState().timeSeries;
  const {
    postFilterOptions,
    ...cargs
  } = args;
  if (cargs.requestedSeries == null || cargs.groups == null) {
    const newSignals = _.map(series, (s) => _.find(signals, (sg) => sg.path === s.signal));
    cargs.requestedSeries = signalsToSeries(recordID, newSignals);
    cargs.groups = selectGroups();
    cargs.groups.addGroup(cargs.requestedSeries);
  }
  let filterReq;
  if (postFilterOptions != null) {
    filterReq = validateFilterOptions(postFilterOptions, signals)
      ? postFilterConfig({
        ...postFilterOptions,
        recordID,
        getState,
        api,
      })
      : postFilterConfig({
        removeAll: true,
        recordID,
        getState,
        api,
      });
  } else {
    filterReq = Promise.resolve();
  }
  return filterReq.then((filtersConfig) => doLoadRecord({
    dispatch,
    getState,
    api,
    rang,
    filtersConfig,
    ...cargs,
  }));
}

export function changeSelectedSignals(requestedSeries, groups) {
  return (dispatch, getState, api) => reloadSeries({
    dispatch,
    getState,
    api,
    args: {
      requestedSeries,
      groups,
    },
  });
}

export function removeSignal(removed) {
  return (dispatch, getState, api) => {
    const ts = getState().timeSeries;
    const recordID = ts.recordInfo.id;
    const newSignals = _(ts.series)
      .map((serie) => _.find(ts.signals, (sg) => sg.path === serie.signal))
      .filter((sig) => sig.path !== removed)
      .value();
    const requestedSeries = signalsToSeries(recordID, newSignals);
    const groups = selectGroups();
    groups.addGroup(requestedSeries);
    return reloadSeries({
      dispatch,
      getState,
      api,
      args: {
        requestedSeries,
        groups,
      },
    });
  };
}

export function setSerieMark(mark) {
  return {
    type: TIMESERIES.SET_MARK,
    mark,
  };
}

export function goToMark() {
  return (dispatch, getState) => {
    const {
      mark,
    } = getState().timeSeries.selection;
    const rng = getState().timeSeries.range;
    if (mark != null) {
      dispatch(updateWindow(rng.centerOn(mark.location)));
    }
  };
}

export function toggleFitMode() {
  return {
    type: TIMESERIES.TOGGLE_FIT_MODE,
  };
}

export function toggleZoomMode() {
  return (dispatch, getState, api) => {
    dispatch({
      type: TIMESERIES.TOGGLE_ZOOM_MODE,
    });
    if (!getState().timeSeries.zoomMode) {
      const r = getState().timeSeries.range;
      dispatch(updateWindow(r.updateWindow(r.current, defaultWindow)));
      startCaching(
        getState().timeSeries.series,
        getState().timeSeries.range,
        dispatch,
        getState,
        api,
      );
    } else {
      dispatch(stopCaching());
    }
  };
}

export function toggleMeasureMode() {
  return {
    type: TIMESERIES.TOGGLE_MEASURE_MODE,
  };
}

export function toggleLimiterMode(checked) {
  return {
    type: TIMESERIES.TOGGLE_LIMITER_MODE,
    checked,
  };
}

// Usually the algo overview has been computed by algorythm, in case it has not, just do nothing
export function fetchAlgoOverview(recordID) {
  return (dispatch, getState, api) => {
    if (process.env.NODE_ENV !== 'production' || api.record == null) {
      return Promise.resolve();
    }
    return Promise.all([
      authFetchOptional(
        `${api.record}/${recordID}/algorithm_overview/`,
        getState,
      ),
      authFetchOptional(`${api.record}/${recordID}/quality_overview/`, getState),
    ])
      .then((resp) => {
        dispatch({
          type: TIMESERIES.SET_ALGO_OVERVIEW,
          algoOverview: _.uniqBy(resp[0].concat(resp[1]), (a) => a.title),
        });
      })
      .catch(_.noop);
  };
}

export function setReferenceLines(referenceLines) {
  return {
    type: TIMESERIES.SET_REFERENCE_LINES,
    referenceLines,
  };
}

export function setSignalSelection(selection) {
  return {
    type: TIMESERIES.SET_SIGNAL_SELECTION,
    selection,
  };
}

export function updateFiltersConfig(filters) {
  return (dispatch, getState, api) => {
    const previousFilters = getConfig(getState).filtersConfig;
    return reloadSeries({
      dispatch,
      getState,
      api,
      args: {
        postFilterOptions: {
          added: filters,
          removed: _.differenceBy(previousFilters, filters, (f) => f.path),
        },
      },
    }).catch(catchErrorMessage(dispatch));
  };
}

export function toggleNumberDisplay(signal) {
  return {
    type: TIMESERIES.TOGGLE_NUMBER_DISPLAY,
    signal,
  };
}

export function toggleSerieHidden(signal) {
  return {
    type: TIMESERIES.TOGGLE_HIDDEN_SERIE,
    signal,
  };
}