/* eslint-disable react/no-access-state-in-setstate */
/* eslint-disable react/button-has-type */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable react/no-deprecated */
/* eslint-disable react/no-array-index-key */
/* eslint-disable no-return-assign */
/* eslint-disable react/prop-types */
/* eslint-disable import/no-extraneous-dependencies */
/* eslint-disable max-classes-per-file */
import React, { Component } from 'react';
import { scaleTime, scaleLinear, scaleBand } from 'd3-scale';
import { axisBottom, axisLeft } from 'd3-axis';
import { select } from 'd3-selection';
import { line as svgLine } from 'd3-shape';
import {
  getMargin,
  transform,
  colorLegend,
  colorPaletteExtended,
} from './plot/utils';
import { timestampFormatter } from '../utils/timeUtils';
import NumberDisplay from './plot/NumberDisplay';
import styles from './WindowReadPlot.module.scss';
import { lt, labelTypes } from '../models/label';

function get(obj, path, def) {
  let curr = obj;
  for (let i = 0; i < path.length; i += 1) {
    if (!curr[path[i]]) return def;
    curr = curr[path[i]];
  }
  return curr;
}

class Labels extends Component {
  constructor(props) {
    super(props);
    this.legend = colorLegend(colorPaletteExtended, labelTypes);
    // Override color for marker type
    this.legend.set(lt.MARKER, 'darkgray');
  }

  render() {
    const {
      xScale, yOrdinalScale, labels, range,
    } = this.props;
    const bw = yOrdinalScale.bandwidth();
    return (
      <g>
        {labels
          .filter((l) => l.start >= range.start && l.start < range.end)
          .flatMap((l) => l.signals.map((s) => {
            const sy = yOrdinalScale(s);
            if (sy == null) {
              return null;
            }
            const sx = xScale(l.start);
            return (
              <rect
                key={`${l.id}-${s}`}
                x={sx}
                y={sy}
                width={xScale(l.end) - sx}
                height={bw}
                fill={this.legend.get(l.type)}
                fillOpacity={0.5}
              />
            );
          }))
          .value()}
      </g>
    );
  }
}

class MultiAxis extends Component {
  constructor(props) {
    super(props);
    this.yAxis = props.yScales.map((y) => axisLeft(y)
      .ticks(3)
      .tickSizeOuter(0));
    this.yAxisElem = [];
  }

  componentDidMount() {
    this.redraw();
  }

  shouldComponentUpdate() {
    return false;
  }

  redraw() {
    if (this.yAxisElem.length > 0) {
      for (let i = 0; i < this.yAxis.length; i += 1) {
        select(this.yAxisElem[i]).call(this.yAxis[i]);
      }
    }
  }

  render() {
    return this.yAxis.map((s, i) => (
      <g key={i} ref={(el) => (this.yAxisElem[i] = el)} className="y-axis axis" />
    ));
  }
}

class TimeAxis extends Component {
  constructor(props) {
    super(props);
    this.xAxis = axisBottom(props.xScale)
      .tickFormat(timestampFormatter(props.recordInfo.timezone))
      .ticks(10);
  }

  componentDidMount() {
    this.redraw();
  }

  componentWillReceiveProps(nextProps) {
    if (this.props.size !== nextProps.size) {
      this.xAxisElem.setAttribute(
        'transform',
        transform(0, nextProps.size.height),
      );
      this.redraw();
    }
  }

  shouldComponentUpdate() {
    return false;
  }

  redraw() {
    select(this.xAxisElem).call(this.xAxis);
  }

  render() {
    return (
      <g
        className="x-axis axis"
        transform={transform(this.props.size.height)}
        ref={(el) => (this.xAxisElem = el)}
      />
    );
  }
}

function SignalInfo(props) {
  return (
    <div className={styles.signalInfo}>
      {props.series.map((s) => (
        <div key={s.signal.path} className={styles.signalInfoElement}>
          {s.signal.name}
          <div className={styles.signalInfoToolbar}>
            <button onClick={() => props.onNumberToggle(s.signal.path)}>
              n
            </button>
          </div>
        </div>
      ))}
    </div>
  );
}

const zeroSize = {
  width: 0,
  height: 0,
  offsetWidth: 0,
  offsetHeight: 0,
};

function inRange(r, p) {
  return p >= r.start && p < r.end;
}
const sortedIndexBy = (arr, n, fn) => {
  const isDescending = fn(arr[0]) > fn(arr[arr.length - 1]);
  const val = fn(n);
  const index = arr.findIndex(
    (el) => (isDescending ? val >= fn(el) : val <= fn(el)),
  );
  return index === -1 ? arr.length : index;
};

function getDataSlice(serie, w, cursor) {
  const { values, threshold } = serie;
  const i = sortedIndexBy(values, { time: cursor }, (v) => v.time);
  if (i < 0 || i >= values.length) {
    return [];
  }
  const end = cursor + w;
  let j = Math.min(i + threshold, values.length - 1);
  if (values[j].time > end) {
    for (let k = j; k >= 0; k -= 1) {
      if (values[k].time < end) {
        j = k + 1;
        break;
      }
    }
  } else if (values[j].time < end) {
    for (let k = j; k < values.length; k += 1) {
      if (values[k].time > end) {
        j = k - 1;
        break;
      }
    }
  }
  if (j < 0 || j >= values.length) {
    return [];
  }
  return values.slice(i, j);
}

function newLinesCache() {
  return {
    cursor: null,
    series: [],
    lines: [],
  };
}

function applyRangeOnScales(scales, ordScale) {
  const step = ordScale.step();
  const bw = ordScale.bandwidth();
  scales.forEach((s, i) => s.range([i * step + bw, i * step]));
  return scales;
}

export default class WindowReadPlot extends Component {
  constructor(props) {
    super(props);
    const {
      range: { current },
      data,
      configuration,
    } = props;
    this.margin = getMargin(10, 20, 20, 60);

    this.state = {
      size: null,
      cursor: current,
      numberDisplay: [],
    };

    const xScale = scaleTime().domain([current, current + data.window]);
    this.xScale = xScale;

    const yOrdinalScale = scaleBand()
      .domain(data.series.map((s) => s.signal.path))
      .paddingInner(0.1);
    this.yOrdinalScale = yOrdinalScale;

    function debounce(func, delay, ...args) {
      let debounceTimer;
      return () => {
        const context = this;
        clearTimeout(debounceTimer);
        debounceTimer = setTimeout(() => func.apply(context, args), delay);
      };
    }

    const yScales = data.series.map((s) => {
      const dm = get(
        configuration,
        ['scaleConfig', s.signal.path],
        s.signal.domain,
      );
      return scaleLinear().domain([dm.min, dm.max]);
    });
    applyRangeOnScales(yScales, yOrdinalScale);
    this.yScales = yScales;

    this.lines = yScales.map((s) => svgLine()
      .x((d) => xScale(d.time))
      .y((d) => s(d.value)));

    this.linesCache = newLinesCache();
    this.onWindowResize = debounce(this.updateSize, 500);
  }

  componentDidMount() {
    setTimeout(() => {
      this.updateSize();
    }, 250);
    window.addEventListener('resize', this.onWindowResize);
  }

  componentWillReceiveProps(nextProps) {
    const hasConfigChanged = this.props.configuration !== nextProps.configuration;
    if (this.props.range !== nextProps.range || hasConfigChanged) {
      if (hasConfigChanged) {
        this.clearCache();
      }
      this.onDataChange(nextProps);
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if (
      prevState.size !== this.state.size
      || prevState.cursor !== this.state.cursor
      || prevProps.configuration !== this.props.configuration
    ) {
      this.redrawAxis();
    }
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.onWindowResize);
  }

  onSizeChange(size) {
    if (size != null) {
      this.yOrdinalScale.range([0, size.height]);
      applyRangeOnScales(this.yScales, this.yOrdinalScale);
      this.xScale.range([0, size.width]);
    }
  }

  onDataChange(props) {
    const { range, data, configuration } = props;
    data.series.forEach((s, i) => {
      const d = get(
        configuration,
        ['scaleConfig', s.signal.path],
        s.signal.domain,
      );
      this.yScales[i].domain([d.min, d.max]);
    });
    const mr = range.range();
    const wr = {
      start: this.state.cursor,
      end: this.state.cursor + data.window,
    };
    if (inRange(wr, mr.start) && inRange(wr, mr.end)) {
      return;
    }
    const cur = range.current;
    this.xScale.domain([cur, cur + data.window]);
    if (this.state.size != null) {
      this.refreshCache(props);
    }
    this.setState({ cursor: cur });
  }

  onNumberToggle(path) {
    this.setState({
      numberDisplay: this.state.numberDisplay.includes(path)
        ? this.state.numberDisplay.filter((n) => n !== path)
        : this.state.numberDisplay.concat(path),
    });
  }

  onClickRect(e) {
    const node = this.clickRect;
    if (node != null) {
      const rect = node.getBoundingClientRect();
      const xPos = e.clientX - rect.left - node.clientLeft;
      this.props.onRangeChange(this.xScale.invert(xPos).getTime());
    }
  }

  getSize() {
    const w = this.container.offsetWidth;
    const h = this.container.offsetHeight;
    return {
      width: w - this.margin.hMargin(),
      height: h - this.margin.vMargin(),
      offsetWidth: w,
      offsetHeight: h,
    };
  }

  refreshCache(props) {
    const {
      range: { current: cur },
      data: { series, window: w },
    } = props;
    if (this.linesCache.cursor !== cur) {
      this.linesCache.cursor = cur;
      this.linesCache.series = [];
      this.linesCache.lines = [];
      for (let i = 0; i < series.length; i += 1) {
        const data = getDataSlice(series[i], w, cur);
        this.linesCache.series.push({
          ...series[i],
          values: data,
        });
        this.linesCache.lines.push(this.lines[i](data));
      }
    }
  }

  clearCache() {
    this.linesCache.cursor = null;
  }

  updateSize() {
    const size = this.getSize();
    this.onSizeChange(size);
    this.clearCache();
    this.refreshCache(this.props);
    this.setState({ size });
  }

  redrawAxis() {
    if (this.multiAxis != null) {
      this.multiAxis.redraw();
    }
    if (this.timeAxis != null) {
      this.timeAxis.redraw();
    }
  }

  render() {
    const size = this.state.size != null ? this.state.size : zeroSize;
    const {
      range: { current: cur, window: w },
      data: { series, window: dw },
      configuration,
      labels,
      recordInfo,
    } = this.props;
    const curX = this.xScale(cur);
    return (
      <div className={styles.wrapper}>
        <SignalInfo series={series} onNumberToggle={this.onNumberToggle} />
        <div className={styles.container} ref={(e) => (this.container = e)}>
          <svg width={size.offsetWidth} height={size.offsetHeight}>
            <g transform={transform(this.margin.left, this.margin.top)}>
              <MultiAxis
                yScales={this.yScales}
                ref={(e) => (this.multiAxis = e)}
              />
              <TimeAxis
                xScale={this.xScale}
                size={size}
                recordInfo={recordInfo}
                ref={(e) => (this.timeAxis = e)}
              />
              <rect
                x={curX}
                y={0}
                width={this.xScale(cur + w) - curX}
                height={size.height}
                fill="rgba(0, 0, 0, 0.05)"
              />
              {series.map((s, i) => (
                <path
                  key={s.signal.path}
                  className="chart-line"
                  stroke={get(
                    configuration,
                    ['colorConfig', s.signal.path],
                    '#000',
                  )}
                  d={this.linesCache.lines[i] || ''}
                />
              ))}
              <NumberDisplay
                enabled={this.state.numberDisplay}
                series={this.linesCache.series}
                xScale={this.xScale}
                yScales={this.yScales}
                window={this.props.data.window}
              />
              <Labels
                range={{
                  start: this.state.cursor,
                  end: this.state.cursor + dw,
                }}
                labels={labels.labels}
                xScale={this.xScale}
                yOrdinalScale={this.yOrdinalScale}
              />
              <rect
                ref={(e) => (this.clickRect = e)}
                height={size.height}
                width={size.width}
                fill="none"
                pointerEvents="all"
                onClick={this.onClickRect}
              />
            </g>
          </svg>
        </div>
      </div>
    );
  }
}
