import _ from 'lodash';

export const queryParser = {
  toString(s) {
    return s != null ? `${s}` : undefined;
  },

  toNumber(n) {
    return n != null ? +n : undefined;
  },

  toBoolean(b) {
    return b != null ? b === 'true' : undefined;
  },

  toDate(d) {
    return d != null ? new Date(+d) : undefined;
  },

  toArray(a) {
    return a != null && a !== '' ? a.split(',') : undefined;
  },

  toObject(o) {
    try {
      return JSON.parse(o);
    } catch (e) {
      return undefined;
    }
  },
};

function parseQueryValue(v) {
  if (v instanceof Date) {
    return `${v.getTime()}`;
  }
  if (v instanceof Array) {
    return window.encodeURIComponent(v.join(','));
  }
  if (v instanceof Object) {
    return JSON.stringify(v);
  }
  return window.encodeURIComponent(`${v}`);
}

function checkQueryValue(v) {
  return (
    v != null
    && v !== ''
    && (!Array.isArray(v) || v.length !== 0)
    && !_.isNaN(v)
  );
}

export const queryWriter = {
  write(f, exclude) {
    return Object.keys(f)
      .filter((k) => exclude == null || !exclude.includes(k))
      .filter((k) => checkQueryValue(f[k]))
      .map((k) => `${k}=${parseQueryValue(f[k])}`)
      .join('&');
  },
};

function QueryParams(base) {
  this.base = base;
  this.resourceID = null;
  this.query = new Map();
}

QueryParams.prototype = {
  updateResource(id) {
    this.resourceID = id;
    return this;
  },

  updateBase(base) {
    this.base = base;
    return this;
  },

  updateQuery(key, value) {
    if (checkQueryValue(value)) {
      this.query.set(key, value);
    } else {
      this.query.delete(key);
    }
    return this;
  },

  buildQuery() {
    const parts = [];
    // eslint-disable-next-line no-restricted-syntax
    for (const [key, value] of this.query) {
      parts.push(`${key}=${parseQueryValue(value)}`);
    }
    return parts.join('&');
  },

  toState() {
    const path = process.env.APP_RUNTIME !== 'electron'
      ? `/${this.base}/${this.resourceID}`
      : `#/${this.base}/${this.resourceID}`;
    const query = this.buildQuery();
    return query !== '' ? `${path}?${query}` : path;
  },

  writeHistory() {
    window.history.replaceState(null, null, this.toState());
  },
};

export default function queryParams(base) {
  return new QueryParams(base);
}