import _ from 'lodash';
import { getHeaders, jwt } from '../../../../constants/globals';

function formatResponseString(resp) {
  if (resp.status === 401) {
    return 'Your session has expired. Please reload the page and log in again.';
  }
  if (resp.status === 403) {
    return 'You cannot access this resource';
  }
  return `URL: ${resp.url}, Status: ${resp.status}`;
}

function throwErrorMessage(r) {
  throw new Error(JSON.stringify(r));
}

export function responseHandler(resp) {
  if (!resp.ok) {
    return resp.text().then((msg) => {
      throw new Error(`${formatResponseString(resp)}, Message : ${msg}`);
    });
  }
  return resp.json().catch(() => Promise.resolve());
}

function mapWithDefault(defaultValue) {
  const m = new Map();
  return {
    get(id) {
      const v = m.get(id);
      return v != null ? v : defaultValue;
    },

    set(id, value) {
      m.set(id, value);
    },

    has(id) {
      return m.has(id);
    },

    toArray() {
      return [...m.values()];
    },
  };
}

export function authFetch(req, getState, params, handler) {
  const headers = getHeaders(jwt());
  return fetch(
    req, {
      headers,
      ...(params != null ? params : {}),
    },
  ).then(handler != null ? handler : responseHandler);
}

export function authFetchWithToken(req, params, handler) {
  const headers = getHeaders(jwt());
  return fetch(
    req, {
      headers,
      ...(params != null ? params : {}),
    },
  ).then(handler != null ? handler : responseHandler);
}

export function fetchUsernamesWithToken(api, content, getID) {
  const id = _(content);
  _.uniqBy(getID)
    .map(getID);
  const m = mapWithDefault({
    username: 'N/A',
    profiles: ['N/A'],
  });
  if (id.length === 0 || !api.user) {
    return Promise.resolve(m);
  }
  return authFetchWithToken(`${api.user}/details/`, {
    method: 'POST',
    body: JSON.stringify({
      id,
    }),
  })
    .then((resp) => {
      resp
        .map((r) => ({
          username: r.email != null ? r.email : r.pseudo,
          id: r.dreemer,
          profiles: r.profiles,
        }))
        .forEach((u) => m.set(u.id, u));
      return m;
    })
    .catch(m.constant());
}

export function fetchUsernames(getState, api, content, getID) {
  return fetchUsernamesWithToken(api, content, getID);
}

export function authFetchFunc(getState) {
  return (req, params, handler) => authFetch(req, getState, params, handler);
}

function throwTextMessage(t) {
  throw new Error(t);
}

function noContentHandler(resp) {
  if (!resp.ok) {
    if (resp.headers.get('Content-Type') === 'application/json') {
      return resp.json().then(throwErrorMessage);
    }
    return resp.text().then(throwTextMessage);
  }
  if (resp.status === 204) {
    throw new Error('Content not yet available');
  }
  return resp.json();
}

export function authFetchOptional(req, getState, params) {
  return authFetch(req, getState, params, noContentHandler);
}

export function downloadBlob(b, filename) {
  const url = window.URL.createObjectURL(b);
  const a = document.createElement('a');
  a.style.display = 'none';
  document.body.appendChild(a);
  a.setAttribute('download', filename);
  a.setAttribute('href', url);
  a.click();
  setTimeout(() => {
    document.body.removeChild(a);
    window.URL.revokeObjectURL(url);
  }, 1000);
}