import React from 'react';
import _ from 'lodash';
import PropTypes from 'prop-types';
import ScoringPlot from './ScoringPlot';
import { stagingSleepOnsetConfig } from '../models/staging';

function HorigramPlot(props) {
  const {
    serie,
    range,
    recordInfo,
    onRangeChange,
  } = props;

  function selectStages(stages) {
    return _(stages)
      .sortBy((s) => s.start)
      .takeWhile((s) => s.stage !== _.last(stagingSleepOnsetConfig.domain))
      .value();
  }

  return (
    <ScoringPlot
      fitData
      serie={selectStages(serie)}
      range={range}
      recordInfo={recordInfo}
      onRangeChange={onRangeChange}
      stagingDomain={stagingSleepOnsetConfig.domain}
    />
  );
}

HorigramPlot.defaultProps = {
  serie: null,
  range: null,
  recordInfo: null,
  onRangeChange: null,
};

HorigramPlot.propTypes = {
  serie: PropTypes.instanceOf(Object),
  range: PropTypes.instanceOf(Object),
  recordInfo: PropTypes.instanceOf(Object),
  onRangeChange: PropTypes.func,
};

export default HorigramPlot;
