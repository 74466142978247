/* eslint-disable react/require-default-props */
/* eslint-disable react/no-array-index-key */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable no-return-assign */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable react/prop-types */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable max-classes-per-file */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import styles from './ApplicationContainer.module.scss';
import Dialog from '../../../shared/dialog/Dialog';
import { ReactComponent as Chevron } from '../../../../assets/images/icons/chevron.svg';

class AppDrawer extends Component {
  constructor(props) {
    super(props);
    this.drawer = null;
  }

  render() {
    return (
      <div
        ref={(e) => (this.drawer = e)}
        className={`${styles.drawer} ${this.props.visible ? styles.drawerVisible : ''}`}
      >
        <div className={styles.drawerContainer}>
          <div className={styles.drawerContent}>
            <button className={styles.toolBoxButton} type="button" onClick={this.props.toggleDrawer}>
              <Chevron fill="#fff" width="7px" className={styles.chevron} />
              Toolbox
            </button>
            {this.props.children}
          </div>
        </div>
      </div>
    );
  }
}

export default class ApplicationContainer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      navigationVisible: false,
    };
    this.help = this.help.bind(this);
    this.openNavigation = this.openNavigation.bind(this);
    this.closeNavigation = this.closeNavigation.bind(this);
    this.dismissError = this.dismissError.bind(this);
  }

  help() {
    const url = window.location.origin + (this.props.docLink != null ? this.props.docLink : '/docs/README.html');
    window.open(url, '_blank');
  }

  openNavigation() {
    this.setState({ navigationVisible: true });
  }

  closeNavigation() {
    this.setState({ navigationVisible: false });
  }

  dismissError() {
    if (this.props.clearErrorMessage) {
      this.props.clearErrorMessage();
    }
  }

  render() {
    return (
      <div className={styles.container}>
        <div className={styles.content}>
          {this.props.children}
        </div>
        <Dialog
          title="Apps"
          display={!this.props.errorMessage && this.state.navigationVisible}
          handleCloseDialog={this.closeNavigation}
          content={(
            <nav className={styles.navigation}>
              {this.props.apps != null
                ? this.props.apps.map(
                  (app, i) => React.cloneElement(
                    app,
                    { key: i, closeNavigation: this.closeNavigation },
                  ),
                )
                : null}
            </nav>
          )}
        />
        {this.props.drawer != null ? (
          <AppDrawer
            visible={this.props.drawerVisible}
            toggleDrawer={this.props.toggleDrawer}
          >
            {this.props.drawer}
          </AppDrawer>
        ) : null}
        <Dialog
          title="Error"
          display={!!this.props.errorMessage}
          handleCloseDialog={this.dismissError}
          content={(
            <p>{`${this.props.errorMessage}`}</p>
          )}
        />
      </div>
    );
  }
}

ApplicationContainer.propTypes = {
  docLink: PropTypes.string,
  clearErrorMessage: PropTypes.func.isRequired,
  errorMessage: PropTypes.instanceOf(Object),
  apps: PropTypes.arrayOf(PropTypes.node),
  drawer: PropTypes.node,
  drawerVisible: PropTypes.bool,
};
