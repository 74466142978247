/* eslint-disable import/no-extraneous-dependencies */
import {
  combineReducers,
} from 'redux';
import _ from 'lodash';
import {
  TIMESERIES as ts,
  APP as ap,
} from '../constants/actionTypes';
import newStaging from '../models/staging';
import {
  element,
  contentTypes,
} from '../models/content';
import {
  defaultConfiguration,
} from '../models/profile';

function series(state = [], action) {
  switch (action.type) {
    case ts.UPDATE_SERIE:
    case ts.LOAD_RECORD:
      return action.series;
    default:
      return state;
  }
}

function signals(state = [], action) {
  switch (action.type) {
    case ts.LOAD_RECORD:
      return action.signals != null ? action.signals : state;
    default:
      return state;
  }
}

function selectedSignals(state = [], action) {
  switch (action.type) {
    case ts.SET_SIGNAL_SELECTION:
      return action.selection;
    case ts.LOAD_RECORD:
      return [];
    default:
      return state;
  }
}

function range(state = {}, action) {
  switch (action.type) {
    case ts.UPDATE_PROGRESS:
      return state.update(action.current);
    case ts.UPDATE_THRESHOLD:
      return state.updateThreshold(action.threshold);
    case ts.UPDATE_WINDOW:
    case ts.LOAD_RECORD:
      return action.range;
    default:
      return state;
  }
}

function staging(state = {}, action) {
  switch (action.type) {
    case ts.START_STAGING:
      return {
        ...state,
        enabled: true,
        stages: newStaging().setStages(
          action.stages,
          action.start,
          action.config,
          action.cursor,
        ),
        signal: action.signal != null ? action.signal : '',
        savingContext: action.savingContext,
      };
    case ts.STOP_STAGING:
      return {
        ...state,
        enabled: false,
        stages: newStaging(),
        savingContext: null,
      };
    case ts.UPDATE_STAGING_CURSOR:
      return {
        ...state,
        stages: state.stages.updateCursor(action.cursor),
      };
    case ts.SET_STAGE:
      return {
        ...state,
        stages: state.stages.add(action.stage),
      };
    case ts.UPDATE_PROGRESS:
      return {
        ...state,
        stages: state.stages.updateCursor(action.current),
      };
    default:
      return state;
  }
}

function selection(state = {}, action) {
  switch (action.type) {
    case ts.SET_SELECTION:
      return state.updateSelection(
        action.selection.start,
        action.selection.end,
        action.selection.signals,
      );
    case ts.SET_MARK:
      return state.setMark(action.mark.location, action.mark.signals);
    default:
      return state;
  }
}

function groups(state = {}, action) {
  switch (action.type) {
    case ts.LOAD_RECORD:
      return action.groups;
    default:
      return state;
  }
}

function labels(state = {}, action) {
  switch (action.type) {
    case ts.ADD_EDIT_LABEL:
      return state.update(action.label);
    case ts.REMOVE_LABEL:
      return state.remove(action.label);
    case ts.SET_LABEL:
      return action.labels;
    case ts.SELECT_LABEL:
      return state.select(action.id);
    case ts.TOGGLE_LABEL_EDIT_MODE:
      return state.toggleEdit();
    case ts.PUSH_COMMIT_LABEL:
      return state.enqueueCommit(action.label);
    case ts.FLUSH_COMMIT_LABEL:
      return state.insert(action.payload).clearCommitQueue();
    case ts.TOGGLE_LABEL_CHANNEL_LAYOUT:
      return state.toggleChannelLayout();
    default:
      return state;
  }
}

function stimulations(state = [], action) {
  switch (action.type) {
    case ts.SET_STIMULATIONS:
      return action.stimulations;
    default:
      return state;
  }
}

function content(state = {}, action) {
  switch (action.type) {
    case ts.ADD_CONTENT:
      return state.concat(action.elements);
    case ts.REMOVE_CONTENT:
      return state.remove(action.ids);
    case ts.UPDATE_CONTENT:
      return action.content;
    case ts.START_STAGING:
      return state.add(element(contentTypes.SCORING_PROGRESS));
    case ts.STOP_STAGING:
      return state.removeByType(contentTypes.SCORING_PROGRESS);
    default:
      return state;
  }
}

function fitMode(state = false, action) {
  switch (action.type) {
    case ts.TOGGLE_FIT_MODE:
      return !state;
    default:
      return state;
  }
}

function zoomMode(state = false, action) {
  switch (action.type) {
    case ts.TOGGLE_ZOOM_MODE:
      return !state;
    default:
      return state;
  }
}

function measureMode(state = false, action) {
  switch (action.type) {
    case ts.TOGGLE_MEASURE_MODE:
      return !state;
    default:
      return state;
  }
}

function seriesCache(state = {}, action) {
  switch (action.type) {
    case ts.START_SERIES_CACHE:
      return state.start(action.config);
    case ts.STOP_SERIES_CACHE:
      return state.stop();
    case ts.UPDATE_PROGRESS:
      return state.update(action.current);
    default:
      return state;
  }
}

function algoOverview(state = [], action) {
  switch (action.type) {
    case ts.SET_ALGO_OVERVIEW:
      return action.algoOverview;
    default:
      return state;
  }
}

function highlights(state = [], action) {
  switch (action.type) {
    case ts.SET_HIGHLIGHTS:
      return action.highlights;
    case ts.START_STAGING:
      if (action.config.highlight) {
        return [{
          start: action.cursor,
          end: action.cursor + action.config.window,
        }];
      }
      return state;
    case ts.STOP_STAGING:
      return [];
    case ts.SET_STAGE:
      if (action.config.highlight) {
        const next = action.cursor + action.config.window;
        return [{
          start: next,
          end: next + action.config.window,
        }];
      }
      return state;
    case ts.UPDATE_STAGING_CURSOR:
      if (action.config.highlight) {
        return [{
          start: action.cursor,
          end: action.cursor + action.config.window,
        }];
      }
      return state;
    default:
      return state;
  }
}

function recordInfo(state = null, action) {
  switch (action.type) {
    case ts.LOAD_RECORD:
      return action.recordInfo !== undefined ? action.recordInfo : state;
    default:
      return state;
  }
}

function referenceLines(state = [], action) {
  switch (action.type) {
    case ts.SET_REFERENCE_LINES:
      return action.referenceLines;
    default:
      return state;
  }
}

function updateSelectedConfig(state, update) {
  const {
    configurations,
    selected,
  } = state;
  return {
    selected,
    configurations: configurations.map(
      (c) => (c.name === selected ? ({ ...c, ...update }) : c),
    ),
  };
}

function profile(state = {}, action) {
  switch (action.type) {
    case ts.CREATE_CONFIGURATION: {
      const {
        configurations,
      } = state;
      if (configurations.find((c) => c.name === action.name) != null) {
        return state;
      }
      const newCfg = defaultConfiguration(action.name);
      return {
        selected: newCfg.name,
        configurations: configurations.concat(newCfg),
      };
    }
    case ts.DELETE_CONFIGURATION:
      if (action.name === 'default') {
        return state;
      }
      return {
        selected: 'default',
        configurations: state.configurations.filter(
          (c) => c.name !== action.name,
        ),
      };
    case ts.UPDATE_CONFIGURATION:
      return updateSelectedConfig(state, action.updated);
    case ts.TOGGLE_LIMITER_MODE:
      return updateSelectedConfig(state, {
        limiterMode: action.checked,
      });
    case ts.UPDATE_WINDOW:
      return updateSelectedConfig(state, {
        windowRange: action.range.window,
      });
    case ts.LOAD_RECORD: {
      const selected = action.selectedProfile != null ? action.selectedProfile : state.selected;
      return {
        selected,
        configurations: state.configurations.map(
          (c) => (c.name === selected ? {
            ...c,
            signalsConfig: action.series.map((s) => s.signal),
            filtersConfig: action.filtersConfig != null
              ? action.filtersConfig : c.filtersConfig,
          }
            : c),
        ),
      };
    }
    default:
      return state;
  }
}

function numberDisplay(state = [], action) {
  switch (action.type) {
    case ts.TOGGLE_NUMBER_DISPLAY:
      return state.find((s) => s === action.signal) != null
        ? state.filter((s) => s !== action.signal)
        : state.concat(action.signal);
    default:
      return state;
  }
}

function hiddenSeries(state = [], action) {
  switch (action.type) {
    case ts.TOGGLE_HIDDEN_SERIE:
      return _.includes(state, action.signal)
        ? _.filter(state, (s) => s !== action.signal)
        : state.concat(action.signal);
    default:
      return state;
  }
}

const INITIAL_STATE = {
  icon: null,
  title: null,
  text: null,
};

function notificationTS(state = INITIAL_STATE, action) {
  let nextState;
  switch (action.type) {
    case 'SET_NOTIFICATION':
      nextState = {
        ...state,
        icon: action.value.icon,
        title: action.value.title,
        text: action.value.text,
      };
      return nextState;
    default:
      return state;
  }
}

const timeSeries = combineReducers({
  recordInfo,
  range,
  series,
  signals,
  selectedSignals,
  seriesCache,
  staging,
  selection,
  groups,
  labels,
  stimulations,
  algoOverview,
  content,
  highlights,
  fitMode,
  zoomMode,
  measureMode,
  referenceLines,
  numberDisplay,
  hiddenSeries,
  profile,
  notificationTS,
});

function user(state = null) {
  return state;
}

function app(state = {}, action) {
  switch (action.type) {
    case ap.SET_TIMESERIES_ERROR_MESSAGE:
      return {
        ...state,
        errorMessage: action.errorMessage,
      };
    case ap.SET_TIMESERIES_PROGRESS:
      return {
        ...state,
        progress: action.value,
      };
    case ap.CLEAR_TIMESERIES_ERROR_MESSAGE:
      return {
        ...state,
        progress: false,
        errorMessage: null,
      };
    default:
      return state;
  }
}

export default combineReducers({
  timeSeries,
  user,
  app,
});