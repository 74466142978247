import React from 'react';
import Lottie from 'react-lottie-player';
import lottieIcon from '../../../assets/lotties/loading.json';

export default function LoadingIcon() {
  return (
    <Lottie
      loop
      animationData={lottieIcon}
      play
      style={{ width: 46, height: 46 }}
    />
  );
}