/* eslint-disable class-methods-use-this */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable react/prop-types */
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { createSelector } from 'reselect';
// import { storageKey, adminRoles } from '../constants/globals';
import { displayHypnogram, closeContent } from '../actions/timeseriesContent';
import { authFetchWithToken } from '../actions/utils';
import { contentTypes } from '../models/content';

class TimeSeriesStagingSelector extends Component {
  constructor(props) {
    super(props);
    this.onHypnoChange = this.onHypnoChange.bind(this);
  }

  onHypnoChange() {
    if (this.isDisplayed()) {
      this.props.dispatch(closeContent(contentTypes.HYPNOGRAM));
    } else {
      this.props.dispatch(displayHypnogram(this.props.recordID));
    }
  }

  fetchIfValid(baseURL, p) {
    if (baseURL == null) {
      return Promise.resolve();
    }
    return authFetchWithToken(`${baseURL}/${p}`, this.props.token);
  }

  checkUserRole() {
    return true;
    // return adminRoles.includes(
    //   window.atob(window.localStorage.getItem(storageKey.role)),
    // );
  }

  isDisplayed() {
    return !!this.props.content.getIds(contentTypes.HYPNOGRAM).length;
  }

  render() {
    return (
      <>
        {this.checkUserRole() && (
          <button
            style={this.isDisplayed() ? { border: '2px solid #FF0064', margin: '-1px' } : {}}
            type="button"
            onClick={this.onHypnoChange}
          >
            Display hypnogram
          </button>
        )}
      </>
    );
  }
}

const seriesSelector = createSelector(
  (state) => ({
    content: state.timeSeries.content,
    token: state.user.token,
  }),
  (x) => x,
);
export default connect(seriesSelector)(TimeSeriesStagingSelector);
