/* eslint-disable no-return-assign */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable react/prop-types */
/* eslint-disable react/no-deprecated */
/* eslint-disable import/no-extraneous-dependencies */
import React, { Component } from 'react';
import { select, mouse } from 'd3-selection';
import { scaleLog, scaleSequential, scaleTime } from 'd3-scale';
import { interpolateGreys as interpolateColors } from 'd3-scale-chromatic';
import styles from './SpectrogramPlot.module.scss';

function createPlot(cvs, el, size, props) {
  const { data } = props.serie;

  // FIXME: Hardcoded scale
  const logScale = scaleLog().domain([1, 100000]);
  const colorLogScale = scaleSequential((d) => interpolateColors(logScale(d)));

  const trange = data.length;
  const frange = data[0].length;

  const ctx = cvs.getContext('2d');
  const dx = size.width / trange;
  const dy = size.height / frange;

  const renderLimit = 4;
  let i = 0;
  let id = null;
  function draw() {
    let count = 0;
    for (; i < trange && count < renderLimit; i += 1, count += 1) {
      for (let j = 0, k = frange - 1; j < frange; j += 1, k -= 1) {
        ctx.fillStyle = colorLogScale(data[i][j]);
        ctx.fillRect(i * dx, k * dy, dx, dy);
      }
    }
    if (count > 0) {
      id = window.requestAnimationFrame(draw);
    }
  }
  id = window.requestAnimationFrame(draw);

  const xScale = scaleTime()
    .range([0, size.width])
    .domain([props.range.start, props.range.end]);
  const svg = select(el)
    .append('svg')
    .attr('width', '100%')
    .attr('height', '100%');
  const cursor = svg
    .append('line')
    .attr('stroke', 'deeppink')
    .attr('stroke-width', 2)
    .attr('y1', 0)
    .attr('y2', size.height);

  function update(range) {
    const x = xScale(range.current);
    cursor.attr('x1', x).attr('x2', x);
  }

  update(props.range);

  svg
    .append('rect')
    .attr('height', size.height)
    .attr('width', size.width)
    .attr('fill', 'none')
    .style('pointer-events', 'all')
    .on('click', () => {
      props.onRangeChange(xScale.invert(mouse(this)[0]).getTime());
    });

  return {
    update,

    destroy() {
      if (id) {
        window.cancelAnimationFrame(id);
      }
      svg.remove();
    },
  };
}

export default class SpectrogramPlot extends Component {
  componentDidMount() {
    const size = {
      width: this.el.offsetWidth,
      height: this.el.offsetHeight,
    };
    this.canvas.width = size.width;
    this.canvas.height = size.height;
    this.plot = createPlot(this.canvas, this.el, size, this.props);
  }

  componentWillReceiveProps(nextProps) {
    if (this.plot && nextProps.range !== this.props.range) {
      this.plot.update(nextProps.range);
    }
  }

  componentWillUnmount() {
    if (this.plot) {
      this.plot.destroy();
      this.plot = null;
    }
  }

  render() {
    return (
      <div className={styles.wrapper}>
        <div className={styles.container} ref={(el) => (this.el = el)}>
          <canvas ref={(c) => (this.canvas = c)} />
        </div>
      </div>
    );
  }
}
