/* eslint-disable import/prefer-default-export */
import {
  backendURL,
  subjectsEndPoint,
  jwt,
  getHeaders,
} from '../../../../constants/globals';

function fetchUsers(content, userID) {
  const users = content || [];
  if (userID) users.push(userID);
  const res = [];
  if (users.length === 0) {
    return Promise.resolve(res);
  }
  const headers = getHeaders(jwt());
  return fetch(`${backendURL()}/dreemer/dreemer/details/`, {
    headers,
    method: 'POST',
    mode: 'cors',
    body: JSON.stringify({
      id: users,
    }),
  }).then((response) => response.json()).then((resp) => {
    resp
      .map((user) => ({
        id: user.dreemer,
        first_name: user.first_name,
        last_name: user.last_name,
        email: user.email,
        pseudo: user.pseudo,
        day_of_birth: user.day_of_birth,
        month_of_birth: user.month_of_birth,
        year_of_birth: user.year_of_birth,
        gender: user.gender,
      }))
      .forEach((u) => res.push(u));
    return res;
  });
}

export function fetchAllSubjects() {
  const headers = getHeaders(jwt());
  return fetch(subjectsEndPoint, {
    headers,
    method: 'GET',
    mode: 'cors',
  }).then((response) => response.json()).then((resp) => Promise.all([
    fetchUsers(resp.allowed_users, resp.user),
  ]).then((r) => r[0]));
}