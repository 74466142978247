export const TIMESERIES = {
  UPDATE_SERIE: 'UPDATE_SERIE',
  UPDATE_THRESHOLD: 'UPDATE_THRESHOLD',
  UPDATE_PROGRESS: 'UPDATE_PROGRESS',
  UPDATE_WINDOW: 'UPDATE_WINDOW',
  LOAD_RECORD: 'LOAD_RECORD',
  START_STAGING: 'START_STAGING',
  STOP_STAGING: 'STOP_STAGING',
  SET_STAGE: 'SET_STAGE',
  UPDATE_STAGING_CURSOR: 'UPDATE_STAGING_CURSOR',
  SET_SELECTION: 'SET_SELECTION',
  SET_MARK: 'SET_MARK',
  SET_LABEL: 'SET_LABEL',
  ADD_EDIT_LABEL: 'ADD_EDIT_LABEL',
  PUSH_COMMIT_LABEL: 'PUSH_COMMIT_LABEL',
  FLUSH_COMMIT_LABEL: 'FLUSH_COMMIT_LABEL',
  REMOVE_LABEL: 'REMOVE_LABEL',
  SELECT_LABEL: 'SELECT_LABEL',
  TOGGLE_LABEL_CHANNEL_LAYOUT: 'TOGGLE_LABEL_CHANNEL_LAYOUT',
  RESET_TIMESERIES: 'RESET_TIMESERIES',
  ADD_CONTENT: 'ADD_CONTENT',
  REMOVE_CONTENT: 'REMOVE_CONTENT',
  UPDATE_CONTENT: 'UPDATE_CONTENT',
  TOGGLE_FIT_MODE: 'TOGGLE_FIT_MODE',
  TOGGLE_MEASURE_MODE: 'TOGGLE_MEASURE_MODE',
  TOGGLE_ZOOM_MODE: 'TOGGLE_ZOOM_MODE',
  TOGGLE_LIMITER_MODE: 'TOGGLE_LIMITER_MODE',
  TOGGLE_LABEL_EDIT_MODE: 'TOGGLE_LABEL_EDIT_MODE',
  SET_VALUE_WINDOW: 'SET_VALUE_WINDOW',
  SET_STIMULATIONS: 'SET_STIMULATIONS',
  SET_ALGO_OVERVIEW: 'SET_ALGO_OVERVIEW',
  START_SERIES_CACHE: 'START_SERIES_CACHE',
  STOP_SERIES_CACHE: 'STOP_SERIES_CACHE',
  SET_HIGHLIGHTS: 'SET_HIGHLIGHTS',
  SET_REFERENCE_LINES: 'SET_REFERENCE_LINES',
  CREATE_CONFIGURATION: 'CREATE_CONFIGURATION',
  UPDATE_CONFIGURATION: 'UPDATE_CONFIGURATION',
  DELETE_CONFIGURATION: 'DELETE_CONFIGURATION',
  SET_SIGNAL_SELECTION: 'SET_SIGNAL_SELECTION',
  TOGGLE_NUMBER_DISPLAY: 'TOGGLE_NUMBER_DISPLAY',
  TOGGLE_HIDDEN_SERIE: 'TOGGLE_HIDDEN_SERIE',
};

export const APP = {
  SET_TIMESERIES_PROGRESS: 'SET_TIMESERIES_PROGRESS',
  SET_TIMESERIES_ERROR_MESSAGE: 'SET_TIMESERIES_ERROR_MESSAGE',
  CLEAR_TIMESERIES_ERROR_MESSAGE: 'CLEAR_TIMESERIES_ERROR_MESSAGE',
};