import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import styles from './Notification.module.scss';
import { ReactComponent as CloseIcon } from '../../../assets/images/icons/close.svg';

function Notification(props) {
  const {
    dispatch,
    data,
  } = props;

  function handleClose() {
    dispatch({
      type: 'SET_NOTIFICATION',
      value: {
        icon: null,
        title: null,
        text: null,
      },
    });
  }

  useEffect(() => {
    if (data.icon || data.title || data.text) {
      setTimeout(() => {
        handleClose();
      }, 5000);
    }
  });

  return (
    <div className={`${styles.container} ${!data.text ? styles.hidden : ''}`}>
      {data.icon}
      <div className={styles.content}>
        <span className={styles.title}>{data.title}</span>
        <span className={styles.text}>{data.text}</span>
      </div>
      <CloseIcon
        className={styles.closeIcon}
        fill="#7A71A6"
        width="12px"
        height="12px"
        onClick={() => handleClose()}
      />
    </div>
  );
}

Notification.defaultProps = {
  dispatch: null,
  data: null,
};

Notification.propTypes = {
  dispatch: PropTypes.instanceOf(Object),
  data: PropTypes.instanceOf(Object),
};

export default Notification;