import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { v4 as uuidv4 } from 'uuid';
import styles from './ColorPicker.module.scss';
import { ReactComponent as Chevron } from '../../../assets/images/icons/chevron.svg';

function ColorPicker(props) {
  const {
    className,
    onChange,
    value,
  } = props;

  const [opened, setOpened] = useState(false);
  const componentRef = useRef();

  useEffect(() => {
    function handleClick(e) {
      if (componentRef && componentRef.current) {
        const ref = componentRef.current;
        if (!ref.contains(e.target)) {
          setOpened(false);
        }
      }
    }
    document.addEventListener('click', handleClick, { capture: true });
    return () => document.removeEventListener('click', handleClick);
  }, []);

  const defaultColors = [
    '#BEB9FF',
    '#483ECD',
    '#070095',
    '#A2CFFE',
    '#0468C8',
    '#29557E',
    '#98DAAB',
    '#4F9764',
    '#136229',
    '#FDEDA3',
    '#F1CF30',
    '#C89C00',
    '#FDCD8B',
    '#FFA431',
    '#9D540D',
    '#FFA4C0',
    '#D60345',
    '#740128',
    '#EDDAFF',
    '#D0B2ED',
    '#794BA5',
    '#DF9393',
    '#000',
  ];

  return (
    <div className={styles.container} ref={componentRef}>
      <div
        className={`${styles.input} ${className}`}
        onClick={() => setOpened(!opened)}
        onKeyPress={null}
        role="button"
        tabIndex={0}
      >
        <span className={styles.dot} style={{ background: value }} />
        <Chevron
          height="20px"
          fill="#7A71A6"
          className={`${styles.chevron} ${opened ? styles.active : ''}`}
          transform=""
        />
      </div>
      {opened && (
        <div className={styles.list}>
          {defaultColors.map((c) => (
            <button
              key={`color_${c}_${uuidv4}`}
              type="button"
              className={styles.dot}
              style={{ background: c }}
              onClick={() => {
                onChange(c);
                setOpened(false);
              }}
              label={c}
            />
          ))}
        </div>
      )}
    </div>
  );
}

ColorPicker.defaultProps = {
  className: '',
  onChange: null,
  value: '',
};

ColorPicker.propTypes = {
  className: PropTypes.string,
  onChange: PropTypes.func,
  value: PropTypes.string,
};

export default ColorPicker;