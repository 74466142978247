import _ from 'lodash';

function newDomain(name, min, max, units = '') {
  return {
    name,
    units,
    min,
    max,
  };
}

export const domain = {
  eeg: newDomain('EEG', -160, 160, 'uV'),
  accelero: newDomain('Accelerometer', -1.5, 1.5),
  eegRaw: newDomain('RAW', -4000000, 4000000),
  pulseOxi: newDomain('Pulse Oxi', 0, 4194304),
  pulseOxiFiltered: newDomain('Pulse Oxi f.', -400, 400),
  respiration: newDomain('Respiration', -0.004, 0.004),
  eog: newDomain('EOG', -150, 150),
  ecg: newDomain('ECG', -2000, 2000),
  emg: newDomain('EMG', -40, 40),
  quality: newDomain('Quality', 0, 1),
  empty: newDomain('Empty', 0, 0),
};

function findDomain(d) {
  switch (d) {
    case 'EEG':
      return domain.eeg;
    case 'RAW':
      return domain.eegRaw;
    case 'ACCELEROMETER':
      return domain.accelero;
    case 'PULSE_OXIMETER_FILTERED':
      return domain.pulseOxiFiltered;
    case 'PULSE_OXIMETER':
      return domain.pulseOxi;
    case 'RESPIRATION':
      return domain.respiration;
    case 'EOG':
      return domain.eog;
    case 'ECG':
      return domain.ecg;
    case 'EMG':
      return domain.emg;
    case 'QUALITY':
      return domain.quality;
    default:
      return domain.empty;
  }
}

export function ensureDomain(s) {
  return typeof s.domain === 'string' ? {
    ...s,
    domain: {
      ...findDomain(s.domain),
    },
  } : {
    ...s,
    domain: {
      ...s.domain,
    },
  };
}

export const scaleReadWrite = {
  read(input) {
    if (typeof input !== 'string') {
      return null;
    }
    const parts = _(input)
      .split(';')
      .map((v) => +v)
      .filter((v) => !_.isNaN(v))
      .sortBy()
      .value();
    if (parts.length !== 2 || parts[0] >= parts[1]) {
      return null;
    }
    return {
      min: parts[0],
      max: parts[1],
    };
  },

  write(d) {
    if (d == null || d.min == null || d.max == null) {
      return null;
    }
    return `${d.min};${d.max}`;
  },
};