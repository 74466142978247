/* eslint-disable react/prop-types */
/* eslint-disable react/destructuring-assignment */
import React, { Component } from 'react';
import _ from 'lodash';
import { transform } from './utils';

export default class ReferenceLines extends Component {
  shouldComponentUpdate(nextProps) {
    if (
      this.props.referenceLines.length === 0
      && nextProps.referenceLines.length === 0
    ) {
      return false;
    }
    return (
      this.props.yScales !== nextProps.yScales
      || this.props.referenceLines !== nextProps.referenceLines
      || this.props.size !== nextProps.size
    );
  }

  render() {
    return _.flatMap(this.props.referenceLines, (l) => _.map(l.signals, (s) => {
      const sci = _.findIndex(this.props.series, (se) => se.signal.path === s);
      if (sci < 0) {
        return null;
      }
      const sc = this.props.yScales[sci];
      return (
        <g
          key={`${l.value}-${s}`}
          className="plot-reference-lines"
          transform={transform(0, sc(l.value))}
        >
          <line
            strokeOpacity="0.8"
            strokeDasharray="5,5"
            stroke="gray"
            strokeWidth="0.8"
            x1={0}
            x2={this.props.size.width - 10}
          />
          <text dy=".35em" x={this.props.size.width - 5}>
            {l.value > 0 ? `+${l.value}` : l.value}
          </text>
        </g>
      );
    }));
  }
}
