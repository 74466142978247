/* eslint-disable react/prop-types */
/* eslint-disable react/destructuring-assignment */
import React, { Component } from 'react';
import Dialog from '../../../../shared/dialog/Dialog';

const titleStyle = {
  fontSize: '1em',
  marginBottom: '1em',
  fontWeight: 'normal',
};

export default class LabelMarkerDialog extends Component {
  constructor(props) {
    super(props);
    this.state = {
      value: '',
    };
  }

  apply() {
    if (this.state.value !== '') {
      this.props.onApply(this.state.value);
    } else {
      this.props.close();
    }
  }

  render() {
    const actions = [
      <button type="button" key="cancel" onClick={this.props.close}>
        Cancel
      </button>,
      <button type="button" key="ok" onClick={this.apply}>
        Ok
      </button>,
    ];
    return (
      <Dialog
        title="Add label marker"
        display
        handleCloseDialog={this.props.close}
        content={(
          <>
            <h2 style={titleStyle}>Set value</h2>
            <input
              type="text"
              placeholder="Value"
              value={this.state.value}
              onChange={(e) => this.setState({ value: e.target.value })}
              onPressEnter={this.apply}
            />
            {actions}
          </>
        )}
      />
    );
  }
}
