import _ from 'lodash';
import {
  validateConfiguration,
} from './profile';

const newID = (() => {
  let id = 1;
  return () => {
    id += 1;
    return id;
  };
});

function Serie(recordID = '', signal = '', values = [], id = 1) {
  this.recordID = recordID;
  this.signal = signal;
  this.values = values;
  this.id = id;
}

Serie.prototype = {
  update(v) {
    return new Serie(this.recordID, this.signal, v, this.id);
  },

  merge(v, range) {
    if (v.length === range.threshold) {
      return this.update(v.slice());
    }
    const r = range.range();
    const res = _(this.values)
      .concat(v)
      .filter((s) => s.time >= r.start && s.time < r.end)
      .sortBy((s) => s.time)
      .value();
    return this.update(res);
  },

  range() {
    const {
      values,
    } = this;
    return {
      start: values[0].time,
      end: values[values.length - 1].time,
    };
  },

  count(from, to) {
    const {
      values,
    } = this;
    let res = 0;
    for (let i = 0; i < values.length; i += 1) {
      const {
        time,
      } = values[i];
      if (time >= from && time < to) {
        res += 1;
      } else if (time > to) {
        break;
      }
    }
    return res;
  },
};

export default function serie(recordID, signal, values) {
  return new Serie(recordID, signal, values, newID());
}

export function defaultSeries(recordID, signals, cfg) {
  const f = cfg != null
    && validateConfiguration(cfg, signals)
    && cfg.signalsConfig.length > 0
    ? (s) => _.includes(cfg.signalsConfig, s.path)
    : (s) => s.default;
  return _(signals)
    .filter(f)
    .map((s) => new Serie(recordID, s.path, [], newID()))
    .value();
}

export function signalsToSeries(recordID, signals) {
  return _.map(signals, (s) => new Serie(recordID, s.path, [], newID()));
}