/* eslint-disable import/prefer-default-export */
import {
  backendURL,
  headbandURL,
  jwt,
  getHeaders,
  mapWithDefault,
} from '../../../constants/globals';

function fetchUser(content) {
  const id = content ? content.map((r) => (r.user)) : [];
  const m = mapWithDefault({
    id: 'N/A',
    username: 'N/A',
  });
  if (id.length === 0) {
    return Promise.resolve(m);
  }
  const headers = getHeaders(jwt());
  return fetch(`${backendURL()}/dreemer/dreemer/details/`, {
    headers,
    method: 'POST',
    mode: 'cors',
    body: JSON.stringify({
      id,
    }),
  }).then((response) => response.json()).then((resp) => {
    resp.forEach((u) => m.set(u.dreemer, u));
    return m;
  });
}

function fetchDevice(content) {
  const id = content ? content.map((r) => (r.device)) : [];
  const m = mapWithDefault({
    nickname: 'N/A',
  });
  if (id.length === 0) {
    return Promise.resolve(m);
  }
  const headers = getHeaders(jwt());
  return fetch(`${headbandURL()}/headband/headband/details/`, {
    headers,
    method: 'POST',
    mode: 'cors',
    body: JSON.stringify({
      id,
    }),
  }).then((response) => response.json()).then((resp) => {
    resp.forEach((u) => m.set(u.id, u));
    return m;
  });
}

export function getRecord(id) {
  const headers = getHeaders(jwt());
  return fetch(`${backendURL()}/record/report/details/`, {
    headers,
    method: 'POST',
    mode: 'cors',
    body: JSON.stringify({
      id: [id],
      latest: true,
    }),
  }).then((response) => response.json()).then((resp) => Promise.all([
    fetchUser(resp),
    fetchDevice(resp),
  ]).then((promise) => {
    const result = resp ? resp.map((record) => ({
      ...record,
      user: promise[0].get(record.user),
      device: promise[1].get(record.device),
    })) : [];
    return result[0];
  }))
    .catch((error) => ({
      err: error,
    }));
}

export function getHypnogram(id) {
  const headers = getHeaders(jwt());
  return fetch(`${backendURL()}/record/record/${id}/latest_hypnogram/`, {
    headers,
    method: 'GET',
    mode: 'cors',
  }).then((response) => response.json());
}

export function getRespirogram(id) {
  const headers = getHeaders(jwt());
  return fetch(`${backendURL()}/record/record/${id}/respirogram/`, {
    headers,
    method: 'GET',
    mode: 'cors',
  }).then((response) => response.json());
}
