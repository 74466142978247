/* eslint-disable prefer-destructuring */
/* eslint-disable prefer-rest-params */
import _ from 'lodash';
import Immutable from 'immutable';

const nonScorable = 'NS';

function stageConfig(name, key) {
  return {
    name,
    key,
  };
}

export const stagingEEGConfig = {
  type: 'EEG',
  domain: ['DEEP', 'N2', 'N1', 'REM', 'WAKE'],
  stages: [
    stageConfig('N1', '1'),
    stageConfig('N2', '2'),
    stageConfig('DEEP', '3'),
    stageConfig('REM', '4'),
    stageConfig('WAKE', '0'),
    stageConfig(nonScorable, '5'),
  ],
  window: 30000,
  highlight: false,
};

export const stagingSleepOnsetConfig = {
  type: 'SleepOnset',
  domain: _()
    .range(10)
    .map((i) => `${i}`)
    .value(),
  stages: _()
    .range(10)
    .map((i) => `${i}`)
    .map((i) => stageConfig(i, i))
    .concat(stageConfig(nonScorable, '?'))
    .value(),
  window: 4000,
  highlight: true,
};

export const stagingRelaxConfig = {
  type: 'Relax',
  domain: ['S0', 'S1', 'S2', 'S3', 'S4', 'S5'],
  stages: [
    stageConfig('S0', '0'),
    stageConfig('S1', '1'),
    stageConfig('S2', '2'),
    stageConfig('S3', '3'),
    stageConfig('S4', '4'),
    stageConfig('S5', '5'),
  ],
  window: 10000,
  highlight: true,
};

export const positiongramConfig = {
  type: 'Positiongram',
  domain: ['RIGHT', 'LEFT', 'FRONT', 'BACK', 'STANDING'],
  stages: [],
  window: 30000,
  highlight: false,
};

export function getStagingConfig(type) {
  switch (type) {
    case stagingEEGConfig.type:
      return stagingEEGConfig;
    case stagingSleepOnsetConfig.type:
      return stagingSleepOnsetConfig;
    case positiongramConfig.type:
      return positiongramConfig;
    default:
      return null;
  }
}

function dataPlotBuilder(prev, v, array) {
  const res = array != null ? array : [];
  if (prev && prev.stage !== nonScorable && prev.end !== v.start) {
    res.push({
      time: new Date(prev.end + (v.start - prev.end) / 2),
      value: '',
    });
  }
  if (v.stage === nonScorable) {
    res.push({
      time: new Date(v.start),
      value: '',
    });
  } else {
    res.push({
      time: new Date(v.start),
      value: v.stage,
    });
    res.push({
      time: new Date(v.end),
      value: v.stage,
    });
  }
  return res;
}

export function toDataPlot(values) {
  return _.flatMap(values, (v, i) => dataPlotBuilder(values[i - 1], v));
}

export function computeStagePercentages(stages) {
  let totalDuration = 0;
  const duration = {};
  _.forEach(stages, (s) => {
    const d = s.end - s.start;
    totalDuration += d;
    duration[s.stage] = (duration[s.stage] || 0) + d;
  });
  _.forEach(Object.keys(duration), (k) => {
    duration[k] = Math.round((duration[k] / totalDuration) * 100);
  });
  return duration;
}

function Stage(s, start, stagingWindow) {
  this.stage = s;
  this.start = start;
  this.end = start + stagingWindow;
}

function doComputeIndexPosition(current, start, stagingWindow) {
  const p = current - start;
  const offset = p % stagingWindow > stagingWindow / 2 ? 1 : 0;
  const index = Math.floor(p / stagingWindow) + offset;
  return {
    index,
    start: start + index * stagingWindow,
  };
}

function Staging(config, values, cursor, start) {
  if (arguments.length === 1 && Immutable.Map.isMap(arguments[0])) {
    this.s = arguments[0];
  } else {
    this.s = Immutable.Map({
      config: config != null ? config : null,
      values: values != null && Immutable.Map.isMap(values)
        ? values
        : Immutable.Map(),
      cursor: cursor != null ? cursor : null,
      start: start != null ? start : null,
    });
  }
}

Staging.prototype = {
  get cursor() {
    return this.s.get('cursor');
  },

  get values() {
    return this.s.get('values');
  },

  get config() {
    return this.s.get('config');
  },

  get start() {
    return this.s.get('start');
  },

  updateCursor(c) {
    return new Staging(this.s.set('cursor', c));
  },

  nextCursor() {
    return this.cursor + this.config.window;
  },

  prevCursor() {
    return Math.max(this.start, this.cursor - this.config.window);
  },

  shouldProgressForward(range) {
    const nextEnd = this.cursor + this.config.window;
    return nextEnd >= range.current + range.window ? this.cursor : null;
  },

  computeIndexPosition() {
    return doComputeIndexPosition(this.cursor, this.start, this.config.window);
  },

  add(s) {
    const p = this.computeIndexPosition();
    const next = p.start + this.config.window;
    return new Staging(
      this.s.withMutations((m) => {
        m.set(
          'values',
          this.values.set(p.index, new Stage(s, p.start, this.config.window)),
        );
        m.set('cursor', next);
      }),
    );
  },

  setStages(stages, start, cfg, cursor) {
    if (cfg == null && this.config == null) {
      throw new Error('Staging config not set');
    }
    if (start == null) {
      throw new Error('Record start not set');
    }
    const config = cfg != null ? cfg : this.config;
    if (stages == null) {
      return new Staging(
        Immutable.Map({
          config,
          start,
          cursor,
          values: this.values,
        }),
      );
    }
    const values = Immutable.Map().withMutations((m) => {
      _.forEach(stages, (s) => {
        const p = doComputeIndexPosition(s.start, start, config.window);
        const stage = _.find(config.stages, (st) => st.name === s.stage);
        if (stage == null) {
          throw new Error('Unknown stage');
        }
        m.set(p.index, new Stage(stage, p.start, config.window));
      });
    });
    return new Staging(
      Immutable.Map({
        config,
        cursor,
        start,
        values,
      }),
    );
  },

  getStageWindow(count) {
    const res = [];
    const { values } = this;
    const { config } = this;
    const { start } = this;
    const p = doComputeIndexPosition(this.cursor, start, config.window);
    for (let i = p.index - count; i <= p.index + count; i += 1) {
      const s = values.get(i);
      res.push(
        new Stage(
          s != null ? s.stage.name : '',
          start + i * config.window,
          config.window,
        ),
      );
    }
    return res;
  },

  toArray() {
    const w = this.config.window;
    const { start } = this;
    const res = Array.from(this.values
      .sortBy((v) => v.start)
      .map((v) => ({
        start: v.start,
        end: v.end,
        stage: v.stage.name,
      // eslint-disable-next-line no-unused-vars
      }))).map(([name, value]) => ({ ...value }));
    if (
      !_.every(res, (s) => s.end - s.start === w && (s.start - start) % w === 0)
    ) {
      throw new Error('Staging cannot be validated');
    }
    return res;
  },

  toPlot() {
    return toDataPlot(this.toArray());
  },
};

export function eegStaging(cursor, start) {
  return new Staging(stagingEEGConfig, null, cursor, start);
}

export function sleepOnsetStaging(cursor, start) {
  return new Staging(stagingSleepOnsetConfig, null, cursor, start);
}

export default function staging(cursor, start) {
  return new Staging(null, null, cursor, start);
}