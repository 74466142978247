/* eslint-disable no-return-assign */
/* eslint-disable react/prop-types */
/* eslint-disable react/destructuring-assignment */
import React from 'react';
import _ from 'lodash';
import shallowCompare from 'react-addons-shallow-compare';
import { select, mouse, event } from 'd3-selection';
import { scalePoint, scaleTime } from 'd3-scale';
import { line as svgLine } from 'd3-shape';
import { axisLeft, axisBottom } from 'd3-axis';
import { drag } from 'd3-drag';
import { transform } from './plot/utils';
import { toDataPlot } from '../models/staging';
import { timestampFormatter } from '../utils/timeUtils';

function computeStagePercentages(serie) {
  let totalDuration = 0;
  const duration = {};
  _.forEach(serie, (s) => {
    const d = s.end - s.start;
    totalDuration += d;
    duration[s.stage] = (duration[s.stage] || 0) + d;
  });
  _.forEach(Object.keys(duration), (k) => {
    duration[k] = Math.round((duration[k] / totalDuration) * 100);
  });
  return duration;
}

function getDataRange(serie) {
  return [serie[0].start, _.last(serie).end];
}

function newTimeFormatter(props) {
  return timestampFormatter(props.recordInfo.timezone, 'HH:mm');
}

const defaultMargin = {
  top: 10, right: 20, bottom: 20, left: 80,
};

function createPlot(el, props) {
  const margin = props.margin || defaultMargin;
  const width = el.offsetWidth - margin.left - margin.right;
  const height = el.offsetHeight - margin.top - margin.bottom;
  const { onRangeChange } = props;
  const svgContainer = select(el)
    .append('svg')
    .attr('width', '100%')
    .attr('height', '100%');
  const svg = svgContainer
    .append('g')
    .attr('transform', transform(margin.left, margin.top));

  const xScale = scaleTime().range([0, width]);

  const xAxis = axisBottom(xScale)
    .ticks(10)
    .tickFormat(newTimeFormatter(props));

  const xAxisElem = svg
    .append('g')
    .classed('x-axis axis', true)
    .attr('transform', transform(0, height));

  const yScale = scalePoint()
    .padding(0.5)
    .range([height, 0]);

  let percentages;

  const yAxis = (hidePercent) => {
    if (hidePercent) return axisLeft(yScale).tickFormat((d) => (d));
    return axisLeft(yScale).tickFormat(
      (d) => (percentages[d] != null ? `${d} (${percentages[d]}%)` : d),
    );
  };

  const yAxisElem = svg.append('g').classed('y-axis axis', true);

  const line = svgLine()
    .x((d) => xScale(d.time))
    .y((d) => yScale(d.value))
    .defined((d) => d.value !== '');

  const path = svg
    .append('path')
    .classed('chart-line', true)
    .attr('stroke', '#000');

  svg
    .append('rect')
    .attr('height', height)
    .attr('width', width)
    .attr('fill', 'none')
    .style('pointer-events', 'all')
    .on('click', () => {
      onRangeChange(xScale.invert(mouse(this)[0]).getTime());
    });

  const brush = svg
    .append('rect')
    .classed('hypnogram-brush', true)
    .attr('fill', 'deeppink')
    .style('opacity', 0.4)
    .attr('y', 0)
    .attr('height', height);

  function getBrushX() {
    return +brush.attr('x');
  }

  const d = drag()
    .on('drag', () => {
      brush.attr(
        'x',
        Math.min(
          Math.max(0, getBrushX() + event.dx),
          width - +brush.attr('width'),
        ),
      );
    })
    .on('end', () => {
      onRangeChange(xScale.invert(getBrushX()).getTime());
    });

  brush.call(d);

  const proba = svgContainer
    .append('text')
    .attr('text-anchor', 'middle')
    .attr('x', margin.left / 2)
    .attr('y', el.offsetHeight - 5);

  return {
    update(p) {
      const timeDomain = p.fitData
        ? getDataRange(p.serie)
        : [p.range.start, p.range.end];
      xScale.domain(timeDomain);
      xAxis.tickFormat(newTimeFormatter(p));
      xAxisElem.call(xAxis);

      percentages = computeStagePercentages(p.serie);
      yScale.domain(p.stagingDomain);
      yAxisElem.call(yAxis(props.hidePercent));

      path.datum(toDataPlot(p.serie)).attr('d', line);

      const r = p.range.range();
      if (p.fitData) {
        const limit = getDataRange(p.serie);
        if (r.start >= limit[0] && r.start < limit[1]) {
          brush
            .attr('x', xScale(r.start))
            .attr('width', 2)
            .style('display', '');
        } else {
          brush.style('display', 'none');
        }
      } else {
        brush
          .attr('x', xScale(r.start))
          .attr('width', Math.max(1, xScale(r.end) - xScale(r.start)));
      }

      if (p.extraData != null) {
        p.plotExtra({
          svg,
          height,
          width,
          xScale,
          yScale,
          data: p.extraData,
        });
      }

      const hasProba = _.get(p.serie, '[0].probability') != null;
      if (hasProba) {
        const currentStage = _.find(
          p.serie,
          (e) => r.start >= e.start && r.start < e.end,
        );
        if (currentStage) {
          proba.text(_.round(currentStage.probability, 2));
        }
      }
    },

    destroy() {
      svgContainer.remove();
    },
  };
}

class ScoringPlot extends React.Component {
  componentDidMount() {
    this.d3Chart = createPlot(this.container, this.props);
    this.d3Chart.update(this.props);
  }

  shouldComponentUpdate(nextProps, nextState) {
    if (shallowCompare(this, nextProps, nextState)) {
      this.d3Chart.update(nextProps);
    }
    return false;
  }

  componentWillUnmount() {
    if (this.d3Chart) {
      this.d3Chart.destroy();
      this.d3Chart = null;
    }
  }

  render() {
    return (
      <div
        className={
          this.props.containerClass != null
            ? this.props.containerClass
            : 'fullsize-container'
        }
        ref={(e) => (this.container = e)}
      />
    );
  }
}

export default ScoringPlot;