import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import {
  Route, Redirect, BrowserRouter as Router, Switch,
} from 'react-router-dom';
import { storageKey } from './constants/globals';
import Store from './reducer/store';
import './index.css';
import '../node_modules/react-tooltip/dist/react-tooltip.css';
import Login from './components/features/auth/Login';
import NoRoute from './components/features/noRoute/NoRoute';
import reportWebVitals from './reportWebVitals';
import Subjects from './components/features/subjects/Subjects';
import Timeseries from './components/features/timeseries/Timeseries';
import Records from './components/features/records/Records';
import Report from './components/features/reportPDF/Report';

function checkTokenValidity(token) {
  if (!token) {
    return false;
  }
  const tokenParts = token.split('.');
  if (tokenParts.length !== 3) {
    return false;
  }
  if (process.env.NODE_ENV === 'production') {
    try {
      const userInfo = JSON.parse(window.atob(tokenParts[1]));
      if (userInfo.exp != null) {
        const expiration = +userInfo.exp;
        if (Number.isNaN(expiration)) {
          return false;
        }
        if (expiration * 1000 - Date.now() < 3600000) {
          return false;
        }
      }
    } catch (e) {
      return false;
    }
  }
  return true;
}

function requireAuth() {
  const user = window.localStorage.getItem(storageKey.user);
  const token = window.localStorage.getItem(storageKey.token);
  if (!user || !checkTokenValidity(token)) {
    return true;
  }
  return false;
}

if (process.env.REACT_APP_DREEM_API === 'staging') document.title = 'STAGING Beacon - Study manager';

const routing = (
  <Provider store={Store}>
    <Router>
      <Switch>
        <Route
          exact
          path="/"
          render={() => <Redirect to="/records" />}
        />
        <Route
          exact
          path="/subjects"
          render={() => (
            requireAuth() ? (
              <Redirect to="/login" />
            ) : (
              <Subjects />
            )
          )}
        />
        <Route
          exact
          path="/records"
          render={() => (
            requireAuth() ? (
              <Redirect to="/login" />
            ) : (
              <Records />
            )
          )}
        />
        <Route
          path="/timeseries/:recordID"
          render={() => (
            requireAuth() ? (
              <Redirect to="/login" />
            ) : (
              <Timeseries />
            )
          )}
        />
        <Route
          path="/reportPDF/:recordID"
          render={() => (
            requireAuth() ? (
              <Redirect to="/login" />
            ) : (
              <Report />
            )
          )}
        />
        <Route path="/login" component={Login} />
        <Route component={NoRoute} />
      </Switch>
    </Router>
  </Provider>
);
ReactDOM.render(routing, document.getElementById('root'));

reportWebVitals();
