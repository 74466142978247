/* eslint-disable no-return-assign */
/* eslint-disable react/no-deprecated */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable react/prop-types */
import React, { Component } from 'react';
import { zoom, zoomIdentity } from 'd3-zoom';
import { select, event } from 'd3-selection';

function isSameTimeScaleDomain(a, b) {
  const da = a.domain();
  const db = b.domain();
  return (
    da[0].getTime() === db[0].getTime() && da[1].getTime() === db[1].getTime()
  );
}

function isZoomIdentity(z) {
  return (
    z.x === zoomIdentity.x && z.y === zoomIdentity.y && z.k === zoomIdentity.k
  );
}

export default class EventListener extends Component {
  constructor(props) {
    super(props);
    this.rangeStart = props.range.start;
    this.rangeEnd = props.range.end;

    this.initZoom();
    this.rid = null;
    this.onZoom = this.onZoom.bind(this);
    this.onWheel = this.onWheel.bind(this);
  }

  componentDidMount() {
    this.rect = select(this.el);
    const that = this;
    this.rect.on('mousedown', () => {
      event.preventDefault();
      that.props.onMouseDown(event, this.el);
    });
    this.updateMeasureMode(this.props);
    this.updateStagingMode(this.props);
  }

  componentWillReceiveProps(nextProps) {
    if (this.props.range !== nextProps.range) {
      // Un jour, on prendra le temps de chercher a quoi sert cette chose.
      // this.rect.call(this.zoom.transform, zoomIdentity);
    }
    if (this.props.measureMode !== nextProps.measureMode) {
      this.updateMeasureMode(nextProps);
    }
    if (this.props.stagingMode !== nextProps.stagingMode) {
      this.updateStagingMode(nextProps);
    } else if (this.props.zoomMode !== nextProps.zoomMode) {
      this.updateZoomMode(nextProps);
    }
  }

  onZoom() {
    this.props.onZoom();
    this.rid = null;
  }

  onWheel() {
    if (this.props.zoomMode || this.props.shiftHandler.shift()) {
      return;
    }
    const d = this.props.xScale.domain();
    const d0 = d[0].getTime();
    const duration = d0 - this.props.xScale.invert(-event.deltaY).getTime();
    const dNext = Math.max(
      this.rangeStart,
      Math.min(d0 + duration, this.rangeEnd),
    );
    this.props.xScale.inner.domain([dNext, dNext + this.props.range.window]);
    this.props.onScrollProgressChange(dNext);
  }

  initZoom() {
    const that = this;
    this.zoom = zoom()
      .scaleExtent([0.1, 20])
      .filter(
        () => !event.button
          && !that.props.stagingMode
          && that.props.zoomMode
          && !that.props.shiftHandler.shift(),
      )
      .on('zoom', () => {
        if (event.sourceEvent == null || isZoomIdentity(event.transform)) {
          return;
        }
        const d = event.transform.rescaleX(that.props.xScaleReference).domain();
        const d0 = d[0].getTime();
        const d1 = d[1].getTime();
        that.props.xScale.inner.domain([
          Math.max(that.rangeStart, d0),
          Math.min(that.rangeEnd, d1),
        ]);
        if (that.rid == null) {
          that.rid = window.requestAnimationFrame(that.onZoom);
        }
      })
      .on('end', () => {
        if (
          isSameTimeScaleDomain(that.props.xScale, that.props.xScaleReference)
        ) {
          return;
        }
        const newDomain = that.props.xScale.domain();
        const newStart = newDomain[0].getTime();
        const newEnd = newDomain[1].getTime();
        that.props.onRangeChange(
          that.props.range.updateWindow(newStart, newEnd - newStart),
        );
      });
  }

  updateMeasureMode(props) {
    if (props.measureMode) {
      const that = this;
      this.rect.on('mousemove', () => {
        that.props.onMouseMove(event, this.el);
      });
    } else {
      this.rect.on('mousemove', null);
    }
  }

  updateStagingMode(props) {
    if (props.stagingMode) {
      this.rect.on('.zoom', null);
      this.rect.on('wheel', null);
    } else {
      this.updateZoomMode(props);
    }
  }

  updateZoomMode(props) {
    if (!props.stagingMode) {
      if (props.zoomMode) {
        this.rect.on('wheel', null);
        // FIXME: Disable buggy mouse pan
        this.rect.call(this.zoom).on('mousedown.zoom', null);
      } else {
        this.rect.on('.zoom', null);
        this.rect.on('wheel', this.onWheel);
      }
    }
  }

  render() {
    return (
      <rect
        ref={(el) => (this.el = el)}
        width={this.props.size.width}
        height={this.props.size.height}
        style={{ fill: 'none', pointerEvents: 'all' }}
      />
    );
  }
}
