import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import styles from './MultipleSelect.module.scss';

function MultipleSelect(props) {
  const {
    selected,
    onSelect,
    disabled,
    placeholder,
    options,
  } = props;
  const [focus, setFocus] = useState(false);
  const componentRef = useRef();
  const inputRef = useRef();

  useEffect(() => {
    function handleClick(e) {
      if (componentRef && componentRef.current) {
        const ref = componentRef.current;
        if (!ref.contains(e.target)) {
          setFocus(false);
        }
      }
    }
    document.addEventListener('click', handleClick, { capture: true });
    return () => document.removeEventListener('click', handleClick);
  }, []);

  const contentWidth = () => {
    if (inputRef && inputRef.current && inputRef.current.offsetWidth) {
      return `calc(${inputRef.current.offsetWidth}px)`;
    }
    return 'unset';
  };

  const isSelected = (v) => selected.includes(v);

  function toggleSelected(v) {
    onSelect(v);
    if (!isSelected(v)) {
      onSelect([...selected, v]);
    } else {
      onSelect(selected.filter((s) => s !== v));
    }
  }

  return (
    <div className={`${styles.container} ${disabled ? styles.disabled : ''}`} ref={componentRef}>
      <input
        ref={inputRef}
        type="text"
        placeholder={placeholder}
        value={selected.toString()}
        onFocus={() => setFocus(true)}
        onChange={() => { }}
      />
      {focus && (
        <div
          className={styles.content}
          style={{ width: contentWidth() }}
        >
          {options.map((o) => (
            <div
              onClick={() => toggleSelected(o)}
              onKeyPress={null}
              role="button"
              tabIndex={0}
              className={`${styles.option} ${isSelected(o) ? styles.selected : ''}`}
            >
              {o}
            </div>
          ))}
        </div>
      )}
    </div>
  );
}

MultipleSelect.defaultProps = {
  selected: [],
  onSelect: null,
  disabled: false,
  placeholder: null,
  options: [],
};

MultipleSelect.propTypes = {
  selected: PropTypes.instanceOf(Array),
  onSelect: PropTypes.func,
  disabled: PropTypes.bool,
  placeholder: PropTypes.string,
  options: PropTypes.instanceOf(Array),
};

export default MultipleSelect;