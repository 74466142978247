import React from 'react';
import PropTypes from 'prop-types';
import styles from './GlobalStyle.module.scss';
import Nav from './nav/Nav';
import Content from './content/Content';

function GlobalStyle(props) {
  const { content } = props;

  return (
    <div className={styles.container}>
      <Nav />
      <Content content={content} />
    </div>
  );
}

GlobalStyle.defaultProps = {
  content: null,
};

GlobalStyle.propTypes = {
  content: PropTypes.element,
};

export default GlobalStyle;