import React from 'react';
import _ from 'lodash';
import { Form } from 'antd';
import MenuItem from '@mui/material/MenuItem';

function getValue(key, obj) {
  const value = key != null ? _.get(obj, key) : obj;
  return `${value}`;
}

export function createOptions(values, key, value) {
  return _.map(
    values,
    (v) => (
      <MenuItem key={getValue(key, v)} value={getValue(value, v)}>{getValue(value, v)}</MenuItem>
    ),
  );
}

export function convertToFields(obj, func) {
  const res = {};
  _.forEach(Object.keys(obj), (k) => {
    res[k] = Form.createFormField({ value: func(k, obj[k]) });
  });
  return res;
}

export const defaultCheckboxFieldOptions = { valuePropName: 'checked' };
