/* eslint-disable react/prop-types */
/* eslint-disable react/destructuring-assignment */
import React from 'react';
import {
  Form, Select, Input, Checkbox,
} from 'antd';
import Dialog from '../../../../shared/dialog/Dialog';
import KeyValueInput, { keyValueInputFieldOptions } from '../KeyValueInput';
import { labelOptions, parseLabelOption } from '../../models/label';
import {
  convertToFields,
  createOptions,
  defaultCheckboxFieldOptions,
} from '../../utils/formUtils';
import styles from './LabelSignalDialog.module.scss';
import { formatTime } from '../../utils/timeUtils';

const FormItem = Form.Item;
const { Option } = Select;
const { TextArea } = Input;

const timeDisplayFormat = 'HH:mm:ss';

function getSignalValue(s) {
  return s instanceof String ? s : s.path;
}

const defaultLabel = {
  value: '',
  comment: '',
  state: true,
  signals: null,
  thumbnail: false,
  properties: null,
};

function mapPropsToFields(props) {
  return convertToFields(
    { ...defaultLabel, ...props.initialLabel },
    (k, v) => {
      switch (k) {
        case 'signals':
          return v != null ? v.map(getSignalValue) : null;
        case 'properties':
          return KeyValueInput.fromObject(v);
        case 'thumbnail':
          return props.allowThumbnail ? v : false;
        default:
          return v;
      }
    },
  );
}

function filterValueOptions(input, option) {
  return option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0;
}

const LabelSignalDialog = Form.create({ mapPropsToFields })(
  class extends React.Component {
    onSubmit(e) {
      e.preventDefault();
      this.props.form.validateFields((err, values) => {
        const labelInfo = parseLabelOption(values.value);
        if (!err && labelInfo != null) {
          const initial = this.props.initialLabel;
          const newProperties = KeyValueInput.toObject(values.properties);
          this.props.addLabel({
            label: {
              signals: values.signals,
              value: labelInfo.value,
              type: labelInfo.type,
              comment: values.comment,
              start: initial.start,
              end: initial.end,
              state: values.state,
              properties:
                initial.properties != null && newProperties == null
                  ? null
                  : newProperties,
            },
            thumbnail: values.thumbnail,
            id: initial.id,
          });
          this.props.form.resetFields();
        }
      });
    }

    computeTitle() {
      return `Add Label : ${formatTime(
        this.props.initialLabel.start,
        this.props.recordInfo.timezone,
        timeDisplayFormat,
      )} - ${formatTime(
        this.props.initialLabel.end,
        this.props.recordInfo.timezone,
        timeDisplayFormat,
      )}`;
    }

    render() {
      if (this.props.initialLabel == null) {
        return (
          <Dialog
            display={this.props.open}
            title="No selection"
            handleCloseDialog={this.props.closeDialog}
            content={(
              <>
                <h2>No selection</h2>
                <button type="button" onClick={this.props.closeDialog}>Close</button>
              </>
            )}
          />
        );
      }
      const { getFieldDecorator } = this.props.form;
      return (
        <Dialog
          title={this.computeTitle()}
          display={this.props.open}
          handleCloseDialog={this.props.closeDialog}
          content={(
            <>
              <Form onSubmit={this.onSubmit} className={styles.container}>
                <FormItem>
                  {getFieldDecorator('value', {
                    rules: [{ required: true, message: 'Required' }],
                  })(
                    <Select
                      placeholder="Value"
                      showSearch
                      filterOption={filterValueOptions}
                    >
                      {labelOptions.map((l) => (
                        <Option key={l.value}>{l.value}</Option>
                      ))}
                    </Select>,
                  )}
                </FormItem>
                <FormItem>
                  {getFieldDecorator('signals', {
                    rules: [{ required: true, message: 'Required' }],
                  })(
                    <Select placeholder="Signals" mode="multiple" allowClear>
                      {createOptions(this.props.signals.map(getSignalValue))}
                    </Select>,
                  )}
                </FormItem>
                <FormItem>
                  {getFieldDecorator('comment')(<TextArea placeholder="Comment" />)}
                </FormItem>
                <FormItem className={styles.checkbox}>
                  {getFieldDecorator('state', defaultCheckboxFieldOptions)(
                    <Checkbox>State</Checkbox>,
                  )}
                </FormItem>
                <FormItem className={styles.checkbox}>
                  {getFieldDecorator('thumbnail', defaultCheckboxFieldOptions)(
                    <Checkbox disabled={!this.props.allowThumbnail}>
                      Thumbnail
                    </Checkbox>,
                  )}
                </FormItem>
                <FormItem>
                  {getFieldDecorator('properties', keyValueInputFieldOptions)(
                    <KeyValueInput />,
                  )}
                </FormItem>
              </Form>
              <button type="button" onClick={this.onSubmit}>Save</button>
            </>
          )}
        />
      );
    }
  },
);

export default LabelSignalDialog;
