/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable no-return-assign */
/* eslint-disable react/no-deprecated */
/* eslint-disable react/prop-types */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable max-classes-per-file */
import React, {
  Component,
} from 'react';
import _ from 'lodash';
import {
  createPortal,
} from 'react-dom';
import {
  select,
  event,
} from 'd3-selection';
import {
  drag,
} from 'd3-drag';
import styles from './plot.module.scss';
import {
  colorPaletteExtended,
  colorLegend,
  transform,
  compareSeries,
} from './utils';
import {
  labelTypes,
  labelsLayout,
  lt,
} from '../../models/label';
import {
  formatTime,
} from '../../utils/timeUtils';

const labelHeight = 20;
const labelDisplayHeight = 10;
const dragbarw = 5;
const dragHandleColorTrue = 'steelblue';
const dragHandleColorFalse = '#700000';
const contentClass = 'label-content';
const leftHandleClass = 'left-handle';
const rightHandleClass = 'right-handle';
const dragThreshold = 100;
const tooltipWidth = 320;

function labelTransform(l) {
  return transform(l.x, l.y);
}

function getHandleColor(l) {
  return l.state ? dragHandleColorTrue : dragHandleColorFalse;
}

function hasDragged(a, b) {
  return Math.abs(a - b) > dragThreshold;
}

function buildHandle(g, lh) {
  return g
    .append('rect')
    .attr('height', lh)
    .attr('width', dragbarw)
    .attr('cursor', 'ew-resize');
}

class Labels extends Component {
  constructor(props) {
    super(props);

    this.legend = colorLegend(colorPaletteExtended, labelTypes);
    // Override color for marker type
    this.legend.set(lt.MARKER, 'darkgray');
    this.targetClass = '';
    this.patchMode = false;

    this.dragBehavior = drag()
      .on('drag', this.dragMove.bind(this))
      .on('start', this.dragStart.bind(this))
      .on('end', this.dragEnd.bind(this));

    this.doUpdate = this.doUpdate.bind(this);
  }

  componentDidMount() {
    if (this.props.labels.labels.length > 0) {
      this.doUpdate(this.props);
    }
  }

  componentWillReceiveProps(n) {
    const p = this.props;
    if (p.labels.labels.length === 0 && n.labels.labels.length === 0) {
      return;
    }
    const shouldUpdate = p.range !== n.range
      || !compareSeries(n.series, p.series)
      || p.labels !== n.labels
      || p.xScale !== n.xScale
      || p.yScales !== n.yScales
      || p.size !== n.size;
    if (shouldUpdate) {
      this.doUpdate(n);
    }
  }

  shouldComponentUpdate() {
    return false;
  }

  dragStart() {
    const e = event.sourceEvent;
    e.preventDefault();
    this.targetClass = e.target.classList.item(0);
    this.patchMode = !!e.metaKey;
  }

  dragMove(d, i, nodes) {
    /* eslint-disable no-param-reassign */
    event.sourceEvent.preventDefault();
    const el = nodes[i];
    switch (this.targetClass) {
      case contentClass:
        d.offset(event.dx);
        select(el).attr('transform', labelTransform(d));
        break;
      case leftHandleClass:
        d.x = Math.min(d.x + event.dx, d.x2);
        break;
      case rightHandleClass:
        d.x2 = Math.max(d.x2 + event.dx, d.x);
        break;
      default:
        break;
    }
    if (
      this.targetClass === leftHandleClass
      || this.targetClass === rightHandleClass
    ) {
      select(el)
        .attr('transform', labelTransform(d))
        .attr('width', d.length());
      select(el.children[0]).attr('width', d.length());
      select(el.children[2]).attr('x', d.length());
    }
    /* eslint-enable no-param-reassign */
  }

  dragEnd(d) {
    event.sourceEvent.preventDefault();
    const newStart = this.props.xScale.invert(d.x).getTime();
    const newEnd = this.props.xScale.invert(d.x2).getTime();
    if (hasDragged(d.start, newStart) || hasDragged(d.end, newEnd)) {
      const newLabel = {
        ...d,
        start: newStart,
        end: newEnd,
      };
      if (this.patchMode) {
        this.props.onLabelEdit(newLabel);
      } else {
        this.props.onLabelCommit(newLabel);
      }
    }
  }

  doUpdate(props) {
    const {
      range,
      series,
      labels,
      xScale,
      size,
      onLabelSelect,
      showTooltip,
      hideTooltip,
    } = props;
    const svg = select(this.el);

    const lh = labels.edit ? labelHeight : labelDisplayHeight;
    const {
      visible,
      placeholder,
    } = labels.labelsForDisplay(
      range.range(),
      series,
    );
    const layout = labelsLayout(
      visible,
      placeholder,
      xScale,
      size.height - lh,
      lh,
    );

    const labelsPlaceholder = svg
      .selectAll(`.${styles.placeholder}`)
      .data(layout.placeholder);
    const labelsElement = svg.selectAll('.chart-label').data(layout.visible);

    labelsPlaceholder.exit().remove();
    labelsElement.exit().remove();

    labelsPlaceholder
      .enter()
      .append('rect')
      .classed(styles.placeholder, true)
      .attr('height', labelDisplayHeight)
      .merge(labelsPlaceholder)
      .attr('width', (l) => l.length())
      .attr('x', (l) => l.x)
      .attr('y', (l) => l.y);

    const g = labelsElement
      .enter()
      .append('g')
      .classed('chart-label', true)
      .on('mousedown', (l) => onLabelSelect(l.id))
      .call(this.dragBehavior);

    g.append('rect')
      .classed(contentClass, true)
      .attr('x', dragbarw)
      .attr('cursor', 'move')
      .on('mouseover', (d) => {
        showTooltip(
          Math.min(event.pageX, size.width - tooltipWidth),
          size.height / 3,
          d,
        );
      })
      .on('mouseout', hideTooltip);

    buildHandle(g, lh).classed(leftHandleClass, true);

    buildHandle(g, lh).classed(rightHandleClass, true);

    const merged = g.merge(labelsElement);

    merged.attr('transform', labelTransform);

    const { legend } = this;
    const selected = labels.selectedID;

    merged
      .select(`.${contentClass}`)
      .classed(styles.selected, (l) => l.id === selected)
      .attr('stroke', (l) => (l.edited ? 'deeppink' : ''))
      .attr('stroke-dasharray', (l) => (l.edited ? '5 5' : ''))
      .attr('stroke-width', (l) => (l.edited ? '2' : '0'))
      .attr('fill', (l) => legend.get(l.type))
      .attr('fill-opacity', 0.5)
      .attr('height', lh)
      .attr('width', (l) => Math.max(0, l.length() - dragbarw * 2));

    merged
      .select(`.${rightHandleClass}`)
      .attr('x', (l) => l.length() - dragbarw)
      .attr('fill', getHandleColor)
      .attr('fill-opacity', 0.8)
      .attr('height', lh);

    merged
      .select(`.${leftHandleClass}`)
      .attr('fill', getHandleColor)
      .attr('fill-opacity', 0.8)
      .attr('height', lh);
  }

  render() {
    return (
      <g ref={
        (el) => (this.el = el)
      }
      />
    );
  }
}

const pillTrue = {
  background: 'steelblue',
};

const pillFalse = {
  background: '#700000',
};

function formatDate(props, field) {
  const value = _.get(props, field, null);
  if (value == null) {
    return 'Invalid date';
  }
  return formatTime(value, props.recordInfo.timezone, 'HH:mm:ss');
}

function LabelTooltip(props) {
  const properties = _.get(props, 'label.properties', null);
  const style = {
    width: `${tooltipWidth}px`,
    top: `${props.y}px`,
    left: `${props.x}px`,
    opacity: props.visible ? '1' : '0',
  };
  return (
    <div
      className={
        styles.tooltip
      }
      style={
        style
      }
    >
      <div className={
        styles.labelValue
      }
      >
        {' '}
        {
          _.get(props, 'label.value', 'Invalid value')
        }
        {' '}

      </div>
      {' '}
      <div
        className={
          styles.labelPill
        }
        style={
          _.get(props, 'label.state', false) ? pillTrue : pillFalse
        }
      >
        {
          _.get(props, 'label.type', 'Invalid type')
        }
      </div>
      {' '}
      <div className={
        styles.labelSignals
      }
      >
        {' '}
        {
          _.join(_.get(props, 'label.signals', []), ', ')
        }
        {' '}

      </div>
      {' '}
      <div className={
        styles.labelTimestamp
      }
      >
        <span className={
          styles.timestamp
        }
        >
          {' '}
          {
            formatDate(props, 'label.start')
          }
          {' '}

        </span>
        {' '}
        <span className={
          styles.timestamp
        }
        >
          {' '}
          {
            formatDate(props, 'label.end')
          }
          {' '}

        </span>
        {' '}

      </div>
      {' '}
      <div className={
        styles.labelComment
      }
      >
        {' '}
        {
          _.get(props, 'label.comment', '')
        }
        {' '}

      </div>
      {' '}
      <div className={
        styles.labelProperties
      }
      >
        {' '}
        {
          properties != null
            ? _(Object.keys(properties))
              .map((k) => `${k}:${properties[k]}`)
              .join(';')
            : ''
        }
        {' '}

      </div>
      {' '}

    </div>
  );
}

class LabelTooltipWrapper extends Component {
  constructor(props) {
    super(props);
    this.el = document.createElement('div');
  }

  componentDidMount() {
    document.body.appendChild(this.el);
  }

  componentWillUnmount() {
    document.body.removeChild(this.el);
  }

  render() {
    return createPortal(<LabelTooltip {
      ...this.props
    }
    />, this.el);
  }
}

class LabelsChannel extends Component {
  constructor(props) {
    super(props);
    this.legend = colorLegend(colorPaletteExtended, labelTypes);
    // Override color for marker type
    this.legend.set(lt.MARKER, 'darkgray');
  }

  render() {
    const {
      xScale,
      yOrdinalScale,
      labels,
      range,
      size,
      showTooltip,
      hideTooltip,
    } = this.props;
    const bw = yOrdinalScale.bandwidth();
    return (
      <g>
        {' '}
        {
          _(labels.labels)
            .filter((l) => l.start >= range.start && l.start < range.end)
            .flatMap((l) => _.map(l.signals, (s) => {
              const sy = yOrdinalScale(s);
              if (sy == null) {
                return null;
              }
              const sx = xScale(l.start);
              const st = (e) => showTooltip(
                Math.min(e.pageX, size.width - tooltipWidth),
                size.height / 3,
                l,
              );
              return (
                <rect
                  key={
                    `${l.id}-${s}`
                  }
                  x={
                    sx
                  }
                  y={
                    sy
                  }
                  width={
                    xScale(l.end) - sx
                  }
                  height={
                    bw
                  }
                  fill={
                    this.legend.get(l.type)
                  }
                  fillOpacity={
                    0.5
                  }
                  onMouseOver={
                    st
                  }
                  onFocus={
                    st
                  }
                  onMouseOut={
                    hideTooltip
                  }
                  onBlur={
                    hideTooltip
                  }
                />
              );
            }))
            .value()
        }
        {' '}

      </g>
    );
  }
}

export default class LabelsWrapper extends Component {
  constructor(props) {
    super(props);
    this.state = {
      tooltipVisible: false,
      tooltipX: 0,
      tooltipY: 0,
      tooltipLabel: null,
    };
  }

  showTooltip(x, y, label) {
    this.setState({
      tooltipVisible: true,
      tooltipX: x,
      tooltipY: y,
      tooltipLabel: {
        ...label,
      },
    });
  }

  hideTooltip() {
    this.setState({
      tooltipVisible: false,
      tooltipLabel: null,
    });
  }

  render() {
    return (
      <>
        {' '}
        {
          this.props.labels.channelLayout && this.props.yScales.length > 1 ? (
            <LabelsChannel
              showTooltip={
                this.showTooltip
              }
              hideTooltip={
                this.hideTooltip
              }
              {
              ...this.props
              }
            />
          ) : (
            <Labels
              showTooltip={
                this.showTooltip
              }
              hideTooltip={
                this.hideTooltip
              }
              {
              ...this.props
              }
            />
          )
        }
        {' '}
        <LabelTooltipWrapper
          recordInfo={
            this.props.recordInfo
          }
          visible={
            this.state.tooltipVisible
          }
          x={
            this.state.tooltipX
          }
          y={
            this.state.tooltipY
          }
          label={
            this.state.tooltipLabel
          }
        />
        {' '}

      </>
    );
  }
}