import React, { useState } from 'react';
import PropTypes, { func } from 'prop-types';
import styles from './SearchBar.module.scss';
import { ReactComponent as SearchIcon } from '../../../assets/images/icons/search.svg';
import { ReactComponent as CloseIcon } from '../../../assets/images/icons/close.svg';

function SearchBar(props) {
  const {
    className,
    value,
    onChange,
    disabled,
    onSubmit,
    placeholder,
    onReset,
    onKeyPress,
  } = props;
  const [displayLengthErr, setDisplayLengthErr] = useState(false);
  const [leftIcon, setLeftIcon] = useState(
    <SearchIcon
      width="20px"
      height="20px"
      fill={disabled ? '#B5B1CB' : '#27214C'}
    />,
  );

  function leftIconIsSearch() {
    setLeftIcon(
      <SearchIcon
        width="20px"
        height="20px"
        fill={disabled ? '#B5B1CB' : '#27214C'}
      />,
    );
  }

  function leftIconIsCross() {
    setLeftIcon(
      <CloseIcon
        width="14px"
        height="14px"
        fill={disabled ? '#B5B1CB' : '#27214C'}
        style={{ cursor: 'pointer' }}
        onClick={onReset}
      />,
    );
  }

  function handleSubmit() {
    if (!value || value.length < 3) {
      setDisplayLengthErr(true);
    } else {
      onSubmit();
    }
  }

  function handleChange(e) {
    setDisplayLengthErr(false);
    onChange(e);
    if (value) {
      leftIconIsCross();
    } else {
      leftIconIsSearch();
    }
  }

  function mouseOver(b) {
    if (value && b && !disabled) {
      leftIconIsCross();
      return;
    }
    leftIconIsSearch();
  }

  return (
    <div
      className={className}
      onMouseOver={() => mouseOver(true)}
      onFocus={() => mouseOver(true)}
      onMouseLeave={() => mouseOver(false)}
    >
      <div className={styles.inputContainer}>
        <div className={styles.iconContainer}>
          {leftIcon}
        </div>
        <input
          className={`${styles.input} ${disabled && styles.disabled}`}
          style={{
            paddingRight: onSubmit ? '6.5rem' : 'unset',
          }}
          type="text"
          value={value || ''}
          onChange={handleChange}
          disabled={disabled}
          placeholder={placeholder}
          onKeyPress={onKeyPress}
        />
        {displayLengthErr && (
          <span className={styles.lengthErr}>
            Your search must contain at least 3 characters
          </span>
        )}
        {onSubmit && (
          <div
            className={`${styles.submitBtn} ${disabled && styles.disabled}`}
            onClick={handleSubmit}
            onKeyPress={null}
            role="button"
            tabIndex={0}
          >
            <span>search</span>
          </div>
        )}
      </div>
    </div>
  );
}

SearchBar.defaultProps = {
  className: '',
  value: '',
  onChange: null,
  disabled: false,
  onSubmit: null,
  placeholder: '',
  onReset: null,
  onKeyPress: null,
};

SearchBar.propTypes = {
  className: PropTypes.string,
  value: PropTypes.string,
  onChange: PropTypes.func,
  disabled: PropTypes.bool,
  onSubmit: PropTypes.func,
  placeholder: PropTypes.string,
  onReset: PropTypes.func,
  onKeyPress: PropTypes.instanceOf(func),
};

export default SearchBar;