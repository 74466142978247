/* eslint-disable class-methods-use-this */
/* eslint-disable consistent-return */
/* eslint-disable react/no-deprecated */
/* eslint-disable react/prop-types */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable import/no-named-as-default */
import React from 'react';
import _ from 'lodash';
import { connect } from 'react-redux';
import styles from './TimeSeries.module.scss';
import LoadingIcon from '../../../shared/loadingIcon/LoadingIcon';
import CustomCard from '../components/CustomCard';
import MultiPlot from '../components/plot/MultiPlot';
import SinglePlot from '../components/plot/SinglePlot';
import {
  fetchRecord,
  updateProgress,
  scrollProgress,
  updateWindow,
  progressForward,
  progressBackward,
  changeSelectedSignals,
  setSerieSelection,
  setSerieMark,
  goToMark,
  windowMatchSelection,
  stopCaching,
  fetchAlgoOverview,
  setReferenceLines,
  removeSignal,
  setSignalSelection,
  updateFiltersConfig,
  toggleNumberDisplay,
  toggleSerieHidden,
  toggleFitMode,
} from '../actions/timeseries';
import { saveStaging, setStage } from '../actions/staging';
import { updateScaleAndColor, editScale } from '../actions/profiles';
import {
  fetchLabels,
  clearLabels,
  addLabel,
  editLabel,
  deleteLabel,
  copyLabel,
  editLabelState,
  selectNextLabel,
  selectPreviousLabel,
  selectLabel,
  toggleLabelEdit,
  toggleLabelChannelLayout,
  pushCommitLabel,
  flushCommitLabel,
  getRecordLabelsUsers,
  addMarkLabel,
} from '../actions/labels';
import {
  displayWindowRead,
} from '../actions/timeseriesContent';
import { TIMESERIES } from '../constants/actionTypes';
import ToolbarProgress from '../components/ToolbarProgress';
import TimeSeriesContent from './TimeSeriesContent';
import SelectSignalDialog from '../components/dialog/SelectSignalDialog';
import { GROUP_TYPES } from '../models/group';
import { defaultWindow } from '../models/range';
import { getConfig } from '../models/profile';
import LabelSignalDialog from '../components/dialog/LabelSignalDialog';
import LabelFilterDialog from '../components/dialog/LabelFilterDialog';
import ReferenceLinesDialog from '../components/dialog/ReferenceLinesDialog';
import ScaleConfigDialog from '../components/dialog/ScaleConfigDialog';
import SeriesFilterDialog from '../components/dialog/SeriesFilterDialog';
import WindowReadDialog from '../components/dialog/WindowReadDialog';
import LabelMarkerDialog from '../components/dialog/LabelMarkerDialog';
import { Keys, keybind } from './utils';
import { ReactComponent as Chevron } from '../../../../assets/images/icons/chevron.svg';
import Notification from '../../../shared/notification/Notification';
// import { queryParser } from '../utils/queryParams';

export const DIALOGS = {
  SELECT_SIGNAL_DIALOG: 'select_signal_dialog',
  LABEL_SIGNAL_DIALOG: 'label_signal_dialog',
  LABEL_FILTER_DIALOG: 'label_filter_dialog',
  REFERENCE_LINES_DIALOG: 'reference_lines_dialog',
  SCALE_CONFIG_DIALOG: 'scale_config_dialog',
  SERIES_FILTER_DIALOG: 'series_filter_dialog',
  WINDOW_READ_DIALOG: 'window_read_dialog',
  LABEL_MARKER_DIALOG: 'label_marker_dialog',
};

function titleRender(recordID) {
  return (
    <div className={styles.title}>
      <span className={styles.breadcrumb} key="breadcrumb">
        Records
        <span>
          <Chevron
            className={styles.chevron}
            height="12px"
            fill="#7A71A6"
            transform=""
          />
          signals
        </span>
      </span>
      <span className={styles.recordID} key="recordTitle">{recordID}</span>
    </div>
  );
}

function createLineCharts(props) {
  const { dispatch } = props;
  const groups = props.groups.list;
  const { series } = props;
  if (groups == null || series == null || !series.length) {
    return [];
  }

  const className = groups.length > 1 ? styles.singleplot : styles.multiplot;

  const onRangeChange = (r) => {
    dispatch(updateWindow(r));
  };

  const onScrollProgressChange = (p) => {
    dispatch(scrollProgress(p));
  };

  const onSerieSelection = (s) => {
    dispatch(setSerieSelection(s));
  };

  const onSerieMark = (m) => {
    dispatch(setSerieMark(m));
  };

  const onLabelEdit = (l) => {
    dispatch(editLabel(props.recordID, l));
  };

  const onLabelSelect = (id) => {
    dispatch(selectLabel(id));
  };

  const onLabelCommit = (l) => {
    dispatch(pushCommitLabel(l));
  };

  const onSerieRemove = (removed) => {
    dispatch(removeSignal(removed));
  };

  const onSignalSelect = (selection) => {
    dispatch(setSignalSelection(selection));
  };

  const onToggleNumberDisplay = (signal) => {
    dispatch(toggleNumberDisplay(signal));
  };

  const onToggleSerieHidden = (signal) => {
    dispatch(toggleSerieHidden(signal));
  };

  const onScaleEdit = (path, domain) => {
    dispatch(editScale(path, domain));
  };

  return groups.map((g) => {
    let chart;
    const groupSeries = g.findSeries(series);
    const configuration = getConfig(props.profile);
    const chartProps = {
      recordInfo: props.recordInfo,
      series: groupSeries.map((serie) => ({
        ...serie,
        signal: _.find(props.signals, (sig) => sig.path === serie.signal),
      })),
      selectedSignals: props.selectedSignals,
      range: props.range,
      labels: props.labels,
      stimulations: props.stimulations,
      selection: props.selection,
      highlights: props.highlights,
      content: props.content,
      fitMode: props.fitMode,
      zoomMode: props.zoomMode,
      measureMode: props.measureMode,
      stagingMode: props.staging.enabled,
      referenceLines: props.referenceLines,
      numberDisplay: props.numberDisplay,
      hiddenSeries: props.hiddenSeries,
      toggleDrawer: props.toggleDrawer,
      configuration,
      onRangeChange,
      onScrollProgressChange,
      onSerieSelection,
      onSerieMark,
      onLabelEdit,
      onLabelSelect,
      onLabelCommit,
      onSerieRemove,
      onSignalSelect,
      onToggleNumberDisplay,
      onToggleSerieHidden,
      onScaleEdit,
    };
    const title = props.content.isEmpty()
      ? titleRender(props.recordID)
      : null;
    if (chartProps.recordInfo.record === null || (chartProps.recordInfo.record.version === 6 && props.viewerVersion === 'external')) {
      chartProps.series = chartProps.series.filter((elem) => {
        if (elem.signal && elem.signal.name === 'CH3 Fp1-F8') return false;
        if (elem.signal && elem.signal.name === 'CH7 Fp1-F7') return false;
        return true;
      });
    }
    if (props.groups.type === GROUP_TYPES.MULTIPLOT) {
      chart = <MultiPlot {...chartProps} />;
    } else {
      chart = <SinglePlot {...chartProps} />;
    }
    return (
      <CustomCard key={g.id} className={className}>
        {title}
        {chart}
      </CustomCard>
    );
  });
}

class TimeSeries extends React.Component {
  constructor(props) {
    super(props);
    this.handleStagingKeys = this.handleStagingKeys.bind(this);
    this.handleSignalNavigation = this.handleSignalNavigation.bind(this);
    this.handleSignalIncrement = this.handleSignalIncrement.bind(this);
    this.handleNextWindow = this.handleNextWindow.bind(this);
    this.handleLabelNavigation = this.handleLabelNavigation.bind(this);
    this.handleCopyLabel = this.handleCopyLabel.bind(this);
    this.handleMarkNavigation = this.handleMarkNavigation.bind(this);
    this.handleToggleLabelEdit = this.handleToggleLabelEdit.bind(this);
    this.handleMatchWindow = this.handleMatchWindow.bind(this);
    this.handleResetDefaultWindow = this.handleResetDefaultWindow.bind(this);
    this.handleDeleteLabel = this.handleDeleteLabel.bind(this);
    this.handleCancelLabel = this.handleCancelLabel.bind(this);
    this.handleConfirmLabel = this.handleConfirmLabel.bind(this);
    this.handleFlushCommitLabel = this.handleFlushCommitLabel.bind(this);
    this.handleCancelCommit = this.handleCancelCommit.bind(this);
    this.handleAddLabelMarker = this.handleAddLabelMarker.bind(this);
    this.isOpened = this.isOpened.bind(this);
    this.loadLabels = this.loadLabels.bind(this);
    this.initKeybinds = this.initKeybinds.bind(this);
    this.addLabel = this.addLabel.bind(this);
  }

  componentDidMount() {
    this.props.dispatch(
      // fetchRecord(this.props.recordID, + this.props.location.query.timestamp),
      fetchRecord(this.props.recordID, 0), // DEBUG
    );
    if (this.props.featuresConfig.algorythmOverview) {
      this.props.dispatch(fetchAlgoOverview(this.props.recordID));
    }
    this.loadLabels();
    this.initKeybinds();
    document.addEventListener('contextmenu', this.blockContextMenu, false);
  }

  componentWillReceiveProps(nextProps) {
    const se = nextProps.staging.enabled;
    if (this.props.staging.enabled !== se) {
      const kb = keybind(
        ['1', '2', '3', '4', '5', '6', '7', '8', '9', '0'],
        this.handleStagingKeys,
        'staging-keys',
      );
      if (se) {
        this.keys.addToggled(kb);
      } else {
        this.keys.removeToggled(kb);
      }
    }
  }

  componentWillUnmount() {
    this.props.dispatch({
      type: TIMESERIES.RESET_TIMESERIES,
    });
    this.props.dispatch(stopCaching());
    if (this.keys) this.keys.unbind();
    this.keys = null;
    document.removeEventListener('contextmenu', this.blockContextMenu, false);
  }

  handleStagingKeys(e, key) {
    if (!this.props.staging.enabled) {
      return;
    }
    e.preventDefault();
    const stage = _.find(
      this.props.staging.stages.config.stages,
      (s) => s.key === key,
    );
    if (stage) {
      this.props.dispatch(setStage(stage));
    }
  }

  handleSignalNavigation(e, key) {
    e.preventDefault();
    switch (key) {
      case 'h':
        this.props.dispatch(progressBackward());
        break;
      case 'l':
        this.props.dispatch(progressForward());
        break;
      default:
        break;
    }
  }

  handleSignalIncrement(e, key) {
    e.preventDefault();
    switch (key) {
      case 'left':
        this.props.dispatch(updateProgress(this.props.range.current - 10000));
        break;
      case 'right':
        this.props.dispatch(updateProgress(this.props.range.current + 10000));
        break;
      default:
        break;
    }
  }

  handleNextWindow(e, key) {
    e.preventDefault();
    if (key === 'enter') {
      this.props.dispatch(
        updateProgress(this.props.range.current + this.props.range.window),
      );
    }
  }

  handleLabelNavigation(e, key) {
    e.preventDefault();
    switch (key) {
      case 'n':
        this.props.dispatch(selectNextLabel());
        break;
      case 'shift+n':
        this.props.dispatch(selectPreviousLabel());
        break;
      default:
        break;
    }
  }

  handleCopyLabel(e) {
    e.preventDefault();
    this.props.dispatch(copyLabel(this.props.recordID));
  }

  handleMarkNavigation(e) {
    e.preventDefault();
    this.props.dispatch(goToMark());
  }

  handleToggleLabelEdit(e) {
    e.preventDefault();
    this.props.dispatch(toggleLabelEdit());
  }

  handleMatchWindow(e) {
    e.preventDefault();
    if (!this.props.staging.enabled && this.props.zoomMode) {
      this.props.dispatch(windowMatchSelection());
    }
  }

  handleResetDefaultWindow(e) {
    e.preventDefault();
    const r = this.props.range;
    this.props.dispatch(updateWindow(r.updateWindow(r.current, defaultWindow)));
  }

  handleDeleteLabel(e) {
    e.preventDefault();
    if (this.props.labels.selectedLabel) {
      this.props.dispatch(
        deleteLabel(this.props.recordID, this.props.labels.selectedLabel),
      );
    }
  }

  handleCancelLabel(e) {
    e.preventDefault();
    this.props.dispatch(editLabelState(this.props.recordID, false));
  }

  handleConfirmLabel(e) {
    e.preventDefault();
    this.props.dispatch(editLabelState(this.props.recordID, true));
  }

  handleFlushCommitLabel(e) {
    e.preventDefault();
    this.props.dispatch(flushCommitLabel(this.props.recordID, true));
  }

  handleCancelCommit(e) {
    e.preventDefault();
    this.props.dispatch(flushCommitLabel(this.props.recordID, false));
  }

  handleAddLabelMarker() {
    const l = this.props.selection.mark.location;
    if (l != null && this.props.range.inRange(l)) {
      this.props.openDialog(DIALOGS.LABEL_MARKER_DIALOG);
    }
  }

  isOpened(dialog) { return this.props.dialog != null && this.props.dialog === dialog; }

  loadLabels() {
    // const q = this.props.location.query;
    // const labelsFilter = {
    //   signals: queryParser.toArray(q.labels_signals),
    //   types: queryParser.toArray(q.labels_types),
    //   users: queryParser.toArray(q.labels_users),
    //   values: queryParser.toArray(q.labels_values),
    // };
    const labelsFilter = [];
    if (_.some(Object.keys(labelsFilter), (k) => labelsFilter[k] != null)) {
      this.props.dispatch(fetchLabels(this.props.recordID, labelsFilter));
    }
  }

  initKeybinds() {
    this.keys = Keys([
      keybind(
        ['h', 'l'],
        _.throttle(this.handleSignalNavigation, 350, { leading: true }),
      ),
      keybind(
        ['left', 'right'],
        _.throttle(this.handleSignalIncrement, 350, { leading: true }),
      ),
      keybind(
        ['enter'],
        _.throttle(this.handleNextWindow, 350, { leading: true }),
      ),
      keybind(['n', 'shift+n'], this.handleLabelNavigation),
      keybind('p', this.handleCopyLabel),
      keybind('f', () => this.props.dispatch(toggleFitMode())),
      keybind('m', this.handleMarkNavigation),
      keybind('i', this.handleToggleLabelEdit),
      keybind('e', () => this.props.dispatch(toggleLabelChannelLayout())),
      keybind('d', this.handleDeleteLabel),
      keybind('c', this.handleCancelLabel),
      keybind('v', this.handleConfirmLabel),
      keybind('g', this.handleFlushCommitLabel),
      keybind('b', this.handleCancelCommit),
      keybind('o', this.handleMatchWindow),
      keybind('r', this.handleResetDefaultWindow),
      keybind('x', this.handleAddLabelMarker),
      keybind('y', () => this.props.openDialog(DIALOGS.LABEL_SIGNAL_DIALOG)),
    ]);
  }

  blockContextMenu(e) {
    e.preventDefault();
    e.stopPropagation();
  }

  addLabel(res) {
    this.props.closeDialog();
    this.props.dispatch(addLabel(this.props.recordID, res.label, res.thumbnail));
  }

  renderSelectSignalDialog(headbandVersion, viewerVersion) {
    if (!this.isOpened(DIALOGS.SELECT_SIGNAL_DIALOG)) {
      return null;
    }
    return (
      <SelectSignalDialog
        open
        viewerVersion={viewerVersion}
        headbandVersion={headbandVersion}
        recordID={this.props.recordID}
        closeDialog={this.props.closeDialog}
        selected={this.props.series.map((s) => s.signal)}
        available={this.props.signals}
        applySelection={(series, groups) => {
          this.props.closeDialog();
          this.props.dispatch(changeSelectedSignals(series, groups));
        }}
      />
    );
  }

  renderLabelSignalDialog() {
    if (!this.isOpened(DIALOGS.LABEL_SIGNAL_DIALOG)) {
      return null;
    }
    let initialLabel = null;
    if (this.props.selection.hasSelection()) {
      const s = this.props.selection.current;
      const defaultSignals = this.props.selectedSignals != null
        && this.props.selectedSignals.length > 0
        ? this.props.selectedSignals
        : s.signals;
      initialLabel = {
        start: s.start,
        end: s.end,
        signals: defaultSignals,
      };
    }
    return (
      <LabelSignalDialog
        open
        recordInfo={this.props.recordInfo}
        signals={this.props.signals}
        closeDialog={this.props.closeDialog}
        addLabel={this.addLabel}
        allowThumbnail={this.props.featuresConfig.labelThumbnail}
        initialLabel={initialLabel}
      />
    );
  }

  renderLabelFilterDialog(headbandVersion, viewerVersion) {
    if (!this.isOpened(DIALOGS.LABEL_FILTER_DIALOG)) {
      return null;
    }
    return (
      <LabelFilterDialog
        open
        viewerVersion={viewerVersion}
        headbandVersion={headbandVersion}
        initialFilters={this.props.labels.filters}
        signals={this.props.signals}
        closeDialog={this.props.closeDialog}
        getLabelsUsers={() => this.props.dispatch(getRecordLabelsUsers(this.props.recordID))}
        apply={(filters) => {
          this.props.dispatch(fetchLabels(this.props.recordID, filters));
          this.props.closeDialog();
        }}
        clear={() => {
          this.props.dispatch(clearLabels());
          this.props.closeDialog();
        }}
      />
    );
  }

  renderReferenceLinesDialog(headbandVersion, viewerVersion) {
    if (!this.isOpened(DIALOGS.REFERENCE_LINES_DIALOG)) {
      return null;
    }
    let s = this.props.signals.map((si) => si.path);
    if (headbandVersion === null || (headbandVersion === 6 && viewerVersion === 'external')) {
      s = s.filter((elem) => {
        if (elem === 'eeg3/filtered') return false;
        if (elem === 'eeg7/filtered') return false;
        if (elem === 'eeg3/raw') return false;
        if (elem === 'eeg7/raw') return false;
        return true;
      });
    }
    return (
      <ReferenceLinesDialog
        close={this.props.closeDialog}
        signals={s}
        applyReferenceLines={(rl) => {
          this.props.dispatch(setReferenceLines(rl));
          this.props.closeDialog();
        }}
      />
    );
  }

  renderScaleConfigDialog() {
    if (!this.isOpened(DIALOGS.SCALE_CONFIG_DIALOG)) {
      return null;
    }
    return (
      <ScaleConfigDialog
        closeDialog={this.props.closeDialog}
        signals={this.props.signals}
        configuration={getConfig(this.props.profile)}
        applyScaleConfig={(scfg, ccfg) => {
          this.props.dispatch(updateScaleAndColor(scfg, ccfg));
          this.props.closeDialog();
        }}
      />
    );
  }

  renderSeriesFilterDialog() {
    if (!this.isOpened(DIALOGS.SERIES_FILTER_DIALOG)) {
      return null;
    }
    return (
      <SeriesFilterDialog
        closeDialog={this.props.closeDialog}
        signals={this.props.signals}
        selectedSignals={this.props.selectedSignals}
        filtersConfig={getConfig(this.props.profile).filtersConfig}
        updateFilters={(filters) => {
          this.props.dispatch(updateFiltersConfig(filters));
          this.props.closeDialog();
        }}
      />
    );
  }

  renderWindowReadDialog() {
    if (!this.isOpened(DIALOGS.WINDOW_READ_DIALOG)) {
      return null;
    }
    return (
      <WindowReadDialog
        close={this.props.closeDialog}
        signals={this.props.signals}
        apply={(s) => {
          this.props.dispatch(displayWindowRead(s));
          this.props.closeDialog();
        }}
      />
    );
  }

  renderLabelMarkerDialog() {
    if (!this.isOpened(DIALOGS.LABEL_MARKER_DIALOG)) {
      return null;
    }
    return (
      <LabelMarkerDialog
        close={this.props.closeDialog}
        onApply={(v) => {
          this.props.dispatch(addMarkLabel(v));
          this.props.closeDialog();
        }}
      />
    );
  }

  render() {
    if (!this.props.series.length) {
      return (
        <div className={styles.loadingContainer}>
          <LoadingIcon />
          <p>{this.props.recordID}</p>
        </div>
      );
    }
    const headbandVersion = this.props.recordInfo.record
      ? this.props.recordInfo.record.version
      : null;
    return (
      <div className={styles.wrapper}>
        <div className={styles.content}>
          <TimeSeriesContent
            headbandVersion={headbandVersion}
            viewerVersion={this.props.viewerVersion}
          />
          <div
            style={
              this.props.groups.list.length > 1
                ? { overflow: 'auto' }
                : { overflow: 'hidden' }
            }
            className={styles.container}
          >
            <div className={styles.fullscreen}>
              {createLineCharts(this.props)}
            </div>
          </div>
          <div className={styles.toolbar}>
            <ToolbarProgress
              recordInfo={this.props.recordInfo}
              staging={this.props.staging}
              range={this.props.range}
              featuresConfig={this.props.featuresConfig}
              algoOverview={this.props.algoOverview}
              onProgressUpdate={(p) => this.props.dispatch(updateProgress(p))}
              saveStaging={() => this.props.dispatch(saveStaging(this.props.recordID))}
            />
          </div>
          {this.renderSelectSignalDialog(headbandVersion, this.props.viewerVersion)}
          {this.renderLabelSignalDialog()}
          {this.renderLabelFilterDialog(headbandVersion, this.props.viewerVersion)}
          {this.renderReferenceLinesDialog(headbandVersion, this.props.viewerVersion)}
          {this.renderScaleConfigDialog()}
          {this.renderSeriesFilterDialog()}
          {this.renderWindowReadDialog()}
          {this.renderLabelMarkerDialog()}
          <Notification
            dispatch={this.props.dispatch}
            data={this.props.notificationTS}
          />
        </div>
      </div>
    );
  }
}

export default connect((state) => state.timeSeries)(TimeSeries);
