/* eslint-disable react/no-access-state-in-setstate */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable react/no-deprecated */
/* eslint-disable react/prop-types */
import React, { Component } from 'react';
import { scaleTime } from 'd3-scale';
import styles from './plot.module.scss';
import { immutableScale } from './utils';
import Marker from './Marker';
import Stimulations from './Stimulations';
import Highlights from './Highlights';
import TimeAxis from './TimeAxis';
import SVGElement from './SVGElement';

function getDomain(range) {
  const currentRange = range.range();
  return [currentRange.start, currentRange.end];
}

export default class BasePlot extends Component {
  constructor(props) {
    super(props);
    const scale = scaleTime().domain(getDomain(props.range));
    this.state = {
      xScale: immutableScale(scale),
    };
    this.xScaleReference = scale.copy();
    this.onPlotSizeUpdate = this.onPlotSizeUpdate.bind(this);
  }

  componentWillReceiveProps(nextProps) {
    if (this.props.range !== nextProps.range) {
      this.updateXScale(nextProps);
    }
  }

  onPlotSizeUpdate(size) {
    const xScale = this.state.xScale.range([0, size.width]);
    this.xScaleReference = xScale.copy();
    this.setState({ xScale });
  }

  updateXScale(props) {
    const xScale = this.state.xScale.domain(getDomain(props.range));
    this.xScaleReference = xScale.copy();
    this.setState({ xScale });
  }

  render() {
    return (
      <SVGElement
        className={styles.signalPlot}
        content={this.props.content}
        onSizeUpdate={this.onPlotSizeUpdate}
      >
        <Marker xScale={this.state.xScale} selection={this.props.selection} />
        <Stimulations
          xScale={this.state.xScale}
          range={this.props.range}
          stimulations={this.props.stimulations}
        />
        <Highlights
          xScale={this.state.xScale}
          range={this.props.range}
          highlights={this.props.highlights}
        />
        <TimeAxis
          xScale={this.state.xScale}
          recordInfo={this.props.recordInfo}
        />
        {React.Children.map(this.props.children, (child) => React.cloneElement(child, {
          xScale: this.state.xScale,
          xScaleReference: this.xScaleReference,
        }))}
      </SVGElement>
    );
  }
}
