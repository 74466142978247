import React from 'react';
import PropTypes from 'prop-types';
import styles from './Content.module.scss';

function Content(props) {
  const { content } = props;
  return (
    <div className={styles.container}>
      {content}
    </div>
  );
}

Content.defaultProps = {
  content: null,
};

Content.propTypes = {
  content: PropTypes.element,
};

export default Content;