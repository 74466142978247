/* eslint-disable no-return-assign */
/* eslint-disable react/no-deprecated */
/* eslint-disable react/prop-types */
/* eslint-disable react/destructuring-assignment */
import React, { Component } from 'react';
import _ from 'lodash';
import { select, event } from 'd3-selection';
import { brushX } from 'd3-brush';
import styles from './plot.module.scss';

export default class Brush extends Component {
  componentDidMount() {
    this.initBrush();
    this.updateBrushSize(this.props.size);

    this.brush.select('.overlay').style('pointer-events', 'none');
    this.props.shiftHandler.addHandler((s) => {
      if (s) {
        this.brush.select('.overlay').style('pointer-events', 'all');
      } else {
        this.brush.select('.overlay').style('pointer-events', 'none');
      }
    });

    this.updateSelection(this.props);
  }

  componentWillReceiveProps(nextProps) {
    if (this.props.selection !== nextProps.selection) {
      this.updateSelection(nextProps);
    }
    if (this.props.size !== nextProps.size) {
      this.updateBrushSize(nextProps.size);
      this.updateSelection(nextProps);
    }
  }

  shouldComponentUpdate() {
    return false;
  }

  clearBrush() {
    this.brush.call(this.b.move, null);
  }

  initBrush() {
    this.brush = select(this.el);
    const that = this;
    this.b = brushX().on('end', () => {
      if (
        event.selection != null
        && event.selection.length > 1
        && event.sourceEvent != null
        && event.sourceEvent.type === 'mouseup'
      ) {
        that.props.onSerieSelection({
          signals: _.map(that.props.series, (s) => s.signal),
          start: that.props.xScale.invert(event.selection[0]).getTime(),
          end: that.props.xScale.invert(event.selection[1]).getTime(),
        });
      }
    });
  }

  updateBrushSize(size) {
    this.b.extent([[0, 0], [size.width, size.height]]);
    this.brush.call(this.b);
  }

  updateSelection(props) {
    if (!props.selection.hasSelection()) {
      this.clearBrush();
    } else {
      const s = props.selection.current;
      this.brush.call(this.b.move, [props.xScale(s.start), props.xScale(s.end)]);
    }
  }

  render() {
    return <g ref={(el) => (this.el = el)} className={styles.brush} />;
  }
}
