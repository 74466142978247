/* eslint-disable import/no-extraneous-dependencies */
import React from 'react';
import { Provider } from 'react-redux';
import { createStore, applyMiddleware } from 'redux';
import thunk from 'redux-thunk';
import PropTypes from 'prop-types';
import reducer from './reducers';
import INITIAL_STATE from './models/state';
import { defaultAPI, defaultFeaturesConfig } from './constants/globals';
import TimeSeriesWrapper from './containers/TimeSeriesWrapper';

function TimeSeriesApp(props) {
  const {
    recordID,
    externalFeaturesConfig,
    user,
  } = props;

  const featuresConfig = externalFeaturesConfig != null
    ? ({ ...defaultFeaturesConfig, ...externalFeaturesConfig })
    : defaultFeaturesConfig;

  const initialState = { ...INITIAL_STATE, user };
  const md = [thunk.withExtraArgument(defaultAPI)];
  const store = createStore(
    reducer,
    initialState,
    // eslint-disable-next-line prefer-spread
    applyMiddleware.apply(null, md),
  );

  return (
    <Provider store={store}>
      <TimeSeriesWrapper
        user={user}
        recordID={recordID}
        featuresConfig={featuresConfig}
        api={defaultAPI}
        viewerVersion="external"
      />
    </Provider>
  );
}

TimeSeriesApp.defaultProps = {
  recordID: '',
  externalFeaturesConfig: {},
  user: {},
};

TimeSeriesApp.propTypes = {
  recordID: PropTypes.string,
  externalFeaturesConfig: PropTypes.instanceOf(Object),
  user: PropTypes.instanceOf(Object),
};

export default TimeSeriesApp;