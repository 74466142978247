import _ from 'lodash';
import {
  scaleLinear,
  scaleBand,
  scaleTime,
} from 'd3-scale';
import {
  line as svgLine,
} from 'd3-shape';
import {
  axisBottom,
  axisLeft,
} from 'd3-axis';
import {
  select,
} from 'd3-selection';
import {
  format,
} from 'd3-format';
import {
  downloadBlob,
} from './utils';
import {
  fitDomain,
  maxDomain,
} from '../components/plot/utils';
import {
  GROUP_TYPES,
} from '../models/group';

const doctype = '<?xml version="1.0" standalone="no"?><!DOCTYPE svg PUBLIC "-//W3C//DTD SVG 1.1//EN" "http://www.w3.org/Graphics/SVG/1.1/DTD/svg11.dtd">';

const prefix = {
  xmlns: 'http://www.w3.org/2000/xmlns/',
  xlink: 'http://www.w3.org/1999/xlink',
  svg: 'http://www.w3.org/2000/svg',
};

function svgToString(el, styles) {
  const svg = el.cloneNode(true);
  svg.setAttribute('version', '1.1');

  const defsEl = document.createElement('defs');
  svg.insertBefore(defsEl, svg.firstChild);

  const styleEl = document.createElement('style');
  styleEl.setAttribute('type', 'text/css');
  defsEl.appendChild(styleEl);

  svg.removeAttribute('xmlns');
  svg.removeAttribute('xlink');
  if (!svg.hasAttributeNS(prefix.xmlns, 'xmlns')) {
    svg.setAttributeNS(prefix.xmlns, 'xmlns', prefix.svg);
  }
  if (!svg.hasAttributeNS(prefix.xmlns, 'xmlns:xlink')) {
    svg.setAttributeNS(prefix.xmlns, 'xmlns:xlink', prefix.xlink);
  }

  return (
    doctype
    + new XMLSerializer()
      .serializeToString(svg)
      .replace('</style>', `<![CDATA[${styles}]]></style>`)
  );
}

const exportStyleSheet = `svg { font: 10px sans-serif; }
.chart-line { stroke-width: 1; fill: none; pointer-events: none; }
.axis path, .axis line { fill: none; stroke: lightgray; shape-rendering: crispEdges; }`;

const filename = 'plot.svg';

export function downloadSVG(el) {
  if (el == null) {
    return;
  }
  downloadBlob(
    new Blob([svgToString(el, exportStyleSheet)], {
      type: 'text/xml',
    }),
    filename,
  );
}

const thumbnailStyle = `svg { font: 10px sans-serif; }
.line { stroke-width: 1; fill: none; pointer-events: none; }
.axis path, .axis line { fill: none; stroke: lightgray; shape-rendering: crispEdges; }`;

function applyRange(scales, range) {
  _.forEach(scales, (s, i) => s.range([(i + 1) * range, i * range]));
  return scales;
}

function createScales(range, series) {
  return applyRange(
    _.map(series, (s) => scaleLinear().domain([s.signal.domain.min, s.signal.domain.max])),
    range,
  );
}

function generateMultiplot({
  svg,
  height,
  xScale,
  series,
  fitMode,
}) {
  const yOrdinalScale = scaleBand()
    .range([0, height])
    .domain(_.map(series, (s) => s.signal.path));
  const yScales = createScales(yOrdinalScale.bandwidth(), series);

  const lines = _.map(yScales, (yScale) => svgLine()
    .x((d) => xScale(d.time))
    .y((d) => yScale(d.value)));

  const yAxis = _.map(yScales, (y) => axisLeft(y)
    .ticks(3)
    .tickFormat(format('.2s'))
    .tickSizeOuter(0));
  const yAxisElem = _.map(yAxis, (a) => svg
    .append('g')
    .classed('axis', true)
    .call(a));
  _.forEach(yAxisElem, (e) => e
    .select('path')
    .style('stroke', '#000')
    .style('stroke-width', 1));

  _.forEach(series, (s, i) => {
    if (fitMode) {
      yScales[i].domain(fitDomain(s.values));
    }
    svg
      .append('path')
      .classed('line', true)
      .attr('stroke', '#000')
      .attr('d', lines[i](s.values));
  });
}

function getLargestDomain(series) {
  const ls = _.maxBy(series, (s) => s.signal.domain.max - s.signal.domain.min);
  return [ls.signal.domain.min, ls.signal.domain.max];
}

function generateSingleplot({
  svg,
  height,
  xScale,
  series,
  fitMode,
}) {
  const yScale = scaleLinear().range([height, 0]);

  if (fitMode) {
    yScale.domain(maxDomain(_.map(series, (s) => fitDomain(s.values))));
  } else {
    yScale.domain(getLargestDomain(series));
  }

  const yAxis = axisLeft(yScale).tickFormat(format('.2s'));

  svg
    .append('g')
    .classed('axis', true)
    .call(yAxis);

  const line = svgLine()
    .x((d) => xScale(d.time))
    .y((d) => yScale(d.value));

  _.forEach(series, (s) => {
    svg
      .append('path')
      .classed('line', true)
      .attr('stroke', '#000')
      .attr('d', line(s.values));
  });
}

/**
 * Generates svg file representing the input data.
 * This is mainly used for generating visual thumbnails for labels.
 */
export function generateThumbnail(series, groups, fitMode, label) {
  const width = 1280;
  const height = 720;
  const margin = {
    top: 10,
    right: 20,
    left: 50,
    bottom: 30,
  };
  const w = width - margin.left - margin.right;
  const h = height - margin.top - margin.bottom;
  const container = select('body')
    .append('svg')
    .attr('width', width)
    .attr('height', height)
    .attr('viewBox', `0 0 ${width} ${height}`);

  const svg = container
    .append('g')
    .attr('transform', `translate(${margin.left}, ${margin.top})`);

  const xScale = scaleTime()
    .range([0, w])
    .domain([label.start, label.end]);

  const xAxis = axisBottom(xScale).ticks(10);

  svg
    .append('g')
    .classed('axis', true)
    .attr('transform', `translate(0, ${h})`)
    .call(xAxis);
  const plotProps = {
    svg,
    height: h,
    xScale,
    series,
    fitMode,
  };
  if (groups.type === GROUP_TYPES.MULTIPLOT) {
    generateMultiplot(plotProps);
  } else {
    generateSingleplot(plotProps);
  }

  const res = svgToString(container.node(), thumbnailStyle);
  container.remove();
  return res;
}