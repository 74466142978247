/* eslint-disable no-return-assign */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable react/no-deprecated */
/* eslint-disable react/prop-types */
import React, { Component } from 'react';
import { axisBottom } from 'd3-axis';
import { select } from 'd3-selection';
import { transform } from './utils';
import { timestampFormatter } from '../../utils/timeUtils';

export default class TimeAxis extends Component {
  constructor(props) {
    super(props);
    this.xAxis = axisBottom(props.xScale.inner)
      .tickFormat(timestampFormatter(props.recordInfo.timezone))
      .ticks(10);
  }

  componentDidMount() {
    this.redraw();
  }

  componentWillReceiveProps(nextProps) {
    let redraw = false;
    if (this.props.recordInfo !== nextProps.recordInfo) {
      this.xAxis.tickFormat(timestampFormatter(nextProps.recordInfo.timezone));
      redraw = true;
    }
    if (this.props.xScale !== nextProps.xScale) {
      redraw = true;
    }
    if (this.props.size !== nextProps.size) {
      this.el.setAttribute('transform', transform(0, nextProps.size.height));
      redraw = true;
    }
    if (redraw) {
      this.redraw();
    }
  }

  shouldComponentUpdate() {
    return false;
  }

  redraw() {
    select(this.el).call(this.xAxis);
  }

  render() {
    return (
      <g
        className="x-axis axis"
        transform={transform(0, this.props.size.height)}
        ref={(el) => (this.el = el)}
      />
    );
  }
}
