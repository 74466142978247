import React from 'react';
import PropTypes from 'prop-types';
import styles from './Switch.module.scss';

function Switch(props) {
  const {
    label,
    value,
    onChange,
    disabled,
  } = props;

  return (
    <div className={`${styles.container} ${disabled ? styles.disabled : ''}`}>
      <div
        className={styles.switch}
        htmlFor={label}
        onClick={() => {
          if (!disabled) onChange(!value);
        }}
        role="none"
      >
        <input type="checkbox" name={label} checked={value} onChange={() => { }} disabled={disabled} />
        <span className={styles.slider} />
      </div>
      <span className={styles.label}>{label}</span>
    </div>
  );
}

Switch.defaultProps = {
  label: '',
  value: false,
  onChange: null,
  disabled: false,
};

Switch.propTypes = {
  label: PropTypes.string,
  value: PropTypes.bool,
  onChange: PropTypes.func,
  disabled: PropTypes.bool,
};

export default Switch;
