/* eslint-disable react/prop-types */
/* eslint-disable react/destructuring-assignment */
import React from 'react';
import _ from 'lodash';
import styles from './ScoringPlot.module.scss';
import ScoringPlot from './ScoringPlot';
import { stagingEEGConfig } from '../models/staging';

function updateStims({
  svg,
  height,
  xScale,
  data: {
    stimulations,
  },
}) {
  const stims = svg.selectAll('.hypnogram-plot-stims').data(stimulations);
  stims.exit().remove();
  stims
    .enter()
    .append('line')
    .classed('hypnogram-plot-stims', true)
    .attr('stroke-opacity', 0.1)
    .attr('stroke', '#700000')
    .attr('stroke-width', 1)
    .style('pointer-events', 'none')
    .attr('y1', 0)
    .attr('y2', height)
    .merge(stims)
    .attr('x1', (d) => xScale(d.time))
    .attr('x2', (d) => xScale(d.time));
}

const isAsleepMarker = {
  name: 'is asleep',
  color: '#75ff07',
};

const wantToSleepMarker = {
  name: 'want to sleep',
  color: '#0dfff4',
};

const alarmRingTimeMarker = {
  name: 'alarm ringtime',
  color: '#ffa005',
};

function updateMarkers({
  svg,
  height,
  xScale,
  data: {
    report,
  },
}) {
  const data = [];
  if (report != null) {
    if (report.sleep_start_time != null) {
      data.push({ time: report.sleep_start_time * 1000, ...isAsleepMarker });
      if (report.sleep_onset_duration != null) {
        data.push({
          time: (report.sleep_start_time - report.sleep_onset_duration) * 1000,
          ...wantToSleepMarker,
        });
      }
    }
    if (report.real_alarm_ring_time != null) {
      data.push({
        time: report.real_alarm_ring_time * 1000,
        ...alarmRingTimeMarker,
      });
    }
  }
  const markers = svg.selectAll('.hypnogram-plot-markers').data(data);
  markers.exit().remove();

  const g = markers
    .enter()
    .append('g')
    .classed('hypnogram-plot-markers', true);

  g.append('line')
    .classed('hypnogram-plot-markers-line', true)
    .attr('stroke-width', 2)
    .attr('pointer-events', 'none')
    .attr('y1', 0);

  g.append('text')
    .classed(styles.hypnogramPlotMarkersText, true)
    .attr('text-anchor', 'middle')
    .attr('font-size', 11)
    .attr('dx', '0.5em')
    .style('writing-mode', 'tb');

  const merged = g.merge(markers);
  merged.attr('transform', (d) => `translate(${xScale(d.time)}, 0)`);
  merged
    .select('.hypnogram-plot-markers-line')
    .attr('stroke', (d) => d.color)
    .attr('y2', height);
  merged
    .select(`.${styles.hypnogramPlotMarkersText}`)
    .attr('y', height / 2)
    .text((d) => d.name);
}

function update(params) {
  updateStims(params);
  updateMarkers(params);
}

export default function HypnogramPlot(props) {
  return (
    <ScoringPlot
      fitData={false}
      serie={props.serie}
      range={props.range}
      recordInfo={props.recordInfo}
      onRangeChange={props.onRangeChange}
      stagingDomain={stagingEEGConfig.domain}
      extraData={{
        stimulations: props.stimulations,
        report: _.get(props, 'recordInfo.record.report', null),
      }}
      plotExtra={update}
      hidePercent
    />
  );
}
