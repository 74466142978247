import React from 'react';
import PropTypes from 'prop-types';
import styles from './PositiongramPlot.module.scss';
import ScoringPlot from './ScoringPlot';
import { positiongramConfig } from '../models/staging';

const positionImages = {
  RIGHT:
    'https://s3.eu-central-1.amazonaws.com/dreem-static/positiongram/positiongram-right.png',
  LEFT:
    'https://s3.eu-central-1.amazonaws.com/dreem-static/positiongram/positiongram-left.png',
  FRONT:
    'https://s3.eu-central-1.amazonaws.com/dreem-static/positiongram/positiongram-front.png',
  BACK:
    'https://s3.eu-central-1.amazonaws.com/dreem-static/positiongram/positiongram-back.png',
  STANDING:
    'https://s3.eu-central-1.amazonaws.com/dreem-static/positiongram/positiongram-wake.png',
};

function computeCurrentStage(props) {
  const r = props.range.range();
  const res = props.serie
    .filter((s) => s.start <= r.end && r.start <= s.end)
    .groupBy((s) => s.stage)
    .map((stageList, stage) => ({
      stage,
      duration: stageList.sumBy(
        (s) => Math.min(r.end, s.end) - Math.max(s.start, r.start),
      ),
    }))
    .maxBy((s) => s.duration);
  return res.stage;
}

function PositiongramPlot(props) {
  const {
    serie,
    range,
    recordInfo,
    onRangeChange,
  } = props;
  const currentStage = computeCurrentStage(props);
  return (
    <div className={styles.container}>
      <ScoringPlot
        fitData={false}
        serie={serie}
        range={range}
        recordInfo={recordInfo}
        onRangeChange={onRangeChange}
        stagingDomain={positiongramConfig.domain}
        containerClass={styles.plotContainer}
        margin={{
          top: 10, right: 20, bottom: 20, left: 100,
        }}
      />
      <div className={styles.currentPosition}>
        <img src={positionImages[currentStage]} alt={currentStage} />
      </div>
    </div>
  );
}

PositiongramPlot.defaultProps = {
  serie: null,
  range: null,
  recordInfo: null,
  onRangeChange: null,
};

PositiongramPlot.propTypes = {
  serie: PropTypes.instanceOf(Object),
  range: PropTypes.instanceOf(Object),
  recordInfo: PropTypes.instanceOf(Object),
  onRangeChange: PropTypes.func,
};

export default PositiongramPlot;
