/* eslint-disable react/prop-types */
import React, { useState } from 'react';
import styles from './ToolbarProgress.module.scss';
import StagingState from './StagingState';
import SignalNavigation from './SignalNavigation';
import { formatSimpleDuration, formatTime } from '../utils/timeUtils';
import Button from '../../../shared/button/Button';
import { ReactComponent as Chevron } from '../../../../assets/images/icons/chevron.svg';

export default function ToolbarProgress(props) {
  const {
    onProgressUpdate,
    range,
    featuresConfig,
    algoOverview,
    staging,
    recordInfo,
    saveStaging,
  } = props;
  const [algorithm, setAlgorithm] = useState(null);
  const [showTimeDetails, setShowTimeDetails] = useState(false);

  const nextWindow = () => onProgressUpdate(range.current + range.window);

  const previousWindow = () => onProgressUpdate(range.current - range.window);

  const navigateTo = (c) => onProgressUpdate(c);

  const renderAlgoOverviewSelect = () => {
    if (!featuresConfig.algorythmOverview) {
      return null;
    }
    return (
      <select
        className={styles.selectAlgorithm}
        value={algorithm}
        onChange={(a) => setAlgorithm(a)}
      >
        {algoOverview.map((a) => (
          <option key={a.title}>{a.title}</option>
        ))}
      </select>
    );
  };

  const renderProgress = () => {
    if (!staging.enabled) {
      return (
        <div className={styles.toolbar}>
          {renderAlgoOverviewSelect()}
          <Chevron
            className={styles.chevron}
            onClick={previousWindow}
            width="16px"
            height="16px"
            fill="#7A71A6"
            transform=""
          />
          <SignalNavigation
            recordInfo={recordInfo}
            range={range}
            algoOverview={algoOverview}
            onRangeChange={navigateTo}
            selectedAlgo={algorithm}
          />
          <Chevron
            className={styles.chevron}
            onClick={nextWindow}
            width="16px"
            height="16px"
            fill="#7A71A6"
            transform="rotate(-180)"
          />
        </div>
      );
    }
    return (
      <div className={styles.toolbarStaging}>
        <StagingState
          range={range}
          stages={staging.stages}
        />
        <div className={styles.stagingSave}>
          <Button
            type="button"
            onClick={saveStaging}
            text="save"
          />
        </div>
      </div>
    );
  };

  return (
    <div className={styles.toolbar}>
      <div
        className={styles.time}
        onMouseOver={() => setShowTimeDetails(true)}
        onFocus={() => setShowTimeDetails(true)}
        onMouseLeave={() => setShowTimeDetails(false)}
      >
        {!showTimeDetails ? (
          formatTime(
            range.current,
            recordInfo.timezone,
          ))
          : (
            <div>
              <div>{`Window range: ${formatSimpleDuration(range.window)}`}</div>
              <div>{`Elapsed: ${formatSimpleDuration(range.current - range.start)}`}</div>
            </div>
          )}
      </div>
      {renderProgress()}
    </div>
  );
}
