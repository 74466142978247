import React, { useCallback, useRef, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment-timezone';
import { v4 as uuidv4 } from 'uuid';
import styles from './DateRangePicker.module.scss';
import { ReactComponent as Filter } from '../../../assets/images/icons/filter.svg';
import { ReactComponent as Chevron } from '../../../assets/images/icons/chevron.svg';
import { ReactComponent as Close } from '../../../assets/images/icons/close.svg';
import { ReactComponent as CalendarEmpty } from '../../../assets/images/icons/calendarEmpty.svg';

function DateRangePicker(props) {
  const {
    disabled,
    startDate,
    setStartDate,
    stopDate,
    setStopDate,
  } = props;
  const componentRef = useRef();
  const inputBarRef = useRef();
  const [focus, setFocus] = useState(false);
  const [date, setDate] = useState(
    new Date(new Date().getFullYear(), new Date().getMonth(), 1),
  );
  const [preStopDate, setPreStopDate] = useState(null);
  const [rightIcon, setRightICon] = useState(<CalendarEmpty fill="#C0BAEE" width="16px" height="16px" />);
  const months = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'];

  const switchFocus = useCallback((newValue) => {
    if (focus === true) setPreStopDate(null);
    if (focus === false && stopDate) setPreStopDate(null);
    setFocus(newValue !== undefined ? newValue : !focus);
  }, [focus, stopDate]);

  useEffect(() => {
    function handleClick(e) {
      if (componentRef && componentRef.current) {
        const ref = componentRef.current;
        if (!ref.contains(e.target)) {
          switchFocus(false);
        }
      }
    }
    document.addEventListener('click', handleClick);
    return () => document.removeEventListener('click', handleClick);
  }, [switchFocus]);

  const calendarWidth = () => {
    if (inputBarRef && inputBarRef.current && inputBarRef.current.offsetWidth) {
      return `calc(${inputBarRef.current.offsetWidth}px - 20px)`;
    }
    return 'unset';
  };

  const handleMonth = (dir) => {
    const nextDate = date;
    switch (dir) {
      case 'prev':
        nextDate.setMonth(date.getMonth() - 1);
        break;
      default:
        nextDate.setMonth(date.getMonth() + 1);
        break;
    }
    setDate(new Date(nextDate));
    setPreStopDate(null);
  };

  const handleDate = (id) => {
    const toDate = new Date(id.replaceAll('_', '/'));
    if (!startDate) {
      setStartDate(toDate);
      setPreStopDate(null);
    } else if (startDate && !stopDate) {
      setStopDate(toDate);
      if (toDate < startDate) {
        const nextStopDate = startDate;
        setStartDate(toDate);
        setStopDate(nextStopDate);
        setPreStopDate(null);
      }
    } else {
      setStartDate(toDate);
      setStopDate(null);
    }
  };

  const dayTemplate = (day, needId) => {
    function id() {
      return `${date.getMonth() + 1}_${day}_${date.getFullYear()}`;
    }
    function classes() {
      let res = `${styles.day} `;
      const actualDate = new Date(date).setDate(day);
      if (needId) {
        if (startDate && new Date(startDate).getTime() === actualDate) {
          res += `${styles.selected} ${stopDate ? styles.startDate : ''} `;
        }
        if (new Date(stopDate).getTime() === actualDate) {
          res += `${styles.selected} ${styles.stopDate} `;
        }
        if (startDate && stopDate) {
          if (actualDate > startDate && actualDate < stopDate) {
            res += `${styles.inRange} `;
          }
        }
        if (preStopDate !== null && startDate && preStopDate !== stopDate) {
          const psdISO = new Date(preStopDate.replaceAll('_', '-')).getTime();

          if (new Date(startDate).getTime() === actualDate) {
            if (psdISO > new Date(startDate).getTime()) {
              res += `${styles.selected} ${styles.startDate} `;
            }
            if (psdISO < new Date(startDate).getTime()) {
              res += `${styles.selected} ${styles.stopDate} `;
            }
          }

          if (psdISO > new Date(startDate).getTime()
            && actualDate > new Date(startDate).getTime()
            && actualDate < psdISO
          ) {
            res += `${styles.inRange} `;
          }

          if (psdISO < new Date(startDate).getTime()
            && actualDate < new Date(startDate).getTime()
            && actualDate > psdISO) {
            res += `${styles.inRange} `;
          }

          if (actualDate === psdISO && !stopDate) {
            if (actualDate > new Date(startDate).getTime()) res += `${styles.selected} ${styles.stopDate} `;
            if (actualDate < new Date(startDate).getTime()) res += `${styles.selected} ${styles.startDate} `;
          }
        }
      }
      return res;
    }
    return (
      <div
        className={styles.day}
        id={needId && id()}
        onClick={() => handleDate(id())}
        onMouseOver={() => !stopDate && setPreStopDate(id())}
        onMouseLeave={() => !stopDate && setPreStopDate(null)}
        onKeyPress={null}
        onFocus={() => null}
        role="button"
        tabIndex={0}
        key={needId ? id() : uuidv4()}
      >
        <span className={classes()}>
          {day || ''}
        </span>
      </div>
    );
  };

  const offStartDays = () => {
    const arr = Array.from(Array(date.getDay()).keys());
    arr.shift();
    return arr.map(() => <div key={uuidv4()} />);
  };

  const monthDays = () => {
    const days = Array.from(
      Array(new Date(date.getFullYear(), date.getMonth() + 1, 0).getDate()).keys(),
    );
    return days.map((i) => dayTemplate(i + 1, true));
  };

  const daysName = () => {
    const dn = ['M', 'T', 'W', 'T', 'F', 'S', 'S'];
    return dn.map((n) => dayTemplate(n));
  };

  const titleRender = () => {
    let dateStartDate;
    let dateStopDate;
    const defaultDateFormat = 'MMM. D YYYY';
    if (startDate) {
      dateStartDate = moment(startDate).format(defaultDateFormat);
    }
    if (stopDate) {
      dateStopDate = moment(stopDate).format(defaultDateFormat);
    }
    if (startDate && stopDate && startDate === stopDate) {
      return dateStartDate;
    }
    return (
      <>
        <span className={startDate ? styles.date : ''}>{startDate ? dateStartDate : 'Start date'}</span>
        <span className={stopDate ? styles.date : ''}>&nbsp;-&nbsp;</span>
        <span className={stopDate ? styles.date : ''}>{stopDate ? dateStopDate : 'Stop date'}</span>
      </>
    );
  };

  const updateRightICon = (mouseIsOver) => {
    if (mouseIsOver && startDate) {
      setRightICon(<Close fill="#fff" width="10px" height="10px" style={{ background: '#C0BAEE', borderRadius: '100px', padding: '3px' }} />);
    } else {
      setRightICon(<CalendarEmpty fill="#C0BAEE" width="16px" height="16px" />);
    }
  };

  const OnRightIconClick = () => {
    if (startDate) setStartDate(null);
    if (stopDate) setStopDate(null);
  };

  return (
    <div className={styles.container} ref={componentRef}>
      <div
        className={`${styles.inputBar} ${focus ? styles.focus : ''} ${disabled ? styles.disabled : ''}`}
        ref={inputBarRef}
        onClick={() => !disabled && switchFocus()}
        onMouseOver={() => updateRightICon(true)}
        onMouseOut={() => updateRightICon(false)}
        onFocus={() => null}
        onBlur={() => null}
        onKeyPress={null}
        role="button"
        tabIndex={0}
      >
        <Filter height="15px" width="18px" fill="#C0BAEE" />
        <span>{titleRender()}</span>
        <div onClick={() => OnRightIconClick()} onKeyPress={null} role="button" tabIndex={0}>
          {rightIcon}
        </div>
      </div>
      {focus && (
        <div className={styles.calendar} style={{ width: calendarWidth() }}>
          <div className={styles.calendarHeader}>
            <h2>{months[date.getMonth()]}</h2>
            <span>{date.getFullYear()}</span>
            <div className={styles.navigation}>
              <div
                onClick={() => handleMonth('prev')}
                onKeyPress={null}
                role="button"
                tabIndex={0}
              >
                <Chevron
                  width="10px"
                  fill="#C0BAEE"
                  transform=""
                />
              </div>
              <div
                onClick={() => handleMonth('next')}
                onKeyPress={null}
                role="button"
                tabIndex={0}
              >
                <Chevron
                  width="10px"
                  fill="#C0BAEE"
                  transform="rotate(-180)"
                />
              </div>
            </div>
          </div>
          <div className={styles.calendarDays}>
            {offStartDays()}
            {monthDays()}
          </div>
          <div className={styles.daysName}>
            {daysName()}
          </div>
        </div>
      )}
    </div>
  );
}

DateRangePicker.defaultProps = {
  disabled: false,
  startDate: null,
  setStartDate: null,
  stopDate: null,
  setStopDate: null,
};

DateRangePicker.propTypes = {
  disabled: PropTypes.bool,
  startDate: PropTypes.instanceOf(Date),
  setStartDate: PropTypes.instanceOf(Function),
  stopDate: PropTypes.instanceOf(Date),
  setStopDate: PropTypes.instanceOf(Function),
};

export default DateRangePicker;
