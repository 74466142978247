/* eslint-disable class-methods-use-this */
/* eslint-disable react/prop-types */
/* eslint-disable react/no-deprecated */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable react/sort-comp */
import React, { Component } from 'react';
import _ from 'lodash';
import { timeFormat } from 'd3-time-format';
import { format as numberFormat } from 'd3-format';
import { bisector } from 'd3-array';
import { mouse } from 'd3-selection';
import { transform } from './utils';

const df = timeFormat('%H:%M:%S:%L');
const fn = numberFormat('.3f');
const bs = bisector((d) => d.time).right;

function serieContainsMark(timestamp, serie) {
  return (
    timestamp >= serie.values[0].time && timestamp <= _.last(serie.values).time
  );
}

export default class Tooltip extends Component {
  constructor(props) {
    super(props);
    this.state = {
      location: _.get(props, 'selection.mark.location', -100000),
    };
  }

  shouldComponentUpdate(nextProps, nextState) {
    if (
      this.state.location === nextState.location
      && this.computeVisible(this.props, this.state.location)
      === this.computeVisible(nextProps, nextState.location)
    ) {
      return false;
    }
    return true;
  }

  componentWillReceiveProps(nextProps) {
    if (
      this.props.selection !== nextProps.selection
      || (this.props.measureMode !== nextProps.measureMode
        && !nextProps.measureMode)
    ) {
      this.setState({
        location: _.get(nextProps, 'selection.mark.location', -100000),
      });
    }
  }

  computeVisible(props, location) {
    return (
      props.range.inRange(location)
      && props.series.length > 0
      && serieContainsMark(location, props.series[0])
    );
  }

  updateCursor(element) {
    if (this.props.measureMode) {
      this.setState({
        location: this.props.xScale.invert(mouse(element)[0]).getTime(),
      });
    }
  }

  render() {
    const x = this.state.location;
    const xPos = this.props.xScale(x);
    const visible = this.computeVisible(this.props, x);
    const values = visible
      ? _.map(this.props.series, (s) => s.values[bs(s.values, x, 1)])
      : [];
    const colors = visible
      ? _.map(this.props.series, (s) => _.get(this.props.colorConfig, s.signal.path, '#000'))
      : [];
    const display = visible ? '' : 'none';
    return (
      <g>
        <text
          className="marker-timestamp-text"
          fontSize="12px"
          fill="deeppink"
          x={xPos + 10}
          y={this.props.size.height / 2}
          display={display}
        >
          {df(x)}
        </text>
        {_.map(values, (v, i) => (
          <g
            key={i}
            className="marker-tooltip"
            transform={transform(xPos + 1.5, this.props.yScales[i](v.value))}
            display={display}
          >
            <circle r="4" stroke="deeppink" fill="none" />
            <text
              className="marker-value-text"
              fontSize="12px"
              x="10"
              fill={colors[i]}
              y={this.props.yScales[i](v.value) < 20 ? 15 : -15}
            >
              {fn(v.value)}
            </text>
          </g>
        ))}
      </g>
    );
  }
}
