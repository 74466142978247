import React from 'react';
import PropTypes from 'prop-types';
import styles from './CustomCard.module.scss';

export function CustomCard(props) {
  const {
    className,
    children,
  } = props;

  return (
    <div className={`${styles.card} ${className || ''}`}>
      {children}
    </div>
  );
}

CustomCard.defaultProps = {
  className: null,
  children: null,
};

CustomCard.propTypes = {
  className: PropTypes.string,
  children: PropTypes.instanceOf(Object),
};

export default CustomCard;
