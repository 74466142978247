/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable no-return-assign */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable react/no-deprecated */
/* eslint-disable react/prop-types */
/* eslint-disable max-classes-per-file */
import React, { Component, Fragment } from 'react';
import _ from 'lodash';
import { format as numberFormat } from 'd3-format';
import { select } from 'd3-selection';
import { axisLeft } from 'd3-axis';
import { line as svgLine } from 'd3-shape';
import { scaleLinear, scaleBand } from 'd3-scale';
import styles from './plot.module.scss';
import { compareSeries, compareConfigurations, fitDomain } from './utils';
import BasePlot from './BasePlot';
import Interaction from './Interaction';
import ReferenceLines from './ReferenceLines';
import NumberDisplay from './NumberDisplay';
import Labels from './Labels';
import SignalInfo from './SignalInfo';

const nFormat = numberFormat('.4');

function setAxisColor(axis, color) {
  select(axis)
    .select('path')
    .style('stroke', color != null ? color : '#000')
    .style('stroke-width', 1);
}

class MultiAxis extends Component {
  constructor(props) {
    super(props);
    this.yAxis = _.map(props.yScales, (y) => axisLeft(y)
      .ticks(3)
      .tickFormat(nFormat)
      .tickSizeOuter(0));
    this.yAxisElem = [];
  }

  componentDidMount() {
    this.redraw();
    this.applyColorToYAxis(this.props);
  }

  componentWillReceiveProps(nextProps) {
    if (this.props.configuration !== nextProps.configuration) {
      this.applyColorToYAxis(nextProps);
    }
    if (
      this.props.fitMode !== nextProps.fitMode
      || this.props.size !== nextProps.size
      || this.props.yScales !== nextProps.yScales
    ) {
      this.redraw();
    }
  }

  shouldComponentUpdate() {
    return false;
  }

  applyColorToYAxis(props) {
    const {
      series,
      configuration: { colorConfig },
    } = props;
    if (series != null && colorConfig != null) {
      for (let i = 0; i < this.yAxisElem.length; i += 1) {
        setAxisColor(this.yAxisElem[i], colorConfig[series[i].signal.path]);
      }
    } else {
      for (let i = 0; i < this.yAxisElem.length; i += 1) {
        setAxisColor(this.yAxisElem[i], null);
      }
    }
  }

  redraw() {
    for (let i = 0; i < this.yAxis.length; i += 1) {
      select(this.yAxisElem[i]).call(this.yAxis[i]);
    }
  }

  render() {
    return _.map(this.yAxis, (s, i) => (
      <g
        key={i}
        ref={(el) => (this.yAxisElem[i] = el)}
        className={`y-axis axis ${styles.axis}`}
      />
    ));
  }
}

function line(xScale, yScale) {
  let limiter = false;
  let domain;
  const ln = svgLine()
    .x((d) => xScale(d.time))
    .y((d) => {
      if (limiter) {
        return yScale(Math.max(Math.min(d.value, domain.max), domain.min));
      }
      return yScale(d.value);
    });
  return {
    setLimiter(value, d) {
      limiter = value;
      domain = d;
    },
    line: ln,
  };
}

class MultiPlotChart extends Component {
  constructor(props) {
    super(props);
    this.lines = _.map(props.yScales, (s) => line(props.xScale, s));
    this.updateLinesLimiter(props);
  }

  shouldComponentUpdate(nextProps) {
    const { props } = this;
    const hasFitChanged = nextProps.fitMode !== props.fitMode;
    return (
      !compareSeries(nextProps.series, props.series)
      || !compareConfigurations(nextProps.configuration, props.configuration)
      || hasFitChanged
      || props.size !== nextProps.size
      || props.hiddenSeries !== nextProps.hiddenSeries
    );
  }

  componentWillUpdate(nextProps) {
    this.updateLinesLimiter(nextProps);
  }

  updateLinesLimiter(props) {
    const {
      series,
      configuration: { scaleConfig, limiterMode },
    } = props;
    for (let i = 0; i < series.length; i += 1) {
      const d = _.get(
        scaleConfig,
        series[i].signal.path,
        series[i].signal.domain,
      );
      this.lines[i].setLimiter(limiterMode, d);
    }
  }

  render() {
    return _.map(this.props.series, (s, i) => {
      const color = !_.find(this.props.hiddenSeries, (hs) => hs === s.signal.path)
        ? this.props.configuration.colorConfig[s.signal.path]
        : 'transparent';
      return (
        <path
          key={s.signal.path}
          className="chart-line"
          stroke={color != null ? color : '#000'}
          d={this.lines[i].line(s.values)}
        />
      );
    });
  }
}

function applyRange(scales, ordScale) {
  const step = ordScale.step();
  const bw = ordScale.bandwidth();
  _.forEach(scales, (s, i) => s.range([i * step + bw, i * step]));
  return scales;
}

function createScales(ordScale, series, cfg) {
  const scales = _.map(series, (s) => {
    const d = _.get(cfg, s.signal.path, s.signal.domain);
    return scaleLinear().domain([d.min, d.max]);
  });
  return applyRange(scales, ordScale);
}

class MultiPlot extends Component {
  constructor(props) {
    super(props);
    this.yOrdinalScale = scaleBand()
      .domain(_.map(props.series, (s) => s.signal.path))
      .paddingInner(0.05);
    this.yScales = createScales(
      this.yOrdinalScale,
      props.series,
      props.configuration.scaleConfig,
    );
    this.onSizeUpdate(props.size);
    this.updateYScales(props);

    this.onZoom = this.onZoom.bind(this);
  }

  componentWillReceiveProps(nextProps) {
    let yScalesUpdated = false;
    if (this.props.size !== nextProps.size) {
      this.onSizeUpdate(nextProps.size);
      yScalesUpdated = true;
    }
    if (
      this.props.fitMode !== nextProps.fitMode
      || !compareSeries(nextProps.series, this.props.series)
      || !compareConfigurations(nextProps.configuration, this.props.configuration)
    ) {
      this.updateYScales(nextProps);
      yScalesUpdated = true;
    }
    if (yScalesUpdated) {
      this.yScales = this.yScales.slice();
    }
  }

  onSizeUpdate(size) {
    this.yOrdinalScale.range([0, size.height]);
    applyRange(this.yScales, this.yOrdinalScale);
  }

  onZoom() {
    this.mpc.forceUpdate();
  }

  // TODO: Update tick format for RAW series
  updateYScales(props) {
    const {
      fitMode,
      series,
      configuration: { scaleConfig },
    } = props;
    for (let i = 0; i < this.yScales.length; i += 1) {
      if (fitMode) {
        const d = fitDomain(series[i].values);
        this.yScales[i].domain(d);
      } else {
        const d = _.get(
          scaleConfig,
          series[i].signal.path,
          series[i].signal.domain,
        );
        this.yScales[i].domain([d.min, d.max]);
      }
    }
  }

  render() {
    const { props } = this;
    return (
      <>
        <Interaction onZoom={this.onZoom} yScales={this.yScales} {...props} />
        <MultiAxis
          configuration={props.configuration}
          fitMode={props.fitMode}
          series={props.series}
          yScales={this.yScales}
          size={props.size}
        />
        <ReferenceLines
          yScales={this.yScales}
          series={props.series}
          referenceLines={props.referenceLines}
          size={props.size}
        />
        <MultiPlotChart
          ref={(e) => (this.mpc = e)}
          yScales={this.yScales}
          {...props}
        />
        <NumberDisplay
          enabled={props.numberDisplay}
          series={props.series}
          yScales={this.yScales}
          xScale={props.xScale}
          window={props.range.window}
        />
        <Labels
          yScales={this.yScales}
          yOrdinalScale={this.yOrdinalScale}
          {...props}
        />
      </>
    );
  }
}

export default function MultiPlotWrapper(props) {
  return (
    <div className={styles.containerWithSignalInfo}>
      <SignalInfo {...props} />
      <BasePlot {...props}>
        <MultiPlot {...props} />
      </BasePlot>
    </div>
  );
}
