/* eslint-disable react/no-access-state-in-setstate */
/* eslint-disable react/prop-types */
/* eslint-disable react/destructuring-assignment */
import React from 'react';
import _ from 'lodash';
import { connect } from 'react-redux';
import { createSelector } from 'reselect';
import styles from './TimeSeriesToolbar.module.scss';
import ProfileSelector from '../components/ProfileSelector';
import {
  updateWindow,
  toggleFitMode,
  toggleZoomMode,
  windowMatchSelection,
  toggleMeasureMode,
  updateThreshold,
  toggleLimiterMode,
} from '../actions/timeseries';
import { toggleLabelEdit, toggleLabelChannelLayout } from '../actions/labels';
import {
  toggleFFT,
  toggleStimulations,
  displayPositiongram,
  displayScoringProgress,
  displaySpectrogram,
  displayRespigram,
  displaySlaca,
  displayHeartgram,
  displayBrainogram,
} from '../actions/timeseriesContent';
import {
  startEEGStaging,
} from '../actions/staging';
import { createConfig, selectConfig, deleteConfig } from '../actions/profiles';
import { downloadSVG } from '../actions/exportPlot';
import TimeSeriesStagingSelector from './TimeSeriesStagingSelector';
import { contentTypes } from '../models/content';
import { saveProfile, saveSelectedProfile, getConfig } from '../models/profile';
import LabelToolbox from './toolbox/LabelToolbox';
import { timeSeriesConfig } from '../constants/globals';
import { ReactComponent as CheckIconGreen } from '../../../../assets/images/icons/checkIconGreen.svg';
import { ReactComponent as WarningIconOrange } from '../../../../assets/images/icons/warningIconOrange.svg';
import Switch from '../../../shared/switch/Switch';
import Select from '../../../shared/select/Select';

function ToolboxGroup(props) {
  const {
    title,
    children,
  } = props;
  return (
    <div className={styles.toolboxGroup}>
      <h4 className={styles.toolboxGroupTitle}>{title}</h4>
      <div className={styles.toolboxGroupContent}>{children}</div>
    </div>
  );
}

const toolboxes = [
  {
    id: 'label-toolbox',
    title: 'Labels',
    render: (key) => <LabelToolbox key={key} />,
  },
];

function saveToolboxConfig(tlbx) {
  window.localStorage.setItem(
    timeSeriesConfig.toolbarToolboxes,
    JSON.stringify(tlbx.map((tl) => tl.id)),
  );
}

function loadToolboxConfig() {
  try {
    const stored = window.localStorage.getItem(
      timeSeriesConfig.toolbarToolboxes,
    );
    if (!stored) {
      return [];
    }
    return _(JSON.parse(stored))
      .map((c) => _.find(toolboxes, (tl) => tl.id === c))
      .filter((c) => c != null)
      .value();
  } catch (err) {
    return [];
  }
}

const windowRangeOptions = [
  { value: 10000, display: '10sec' },
  { value: 30000, display: '30sec' },
  { value: 60000, display: '1min' },
  { value: 120000, display: '2min' },
  { value: 180000, display: '3min' },
  { value: 300000, display: '5min' },
  { value: 600000, display: '10min' },
  { value: 1800000, display: '30min' },
  { value: 3600000, display: '1h' },
  { value: 'night', display: 'Whole night' },
];

class TimeSeriesToolbar extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      toolboxes: loadToolboxConfig(),
    };
    this.onProfileSave = this.onProfileSave.bind(this);
    this.onProfileChange = this.onProfileChange.bind(this);
    this.setWindow = this.setWindow.bind(this);
    this.setThreshold = this.setThreshold.bind(this);
    this.editLabel = this.editLabel.bind(this);
    this.labelPerChannel = this.labelPerChannel.bind(this);
    this.positionogram = this.positionogram.bind(this);
    this.heartgram = this.heartgram.bind(this);
    this.respigram = this.respigram.bind(this);
    this.slaca = this.slaca.bind(this);
    this.brainogram = this.brainogram.bind(this);
    this.spectrogram = this.spectrogram.bind(this);
    this.fft = this.fft.bind(this);
    this.scoringProgress = this.scoringProgress.bind(this);
    this.downloadMultiplotSVG = this.downloadMultiplotSVG.bind(this);
    this.limiterMode = this.limiterMode.bind(this);
    this.startEEGStaging = this.startEEGStaging.bind(this);
    this.toggleToolbox = this.toggleToolbox.bind(this);
  }

  onProfileSave() {
    if (saveProfile(this.props.profile)) {
      this.props.dispatch({
        type: 'SET_NOTIFICATION',
        value: {
          icon: <CheckIconGreen />,
          title: 'Saved',
          text: `The ${this.props.profile.selected} profile has been saved.`,
        },
      });
    } else {
      this.props.dispatch({
        type: 'SET_NOTIFICATION',
        value: {
          icon: <WarningIconOrange />,
          title: 'Warning',
          text: `The ${this.props.profile.selected} profile cannot be saved.`,
        },
      });
    }
  }

  onProfileChange(name) {
    this.props.dispatch(selectConfig(name));
    saveSelectedProfile(name);
  }

  setWindow(strValue) {
    const { dispatch, range } = this.props;
    if (strValue === 'night') {
      dispatch(
        updateWindow(range.updateWindow(range.start, range.end - range.start)),
      );
    } else {
      const value = +strValue;
      if (!Number.isNaN(value) && value < range.length) {
        dispatch(updateWindow(range.updateWindow(range.current, value)));
      }
    }
  }

  setThreshold(strValue) {
    const value = +strValue;
    if (value !== this.props.range.threshold) {
      this.props.dispatch(updateThreshold(value));
    }
  }

  editLabel() {
    this.props.dispatch(toggleLabelEdit());
  }

  labelPerChannel() {
    this.props.dispatch(toggleLabelChannelLayout());
  }

  positionogram() {
    this.props.dispatch(displayPositiongram(this.props.recordID));
  }

  heartgram() {
    this.props.dispatch(displayHeartgram(this.props.recordID));
  }

  respigram() {
    this.props.dispatch(displayRespigram(this.props.recordID));
  }

  slaca() {
    this.props.dispatch(displaySlaca(this.props.recordID));
  }

  brainogram() {
    this.props.dispatch(displayBrainogram(this.props.recordID));
  }

  spectrogram(s) {
    this.props.dispatch(displaySpectrogram(s));
  }

  fft() {
    this.props.dispatch(toggleFFT());
  }

  fftDisplayed() {
    return !!this.props.content.getIds(contentTypes.FFT).length;
  }

  scoringProgress() {
    this.props.dispatch(displayScoringProgress());
  }

  // eslint-disable-next-line class-methods-use-this
  downloadMultiplotSVG() {
    downloadSVG(document.querySelector('.plot svg'));
  }

  limiterMode(checked) {
    this.props.dispatch(toggleLimiterMode(checked));
  }

  startEEGStaging() {
    this.props.dispatch(startEEGStaging(this.props.recordID));
  }

  toggleToolbox(toolbox) {
    const visible = _.find(this.state.toolboxes, (tl) => tl.id === toolbox.id);
    const active = visible
      ? this.state.toolboxes.filter((tl) => tl.id !== toolbox.id)
      : this.state.toolboxes.concat(toolbox);
    saveToolboxConfig(active);
    this.setState({ toolboxes: active });
  }

  renderSpectrogramSelection() {
    if (!this.props.featuresConfig.spectrogram) {
      return null;
    }
    const s = this.props.content.get(contentTypes.SPECTROGRAM);
    return (
      <select
        value={s.length ? s[0].data.signal.path : undefined}
        onChange={(e) => this.spectrogram(e.target.value)}
        placeholder="Spectrogram"
      >
        {this.props.signals.map((sg) => (
          <option key={sg.path}>{sg.path}</option>
        ))}
      </select>
    );
  }

  renderExtra() {
    if (!this.props.featuresConfig.stimulations) {
      return null;
    }
    return (
      <ToolboxGroup title="Extra">
        <button
          type="button"
          onClick={() => this.props.dispatch(toggleStimulations(this.props.recordID))}
        >
          Stimulations
        </button>
      </ToolboxGroup>
    );
  }

  render() {
    const { threshold, window: rw } = this.props.range;
    const zoomDisabled = !this.props.zoomMode || this.props.staging.enabled;
    const { limiterMode } = getConfig(this.props.profile);
    const fc = this.props.featuresConfig;
    const windowRangeOptionsRender = windowRangeOptions.map((wr) => ({
      key: wr.value,
      name: wr.display,
    }));
    const smpOptions = [
      { key: 1000, name: '1000smp' },
      { key: 2000, name: '2000smp' },
      { key: 4000, name: '4000smp' },
      { key: 8000, name: '8000smp' },
    ];
    return (
      <div className={styles.toolboxContainer}>
        <ToolboxGroup title="Profile">
          <ProfileSelector
            profile={this.props.profile}
            signals={this.props.signals}
            onProfileChange={this.onProfileChange}
            onProfileCreate={(name) => this.props.dispatch(createConfig(name))}
            onProfileDelete={(name) => this.props.dispatch(deleteConfig(name))}
            onProfileSave={this.onProfileSave}
          />
        </ToolboxGroup>
        <ToolboxGroup title="Signals">
          <button type="button" onClick={this.props.openSelectSignal}>Select signals</button>
          <button type="button" onClick={this.props.openScaleConfigDialog}>
            Scale config
          </button>
          {fc.seriesFilters ? (
            <button type="button" onClick={this.props.openSeriesFilterDialog}>Filters</button>
          ) : null}
          <button type="button" onClick={this.downloadMultiplotSVG}>Export as SVG</button>
        </ToolboxGroup>
        <ToolboxGroup title="Display">
          <Switch
            label="Limiter"
            value={limiterMode}
            onChange={(v) => this.limiterMode(v)}
          />
          <button type="button" onClick={this.props.openReferenceLinesDialog}>
            Reference lines
          </button>
          <Switch
            label="Zoom"
            value={this.props.zoomMode}
            disabled={this.props.staging.enabled}
            onChange={() => this.props.dispatch(toggleZoomMode())}
          />
          <Switch
            label="Measure"
            value={this.props.measureMode}
            onChange={() => this.props.dispatch(toggleMeasureMode())}
          />
          <Switch
            label="Fit"
            value={this.props.fitMode}
            onChange={() => this.props.dispatch(toggleFitMode())}
          />
          <button
            type="button"
            disabled={zoomDisabled}
            onClick={() => this.props.dispatch(windowMatchSelection())}
          >
            Match
          </button>
          <Select
            options={windowRangeOptionsRender}
            selected={_.get(_.find(windowRangeOptions, (wr) => wr.value === rw), 'display', 'Custom')}
            setSelected={(e) => this.setWindow(e)}
            disabled={zoomDisabled}
          />
          <Select
            options={smpOptions}
            selected={threshold}
            setSelected={(e) => this.setThreshold(e)}
            disabled={zoomDisabled}
            units="smp"
          />
        </ToolboxGroup>
        <ToolboxGroup title="Analysis">
          <TimeSeriesStagingSelector
            api={this.props.api}
            recordID={this.props.recordID}
          />
          {/* <button
            className={`${this.fftDisplayed() ? styles.active : ''}`}
            type="button"
            onClick={this.fft}
          >
            FFT
          </button> */}
          <button
            type="button"
            onClick={this.scoringProgress}
            disabled={!this.props.staging.enabled}
          >
            Scoring Progress
          </button>
          {fc.positionogram ? (
            <button type="button" onClick={this.positionogram}>Positionogram</button>
          ) : null}
          {this.renderSpectrogramSelection()}
          {fc.windowRead ? (
            <button type="button" onClick={this.props.openWindowReadDialog}>
              Window Read
            </button>
          ) : null}
          {fc.physioMetrics ? (
            <button type="button" onClick={this.heartgram}>Heartgram</button>
          ) : null}
          {fc.physioMetrics ? (
            <button type="button" onClick={this.respigram}>Respigram</button>
          ) : null}
          {fc.physioMetrics ? (
            <button type="button" onClick={this.brainogram}>Brainogram</button>
          ) : null}
          {fc.physioMetrics ? (
            <button type="button" onClick={this.slaca}>SLACA</button>
          ) : null}
        </ToolboxGroup>
        {this.renderExtra()}
        <ToolboxGroup title="Scoring">
          <button
            type="button"
            onClick={this.startEEGStaging}
            disabled={this.props.staging.enabled}
          >
            EEG
          </button>
        </ToolboxGroup>
      </div>
    );
  }
}

const seriesSelector = createSelector(
  (state) => state.timeSeries,
  (timeSeries) => timeSeries,
);
export default connect(seriesSelector)(TimeSeriesToolbar);
