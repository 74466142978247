/* eslint-disable react/no-access-state-in-setstate */
/* eslint-disable react/prop-types */
/* eslint-disable react/destructuring-assignment */
import React, { Component } from 'react';
import { connect } from 'react-redux';
import ApplicationContainer from './ApplicationContainer';
import { clearErrorMessage } from '../actions/app';
import TimeSeries, { DIALOGS } from './TimeSeries';
import TimeSeriesToolbar from './TimeSeriesToolbar';

class TimeSeriesWrapper extends Component {
  constructor(props) {
    super(props);
    this.state = {
      drawerVisible: false,
      dialog: null,
    };
    this.clearErrorMessage = this.clearErrorMessage.bind(this);
    this.toggleDrawer = this.toggleDrawer.bind(this);
    this.dialogOpener = this.dialogOpener.bind(this);
    this.openDialog = this.openDialog.bind(this);
    this.closeDialog = this.closeDialog.bind(this);
  }

  clearErrorMessage() {
    this.props.dispatch(clearErrorMessage());
  }

  toggleDrawer() {
    this.setState({ drawerVisible: !this.state.drawerVisible });
  }

  closeDialog() {
    this.setState({ dialog: null });
  }

  openDialog(dialog) {
    this.setState({ dialog, drawerVisible: false });
  }

  dialogOpener(dialog) {
    return (e) => {
      // Sometimes toolbar buttons are triggered by the enter key after
      // using the enter key when the modal was opened.
      // The triggered event is a click MouseEvent in both cases
      // so there is no way to make a difference besides supposing
      // physical buttons are not located at (0, 0).
      // What the fuckin fuck is this fuckery
      if (e != null && e.screenX !== 0 && e.screenY !== 0) {
        this.setState({ dialog, drawerVisible: false });
      }
    };
  }

  render() {
    return (
      <ApplicationContainer
        clearErrorMessage={this.clearErrorMessage}
        progress={this.props.progress}
        logout={this.props.logout}
        errorMessage={this.props.errorMessage}
        apps={this.props.apps}
        drawer={(
          <TimeSeriesToolbar
            recordID={this.props.recordID}
            api={this.props.api}
            featuresConfig={this.props.featuresConfig}
            openSelectSignal={this.dialogOpener(DIALOGS.SELECT_SIGNAL_DIALOG)}
            openAddLabel={this.dialogOpener(DIALOGS.LABEL_SIGNAL_DIALOG)}
            openFilterLabels={this.dialogOpener(DIALOGS.LABEL_FILTER_DIALOG)}
            openReferenceLinesDialog={this.dialogOpener(
              DIALOGS.REFERENCE_LINES_DIALOG,
            )}
            openScaleConfigDialog={this.dialogOpener(
              DIALOGS.SCALE_CONFIG_DIALOG,
            )}
            openSeriesFilterDialog={this.dialogOpener(
              DIALOGS.SERIES_FILTER_DIALOG,
            )}
            openWindowReadDialog={this.dialogOpener(DIALOGS.WINDOW_READ_DIALOG)}
          />
        )}
        drawerVisible={this.state.drawerVisible}
        toggleDrawer={this.toggleDrawer}
      >
        <TimeSeries
          viewerVersion={this.props.viewerVersion}
          recordID={this.props.recordID}
          api={this.props.api}
          featuresConfig={this.props.featuresConfig}
          location={this.props.location}
          dialog={this.state.dialog}
          openDialog={this.openDialog}
          closeDialog={this.closeDialog}
          toggleDrawer={this.toggleDrawer}
        />
      </ApplicationContainer>
    );
  }
}

export default connect((state) => state.app)(TimeSeriesWrapper);
