/* eslint-disable no-return-assign */
/* eslint-disable react/prop-types */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable react/no-deprecated */
import React, { Component } from 'react';
import _ from 'lodash';
import { transform, getMargin } from './utils';

export default class SVGElement extends Component {
  constructor(props) {
    super(props);
    this.state = {
      size: null,
    };
    this.margin = getMargin();
    this.updateSize = this.updateSize.bind(this);
    this.onWindowResize = _.debounce(this.updateSize, 500);
  }

  componentDidMount() {
    this.updateSize();
    window.addEventListener('resize', this.onWindowResize);
  }

  componentWillReceiveProps(nextProps) {
    if (this.props.content.size() !== nextProps.content.size()) {
      // Mandatory delay, otherwise offsetWidth and offsetHeight are invalid
      setTimeout(() => {
        this.updateSize();
      }, 500);
    }
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.onWindowResize);
  }

  updateSize() {
    if (this.el == null) {
      throw new Error('Ref is not available');
    }
    const h = this.el.offsetHeight;
    const w = this.el.offsetWidth;
    const size = {
      width: w - this.margin.hMargin(),
      height: h - this.margin.vMargin(),
      offsetWidth: w,
      offsetHeight: h,
    };

    if (this.props.onSizeUpdate) {
      this.props.onSizeUpdate(size);
    }

    this.setState({ size });
  }

  doRender() {
    if (this.state.size == null) {
      return null;
    }
    return (
      <svg
        width={this.state.size.offsetWidth}
        height={this.state.size.offsetHeight}
      >
        <g transform={transform(this.margin.left, this.margin.top)}>
          {React.Children.map(
            this.props.children,
            (child) => React.cloneElement(child, { size: this.state.size }),
          )}
        </g>
      </svg>
    );
  }

  render() {
    return (
      <div className={this.props.className} ref={(e) => (this.el = e)}>
        {this.doRender()}
      </div>
    );
  }
}
