/* eslint-disable react/prop-types */
/* eslint-disable react/destructuring-assignment */
import React from 'react';
import _ from 'lodash';
import { Form } from 'antd';
import Select from '@mui/material/Select';
import KeyValueInput, { keyValueInputFieldOptions } from '../KeyValueInput';
import { labelTypes, labelValues } from '../../models/label';
import { convertToFields, createOptions } from '../../utils/formUtils';
import Dialog from '../../../../shared/dialog/Dialog';

const FormItem = Form.Item;

function getOrEmpty(v) {
  return v != null ? v : [];
}

const defaultLabelsFilter = {
  signals: [],
  types: [],
  users: [],
  values: [],
  properties: [],
};

function mapPropsToFields(props) {
  return convertToFields(
    { ...defaultLabelsFilter, ...props.initialFilters },
    (k, v) => getOrEmpty(v),
  );
}

const LabelFilterDialog = Form.create({ mapPropsToFields })(
  class extends React.Component {
    constructor(props) {
      super(props);
      this.state = {
        usersList: [],
      };
      this.onSubmit = this.onSubmit.bind(this);
    }

    componentDidMount() {
      this.props.getLabelsUsers().then((ul) => {
        this.setState({
          usersList: _.map(ul, (u) => ({ value: u.id, label: u.username })),
        });
      });
    }

    onSubmit(e) {
      e.preventDefault();
      this.props.form.validateFields((err, fields) => {
        if (!err) {
          this.props.apply({
            values: _(fields.values)
              .split(';')
              .map(_.trim)
              .filter((s) => s !== '')
              .value(),
            types: fields.types,
            signals: fields.signals,
            users: fields.users,
            properties: KeyValueInput.toObject(fields.properties),
          });
        }
      });
    }

    renderFooter() {
      return [
        <button type="button" key="cancel" onClick={this.props.closeDialog}>
          Cancel
        </button>,
        <button type="button" key="clear" onClick={this.props.clear}>
          Clear
        </button>,
        <button type="button" key="apply" onClick={this.onSubmit}>
          Apply
        </button>,
      ];
    }

    render() {
      const { getFieldDecorator } = this.props.form;
      let { signals } = this.props;
      if (this.props.headbandVersion === null || (this.props.headbandVersion === 6 && this.props.viewerVersion === 'external')) {
        signals = signals.filter((elem) => {
          if (elem.name === 'CH3 Fp1-F8') return false;
          if (elem.name === 'CH7 Fp1-F7') return false;
          if (elem.name === 'RAW3 Fp1-F8') return false;
          if (elem.name === 'RAW7 Fp1-F7') return false;
          return true;
        });
      }
      return (
        <Dialog
          display={this.props.open}
          handleCloseDialog={this.props.closeDialog}
          title="Select labels"
          content={(
            <>
              <Form onSubmit={this.onSubmit}>
                <FormItem>
                  {getFieldDecorator('values')(
                    <Select
                      multiple
                      placeholder="Values"
                    >
                      {createOptions(labelValues)}
                    </Select>,
                  )}
                </FormItem>
                <FormItem>
                  {getFieldDecorator('types')(
                    <Select multiple placeholder="Types">
                      {createOptions(labelTypes)}
                    </Select>,
                  )}
                </FormItem>
                <FormItem>
                  {getFieldDecorator('signals')(
                    <Select multiple placeholder="Signals">
                      {createOptions(signals, 'path', 'path')}
                    </Select>,
                  )}
                </FormItem>
                <FormItem>
                  {getFieldDecorator('users')(
                    <Select multiple placeholder="Users">
                      {createOptions(this.state.usersList, 'value', 'label')}
                    </Select>,
                  )}
                </FormItem>
                <FormItem>
                  {getFieldDecorator('properties', keyValueInputFieldOptions)(
                    <KeyValueInput />,
                  )}
                </FormItem>
              </Form>
              {this.renderFooter()}
            </>
          )}
        />
      );
    }
  },
);

export default LabelFilterDialog;
