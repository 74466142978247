/* eslint-disable no-return-assign */
/* eslint-disable react/prop-types */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable react/no-deprecated */
import React, { Component } from 'react';
import { format as numberFormat } from 'd3-format';
import { axisLeft } from 'd3-axis';
import { select } from 'd3-selection';

const nFormat = numberFormat('.3d');

export default class YAxis extends Component {
  constructor(props) {
    super(props);
    this.yAxis = axisLeft(props.yScale.inner).tickFormat(nFormat);
  }

  componentDidMount() {
    this.redraw();
  }

  componentWillReceiveProps(nextProps) {
    if (this.props.yScale !== nextProps.yScale) {
      this.redraw();
    }
  }

  shouldComponentUpdate() {
    return false;
  }

  redraw() {
    select(this.el).call(this.yAxis);
  }

  render() {
    return <g className="y-axis axis" ref={(el) => (this.el = el)} />;
  }
}
