import React from 'react';
import PropTypes from 'prop-types';
import { v4 as uuidv4 } from 'uuid';
import LoadingIcon from '../../../shared/loadingIcon/LoadingIcon';
import styles from './Table.module.scss';
import { ReactComponent as Chevron } from '../../../../assets/images/icons/chevron.svg';
import { ReactComponent as SortArrowIcon } from '../../../../assets/images/icons/sortArrow.svg';

function Table(props) {
  const {
    columns,
    rows,
    handlePageChange,
    loading,
    count,
    page,
    prev,
    next,
    limit,
    expandableRowRender,
    selected,
    onRowClick,
    error,
    ordering,
    setOrdering,
  } = props;

  function toggleOrdering(col, asc = false) {
    if (!col) return;
    const nextOrdering = `${asc ? '-' : ''}${col}`;
    if (ordering === nextOrdering) {
      setOrdering('RESET');
    } else {
      setOrdering(nextOrdering);
    }
  }

  function orderingButtonColor(col, asc) {
    if (col) {
      const colName = `${asc ? '-' : ''}${col}`;
      if (colName === ordering) return '#ff0064';
    }
    return '#C0BAEE';
  }

  const headers = columns ? columns.map((elem) => (
    <th key={`column_${elem.title}_${uuidv4()}`}>
      <div>
        <span>{elem.title}</span>
        {elem.col && (
          <div className={styles.sort}>
            <SortArrowIcon onClick={() => toggleOrdering(elem.col, true)} fill={orderingButtonColor(elem.col, true)} width="10px" />
            <SortArrowIcon onClick={() => toggleOrdering(elem.col)} fill={orderingButtonColor(elem.col)} width="10px" />
          </div>
        )}
      </div>
    </th>
  )) : null;

  const rowsRender = rows ? rows.map((row, index) => {
    const cols = columns.map((elem) => (
      <td
        style={{ width: elem.width || 'auto' }}
        key={`cols_${row.id}_${elem.id}_${uuidv4()}`}
      >
        {elem.render ? elem.render(row) : row[elem.field] || 'N/A'}
      </td>
    ));
    const rowSelected = selected === row.record;
    if (expandableRowRender) {
      cols.push(
        <td>
          <Chevron
            id={`chevronER_${index}`}
            width="16px"
            fill="#C0BAEE"
            style={{ marginRight: '1rem', transform: rowSelected ? 'rotate(90deg)' : 'rotate(-90deg)' }}
            transform=""
          />
        </td>,
      );
    }
    return (
      <>
        <tr
          key={`row_${row.id}_${uuidv4()}`}
          onClick={() => onRowClick(row.record)}
          className={`${rowSelected ? styles.selected : ''} ${!row.report && !row.light_report ? styles.unavailable : ''}`}
        >
          {cols}
        </tr>
        {expandableRowRender && rowSelected && (
          <tr key={`expandableRow_${row.id}_${uuidv4()}`} id={`expandableRow_${index}`} className={styles.expandableRow}>
            <td colSpan={cols.length}>
              {expandableRowRender(row)}
            </td>
          </tr>
        )}
      </>
    );
  }) : null;

  const position = loading ? "Loading..." : `${page * limit + 1} - ${page * limit + rows.length} out of ${count}`;

  function handlePage(e) {
    if (e === 'next') handlePageChange(page + 1);
    if (e === 'prev') handlePageChange(page - 1);
  }

  return (
    <>
      <div className={styles.container}>
        <div className={styles.tableContainer}>
          <table className={styles.table}>
            <thead>
              <tr>
                {headers}
                {expandableRowRender && <th className={styles.headers}>&nbsp;</th>}
              </tr>
            </thead>
            {loading || !rows.length
              ? (
                <tbody className={styles.loadingContainer}>
                  {loading && (
                    <tr>
                      <td><LoadingIcon /></td>
                    </tr>
                  )}
                  {!rows.length && !loading && (
                    <tr>
                      <td>{error || 'No data'}</td>
                    </tr>
                  )}
                </tbody>
              )
              : (
                <tbody className={styles.tbody}>
                  {rowsRender}
                </tbody>
              )}
          </table>
        </div>
      </div>
      <div className={styles.footer}>
        <span className={styles.position}>{position}</span>
        <div className={styles.pager}>
          <button type="button" className={styles.button} onClick={() => handlePage('prev')} disabled={!prev || loading}>
            <Chevron
              width="20px"
              height="20px"
              fill={!prev ? '#E2E0EB' : '#C0BAEE'}
              transform=""
            />
            &nbsp;Previous
          </button>
          <button type="button" className={styles.button} onClick={() => handlePage('next')} disabled={!next || loading}>
            Next&nbsp;
            <Chevron
              className={styles.chevronNext}
              style={{ transform: 'rotate(180deg)' }}
              width="20px"
              height="20px"
              fill={!prev ? '#E2E0EB' : '#C0BAEE'}
              transform=""
            />
          </button>
        </div>
      </div>
    </>
  );
}

Table.defaultProps = {
  columns: [],
  rows: [],
  handlePageChange: null,
  loading: false,
  count: 0,
  page: 0,
  prev: '',
  next: '',
  limit: 0,
  expandableRowRender: null,
  selected: null,
  onRowClick: null,
  error: null,
  ordering: '',
  setOrdering: null,
};

Table.propTypes = {
  columns: PropTypes.instanceOf(Array),
  rows: PropTypes.instanceOf(Array),
  handlePageChange: PropTypes.instanceOf(Function),
  loading: PropTypes.bool,
  count: PropTypes.number,
  page: PropTypes.number,
  prev: PropTypes.string,
  next: PropTypes.string,
  limit: PropTypes.number,
  expandableRowRender: PropTypes.instanceOf(Function),
  selected: PropTypes.string,
  onRowClick: PropTypes.instanceOf(Function),
  error: PropTypes.string,
  ordering: PropTypes.string,
  setOrdering: PropTypes.instanceOf(Function),
};

export default Table;