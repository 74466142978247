/* eslint-disable prefer-rest-params */
import Immutable from 'immutable';

function Selection(signals = [], start = 0, end = 0) {
  if (arguments.length === 1 && typeof arguments[0] === 'object') {
    // eslint-disable-next-line prefer-destructuring
    this.s = arguments[0];
  } else {
    this.s = Immutable.Map({
      current: {
        start,
        end,
        signals,
      },
      mark: {
        location: null,
        signals,
      },
    });
  }
}

function getCurrent(start, end, signals) {
  return start != null ? {
    start,
    end,
    signals,
  } : null;
}

Selection.prototype = {
  get signals() {
    return this.s.get('signals');
  },

  get current() {
    return this.s.get('current');
  },

  get mark() {
    return this.s.get('mark');
  },

  hasSelection() {
    const s = this.current;
    return s != null && s.start !== s.end;
  },

  updateSelection(start, end, signals) {
    return new Selection(
      this.s.set('current', getCurrent(start, end, signals)),
    );
  },

  setMark(location, signals) {
    const previousLocation = this.mark.location;
    const ms = previousLocation != null
      ? getCurrent(
        Math.min(previousLocation, location),
        Math.max(previousLocation, location),
        signals,
      )
      : null;
    return new Selection(
      this.s.withMutations((s) => s.set('current', ms).set('mark', {
        location,
        signals,
      })),
    );
  },
};

export default function selection(signals, start, end) {
  return new Selection(signals, start, end);
}