/* eslint-disable no-return-assign */
/* eslint-disable react/prop-types */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable import/no-extraneous-dependencies */
import React from 'react';
import { select } from 'd3-selection';
import styles from './StagingState.module.scss';
import { transform } from './plot/utils';

const stagingWindowCount = 5;

function createState(el) {
  const width = el.offsetWidth;
  const height = el.offsetHeight;
  const svg = select(el)
    .append('svg')
    .attr('width', '100%')
    .attr('height', '100%');
  const step = width / (stagingWindowCount * 2);
  const lineX = width / 2 - 5;
  svg
    .append('line')
    .attr('stroke', 'deeppink')
    .attr('stroke-opacity', 0.7)
    .attr('x1', lineX)
    .attr('x2', lineX)
    .attr('y1', 0)
    .attr('y2', height);

  return {
    update(range, stages) {
      if (stages == null) {
        return;
      }
      const states = svg
        .selectAll(`.${styles.state}`)
        .data(stages.getStageWindow(stagingWindowCount));
      states.exit().remove();
      const g = states
        .enter()
        .append('g')
        .classed(styles.state, true);
      g.append('text').classed('staging-state-name', true);
      g
        .merge(states)
        .attr('transform', (d, i) => transform(i * step, 33))
        .classed(styles.current, (d, i) => i === stagingWindowCount)
        .select('.staging-state-name')
        .text((d) => d.stage);
    },

    destroy() {
      svg.remove();
    },
  };
}

class StagingState extends React.Component {
  componentDidMount() {
    this.d3Elem = createState(this.container, this.props);
    this.d3Elem.update(this.props.range, this.props.stages);
  }

  shouldComponentUpdate(nextProps) {
    if (
      nextProps.range !== this.props.range
      || nextProps.stages !== this.props.stages
    ) {
      this.d3Elem.update(nextProps.range, nextProps.stages);
    }
    return false;
  }

  componentWillUnmount() {
    if (this.d3Elem) {
      this.d3Elem.destroy();
      this.d3Elem = null;
    }
  }

  render() {
    return <div className={styles.container} ref={(e) => (this.container = e)} />;
  }
}

export default StagingState;
