import React, { useState } from 'react';
import PropTypes from 'prop-types';
import TextField from '../../shared/textField/TextField';
import Button from '../../shared/button/Button';
import styles from './LoginForm.module.scss';
import LoadingIcon from '../../shared/loadingIcon/LoadingIcon';
import { ReactComponent as LogoFull } from '../../../assets/images/logos/full-logo.svg';
import { loginURL } from '../../../constants/globals';

function LoginForm(props) {
  const {
    msg,
    loading,
    submit,
  } = props;

  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');

  const handleSubmit = (event) => {
    submit({
      username,
      password,
    });
    event.preventDefault();
  };

  return (
    <form className={styles.container} onSubmit={handleSubmit}>
      <LogoFull width="150px" />
      <h5>
        First time around?&nbsp;
        <a href={`${loginURL()}/create_password/`}>Create your password</a>
      </h5>
      <div className={styles.form}>
        <TextField
          className={styles.input}
          name="username"
          type="text"
          label="EMAIL"
          placeholder="Type your administrator email"
          title="email"
          value={username}
          onChange={(e) => setUsername(e.target.value.trim())}
          required
          disabled={loading}
        />
        <TextField
          className={styles.input}
          name="password"
          type="password"
          label="PASSWORD"
          placeholder="Type your password"
          title="password"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
          required
          disabled={loading}
          autoComplete="current-password"
        />
      </div>
      <a href={`${loginURL()}/recover/`} className={styles.forgotText}>
        Forgot your password
      </a>
      {!loading
        ? (
          <Button
            className={styles.button}
            disabled={loading}
            text="Log in"
            type="submit"
          />
        )
        : <LoadingIcon />}
      {msg ? <p className={styles.warnText}>{msg}</p> : null}
    </form>
  );
}

LoginForm.defaultProps = {
  msg: null,
  loading: false,
  submit: null,
};

LoginForm.propTypes = {
  msg: PropTypes.string,
  loading: PropTypes.bool,
  submit: PropTypes.instanceOf(Function),
};

export default LoginForm;