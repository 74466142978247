import _ from 'lodash';

function Cursor(params) {
  this.signalWindow = params.signalWindow;
  this.windowCount = Math.max(1, params.windowCount);
  this.range = params.range;
  this.index = null;
  this.maxIndex = this.computeIndex(params.range.end);
}

Cursor.prototype = {
  computeIndex(p) {
    return Math.floor((p - this.range.start) / this.signalWindow);
  },

  computeIndexRange(index) {
    const start = this.range.start + index * this.signalWindow;
    return {
      index,
      start,
      end: start + this.signalWindow,
    };
  },

  computeDiff(prev, p) {
    const prevWindows = this.computeWindows(prev);
    const nextWindows = this.computeWindows(this.computeIndex(p));
    return {
      added: _.differenceBy(nextWindows, prevWindows, (w) => w.index),
      removed: _.differenceBy(prevWindows, nextWindows, (w) => w.index),
    };
  },

  setIndex(p) {
    this.index = this.computeIndex(p);
  },

  computeWindows(index) {
    const w = [];
    if (index == null) {
      return w;
    }
    for (let p = Math.max(0, index - this.windowCount); p < index; p += 1) {
      w.push(this.computeIndexRange(p));
    }
    w.push(this.computeIndexRange(index));
    for (
      let p = index + 1; p < this.maxIndex && p <= index + this.windowCount; p += 1
    ) {
      w.push(this.computeIndexRange(p));
    }
    return w;
  },

  getWindows() {
    return this.computeWindows(this.index);
  },
};

export function cursor(params) {
  return new Cursor(params);
}

function Cache(config) {
  this.recordID = config.recordID;
  this.data = new Map();
  this.authFetch = config.authFetch;
  this.url = config.url;
  this.cursor = cursor(config);
  this.loadingCache = new Map();
}

function getValuesInRange(res, responses, from, to) {
  _.forEach(responses, (resp, i) => {
    const startIndex = _.sortedIndexBy(resp.values, {
      time: from,
    }, (v) => v.time);
    for (let j = startIndex; j < resp.values.length; j += 1) {
      const v = resp.values[j];
      if (v.time < from || v.time >= to) {
        break;
      }
      res[i].values.push(v);
    }
  });
}

Cache.prototype = {
  buildRequests(r) {
    return `${this.url}&from=${r.start}&to=${r.end}`;
  },

  fetchSignal(r) {
    if (this.loadingCache.has(r.index)) {
      return this.loadingCache.get(r.index);
    }
    if (this.data.has(r.index)) {
      return Promise.resolve(this.data.get(r.index));
    }
    const p = this.authFetch(this.buildRequests(r));
    this.loadingCache.set(r.index, p);
    return p;
  },

  unload(removed) {
    _.forEach(removed, (r) => {
      this.data.delete(r.index);
    });
  },

  load(position) {
    let p = Promise.resolve();
    const diff = this.cursor.computeDiff(this.cursor.index, position);
    if (diff.added.length === 0 && diff.removed.length === 0) {
      return p;
    }
    _.forEach(diff.added, (r) => {
      p = p.then(() => this.fetchSignal(r)).then((resp) => {
        this.data.set(r.index, resp);
        this.loadingCache.delete(r.index);
      });
    });
    p = p.then(() => {
      this.unload(diff.removed);
      this.cursor.setIndex(position);
    });
    return p;
  },

  getSignalPart(range) {
    const to = range.from + this.cursor.signalWindow;
    const startIndex = this.cursor.computeIndex(range.from);
    const endIndex = this.cursor.computeIndex(to);
    return this.load(range.from).then(() => {
      if (!this.data.has(startIndex) || !this.data.has(endIndex)) {
        throw new Error(
          `bucket ${startIndex} and ${endIndex} not found`,
          Array.from(this.data.keys()),
        );
      }
      const startData = this.data.get(startIndex);
      const res = _.map(startData, (d) => ({
        id: d.id,
        signal: d.signal,
        values: [],
      }));
      getValuesInRange(res, startData, range.from, to);
      getValuesInRange(res, this.data.get(endIndex), range.from, to);
      return res;
    });
  },
};

export function cache(config) {
  return new Cache(config);
}

function SeriesCache(c) {
  this.cache = c;
}

SeriesCache.prototype = {
  start(config) {
    const c = cache(config);
    c.load(config.start);
    return new SeriesCache(c);
  },

  stop() {
    return new SeriesCache(null);
  },

  getSignalPart(range) {
    return this.cache.getSignalPart(range);
  },

  update(position) {
    if (this.cache != null) {
      this.cache.load(position);
    }
    return new SeriesCache(this.cache);
  },
};

export default function seriesCache() {
  return new SeriesCache(null);
}