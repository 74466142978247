import React from 'react';
import styles from './NoRoute.module.scss';
import { ReactComponent as Logo } from '../../../assets/images/logos/logo.svg';

export default function NoRoute() {
  return (
    <div className={styles.container}>
      <div className={styles.content}>
        <Logo width="57px" height="50px" style={{ margin: '1rem 0' }} />
        <h1>Sorry</h1>
        <p>The page you were looking for doesn&#39;t exist</p>
      </div>
    </div>
  );
}