import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import styles from './List.module.scss';
import SearchBar from '../../../shared/searchBar/SearchBar';
import LoadingIcon from '../../../shared/loadingIcon/LoadingIcon';
// import plusIcon from '../../../../assets/images/icons/plus.png';
import { fetchAllSubjects } from './actions';
import Row from './Row';

function List(props) {
  const {
    selected,
    onRowClick,
  } = props;

  const [search, setSearch] = useState();
  const [loading, setLoading] = useState(true);
  const [result, setResult] = useState([]);

  useEffect(() => {
    fetchAllSubjects().then((e) => {
      setResult(e);
      setLoading(false);
      const queryID = new URLSearchParams(window.location.search).get('id');
      if (queryID) {
        const containQuery = e.filter((r) => r.id === queryID);
        onRowClick(containQuery.length ? queryID : e[0].id);
      }
    });
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    const querySearch = new URLSearchParams(window.location.search).get('search');
    setSearch(querySearch);
    // eslint-disable-next-line
  }, []);

  const handleSearch = (e) => {
    setSearch(e.target.value || '');
    const url = new URL(window.location);
    url.searchParams.set('search', e.target.value);
    window.history.pushState({}, '', url);
    e.preventDefault();
  };

  const rows = result.map((row) => (
    <Row key={`list_row_${row.id}`} row={row} selected={row.id === selected} onRowClick={onRowClick} />
  )).filter((e) => {
    if (search && e.props.row) {
      if (e.props.row.email
        && e.props.row.email.toLowerCase().includes(search.toLowerCase())) return true;
      if (e.props.row.first_name
        && e.props.row.first_name.toLowerCase().includes(search.toLowerCase())) return true;
      if (e.props.row.last_name
        && e.props.row.last_name.toLowerCase().includes(search.toLowerCase())) return true;
      if (e.props.row.id
        && e.props.row.id.toLowerCase().includes(search.toLowerCase())) return true;
      if (e.props.row.pseudo
        && e.props.row.pseudo.toLowerCase().includes(search.toLowerCase())) return true;
      return false;
    }
    return true;
  });

  return (
    <div className={styles.container}>
      <div className={`${styles.newSubjectButton} ${styles.disabled}`}>
        {/* <img src={plusIcon} alt="" /> */}
        <span>new subject</span>
      </div>
      <h1 className={styles.title}>Subjects</h1>
      <SearchBar
        className={styles.searchBar}
        value={search}
        onChange={handleSearch}
        disabled={loading}
      />
      {
        loading ? (
          <div style={{ width: 'fit-content', margin: '5rem auto' }}>
            <LoadingIcon />
          </div>
        ) : <div>{rows}</div>
      }
    </div>
  );
}

List.defaultProps = {
  selected: '',
  onRowClick: null,
};

List.propTypes = {
  selected: PropTypes.string,
  onRowClick: PropTypes.instanceOf(Function),
};

export default List;