import React from 'react';
import { useLocation, useHistory } from 'react-router-dom';
import PropTypes from 'prop-types';
import styles from './Item.module.scss';

function Item(props) {
  const { pathname } = useLocation();
  const history = useHistory();
  const {
    selected,
    title,
    route,
    logo,
    onClick,
  } = props;

  const handleGo = () => {
    if (onClick) onClick();
    if (route && route !== pathname) {
      history.push(route);
    }
  };

  return (
    <div
      className={`${styles.container} ${!selected || styles.selected}`}
      onClick={() => handleGo()}
      onKeyPress={null}
      role="button"
      tabIndex={0}
    >
      {logo}
      <span className={styles.title}>{title}</span>
    </div>
  );
}

Item.defaultProps = {
  selected: false,
  title: '',
  route: '',
  logo: null,
  onClick: null,
};

Item.propTypes = {
  selected: PropTypes.bool,
  title: PropTypes.string,
  route: PropTypes.string,
  logo: PropTypes.instanceOf(Object),
  onClick: PropTypes.func,
};

export default Item;