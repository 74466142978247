/* eslint-disable react/destructuring-assignment */
/* eslint-disable no-return-assign */
/* eslint-disable react/prop-types */
/* eslint-disable no-param-reassign */
/* eslint-disable max-classes-per-file */
import React, { Component } from 'react';
import { DragSource, DropTarget } from 'react-dnd';
import styles from './SortableList.module.scss';

const elementSource = {
  beginDrag(props) {
    return {
      id: props.id,
      title: props.title,
      index: props.index,
    };
  },
};

const elementTarget = {
  hover(props, monitor, component) {
    if (props.title !== monitor.getItem().title) {
      return;
    }
    const dragIndex = monitor.getItem().index;
    const hoverIndex = props.index;
    if (dragIndex === hoverIndex) {
      return;
    }
    const hoverBoundingRect = component.el.getBoundingClientRect();
    const hoverMiddleY = (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2;
    const clientOffset = monitor.getClientOffset();
    const hoverClientY = clientOffset.y - hoverBoundingRect.top;
    if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) {
      return;
    }
    if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) {
      return;
    }
    props.moveElement(dragIndex, hoverIndex);
    monitor.getItem().index = hoverIndex;
  },

  canDrop() {
    return false;
  },
};

function targetConnector(connect) {
  return {
    connectDropTarget: connect.dropTarget(),
  };
}

function sourceConnector(connect, monitor) {
  return {
    connectDragSource: connect.dragSource(),
    isDragging: monitor.isDragging(),
  };
}

class ElementImpl extends Component {
  render() {
    const {
      id, isDragging, connectDragSource, connectDropTarget,
    } = this.props;
    const opacity = isDragging ? 0 : 1;
    return connectDragSource(
      connectDropTarget(
        <div
          ref={(el) => (this.el = el)}
          className={styles.element}
          style={{ opacity }}
        >
          <div>{id}</div>
        </div>,
      ),
    );
  }
}

export const Element = DragSource('element', elementSource, sourceConnector)(
  DropTarget('element', elementTarget, targetConnector)(ElementImpl),
);

class ListImpl extends Component {
  constructor(props) {
    super(props);
    this.moveElement = this.moveElement.bind(this);
  }

  moveElement(dragIndex, hoverIndex) {
    const e = this.props.elements.slice();
    e.splice(hoverIndex, 0, e.splice(dragIndex, 1)[0]);
    this.props.onSelectionChange(e);
  }

  render() {
    const { connectDropTarget, title, elements } = this.props;
    return connectDropTarget(
      <div className={styles.container}>
        <h5>{title}</h5>
        <div ref={(el) => (this.el = el)} className={styles.scroll}>
          {elements.map((s, i) => (
            <Element
              title={title}
              key={s}
              index={i}
              id={s}
              moveElement={this.moveElement}
            />
          ))}
        </div>
      </div>,
    );
  }
}

const listTarget = {
  drop(props, monitor, component) {
    const { id } = monitor.getItem();
    if (props.elements.includes(id)) {
      return;
    }
    if (component.el.children.length === 0) {
      props.onSelectionChange([id]);
      return;
    }
    const doff = monitor.getClientOffset();
    const ch = component.el.children[0].getBoundingClientRect();
    const pos = Math.max(doff.y - ch.top, 0);
    const h = ch.bottom - ch.top;
    const index = Math.round(pos / h);
    const elements = props.elements.slice();
    elements.splice(index, 0, id);
    props.onSelectionChange(elements);
  },
};

function listCollector(connect) {
  return {
    connectDropTarget: connect.dropTarget(),
  };
}

export const List = DropTarget('element', listTarget, listCollector)(ListImpl);
