import {
  List,
} from 'immutable';

export const contentTypes = {
  FFT: 'FFT',
  SPECTROGRAM: 'SPECTROGRAM',
  HYPNOGRAM: 'HYPNOGRAM',
  HORIGRAM: 'HORIGRAM',
  POSITIONGRAM: 'POSITIONGRAM',
  SCORING_PROGRESS: 'SCORING_PROGRESS',
  WINDOW_READ: 'WINDOW_READ',
  HEARTGRAM: 'HEARTGRAM',
  RESPIGRAM: 'RESPIGRAM',
  BRAINOGRAM: 'BRAINOGRAM',
  SLACA: 'SLACA',
  IMPORTED: 'IMPORTED',
};

function Content(l) {
  // eslint-disable-next-line no-nested-ternary
  this.content = List.isList(l) ? l : Array.isArray(l) ? List(l) : List();
}

Content.prototype = {
  // eslint-disable-next-line no-shadow
  add(element) {
    return new Content(this.content.push(element));
  },

  concat(elements) {
    return new Content(this.content.concat(elements));
  },

  getIds(type) {
    return this.content
      .filter((e) => e.type === type)
      .map((e) => e.id)
      .toArray();
  },

  remove(ids) {
    if (!ids || ids.length === 0) {
      return this;
    }
    return new Content(this.content.filter((e) => !ids.includes(e.id)));
  },

  removeByType(type) {
    return new Content(this.content.filter((e) => e.type !== type));
  },

  get(type) {
    return this.content.filter((e) => e.type === type).toArray();
  },

  map(f) {
    return this.content.map(f).toArray();
  },

  isEmpty() {
    return this.content.size === 0;
  },

  toArray() {
    return this.content.toArray();
  },

  size() {
    return this.content.size;
  },
};

let elementID = 1;

export function element(type, data, id) {
  return {
    // eslint-disable-next-line no-plusplus
    id: id != null ? id : elementID++,
    type,
    data,
  };
}

export default function content(list) {
  return new Content(list);
}