/* eslint-disable react/no-deprecated */
/* eslint-disable no-return-assign */
/* eslint-disable react/prop-types */
/* eslint-disable react/destructuring-assignment */
import React from 'react';
import styles from './ProfileSelector.module.scss';
import { validateConfiguration } from '../models/profile';
import TextField from '../../../shared/textField/TextField';
import { ReactComponent as SaveIcon } from '../../../../assets/images/icons/save.svg';
import { ReactComponent as PlusIcon } from '../../../../assets/images/icons/plus.svg';
import { ReactComponent as MinusIcon } from '../../../../assets/images/icons/minus.svg';
import Select from '../../../shared/select/Select';

function computeValidation(props) {
  const { profile: { configurations }, signals } = props;
  const res = {};
  configurations.forEach((c) => {
    res[c.name] = validateConfiguration(c, signals);
  });
  return res;
}

export default class ProfileSelector extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      creating: false,
      validation: computeValidation(props),
      profileInputValue: '',
    };
    this.createInput = null;
    this.switchMode = this.switchMode.bind(this);
    this.onProfileCreate = this.onProfileCreate.bind(this);
    this.onProfileDelete = this.onProfileDelete.bind(this);
  }

  componentWillReceiveProps(nextProps) {
    if (
      this.props.profile.configurations !== nextProps.profile.configurations
      || this.props.signals !== nextProps.signals
    ) {
      this.setState({
        validation: computeValidation(nextProps),
      });
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.state.creating && !prevState.creating) {
      if (this.createInput != null) {
        this.createInput.focus();
      }
    }
  }

  onProfileCreate(e) {
    this.setState({
      creating: false,
      profileInputValue: '',
    });
    this.props.onProfileCreate(e.target.value);
  }

  onProfileDelete() {
    this.props.onProfileDelete(this.props.profile.selected);
  }

  switchMode() {
    this.setState({ creating: true });
  }

  renderCreating() {
    return (
      <div>
        <TextField
          inputClassName={styles.profileInput}
          placeholder="Profile name"
          onPressEnter={this.onProfileCreate}
          value={this.state.profileInputValue}
          onChange={(e) => this.setState({
            profileInputValue: e.target.value,
          })}
          onKeyPress={(e) => {
            if (e.key === 'Enter') this.onProfileCreate(e);
          }}
        />
      </div>
    );
  }

  renderSelect() {
    const { selected, configurations } = this.props.profile;
    const options = configurations.map((c) => ({
      name: c.name,
      key: c.name,
      disabled: this.state.validation[c.name] === false,
    }));
    return (
      <div className={styles.select}>
        <button
          type="button"
          onClick={this.switchMode}
        >
          <PlusIcon
            width="16px"
            height="16px"
          />
        </button>
        <button
          type="button"
          onClick={this.onProfileDelete}
        >
          <MinusIcon
            width="16px"
            height="16px"
          />
        </button>
        <button
          type="button"
          onClick={this.props.onProfileSave}
        >
          <SaveIcon
            width="16px"
            height="16px"
          />
        </button>
        <Select
          options={options}
          selected={selected}
          setSelected={(elem) => this.props.onProfileChange(elem)}
        />
      </div>
    );
  }

  render() {
    return (
      <div>
        {this.state.creating ? this.renderCreating() : this.renderSelect()}
      </div>
    );
  }
}
