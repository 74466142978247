import _ from 'lodash';
import {
  timeSeriesConfig,
} from '../constants/globals';
import {
  defaultWindow,
} from './range';

export function getConfig(arg, name) {
  const {
    selected,
    configurations,
  } = _.isFunction(arg)
    ? arg().timeSeries.profile
    : arg;
  const match = name != null ? name : selected;
  return _.find(configurations, (c) => c.name === match);
}

function confMatchSignals(conf, signals) {
  return _.every(conf, (c) => _.find(signals, (s) => c === (typeof s === 'object' ? s.path : s)));
}

export function validateConfiguration(config, signals) {
  const {
    scaleConfig,
    colorConfig,
    signalsConfig,
    filtersConfig,
  } = config;
  return (
    confMatchSignals(Object.keys(scaleConfig), signals)
    && confMatchSignals(Object.keys(colorConfig), signals)
    && confMatchSignals(signalsConfig, signals)
    && confMatchSignals(_.map(filtersConfig, (fc) => fc.path), signals)
  );
}

export function defaultConfiguration(name) {
  const res = {
    name: 'default',
    scaleConfig: {},
    colorConfig: {},
    signalsConfig: [],
    filtersConfig: [],
    limiterMode: false,
    windowRange: defaultWindow,
  };
  if (name != null) {
    res.name = name;
  }
  return res;
}

function defaultProfile() {
  return {
    selected: 'default',
    configurations: [defaultConfiguration()],
  };
}

export function parseProfile() {
  try {
    const saved = window.localStorage.getItem(timeSeriesConfig.profile);
    return saved ? JSON.parse(saved) : defaultProfile();
  } catch (e) {
    return defaultProfile();
  }
}

export function resetFiltersConfig(cfg) {
  return _.map(cfg, (fc) => {
    const f = {
      ...fc,
    };
    delete f.id;
    delete f.coefficients;
    return f;
  });
}

function cleanConfig(c) {
  return {
    ...c,
    filtersConfig: resetFiltersConfig(c.filtersConfig),
  };
}

export function saveProfile(profile) {
  if (profile.selected === 'default') {
    return false;
  }
  const cfgToSave = cleanConfig(getConfig(profile));
  const saved = parseProfile();
  const configurations = _.find(saved.configurations, (c) => c.name === cfgToSave.name) != null
    ? _.map(
      saved.configurations,
      (c) => (c.name === cfgToSave.name ? cfgToSave : cleanConfig(c)),
    )
    : saved.configurations.concat(cfgToSave);
  window.localStorage.setItem(
    timeSeriesConfig.profile,
    JSON.stringify({
      ...profile,
      configurations,
    }),
  );
  return true;
}

export function saveSelectedProfile(selected) {
  const saved = parseProfile();
  window.localStorage.setItem(
    timeSeriesConfig.profile,
    JSON.stringify({
      ...saved,
      selected,
    }),
  );
  return true;
}