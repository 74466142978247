/* eslint-disable react/prop-types */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable max-classes-per-file */
import React from 'react';
import _ from 'lodash';
import styles from './KeyValueInput.module.scss';
import { ReactComponent as Close } from '../../../../assets/images/icons/close.svg';

export const keyValueInputFieldOptions = {
  valuePropName: 'values',
  trigger: 'onUpdate',
  validateTrigger: 'onUpdate',
  getValueFromEvent: _.identity,
};

class InputRow extends React.Component {
  handleKeyEdit(e) {
    this.props.onEdit(this.props.dataKey, {
      key: e.target.value,
      value: this.getValue(),
    });
  }

  handleValueEdit(e) {
    this.props.onEdit(this.props.dataKey, {
      key: this.getKey('null'),
      value: e.target.value,
    });
  }

  getValue(defaultValue = '') {
    return this.props.data != null ? this.props.data.value : defaultValue;
  }

  getKey(defaultKey = '') {
    return this.props.data != null ? this.props.data.key : defaultKey;
  }

  clear() {
    this.props.onClear(this.props.dataKey);
  }

  render() {
    return (
      <div className={styles.inputRow}>
        <div className={styles.inputRowText}>
          <input
            type="text"
            className={styles.inputField}
            id={`${this.props.dataKey}-keyvalue-key-input`}
            value={this.getKey()}
            onChange={this.handleKeyEdit}
          />
          <input
            type="text"
            className={styles.inputField}
            id={`${this.props.dataKey}-keyvalue-value-input`}
            value={this.getValue()}
            onChange={this.handleValueEdit}
          />
        </div>
        <button
          type="button"
          disabled={this.props.disableClear}
          onClick={this.clear}
        >
          <Close fill="#7A71A6" width="14px" height="14px" />
        </button>
      </div>
    );
  }
}

function convertor(v) {
  if (v === '') {
    return null;
  }
  const lc = (`${v}`).toLowerCase();
  if (!_.isNaN(+lc)) {
    return +lc;
  }
  if (lc === 'true') {
    return true;
  }
  if (lc === 'false') {
    return false;
  }
  return lc;
}

class KeyValueInput extends React.Component {
  static toObject(data) {
    if (data == null || data.length === 0) {
      return undefined;
    }
    const res = {};
    _.forEach(data, (d) => {
      res[d.key.toLowerCase()] = convertor(d.value);
    });
    return res;
  }

  static fromObject(obj) {
    if (obj == null) {
      return [];
    }
    return _.map(Object.keys(obj), (k) => ({ key: k, value: obj[k] }));
  }

  handleEdit(index, v) {
    const values = this.props.values.slice();
    values[index] = v;
    if (this.props.onUpdate) {
      this.props.onUpdate(values);
    }
  }

  handleClear(index) {
    if (this.props.onUpdate) {
      this.props.onUpdate(_.filter(this.props.values, (v, i) => i !== index));
    }
  }

  renderRows() {
    const res = [];
    const valuesCount = this.props.values != null ? this.props.values.length : 0;
    for (let i = 0; i < valuesCount + 1; i += 1) {
      res.push(
        <InputRow
          disableClear={i >= valuesCount}
          key={`keyvalue-${i}`}
          dataKey={i}
          data={this.props.values != null ? this.props.values[i] : null}
          onEdit={this.handleEdit}
          onClear={this.handleClear}
        />,
      );
    }
    return res;
  }

  render() {
    return (
      <div>
        <h1 className={styles.title}>{this.props.title || 'Properties'}</h1>
        {this.renderRows()}
      </div>
    );
  }
}

export default KeyValueInput;
