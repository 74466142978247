import {
  TIMESERIES,
} from '../constants/actionTypes';
import {
  authFetch,
} from './utils';
import {
  catchErrorMessage,
  toggleProgressBar,
} from './app';
import newContent, {
  contentTypes,
  element,
} from '../models/content';
import {
  ensureDomain,
} from '../models/signal';

function updateFFT(dispatch, getState, api, id) {
  const {
    series,
  } = getState().timeSeries;
  const r = getState().timeSeries.range.range();
  return authFetch(
    `${api.sample}/series/${series[0].recordID}/fft?from=${r.start}&to=${
      r.end
    }&threshold=500&series=${(series)
      .map((s) => s.signal)
      .join(',')}`, getState,
  )
    .then((data) => element(contentTypes.FFT, data, id))
    .catch(catchErrorMessage(dispatch));
}

function updateContent(content) {
  return {
    type: TIMESERIES.UPDATE_CONTENT,
    content,
  };
}

/** Close a specific type of content */
export function closeContent(type) {
  return (dispatch, getState) => {
    if (type == null) {
      dispatch(updateContent(newContent()));
    } else {
      dispatch(updateContent(getState().timeSeries.content.removeByType(type)));
    }
  };
}

function addContent(elements) {
  return {
    type: TIMESERIES.ADD_CONTENT,
    elements: Array.isArray(elements) ? elements : [elements],
  };
}

export function removeContent(ids) {
  return {
    type: TIMESERIES.REMOVE_CONTENT,
    ids: Array.isArray(ids) ? ids : [ids],
  };
}

export function updateAllContent(dispatch, getState, api) {
  const p = getState().timeSeries.content.map((el) => {
    switch (el.type) {
      case contentTypes.FFT:
        return updateFFT(dispatch, getState, api, el.id);
      default:
        return Promise.resolve(el);
    }
  });
  return Promise.all(p).then((elements) => dispatch(updateContent(newContent(elements))));
}

export function toggleFFT() {
  return (dispatch, getState, api) => {
    const ct = getState().timeSeries.content;
    const fftIds = ct.getIds(contentTypes.FFT);
    if (fftIds.length > 0) {
      dispatch(removeContent(fftIds));
      return Promise.resolve();
    }
    return updateFFT(dispatch, getState, api).then((el) => dispatch(addContent(el)));
  };
}

export function displayHypnogram(recordID) {
  return (dispatch, getState, api) => {
    const ct = getState().timeSeries.content;
    let updatedContent = ct;
    return authFetch(`${api.staging.hypnogram}/${recordID}/latest_hypnogram/`, getState)
      .then((resp) => {
        if (resp.stages) {
          updatedContent = updatedContent.concat(element(contentTypes.HYPNOGRAM, resp.stages));
        }
        if (!Array.from(
          getState().timeSeries.content.content,
        ).find((e) => e.type === contentTypes.HYPNOGRAM)) {
          dispatch(updateContent(updatedContent));
        }
      })
      .catch(catchErrorMessage(dispatch));
  };
}

export function toggleStimulations(recordID) {
  return (dispatch, getState, api) => {
    if (getState().timeSeries.stimulations.length !== 0) {
      dispatch({
        type: TIMESERIES.SET_STIMULATIONS,
        stimulations: [],
      });
      return Promise.resolve();
    }
    return authFetch(`${api.sample}/content/${recordID}/stimulations`, getState)
      .then((stimulations) => {
        dispatch({
          type: TIMESERIES.SET_STIMULATIONS,
          stimulations,
        });
      })
      .catch(catchErrorMessage(dispatch));
  };
}

function optionalContentHandler(name) {
  return (resp) => {
    if (!resp.ok) {
      throw new Error(`Server error, status code : ${resp.status}`);
    }
    if (resp.status === 204) {
      throw new Error(`${name} not available for this record`);
    }
    return resp.json();
  };
}

export function displayPositiongram(recordID) {
  return (dispatch, getState, api) => {
    if (!api.record) {
      return;
    }
    const cancel = toggleProgressBar(dispatch);
    authFetch(
      `${api.record}/${recordID}/positionogram/`,
      getState,
      null,
      optionalContentHandler('positiongram'),
    )
      .then((values) => {
        cancel();
        // eslint-disable-next-line no-param-reassign, no-return-assign
        values.forEach((v) => (v.stage = v.stage.toUpperCase()));
        dispatch(addContent(element(contentTypes.POSITIONGRAM, values)));
      })
      .catch(catchErrorMessage(dispatch, cancel));
  };
}

export function displayHeartgram(recordID) {
  return (dispatch, getState, api) => {
    if (!api.record) {
      return;
    }
    const cancel = toggleProgressBar(dispatch);
    authFetch(`${api.record}/${recordID}/heartgram/`, getState)
      .then((resp) => {
        cancel();
        if (resp.heartgram == null || !resp.heartgram.length) {
          throw new Error('Heartgram data is not available for this record');
        }
        const { start } = getState().timeSeries.range;
        const step = 300000;
        const data = resp.heartgram.map((d, i) => ({
          time: start + i * step,
          value: d,
        }));
        dispatch(addContent(element(contentTypes.HEARTGRAM, data)));
      })
      .catch(catchErrorMessage(dispatch, cancel));
  };
}

export function displayRespigram(recordID) {
  return (dispatch, getState, api) => {
    if (!api.record) {
      return;
    }
    const cancel = toggleProgressBar(dispatch);
    authFetch(`${api.record}/${recordID}/respigram/`, getState)
      .then((resp) => {
        cancel();
        if (resp.respigram == null || !resp.respigram.length) {
          throw new Error('Respigram data is not available for this record');
        }
        const { start } = getState().timeSeries.range;
        const step = 300000;
        const data = resp.respigram.map((d, i) => ({
          time: start + i * step,
          value: d,
        }));
        dispatch(addContent(element(contentTypes.RESPIGRAM, data)));
      })
      .catch(catchErrorMessage(dispatch, cancel));
  };
}

export function displaySlaca(recordID) {
  return (dispatch, getState, api) => {
    if (!api.record) {
      return;
    }
    const cancel = toggleProgressBar(dispatch);
    authFetch(`${api.record}/${recordID}/slaca_quality/`, getState, null, optionalContentHandler('slaca'))
      .then((resp) => {
        cancel();
        const data = resp.map((r) => ({
          title: r.title,
          serie: r.values.flatMap((v) => [{
            time: v.start,
            value: v.value,
          }, {
            time: v.end,
            value: v.value,
          }]),
        }));
        dispatch(addContent(element(contentTypes.SLACA, data)));
      })
      .catch(catchErrorMessage(dispatch, cancel));
  };
}

export function displayBrainogram(recordID) {
  return (dispatch, getState, api) => {
    if (!api.record) {
      return;
    }
    const cancel = toggleProgressBar(dispatch);
    authFetch(`${api.record}/${recordID}/brainogram/`, getState)
      .then((resp) => {
        cancel();
        if (resp.brainogram == null || !resp.brainogram.length) {
          throw new Error('Brainogram data is not available for this record');
        }
        // 10s of record before first value
        const start = getState().timeSeries.range.start + 10000;
        const step = 10000;
        const data = resp.brainogram.map((d, i) => ({
          time: start + i * step,
          value: d,
        }));
        dispatch(addContent(element(contentTypes.BRAINOGRAM, data)));
      })
      .catch(catchErrorMessage(dispatch, cancel));
  };
}

export function displayScoringProgress() {
  return (dispatch) => {
    dispatch(addContent(element(contentTypes.SCORING_PROGRESS, null)));
  };
}

export function displaySpectrogram(signal) {
  return (dispatch, getState, api) => {
    const { series } = getState().timeSeries;
    const cancel = toggleProgressBar(dispatch);
    authFetch(
      `${api.sample}/series/${
        series[0].recordID
      }/spectrogram?serie=${signal}&tstep=30000&flimit=60`, getState,
    )
      .then((res) => {
        cancel();
        let ct = getState().timeSeries.content;
        ct = ct.removeByType(contentTypes.SPECTROGRAM);
        ct = ct.add(element(contentTypes.SPECTROGRAM, res));
        dispatch(updateContent(ct));
      })
      .catch(catchErrorMessage(dispatch, cancel));
  };
}

export function displayWindowRead(params) {
  return (dispatch, getState, api) => {
    const cancel = toggleProgressBar(dispatch);
    const {
      content,
      recordInfo: {
        id,
      },
    } = getState().timeSeries;
    const channels = params.signals.join(',');
    return authFetch(
      `${api.sample}/series/${id}/full?series=${channels}&window=${
        params.window
      }`,
      getState,
    )
      .then((resp) => {
        cancel();
        const data = {
          series: resp.map((r) => ({
            ...r,
            signal: ensureDomain(r.signal),
          })),
          window: params.window,
          size: params.size,
        };
        const ct = content.add(element(contentTypes.WINDOW_READ, data));
        dispatch(updateContent(ct));
      })
      .catch(catchErrorMessage(dispatch, cancel));
  };
}