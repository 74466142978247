import _ from 'lodash';

export const colorPalette = [
  'rgba(190, 185, 255, 1)',
  'rgba(241, 207, 48, 1)',
  'rgba(255, 164, 49, 1)',
  'rgba(162, 207, 254, 1)',
  'rgba(255, 164, 192, 1)',
  'rgb(0, 0, 0)',
  'rgb(213, 94, 0)',
  'rgb(204, 121, 167)',
  'rgb(123, 127, 227)',
  'rgb(129, 217, 191)',
  'rgb(227, 177, 123)',
];

export const colorPaletteExtended = [
  'rgb(141,211,199)',
  'rgb(255,255,179)',
  'rgb(90,186,218)',
  'rgb(251,128,114)',
  'rgb(128,177,211)',
  'rgb(253,180,98)',
  'rgb(179,222,105)',
  'rgb(252,205,229)',
  'rgb(217,217,217)',
  'rgb(188,128,189)',
  'rgb(204,235,197)',
  'rgb(255,237,111)',
];

export function colorLegend(palette, array) {
  const m = new Map();
  _.forEach(array, (a, i) => m.set(a, palette[i % palette.length]));
  return m;
}

export function transform(x, y) {
  const translateX = x != null ? x : 0;
  const translateY = y != null ? y : 0;
  return `translate(${translateX}, ${translateY})`;
}

export function immutableScale(scale) {
  const wrapper = (v) => scale(v);
  wrapper.domain = (d) => {
    if (d != null) {
      scale.domain(d);
      return immutableScale(scale);
    }
    return scale.domain();
  };

  wrapper.range = (r) => {
    if (r != null) {
      scale.range(r);
      return immutableScale(scale);
    }
    return scale.range();
  };
  wrapper.copy = () => scale.copy();

  wrapper.invert = (v) => scale.invert(v);

  wrapper.inner = scale;
  return wrapper;
}

export function compareSeries(s1, s2) {
  if (s1.length !== s2.length) {
    return false;
  }
  for (let i = 0; i < s1.length; i += 1) {
    const se1 = s1[i];
    const se2 = s2[i];
    if (se1.recordID !== se2.recordID) {
      return false;
    }
    if (se1.id !== se2.id) {
      return false;
    }
    if (se1.signal !== se2.signal) {
      return false;
    }
    if (se1.values !== se2.values) {
      return false;
    }
  }
  return true;
}

export function compareConfigurations(c1, c2) {
  if (c1.name !== c2.name) {
    return false;
  }
  if (c1.colorConfig !== c2.colorConfig) {
    return false;
  }
  if (c1.scaleConfig !== c2.scaleConfig) {
    return false;
  }
  if (c1.limiterMode !== c2.limiterMode) {
    return false;
  }
  return true;
}

export function fitDomain(values) {
  let max = Number.NEGATIVE_INFINITY;
  let min = Number.POSITIVE_INFINITY;
  for (let i = 0; i < values.length; i += 1) {
    min = Math.min(min, values[i].value);
    max = Math.max(max, values[i].value);
  }
  return [min, max];
}

export function maxDomain(domains) {
  let max = Number.NEGATIVE_INFINITY;
  let min = Number.POSITIVE_INFINITY;
  for (let i = 0; i < domains.length; i += 1) {
    min = Math.min(min, domains[i][0]);
    max = Math.max(max, domains[i][1]);
  }
  return [min, max];
}

export function getMargin(top = 10, right = 20, bottom = 20, left = 60) {
  return {
    top,
    right,
    bottom,
    left,
    hMargin() {
      return left + right;
    },
    vMargin() {
      return top + bottom;
    },
  };
}