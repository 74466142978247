/* eslint-disable no-return-assign */
/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable react/no-deprecated */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable react/prop-types */
/* eslint-disable max-classes-per-file */
import React, { Component, Fragment } from 'react';
import _ from 'lodash';
import { scaleLinear } from 'd3-scale';
import { line as svgLine } from 'd3-shape';
import BasePlot from './BasePlot';
import Interaction from './Interaction';
import ReferenceLines from './ReferenceLines';
import Labels from './Labels';
import YAxis from './YAxis';
import {
  immutableScale,
  maxDomain,
  fitDomain,
  compareSeries,
  compareConfigurations,
  colorPalette,
} from './utils';

class LineChart extends Component {
  constructor(props) {
    super(props);
    this.line = svgLine()
      .x((d) => props.xScale(d.time))
      .y((d) => props.yScale(d.value));
  }

  shouldComponentUpdate(nextProps) {
    const { props } = this;
    const hasFitChanged = nextProps.fitMode !== props.fitMode;
    return (
      !compareSeries(nextProps.series, props.series)
      || !compareConfigurations(nextProps.configuration, props.configuration)
      || hasFitChanged
      || props.size !== nextProps.size
    );
  }

  render() {
    return _.map(this.props.series, (s, i) => (
      <path
        key={s.signal.path}
        className="chart-line"
        stroke={colorPalette[i]}
        d={this.line(s.values)}
      />
    ));
  }
}

function getLargestDomain(series) {
  const ld = _(series)
    .map((s) => s.signal.domain)
    .maxBy((d) => d.max - d.min);
  return [ld.min, ld.max];
}

function updateYScaleDomain(props, scale) {
  const d = props.fitMode
    ? maxDomain(_.map(props.series, (s) => fitDomain(s.values)))
    : getLargestDomain(props.series);
  scale.domain(d);
}

function updateYScaleRange(size, scale) {
  scale.range([size.height, 0]);
}

class SinglePlot extends Component {
  constructor(props) {
    super(props);
    const scale = scaleLinear();
    updateYScaleDomain(props, scale);
    updateYScaleRange(props.size, scale);
    this.state = {
      yScale: immutableScale(scale),
    };
    this.onZoom = this.onZoom.bind(this);
  }

  componentWillReceiveProps(nextProps) {
    let updated = false;
    const scale = this.state.yScale.inner;
    if (this.props.size !== nextProps.size) {
      updateYScaleRange(nextProps.size, scale);
      updated = true;
    }
    if (
      this.props.fitMode !== nextProps.fitMode
      || !compareSeries(nextProps.series, this.props.series)
    ) {
      updateYScaleDomain(nextProps, scale);
      updated = true;
    }
    if (updated) {
      this.setState({ yScale: immutableScale(scale) });
    }
  }

  onZoom() {
    this.lc.forceUpdate();
  }

  render() {
    const { props } = this;
    const yScales = [this.state.yScale];
    return (
      <>
        <Interaction onZoom={this.onZoom} yScales={yScales} {...props} />
        <YAxis yScale={this.state.yScale} />
        <ReferenceLines
          yScales={yScales}
          series={props.series}
          referenceLines={props.referenceLines}
          size={props.size}
        />
        <LineChart
          ref={(e) => (this.lc = e)}
          yScale={this.state.yScale}
          {...props}
        />
        <Labels yScales={yScales} {...props} />
      </>
    );
  }
}

export default function SinglePlotWrapper(props) {
  return (
    <div>
      <BasePlot {...props}>
        <SinglePlot {...props} />
      </BasePlot>
    </div>
  );
}
