export const defaultRecordInfo = {
  id: '',
  record: null,
  reference: 'N/A',
  user: 'N/A',
  device: 'N/A',
  timezone: null,
};

function valueOrDefault(v) {
  return v != null && v !== '' ? v : 'N/A';
}

export function newDefaultRecordInfo(id) {
  return {
    ...defaultRecordInfo,
    id: id != null ? id : '',
  };
}

export function newRecordInfo(r) {
  return {
    id: r.id != null ? r.id : '',
    record: r.record != null ? r.record : null,
    reference: valueOrDefault(r.reference),
    user: valueOrDefault(r.user),
    device: valueOrDefault(r.device),
    timezone: r.timezone != null ? r.timezone : null,
  };
}