import React, { useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import styles from './Dialog.module.scss';
import { ReactComponent as Close } from '../../../assets/images/icons/close.svg';

function Dialog(props) {
  const {
    display,
    handleCloseDialog,
    className,
    title,
    content,
    footer,
  } = props;

  const dialogRef = useRef(null);

  useEffect(() => {
    if (display) {
      if (dialogRef.current) {
        dialogRef.current.classList.toggle(styles.open);
      }
    }
  }, [display]);

  function close() {
    dialogRef.current.classList.toggle(styles.open);
    handleCloseDialog();
  }

  return (
    <div ref={dialogRef} className={`${styles.container} ${className}`}>
      <div
        className={styles.background}
        onClick={close}
        onKeyPress={null}
        role="button"
        tabIndex={0}
      >
        &nbsp;
      </div>
      <div className={styles.dialog}>
        <Close
          className={styles.closeIcon}
          onClick={close}
          fill="#7A71A6"
          width="14px"
          height="14px"
        />
        <h5 className={styles.title}>{title}</h5>
        <div className={styles.content}>
          {content}
        </div>
        {footer && (
          <div className={styles.footer}>
            {footer}
          </div>
        )}
      </div>
    </div>
  );
}

Dialog.defaultProps = {
  display: false,
  handleCloseDialog: null,
  className: '',
  title: '',
  content: null,
  footer: null,
};

Dialog.propTypes = {
  display: PropTypes.bool,
  handleCloseDialog: PropTypes.func,
  className: PropTypes.string,
  title: PropTypes.string,
  content: PropTypes.instanceOf(Object),
  footer: PropTypes.instanceOf(Object),
};

export default Dialog;