import {
  TIMESERIES,
} from '../constants/actionTypes';
import {
  authFetch,
} from './utils';
import {
  updateProgress,
  updateWindow,
} from './timeseries';
import {
  stagingEEGConfig,
  stagingSleepOnsetConfig,
  stagingRelaxConfig,
} from '../models/staging';
import {
  defaultWindow,
} from '../models/range';
import {
  toggleProgressBar,
  catchErrorMessage,
} from './app';
import {
  formatTime,
} from '../utils/timeUtils';

function getSubmitConfig(config, api) {
  switch (config.type) {
    case stagingEEGConfig.type:
      return {
        url: api.staging.eeg,
        minLength: 60,
        tag: {
          name: 'scored',
        },
      };
    case stagingSleepOnsetConfig.type:
      return {
        url: api.staging.sleepOnset,
        minLength: 10,
        tag: {
          name: 'hori_scored',
        },
      };
    case stagingRelaxConfig.type:
      return {
        url: api.staging.relax,
        minLength: 3,
        tag: null,
      };
    default:
      throw new Error('Unknown staging type');
  }
}

/** Saves and finish the staging process */
export function saveStaging() {
  return (dispatch, getState) => {
    const { staging } = getState().timeSeries;
    if (staging.enabled) {
      const cancel = toggleProgressBar(dispatch);
      staging.savingContext.stopTimer();
      staging.savingContext
        .save()
        .then(() => {
          cancel();
          dispatch({
            type: TIMESERIES.STOP_STAGING,
          });
        })
        .catch(catchErrorMessage(dispatch, cancel));
    }
  };
}

/**
 * Creates a saving context.
 * This allows saving the user's input automatically every
 * 30s in order to avoid any loss of work. When the actual
 * saving request is sent, the request method is determined
 * depending on the initial configuration.
 */
function newSavingContext({
  dispatch,
  getState,
  api,
  recordID,
  config,
  previous,
  method,
}) {
  if (method === 'PATCH' && previous.id == null) {
    throw new Error('No previous ID when attempt to patch');
  }

  const saveInterval = 30000;
  const submitConfig = getSubmitConfig(config, api);
  let saveMethod = method;
  let saveId = previous.id != null ? previous.id : null;
  let saveTimer = null;

  function tagRecord(input) {
    if (
      process.env.NODE_ENV === 'production'
      && api.record
      && input.length >= submitConfig.minLength
      && submitConfig.tag != null
    ) {
      return () => authFetch(`${api.record}/${recordID}/tag/`, getState, {
        method: 'POST',
        body: JSON.stringify(submitConfig.tag),
      });
    }
    return () => Promise.resolve();
  }

  function save() {
    const { staging } = getState().timeSeries;
    const { stages } = staging;
    const input = stages.toArray();
    if (input.length === 0) {
      return Promise.resolve();
    }
    const { sstart } = stages.config;
    const body = {
      user: getState().user.uuid,
      record: recordID,
      stages: input,
      signal: staging.signal != null && staging.signal !== ''
        ? staging.signal : undefined,
      start_time: sstart.start,
    };
    if (saveMethod === 'POST') {
      return authFetch(`${submitConfig.url}/`, getState, {
        method: 'POST',
        body: JSON.stringify(body),
      })
        .then((resp) => {
          saveMethod = 'PATCH';
          saveId = resp.id;
        })
        .then(tagRecord(input));
    // eslint-disable-next-line no-else-return
    } else if (saveMethod === 'PATCH') {
      if (saveId == null) {
        return Promise.reject(new Error('No scoring saving id'));
      }
      return authFetch(`${submitConfig.url}/${saveId}/`, getState, {
        method: 'PATCH',
        body: JSON.stringify(body),
      }).then(tagRecord(input));
    }
    return Promise.reject(new Error(`Invalid method ${saveMethod}`));
  }
  return {
    save,
    startTimer() {
      if (method != null && saveTimer == null) {
        saveTimer = window.setInterval(() => save()
          .catch(catchErrorMessage(dispatch)), saveInterval);
      }
    },
    stopTimer() {
      if (method != null && saveTimer != null) {
        window.clearInterval(saveTimer);
        saveTimer = null;
      }
    },
  };
}

/**
 * Triggers the staging process.
 * First the potential existing stages from previous session are loaded,
 * a new saving context is created and the staging mode is enabled.
 */
function doStartStaging({
  dispatch,
  getState,
  api,
  fetchExistingStages,
  config,
  recordID,
  signal,
}) {
  const cancel = toggleProgressBar(dispatch);
  if (!getState().timeSeries.staging.enabled) {
    return fetchExistingStages()
      .then((stages) => {
        cancel();
        const { sstart } = config;
        if (sstart == null) {
          throw new Error('Staging start cannot be null');
        }
        let r = getState().timeSeries.range;
        dispatch(
          updateWindow(
            r.updateWindow(
              r.current < sstart.start ? sstart.start : r.current,
              defaultWindow,
            ),
          ),
        );
        const savingContext = newSavingContext({
          dispatch,
          getState,
          api,
          recordID,
          config,
          previous: sstart.previous,
          method: sstart.method,
        });
        savingContext.startTimer();
        r = getState().timeSeries.range;
        dispatch({
          type: TIMESERIES.START_STAGING,
          config,
          stages,
          signal,
          cursor: r.current,
          start: sstart.start,
          savingContext,
        });
      })
      .catch(catchErrorMessage(dispatch, cancel));
  }
  return null;
}

function stagingStart(start, previous, method) {
  if (method === 'PATCH' && previous.id == null) {
    throw new Error('No previous ID when attempt to patch');
  }
  return {
    start,
    previous,
    method,
  };
}

/** A previous staging is said to match only if start_time match */
function prevStaging(previous, mark) {
  if (previous == null) {
    return {
      id: null,
      load: false,
    };
  }
  if (mark != null && previous.start_time != null) {
    return {
      id: previous.id,
      load: mark === previous.start_time,
    };
  }
  return {
    id: previous.id,
    load: true,
  };
}

/**
 * Determines the staging start and therefore if we should
 * create a new staging or patch an existing one.
 */
function getStagingStart(getState, previous) {
  const ts = getState().timeSeries;
  const mark = ts.selection.mark.location;
  const prev = prevStaging(previous, mark);
  if (mark != null) {
    return stagingStart(mark, prev, prev.id != null ? 'PATCH' : 'POST');
  }
  if (previous != null && previous.start_time != null) {
    return stagingStart(previous.start_time, prev, 'PATCH');
  }
  return stagingStart(ts.range.start, prev, prev.id != null ? 'PATCH' : 'POST');
}

const startMessageFormat = 'HH:mm:ss';

export function startEEGStaging(recordID) {
  return (dispatch, getState, api) => {
    if (!api.staging.eeg) {
      return;
    }
    // eslint-disable-next-line consistent-return
    return authFetch(
      `${api.staging.eeg}/?record=${recordID}&user=${getState().user.uuid}`,
      getState,
    ).then((resp) => {
      const { results } = resp;
      if (results.length > 1) {
        throw new Error('Multiple staging for a single user');
      }
      const previous = results.length !== 0 ? results[0] : null;
      const sstart = getStagingStart(getState, previous);
      if (sstart.previous.id != null && !sstart.previous.load) {
        const { recordInfo } = getState().timeSeries;
        // eslint-disable-next-line no-alert
        const ok = window.confirm(
          `Do you really want to overwrite the existing scoring starting at ${formatTime(
            previous.start_time,
            recordInfo.timezone,
            startMessageFormat,
          )} with a new one starting at ${formatTime(
            sstart.start,
            recordInfo.timezone,
            startMessageFormat,
          )} ?`,
        );
        if (!ok) {
          return;
        }
      }
      // eslint-disable-next-line consistent-return
      return doStartStaging({
        dispatch,
        getState,
        api,
        config: { ...stagingEEGConfig, stagingStart: sstart },
        recordID,
        fetchExistingStages() {
          return sstart.previous.load
            ? authFetch(
              `${api.staging.eeg}/${sstart.previous.id}/`,
              getState,
            ).then((r) => r.stages)
            : Promise.resolve(null);
        },
      });
    });
  };
}

export function startSleepOnsetStaging(recordID) {
  return (dispatch, getState, api) => {
    if (!api.staging.sleepOnset) {
      return;
    }
    // eslint-disable-next-line consistent-return
    return authFetch(
      `${api.staging.sleepOnset}/?record=${recordID}&user=${
        getState().user.uuid
      }`,
      getState,
    ).then((resp) => {
      if (resp.results.length > 1) {
        throw new Error('Multiple staging for a single user');
      }
      const previous = resp.results.length !== 0 ? resp.results[0] : null;
      const sstart = stagingStart(
        getState().timeSeries.range.start,
        previous != null ? {
          id: previous.id,
          load: true,
        } : {
          id: null,
          load: false,
        },
        previous != null ? 'PATCH' : 'POST',
      );
      return doStartStaging({
        dispatch,
        getState,
        api,
        config: { ...stagingSleepOnsetConfig, stagingStart: sstart },
        recordID,
        fetchExistingStages() {
          return Promise.resolve(sstart.previous.load ? previous.stages : null);
        },
      });
    });
  };
}

export function startRelaxStaging(recordID) {
  return (dispatch, getState, api) => {
    if (!api.staging.relax) {
      return Promise.resolve();
    }
    return authFetch(
      `${api.staging.relax}/?record=${recordID}&user=${getState().user.id}`,
      getState,
    ).then((resp) => {
      const { results } = resp;
      if (results.length > 1) {
        throw new Error('Multiple staging for a single user');
      }
      const previous = results.length !== 0 ? results[0] : null;
      const sstart = getStagingStart(getState, previous);
      if (sstart.previous.id != null && !sstart.previous.load) {
        const { recordInfo } = getState().timeSeries;
        // eslint-disable-next-line no-alert
        const ok = window.confirm(
          `Do you really want to overwrite the existing scoring starting at ${formatTime(
            previous.start_time,
            recordInfo.timezone,
            startMessageFormat,
          )} with a new one starting at ${formatTime(
            sstart.start,
            recordInfo.timezone,
            startMessageFormat,
          )} ?`,
        );
        if (!ok) {
          return Promise.resolve();
        }
      }
      return doStartStaging({
        dispatch,
        getState,
        api,
        config: { ...stagingRelaxConfig, stagingStart: sstart },
        recordID,
        fetchExistingStages() {
          return sstart.previous.load
            ? authFetch(
              `${api.staging.relax}/${sstart.previous.id}/`,
              getState,
            ).then((r) => r.stages)
            : Promise.resolve(null);
        },
      });
    });
  };
}

export function setStage(stage) {
  return (dispatch, getState) => {
    const st = getState().timeSeries.staging;
    if (!st.enabled) {
      return;
    }
    dispatch({
      type: TIMESERIES.SET_STAGE,
      stage,
      config: st.stages.config,
      cursor: st.stages.cursor,
    });
    const next = getState().timeSeries.staging.stages.shouldProgressForward(
      getState().timeSeries.range,
    );
    if (next != null) {
      dispatch(updateProgress(next));
    }
  };
}