/* eslint-disable react/prop-types */
import React from 'react';
import _ from 'lodash';

export default function Marker(props) {
  const {
    size,
    xScale,
  } = props;
  const location = _.get(props, 'selection.mark.location', null);
  return (
    <rect
      width="3"
      height={size.height}
      fill="deeppink"
      pointerEvents="none"
      x={location != null ? xScale(location) : 0}
      display={location != null ? '' : 'none'}
    />
  );
}
