import range from './range';
import staging from './staging';
import selectGroups from './group';
import labels from './label';
import selection from './selection';
import content from './content';
import seriesCache from './seriesCache';
import {
  parseProfile,
} from './profile';
import {
  defaultRecordInfo,
} from './recordInfo';

export function initialTimeseries() {
  return {
    // Record information
    recordInfo: defaultRecordInfo,
    // Display range timestamps
    range: range(0, 8 * 60 * 60 * 1000),
    // Main series data
    series: [],
    // Array of available signals in the current file
    signals: [],
    // Signal selection
    selectedSignals: [],
    // Cache used for fetched portion of the signals
    seriesCache: seriesCache(),
    // Currently displayed content
    content: content(),
    // Staging feature state
    staging: {
      enabled: false,
      stages: staging(),
      signal: null,
      savingContext: null,
    },
    // Currently loaded labels
    labels: labels(),
    // Stimulations information for this record
    stimulations: [],
    // Algorithm overview data fetched from the algorythm service
    algoOverview: [],
    // Signal grouping method
    groups: selectGroups(),
    // Range selection along with current marker
    selection: selection(),
    // Section of the signals to highlight
    highlights: [],
    // Enable automatic y axis scaling
    fitMode: false,
    // Enable/Disable the zooming feature
    zoomMode: true,
    // Enable/Disable the value measure overlay
    measureMode: false,
    // List of reference lines to display
    referenceLines: [],
    // Value numbers to display
    numberDisplay: [],
    // Hide/Show signal, hidden signals are still loaded
    hiddenSeries: [],
    // User saved profile/configuration
    profile: parseProfile(),
  };
}

const INITIAL_STATE = {
  timeSeries: initialTimeseries(),
  user: null,
  app: {
    errorMessage: null,
    progress: false,
  },
};

export default INITIAL_STATE;