/* eslint-disable react/prop-types */
/* eslint-disable react/destructuring-assignment */
import React, { Component } from 'react';
import _ from 'lodash';

export default class Highlights extends Component {
  shouldComponentUpdate(nextProps) {
    if (
      this.props.highlights.length === 0
      && nextProps.highlights.length === 0
    ) {
      return false;
    }
    return (
      this.props.highlights !== nextProps.highlights
      || this.props.range !== nextProps.range
      || this.props.xScale !== nextProps.xScale
      || this.props.size !== nextProps.size
    );
  }

  render() {
    const r = this.props.range.range();
    const current = _.filter(
      this.props.highlights,
      (h) => h.start >= r.start || h.end < r.end,
    );
    return _.map(current, (h, i) => {
      const xStart = this.props.xScale(h.start);
      const xEnd = this.props.xScale(h.end);
      return (
        <rect
          key={i}
          className="timeseries-highlight"
          fill="deeppink"
          fillOpacity="0.1"
          x={xStart}
          y="0"
          width={xEnd - xStart}
          height={this.props.size.height}
        />
      );
    });
  }
}
