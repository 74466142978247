import Mousetrap from 'mousetrap';

export function Keys(shortcuts = []) {
  const slist = shortcuts.slice();
  let toggled = [];
  slist.forEach((s) => Mousetrap.bind(s.key, s.callback));
  return {
    addToggled(kb) {
      if (!kb.id) {
        throw new Error('Toggled keybinds must have an unique id');
      }
      const prev = toggled.find((t) => t.id === kb.id);
      if (prev == null) {
        toggled.push(kb);
        // FIXME: Delay is set on add in order for potential clean-up to occur before
        setTimeout(() => {
          Mousetrap.bind(kb.key, kb.callback);
        }, 500);
      }
    },

    removeToggled(kb) {
      const prev = toggled.find((t) => t.id === kb.id);
      if (prev != null) {
        Mousetrap.unbind(prev.key);
        toggled = toggled.filter((t) => t.id !== kb.id);
      }
    },

    unbind() {
      slist.forEach((s) => Mousetrap.unbind(s.key));
      toggled.forEach((t) => Mousetrap.unbind(t.key));
    },
  };
}

export function keybind(key, callback, id = null) {
  return {
    key,
    callback,
    id,
  };
}

export function SelectionModifier() {
  let multiple = false;
  let contiguous = false;

  function down(e) {
    if (e.target.tagName.toLowerCase() !== 'input') {
      multiple = e.metaKey || e.keyCode === 67;
      contiguous = e.shiftKey || e.keyCode === 83;
    }
  }

  function up(e) {
    if (e.target.tagName.toLowerCase() !== 'input') {
      if (e.keyCode === 67) {
        multiple = false;
      } else if (e.keyCode === 83) {
        contiguous = false;
      } else {
        multiple = e.metaKey;
        contiguous = e.shiftKey;
      }
    }
  }
  window.document.addEventListener('keydown', down);
  window.document.addEventListener('keyup', up);
  return {
    unbind() {
      window.document.removeEventListener('keydown', down);
      window.document.removeEventListener('keyup', up);
    },
    contiguous() {
      return contiguous;
    },
    multiple() {
      return multiple;
    },
  };
}