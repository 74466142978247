import moment from 'moment-timezone';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { Link } from 'react-router-dom';
import { Tooltip } from 'react-tooltip';
import { ReactComponent as CopyIcon } from '../../../assets/images/icons/copy.svg';
import { ReactComponent as ErrorIcon } from '../../../assets/images/icons/error.svg';
import { ReactComponent as QualityIcon } from '../../../assets/images/icons/quality.svg';
import { ReactComponent as QualityAlertIcon } from '../../../assets/images/icons/qualityAlert.svg';
import { ReactComponent as QualityOnlyLightReportIcon } from '../../../assets/images/icons/qualityOnlyLightReport.svg';
import { ReactComponent as RawDataIcon } from '../../../assets/images/icons/rawData.svg';
import { ReactComponent as RawDataAlertIcon } from '../../../assets/images/icons/rawDataAlert.svg';
import GlobalStyle from '../../layout/GlobalStyle';
import DateRangePicker from '../../shared/dateRangePicker/DateRangePicker';
import DownloadMenu from '../../shared/downloadMenu/DownloadMenu';
import MinMaxDurationPicker from '../../shared/minMaxDurationPicker/MinMaxDurationPicker';
import SearchBar from '../../shared/searchBar/SearchBar';
import styles from './Records.module.scss';
import { fetchRecords } from './actions';
import Table from './table/Table';

function useDebouncedValue(value, delay = 1000) {
  const [debouncedValue, setDebouncedValue] = useState(value)

  useEffect(() => {
    const timer = setTimeout(() => setDebouncedValue(value), delay)

    return () => {
      clearTimeout(timer)
    }
  }, [value, delay])

  return debouncedValue
}

function RecordsContainer() {
  const isInitialMount = useRef(true);
  const [records, setRecords] = useState([]);
  const [next, setNext] = useState(null);
  const [prev, setPrev] = useState(null);
  const [page, setPage] = useState(0);
  const [count, setCount] = useState(null);
  const [limit] = useState(30);
  const [loading, setLoading] = useState(true);
  const [selectedId, setSelectedId] = useState('');
  const [startDate, setStartDate] = useState(null);
  const [stopDate, setStopDate] = useState(null);
  const [minDuration, setMinDuration] = useState(null);
  const [maxDuration, setMaxDuration] = useState(null);
  const [search, setSearch] = useState(null);
  const [error, setError] = useState(null);
  const [ordering, setOrdering] = useState('-metadata__start_time');

  const debouncedSearch = useDebouncedValue(search);

  const loadData = useCallback((req) => {
    function turnsIntoSeconds(m) {
      if (!m) return null;
      const hours = Number(m.format('HH')) * 60;
      const minutes = Number(m.format('mm'));
      return (hours + minutes) * 60;
    }
    setLoading(true);
    fetchRecords(
      req,
      limit,
      ordering,
      startDate && moment(startDate).unix(),
      stopDate && moment(stopDate).unix(),
      minDuration && turnsIntoSeconds(minDuration),
      maxDuration && turnsIntoSeconds(maxDuration),
      debouncedSearch,
    ).then((e) => {
      if (e.err) {
        setError(e.err.toString());
      } else {
        setError(null);
        setCount(e.count);
        setNext(e.next);
        setPrev(e.previous);
        setRecords(e.results);
      }
      setLoading(false);
    });
  }, [limit, maxDuration, minDuration, ordering, debouncedSearch, startDate, stopDate])

  useEffect(() => {
    loadData(null);
    const queryID = new URLSearchParams(window.location.search).get('id');
    setSelectedId(queryID);
  }, [loadData]);

  useEffect(() => {
    if (ordering) {
      if (ordering === 'RESET') {
        setOrdering('');
      }
      loadData(null);
    }
  }, [ordering, loadData]);

  useEffect(() => {
    if (isInitialMount.current) {
      isInitialMount.current = false;
    } else {
      loadData();
    }
  }, [startDate, stopDate, minDuration, maxDuration, loadData]);

  function handlePageChange(p) {
    if (p > page) {
      loadData(next);
    } else {
      loadData(prev);
    }
    setPage(p);
  }

  function handleTooltipClick(e, uuid) {
    e.preventDefault();
    e.stopPropagation();
    navigator.clipboard.writeText(`${uuid}`);
    const clickToCopyText = document.querySelector(`#clickToCopyText`);
    const copiedToClipboardText = document.querySelector(`#copiedToClipboardText`);
    clickToCopyText.style.display = 'none';
    copiedToClipboardText.style.display = 'flex';
    setTimeout(() => {
      clickToCopyText.style.display = 'flex';
      copiedToClipboardText.style.display = 'none';
    }, 1500);
  }

  const defaultDateFormatNoTz = 'DD/MM/YY HH[H]mm';

  const columns = [
    {
      title: 'uuid',
      col: 'record',
      render: (r) => (
        <div data-tooltip-id="tooltip-uuid" data-tooltip-content={r.record}>
          {`${r.record.split('-')[0]}...`}
        </div>
      ),
    },
    {
      title: 'participant email',
      col: 'user',
      render: (r) => r.user.username,
    },
    {
      title: 'headband id',
      col: 'device',
      render: (r) => r.device.nickname,
    },
    {
      title: 'Start',
      col: 'metadata__start_time',
      render: (r) => {
        if (
          (r.report && r.report.endpoints.record_start_iso)
          || (r.light_report && r.light_report.start_time)) {
          const originalDate = r.report
            ? r.report.endpoints.record_start_iso
            : r.light_report.start_time;
          const dateRender = moment(originalDate.slice(0, -6)).format(
            defaultDateFormatNoTz,
          );
          if (originalDate.includes('+') || originalDate.includes('-')) {
            return `${dateRender} ${originalDate.slice(-6).replace(':', '')}`;
          }
          return dateRender;
        }
        return 'N/A';
      },
    },
    {
      title: 'End',
      col: 'metadata__stop_time',
      render: (r) => {
        if ((r.report && r.report.endpoints.record_start_iso)
          || (r.light_report && r.light_report.stop_time)) {
          const originalDate = r.report
            ? r.report.endpoints.record_start_iso
            : r.light_report.stop_time;
          let dateRender;
          if (r.report) {
            dateRender = moment(
              new Date(originalDate.slice(0, -6)).setTime(
                new Date(originalDate.slice(0, -6)).getTime()
                + r.report.endpoints.record_duration * 1000,
              ),
            ).format(defaultDateFormatNoTz);
          } else {
            dateRender = moment(originalDate.slice(0, -6)).format(
              defaultDateFormatNoTz,
            );
          }
          if (originalDate.slice(-6)) {
            return `${dateRender} ${originalDate.slice(-6).replace(':', '')}`;
          }
          return dateRender;
        }
        return 'N/A';
      },
    },
    {
      title: 'Duration',
      render: (r) => {
        if ((r.report && r.report.endpoints.record_duration)
          || (r.light_report && r.light_report.start_time && r.light_report.stop_time)) {
          let hours;
          let minutes;
          if (r.report) {
            hours = parseInt(Number(r.report.endpoints.record_duration) / 60 / 60, 10);
            minutes = parseInt(
              (Number(r.report.endpoints.record_duration) - hours * 60 * 60) / 60,
              10,
            );
          } else {
            hours = parseInt(Number(r.light_report.duration) / 60 / 60, 10);
            minutes = parseInt(
              (Number(r.light_report.duration) - hours * 60 * 60) / 60,
              10,
            );
          }
          const warnTime = hours < 1;
          if (warnTime) {
            return (
              <div style={{ display: 'flex', alignItems: 'center', gap: '8px' }}>
                {`${minutes}min`}
                {warnTime ? <ErrorIcon width="13px" height="13px" /> : null}
              </div>
            );
          }
          if (hours < 10) hours = `0${hours}`;
          if (minutes < 10) minutes = `0${minutes}`;
          return `${hours}H${minutes}`;
        }
        return 'N/A';
      },
    },
    {
      title: 'alerts',
      render: (r) => {
        function qualityAlert() {
          if (!r.report) {
            return <QualityOnlyLightReportIcon width="32px" height="32px" />;
          }
          if (r.report.endpoints) {
            const offHead = Math.round(Number(r.report.endpoints.proportion_off_head) * 100);
            const scorability = Math.round(Number(r.report.endpoints.proportion_scorable) * 100);
            const sumBadEEG = () => {
              let counter = 0;
              if (r.report.endpoints.proportion_good_quality_eeg1 < 0.5) counter += 1;
              if (r.report.endpoints.proportion_good_quality_eeg2 < 0.5) counter += 1;
              if (r.device.hardware !== 4
                && r.report.endpoints.proportion_good_quality_eeg3 < 0.5) counter += 1;
              if (r.report.endpoints.proportion_good_quality_eeg4 < 0.5) counter += 1;
              return counter >= 2;
            };
            if (offHead > 10 || scorability < 85 || sumBadEEG()) {
              return <QualityAlertIcon width="32px" height="32px" />;
            }
          }
          return <QualityIcon width="32px" height="32px" />;
        }
        function rawDataAlert() {
          if (r.completion_status < 2) {
            return <RawDataAlertIcon width="32px" height="32px" />;
          }
          return <RawDataIcon width="32px" height="32px" />;
        }
        return (
          <div style={{ display: 'flex', gap: '16px' }}>
            {qualityAlert()}
            {rawDataAlert()}
          </div>
        );
      },
    },
    {
      render: (r) => (
        r.completion_status < 2 ? (
          <span className={`${styles.viewSignalsBtn} ${styles.disabled}`}>
            View signals
          </span>
        ) : (
          <Link
            to={`/timeseries/${r.record}`}
            target="_blank"
            className={styles.viewSignalsBtn}
          >
            View signals
          </Link>
        )
      ),
    },
    {
      render: (r) => (
        r.completion_status < 2 ? (
          <span className={`${styles.viewSignalsBtn} ${styles.disabled}`}>
            report PDF
          </span>
        ) : (
          <Link
            to={`/reportPDF/${r.record}`}
            target="_blank"
            className={styles.viewSignalsBtn}
          >
            report PDF
          </Link>
        )
      ),
    },
  ];

  function expandableRowRender() {
    const record = records.find((r) => r.record === selectedId);
    function valueOrDefault(value, units = '') {
      if (value === 0) return value + units;
      return value ? value + units : 'N/A';
    }
    function secondsToHms(v) {
      const d = Number(v);
      const h = Math.floor(d / 3600);
      const m = Math.floor((d % 3600) / 60);
      const s = Math.floor((d % 3600) % 60);
      const hDisplay = h > 0 ? `${h}h` : '';
      const mDisplay = m > 0 ? `${m}min` : '';
      const sDisplay = s > 0 ? `${s}sec` : '';
      return hDisplay + mDisplay + sDisplay;
    }
    function hoursOfDate(d) {
      const date = new Date(d);
      const h = `${date.getHours()}h`;
      const min = `${date.getMinutes()}min`;
      const sec = `${date.getSeconds()}sec`;
      return h + min + sec;
    }
    function qualityBox(c, hiddenValue) {
      const colors = ['#FF5151', '#FFA177', '#55AB68'];
      function qualityColor(q) {
        if (q < 50) {
          return colors[0];
        } if (q >= 70) {
          return colors[2];
        }
        return colors[1];
      }
      function offHeadColor(q) {
        if (q <= 5) {
          return colors[2];
        } if (q > 10) {
          return colors[0];
        }
        return colors[1];
      }
      function recordQualityColor(q) {
        if (q < 70) {
          return colors[0];
        } if (q >= 70 && q < 85) {
          return colors[1];
        }
        return colors[2];
      }
      const square = (qv, qc, tooltipValue) => {
        return (
          <div
            className={styles.squareQuality}
            style={{ background: qc(qv) }}
            data-tooltip-id={tooltipValue ? "tooltip-square" : "tooltip-none"}
            data-tooltip-content={tooltipValue}
          >
            {hiddenValue ? null : qv}
          </div>
        );
      };
      if (c === 'channels') {
        if (record.report) {
          return (
            <div className={styles.qualityContainer}>
              {square(
                Math.round(record.report.endpoints.proportion_good_quality_eeg1 * 100),
                qualityColor,
                record.device.hardware !== 4 ? 'CH1 (Fpz-O1)' : 'CH1 (F7-O1)',
              )}
              {square(
                Math.round(record.report.endpoints.proportion_good_quality_eeg2 * 100),
                qualityColor,
                record.device.hardware !== 4 ? 'CH2 (Fpz-O2)' : 'CH2 (F8-O2)',
              )}
              {record.device.hardware !== 4
                ? square(
                  Math.round(record.report.endpoints.proportion_good_quality_eeg3 * 100),
                  qualityColor,
                  'CH3 (Fpz - F7)',
                )
                : null}
              {square(
                Math.round(record.report.endpoints.proportion_good_quality_eeg4 * 100),
                qualityColor,
                'CH4 (F8-F7)',
              )}
            </div>
          );
        }
        if (record.light_report) {
          return (
            <div className={styles.qualityContainer}>
              {square(
                record.light_report.quality_eeg_1 * 50,
                qualityColor,
                record.device.hardware !== 4 ? 'CH1 (Fpz-O1)' : 'CH1 (F7-O1)',
              )}
              {square(
                record.light_report.quality_eeg_2 * 50,
                qualityColor,
                record.device.hardware !== 4 ? 'CH2 (Fpz-O2)' : 'CH2 (F8-O2)',
              )}
              {record.light_report.quality_eeg_3 ? (
                square(
                  record.light_report.quality_eeg_3 * 50,
                  qualityColor,
                  'CH3 (Fpz - F7)',
                )
              ) : null}
              {square(
                record.light_report.quality_eeg_4 * 50,
                qualityColor,
                'CH4 (F8-F7)',
              )}
            </div>
          );
        }
      }
      if (c === 'offhead') {
        if (record.report) {
          return (
            <div className={styles.qualityContainer}>
              {square(Math.round(
                Number(record.report.endpoints.proportion_off_head) * 100,
              ), offHeadColor)}
            </div>
          );
        }
      }
      if (c === 'quality') {
        if (record.report) {
          return (
            <div className={styles.qualityContainer}>
              {square(Math.round(
                Number(record.report.endpoints.proportion_scorable) * 100,
              ), recordQualityColor)}
            </div>
          );
        }
      }
      return null;
    }
    const field = (title, value) => (
      <li>
        {`${title}:`}
        <span>{value}</span>
      </li>
    );
    function durationAlert() {
      if (!record.report && !record.light_report) return null;
      let hours = 0;
      if (record.light_report) {
        hours = parseInt(
          Number(record.light_report.duration) / 60 / 60, 10,
        );
      }
      if (record.report) {
        hours = parseInt(
          Number(record.report.endpoints.record_duration) / 60 / 60, 10,
        );
      }
      if (hours < 1) {
        return (
          <div style={{ display: 'flex', alignItems: 'center', gap: '8px' }}>
            <ErrorIcon width="13px" height="13px" />
            <span>Record duration is less than 1h</span>
          </div>
        );
      }
      return null;
    }
    function qualityAlert() {
      if (!record.report) {
        return (
          <div style={{ display: 'flex', alignItems: 'center', gap: '8px' }}>
            <QualityOnlyLightReportIcon width="13px" height="13px" />
            <span>Report unavailable</span>
          </div>
        );
      }
      if (record.report) {
        if (record.report.endpoints && record.report.endpoints.proportion_off_head) {
          const offHead = Math.round(Number(record.report.endpoints.proportion_off_head) * 100);
          const scorability = Math.round(Number(record.report.endpoints.proportion_scorable) * 100);
          const sumBadEEG = () => {
            let counter = 0;
            if (record.report.endpoints.proportion_good_quality_eeg1 < 0.5) counter += 1;
            if (record.report.endpoints.proportion_good_quality_eeg2 < 0.5) counter += 1;
            if (record.device.hardware !== 4
              && record.report.endpoints.proportion_good_quality_eeg3 < 0.5) counter += 1;
            if (record.report.endpoints.proportion_good_quality_eeg4 < 0.5) counter += 1;
            return counter >= 2;
          };
          if (offHead > 10 || scorability < 85 || sumBadEEG()) {
            return (
              <div style={{ display: 'flex', alignItems: 'center', gap: '8px' }}>
                <QualityAlertIcon width="13px" height="13px" />
                <span>Bad quality</span>
              </div>
            );
          }
        }
      }
      return null;
    }
    function rawDataAlert() {
      if (record.completion_status < 2) {
        return (
          <div style={{ display: 'flex', alignItems: 'center', gap: '8px' }}>
            <RawDataAlertIcon width="13px" height="13px" />
            <span>Raw data unavailable</span>
          </div>
        );
      }
      return null;
    }

    if (record.report && record.report.endpoints.avg_heart_rate) {
      return (
        <div className={styles.expandableRowRender}>
          <div>
            <h3>Hello PAT record !</h3>
            <img src="https://www.lerugbynistere.fr/photos/620_px/patate.jpg" alt="" />
          </div>
          <p style={{ lineBreak: 'anywhere' }}>
            {JSON.stringify(record.report)}
          </p>
        </div>
      );
    }

    return (
      <div className={styles.expandableRowRender}>
        <div>
          <h3>Recording infos</h3>
          <div style={{ display: 'flex', gap: '2rem' }}>
            {(record.report || record.light_report) && (
              <div className={styles.qualityItem}>
                {qualityBox('channels', !record.report)}
                <span>Channel Quality</span>
              </div>
            )}
            {record.report && !record.report.endpoints.avg_heart_rate && (
              <>
                <div className={styles.qualityItem}>
                  {qualityBox('offhead')}
                  <span>Off Head</span>
                </div>
                <div className={styles.qualityItem}>
                  {qualityBox('quality')}
                  <span>Record Quality</span>
                </div>
              </>
            )}
          </div>
          <>
            {durationAlert()}
            {qualityAlert()}
            {rawDataAlert()}
          </>
        </div>
        {record.report && !record.report.endpoints.avg_heart_rate && (
          <div>
            <h3>Sleep metrics</h3>
            <div className={styles.metricsArray}>
              <div>
                <h4>Overview</h4>
                <ul>
                  {field('START TIME', valueOrDefault(hoursOfDate(record.report.endpoints.lights_off.replace(' ', 'T'))))}
                  {field('END TIME', valueOrDefault(hoursOfDate(record.report.endpoints.lights_on.replace(' ', 'T'))))}
                  {field('TRT', valueOrDefault(secondsToHms(record.report.endpoints.record_duration)))}
                </ul>
              </div>
              <div>
                <h4>Sleep / Wake</h4>
                <ul>
                  {field('SOL', valueOrDefault(record.report.endpoints.sol, 'min'))}
                  {field('TST', valueOrDefault(record.report.endpoints.tst, 'min'))}
                  {field('WASO', valueOrDefault(record.report.endpoints.waso_aasm, 'min'))}
                  {field('SE', valueOrDefault(Math.round(record.report.endpoints.sleep_efficiency), '%'))}
                </ul>
              </div>
              <div>
                <h4>Sleep stages</h4>
                <ul>
                  {field('WAKE', valueOrDefault(record.report.endpoints.wake_duration, 'min'))}
                  {field('N1', `${valueOrDefault(record.report.endpoints.n1_duration, 'min')} (${valueOrDefault(Math.round(record.report.endpoints.n1_percentage), '%')})`)}
                  {field('N2', `${valueOrDefault(record.report.endpoints.n2_duration, 'min')} (${valueOrDefault(Math.round(record.report.endpoints.n2_percentage), '%')})`)}
                  {field('N3', `${valueOrDefault(record.report.endpoints.n3_duration, 'min')} (${valueOrDefault(Math.round(record.report.endpoints.n3_percentage), '%')})`)}
                  {field('NREM', `${valueOrDefault(record.report.endpoints.nrem_duration, 'min')} (${valueOrDefault(Math.round(record.report.endpoints.nrem_percentage), '%')})`)}
                  {field('REM', `${valueOrDefault(record.report.endpoints.rem_duration, 'min')} (${valueOrDefault(Math.round(record.report.endpoints.rem_percentage), '%')})`)}
                </ul>
              </div>
              <div>
                <h4>
                  Latency to specific
                  <br />
                  sleep stage
                </h4>
                <ul>
                  {field('LPS', valueOrDefault(record.report.endpoints.lps, 'min'))}
                  {field('LN2', valueOrDefault(record.report.endpoints.n2_latency_aasm, 'min'))}
                  {field('LN3', valueOrDefault(record.report.endpoints.n3_latency_aasm, 'min'))}
                  {field('LREM', valueOrDefault(record.report.endpoints.rem_latency_aasm, 'min'))}
                </ul>
              </div>
            </div>
          </div>
        )}
      </div>
    );
  }

  function handleRowClick(id) {
    setSelectedId(id === selectedId ? null : id);
    const url = new URL(window.location);
    const queryID = new URLSearchParams(window.location.search).get('id');
    url.searchParams.set('id', id);
    if (id === queryID) url.searchParams.delete('id');
    window.history.pushState({}, '', url);
  }

  function getRecord(id) {
    return records.filter((r) => r.record === id)[0];
  }

  console.log(getRecord(selectedId));

  return (
    <div className={styles.container}>
      <div className={styles.header}>
        <SearchBar
          disabled={loading}
          placeholder="Search user, record...."
          value={search}
          onChange={(e) => setSearch(e.target.value || '')}
          className={styles.searchBar}
          onSubmit={() => loadData()}
          onReset={() => setSearch(null)}
          onKeyPress={(e) => {
            if (e.key === 'Enter') loadData();
          }}
        />
        <h1>Records</h1>
        <div className={styles.filtersContainer}>
          <DateRangePicker
            disabled={loading}
            startDate={startDate}
            setStartDate={setStartDate}
            stopDate={stopDate}
            setStopDate={setStopDate}
          />
          <MinMaxDurationPicker
            disabled={loading}
            minDuration={minDuration}
            setMinDuration={setMinDuration}
            maxDuration={maxDuration}
            setMaxDuration={setMaxDuration}
          />
          {selectedId}
          <DownloadMenu
            disabled={loading}
            selected={selectedId}
            getRecord={getRecord}
            disabledButton={{
              h5: selectedId
                && getRecord(selectedId)
                && getRecord(selectedId).h5_status !== 1
                && getRecord(selectedId).h5_status !== 4,
              raw: selectedId && getRecord(selectedId)
                ? getRecord(selectedId).completion_status < 2
                : false,
              edf: selectedId
                && getRecord(selectedId)
                && getRecord(selectedId).h5_status !== 1
                && getRecord(selectedId).h5_status !== 4,
              hypnogram: selectedId && getRecord(selectedId)
                ? getRecord(selectedId).completion_status < 3
                : false,
              csv: selectedId && getRecord(selectedId)
                ? getRecord(selectedId).completion_status < 3
                : false,
            }}
          />
        </div>
      </div>
      <Table
        columns={columns}
        rows={records}
        page={page}
        handlePageChange={(p) => handlePageChange(p)}
        count={count}
        next={next}
        prev={prev}
        loading={loading}
        limit={limit}
        expandableRowRender={expandableRowRender}
        selected={selectedId}
        onRowClick={(id) => handleRowClick(id)}
        error={error}
        ordering={ordering}
        setOrdering={setOrdering}
      />
      <Tooltip 
        className={styles.tooltip}
        disableStyleInjection="core"
        float={true}
        id="tooltip-square" 
      />  
      <Tooltip 
        className={`${styles.tooltip} ${styles.uuidTooltip}`} 
        clickable={true}
        disableStyleInjection="core"
        id="tooltip-uuid" 
        render={({ content }) => (
          <button
            type="button"
            className={styles.tooltipButton}
            onClick={(e) => handleTooltipClick(e, content)}
          >
            {content}
              <span id={`clickToCopyText`}>Click to copy</span>
              <span id={`copiedToClipboardText`}>
                <CopyIcon width="14px" height="14px" />
                &nbsp;Copied to clipboard
              </span>
          </button>)}
      />
    </div>
  );
}

export default function Records() {
  return (
    <GlobalStyle content={<RecordsContainer />} />
  );
}
