/* eslint-disable import/prefer-default-export */
import {
  backendURL,
  headbandURL,
  jwt,
  getHeaders,
  user,
  mapWithDefault,
} from '../../../constants/globals';

function fetchUsers(content) {
  const id = content ? content.map((r) => (r.user)) : [];
  const m = mapWithDefault({
    id: 'N/A',
    username: 'N/A',
  });
  if (id.length === 0) {
    return Promise.resolve(m);
  }
  const headers = getHeaders(jwt());
  return fetch(`${backendURL()}/dreemer/dreemer/details/`, {
    headers,
    method: 'POST',
    mode: 'cors',
    body: JSON.stringify({
      id,
    }),
  }).then((response) => response.json()).then((resp) => {
    resp
      .map((u) => ({
        id: u.dreemer,
        username: u.email || u.pseudo,
      }))
      .forEach((u) => m.set(u.id, u));
    return m;
  });
}

function fetchDevices(content) {
  const id = content ? content.map((r) => (r.device)) : [];
  const m = mapWithDefault({
    nickname: 'N/A',
  });
  if (id.length === 0) {
    return Promise.resolve(m);
  }
  const headers = getHeaders(jwt());
  return fetch(`${headbandURL()}/headband/headband/details/`, {
    headers,
    method: 'POST',
    mode: 'cors',
    body: JSON.stringify({
      id,
    }),
  }).then((response) => response.json()).then((resp) => {
    resp.forEach((u) => m.set(u.id, u));
    return m;
  });
}

export function fetchRecords(
  req,
  limit,
  ordering,
  fromDate,
  toDate,
  minDuration,
  maxDuration,
  search,
) {
  const headers = getHeaders(jwt());
  const url = new URL(`${backendURL()}/record/restricted_record/`);
  const params = {
    restricted_list_user: user(),
    limit,
    ordering,
    from_date: fromDate || '',
    to_date: toDate || '',
    min_duration: minDuration || '',
    max_duration: maxDuration || '',
    search: search || '',
  };
  url.search = new URLSearchParams(params).toString();
  return fetch(req || url, {
    headers,
    method: 'GET',
    mode: 'cors',
  }).then((response) => response.json()).then((resp) => Promise.all([
    fetchUsers(resp.results),
    fetchDevices(resp.results),
  ]).then((promise) => {
    const results = resp.results ? resp.results.map((record) => ({
      ...record,
      user: promise[0].get(record.user),
      device: promise[1].get(record.device),
    })) : [];
    return {
      ...resp,
      results,
    };
  }))
    .catch((error) => ({
      err: error,
    }));
}
