import {
  APP,
} from '../constants/actionTypes';

/** Toggle the global progress indicator */
export function toggleProgressBar(dispatch, delay) {
  let executed = false;
  const id = setTimeout(() => {
    executed = true;
    dispatch({
      type: APP.SET_TIMESERIES_PROGRESS,
      value: true,
    });
  }, delay != null ? delay : 300);
  return function () {
    if (!executed) {
      clearTimeout(id);
    } else {
      dispatch({
        type: APP.SET_TIMESERIES_PROGRESS,
        value: false,
      });
    }
  };
}

/** Set global error message */
export function setErrorMessage(errorMessage) {
  return {
    type: APP.SET_TIMESERIES_ERROR_MESSAGE,
    errorMessage,
  };
}

export function catchErrorMessage(dispatch, cancel) {
  return function (e) {
    dispatch(setErrorMessage(e));
    if (cancel != null) {
      cancel();
    }
  };
}

export function clearErrorMessage() {
  return {
    type: APP.CLEAR_TIMESERIES_ERROR_MESSAGE,
  };
}