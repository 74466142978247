/* eslint-disable react/prop-types */
/* eslint-disable react/destructuring-assignment */
import React, { Component } from 'react';
import _ from 'lodash';

export default class Stimulations extends Component {
  shouldComponentUpdate(nextProps) {
    if (
      this.props.stimulations.length === 0
      && nextProps.stimulations.length === 0
    ) {
      return false;
    }
    return (
      this.props.stimulations !== nextProps.stimulations
      || this.props.range !== nextProps.range
      || this.props.xScale !== nextProps.xScale
      || this.props.size !== nextProps.size
    );
  }

  filterStimulations() {
    if (this.props.stimulations.length === 0) {
      return [];
    }
    const r = this.props.range.range();
    const start = _.sortedIndexBy(
      this.props.stimulations,
      { time: r.start },
      (s) => s.time,
    );
    const end = _.sortedIndexBy(
      this.props.stimulations,
      { time: r.end },
      (s) => s.time,
    );
    return this.props.stimulations.slice(start, end);
  }

  render() {
    const currentStimulations = this.filterStimulations();
    return _.map(currentStimulations, (s) => {
      const x = this.props.xScale(s.time);
      return (
        <line
          key={s.time}
          className="sleep-stimulation"
          strokeOpacity="0.8"
          stroke="#700000"
          strokeWidth="1"
          y1="0"
          y2={this.props.size.height}
          x1={x}
          x2={x}
          strokeDasharray={s.intensity > 0 ? '' : '5 5'}
        />
      );
    });
  }
}
