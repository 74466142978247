import React, { useState } from 'react';
import PropTypes from 'prop-types';
import styles from './Select.module.scss';
import { ReactComponent as Chevron } from '../../../assets/images/icons/chevron.svg';

function Select(props) {
  const [display, setDisplay] = useState(false);
  const {
    options,
    selected,
    setSelected,
    style,
    className,
    logo,
    disabled,
    units,
    placeholder,
  } = props;

  const optionsList = options
    .map((elem) => (
      <div
        className={styles.options}
        key={elem.key}
        onClick={() => {
          if (!disabled) {
            setSelected(elem.key);
            setDisplay(false);
          }
        }}
        onKeyPress={null}
        role="button"
        tabIndex={0}
      >
        <span className={styles.text}>{elem.name}</span>
      </div>
    ));

  return (
    <div className={`${styles.container} ${className} ${disabled ? styles.disabled : ''}`} style={style}>
      <div
        className={styles.select}
        onClick={() => {
          if (!disabled) setDisplay(!display);
        }}
        onKeyPress={null}
        role="button"
        tabIndex={0}
      >
        <div>
          {logo && <img src={logo} alt="select icon" className={styles.icon} />}
          <span className={styles.text}>
            {selected || placeholder}
            {selected && units}
          </span>
        </div>
        <Chevron
          className={`${styles.icon} ${styles.chevron} ${display ? styles.down : null}`}
          width="16px"
          height="16px"
          fill="#7A71A6"
          transform=""
        />
      </div>
      {display && (
        <div className={styles.listOptions}>
          {optionsList}
        </div>
      )}
    </div>
  );
}

Select.defaultProps = {
  options: [],
  selected: null,
  style: {},
  className: '',
  logo: null,
  disabled: false,
  units: null,
  placeholder: null,
};

Select.propTypes = {
  options: PropTypes.instanceOf(Array),
  // eslint-disable-next-line react/forbid-prop-types
  selected: PropTypes.any,
  setSelected: PropTypes.func.isRequired,
  style: PropTypes.instanceOf(Object),
  className: PropTypes.string,
  logo: PropTypes.string,
  disabled: PropTypes.bool,
  units: PropTypes.string,
  placeholder: PropTypes.string,
};

export default Select;