/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable react/no-access-state-in-setstate */
/* eslint-disable no-return-assign */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable react/prop-types */
/* eslint-disable import/no-extraneous-dependencies */
/* eslint-disable max-classes-per-file */
import React, { Component } from 'react';
import { Select } from 'antd';
import { scaleTime, scaleLinear, scalePoint } from 'd3-scale';
import { line as svgLine } from 'd3-shape';
import { extent } from 'd3-array';
import { axisBottom, axisLeft } from 'd3-axis';
import { format as numberFormat } from 'd3-format';
import { select } from 'd3-selection';
import styles from './LinePlot.module.scss';
import { transform } from './plot/utils';
import { timestampFormatter } from '../utils/timeUtils';

const { Option } = Select;

const nFormat = numberFormat('.3d');
const fFormat = numberFormat('.2f');

function yAxisFormat(v) {
  if (typeof v !== 'number') {
    return v;
  }
  return v < 1 ? fFormat(v) : nFormat(v);
}

class YAxis extends Component {
  constructor(props) {
    super(props);
    this.yAxis = axisLeft(props.yScale)
      .ticks(5)
      .tickFormat(yAxisFormat);
    this.selectedEl = null;
  }

  componentDidMount() {
    this.selectedEl = select(this.el);
    this.update();
  }

  componentDidUpdate(prevProps) {
    if (this.props.yScale !== prevProps.yScale) {
      this.update();
    }
  }

  update() {
    this.yAxis.scale(this.props.yScale);
    this.selectedEl.call(this.yAxis);
  }

  render() {
    return <g className="y-axis axis" ref={(el) => (this.el = el)} />;
  }
}

class TimeAxis extends Component {
  constructor(props) {
    super(props);
    this.xAxis = axisBottom(props.xScale)
      .tickFormat(timestampFormatter(props.timezone))
      .ticks(10);
    this.selectedEl = null;
  }

  componentDidMount() {
    this.selectedEl = select(this.el);
    this.update();
  }

  componentDidUpdate(prevProps) {
    if (this.props.xScale !== prevProps.xScale) {
      this.update();
    }
  }

  update() {
    this.xAxis.scale(this.props.xScale);
    this.selectedEl.call(this.xAxis);
  }

  render() {
    return (
      <g
        className="x-axis axis"
        transform={transform(0, this.props.size.height)}
        ref={(el) => (this.el = el)}
      />
    );
  }
}

const margin = {
  top: 10, left: 80, right: 20, bottom: 20,
};
const clickRectStyle = {
  pointerEvents: 'all',
};

export default class LinePlot extends Component {
  constructor(props) {
    super(props);
    this.state = {
      xScale: scaleTime(),
      yScale:
        props.serieType === 'discrete'
          ? scalePoint().padding(0.5)
          : scaleLinear(),
      size: { width: 0, height: 0 },
    };
    this.line = svgLine()
      .x((d) => this.state.xScale(d.time))
      .y((d) => this.state.yScale(d.value));
    if (props.defined) {
      this.line.defined(props.defined);
    }
  }

  componentDidMount() {
    this.update(true);
  }

  componentDidUpdate(prevProps) {
    if (
      this.props.serie !== prevProps.serie
      || this.props.range !== prevProps.range
    ) {
      this.update(this.props.serie !== prevProps.serie);
    }
  }

  onRectClick(e) {
    const inverted = this.state.xScale.invert(
      e.nativeEvent.offsetX - margin.left,
    );
    this.props.onRangeChange(inverted.getTime());
  }

  update(dataUpdated) {
    const width = this.container != null
      ? this.container.offsetWidth - margin.left - margin.right
      : 0;
    const height = this.container != null
      ? this.container.offsetHeight - margin.top - margin.bottom
      : 0;
    const xScale = this.state.xScale.copy();
    const yScale = this.state.yScale.copy();
    xScale.range([0, width]);
    yScale.range([height, 0]);
    xScale.domain([this.props.range.start, this.props.range.end]);
    if (dataUpdated) {
      const defined = this.props.defined != null
        ? this.props.defined
        : () => true;
      if (this.props.yScale) {
        yScale.domain(this.props.yScale);
      } else {
        yScale.domain(
          extent(this.props.serie, (d) => (defined(d) ? d.value : null)),
        );
      }
    }
    this.setState({ xScale, yScale, size: { width, height } });
  }

  render() {
    const range = this.props.range.range();
    const currentX = this.state.xScale(range.start);
    return (
      <div className="fullsize-container" ref={(e) => (this.container = e)}>
        <svg width="100%" height="100%">
          <g transform={transform(margin.left, margin.top)}>
            <YAxis yScale={this.state.yScale} />
            <TimeAxis
              xScale={this.state.xScale}
              timezone={this.props.recordInfo.timezone}
              size={this.state.size}
            />
            <path
              className="chart-line"
              stroke="#000"
              d={this.line(this.props.serie)}
            />
            <rect
              height={this.state.size.height}
              fill="deeppink"
              fillOpacity={0.4}
              y={0}
              x={currentX}
              width={Math.max(1, this.state.xScale(range.end) - currentX)}
            />
            <rect
              height={this.state.size.height}
              width={this.state.size.width}
              fill="none"
              style={clickRectStyle}
              onClick={this.onRectClick}
            />
          </g>
        </svg>
      </div>
    );
  }
}

export class LinePlotSelector extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selected: props.series[0].title,
    };
  }

  updateSelection(s) {
    this.setState({ selected: s });
  }

  render() {
    const serie = this.props.series.find((s) => s.title === this.state.selected);
    return (
      <div style={{ position: 'relative' }}>
        <Select
          className={styles.signalSelect}
          value={this.state.selected}
          onChange={this.updateSelection}
        >
          {this.props.series.map((s) => <Option key={s.title} value={s.title}>{s.title}</Option>)}
        </Select>
        {serie != null ? <LinePlot serie={serie.serie} {...this.props} /> : null}
      </div>
    );
  }
}
