/* eslint-disable no-param-reassign */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable react/prop-types */
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { updateProgress } from '../actions/timeseries';
import { removeContent } from '../actions/timeseriesContent';
import { contentTypes } from '../models/content';
import {
  stagingEEGConfig,
  stagingSleepOnsetConfig,
  stagingRelaxConfig,
} from '../models/staging';
import { getConfig } from '../models/profile';
import FFTPlot from '../components/FFTPlot';
import HypnogramPlot from '../components/HypnogramPlot';
import HorigramPlot from '../components/HorigramPlot';
import PositiongramPlot from '../components/PositiongramPlot';
import SpectrogramPlot from '../components/SpectrogramPlot';
import WindowReadPlot from '../components/WindowReadPlot';
import ScoringPlot from '../components/ScoringPlot';
import LinePlot, { LinePlotSelector } from '../components/LinePlot';
import styles from './TimeSeriesContent.module.scss';
import { ReactComponent as Close } from '../../../../assets/images/icons/close.svg';

function Element(props) {
  const {
    style,
    onClose,
    elid,
    children,
  } = props;

  console.log(props.children.type === FFTPlot);
  return (
    <div style={style} className={styles.element}>
      {props.children.type !== FFTPlot && (
        <button
          type="button"
          className={styles.close}
          onClick={() => onClose(elid)}
        >
          <Close fill="#3D366E" width="9px" height="9px" />
        </button>
      )}
      {children}
    </div>
  );
}

function getStyleForContent(el) {
  switch (el.type) {
    case contentTypes.WINDOW_READ: {
      return { height: `${el.data.size}px` };
    }
    default:
      return null;
  }
}

class TimeSeriesContent extends Component {
  constructor(props) {
    super(props);
    this.onRangeChange = this.onRangeChange.bind(this);
    this.onClose = this.onClose.bind(this);
  }

  onRangeChange(current) {
    this.props.dispatch(updateProgress(current));
  }

  onClose(id) {
    if (id) {
      this.props.dispatch(removeContent(id));
    }
  }

  renderElement(element) {
    if (element.data) {
      if (
        this.props.headbandVersion === null
        || (this.props.headbandVersion === 6
          && this.props.viewerVersion === 'external')
      ) {
        element.data = element.data.filter((elem) => {
          if (elem.signal && elem.signal.name === 'CH3 Fp1-F8') return false;
          if (elem.signal && elem.signal.name === 'CH7 Fp1-F7') return false;
          return true;
        });
      }
    }
    const { props } = this;
    switch (element.type) {
      case contentTypes.FFT:
        return <FFTPlot series={element.data} onClose={() => this.onClose(element.id)} />;
      case contentTypes.HYPNOGRAM:
        return (
          <HypnogramPlot
            recordInfo={props.recordInfo}
            range={props.range}
            serie={element.data}
            onRangeChange={this.onRangeChange}
            stimulations={props.stimulations}
          />
        );
      case contentTypes.HORIGRAM:
        return (
          <HorigramPlot
            recordInfo={props.recordInfo}
            range={props.range}
            serie={element.data}
            onRangeChange={this.onRangeChange}
          />
        );
      case contentTypes.POSITIONGRAM:
        return (
          <PositiongramPlot
            recordInfo={props.recordInfo}
            range={props.range}
            serie={element.data}
            onRangeChange={this.onRangeChange}
          />
        );
      case contentTypes.HEARTGRAM:
        return (
          <LinePlot
            recordInfo={props.recordInfo}
            range={props.range}
            serie={element.data}
            onRangeChange={this.onRangeChange}
            defined={(d) => d.value != null && d.value !== 255}
          />
        );
      case contentTypes.RESPIGRAM:
        return (
          <LinePlot
            recordInfo={props.recordInfo}
            range={props.range}
            serie={element.data}
            onRangeChange={this.onRangeChange}
          />
        );
      case contentTypes.BRAINOGRAM:
        return (
          <LinePlot
            recordInfo={props.recordInfo}
            range={props.range}
            serie={element.data}
            onRangeChange={this.onRangeChange}
            defined={(d) => d.value != null && d.value !== 255}
          />
        );
      case contentTypes.SCORING_PROGRESS:
        switch (props.staging.stages.config.type) {
          case stagingEEGConfig.type:
            return (
              <HypnogramPlot
                recordInfo={props.recordInfo}
                range={props.range}
                serie={props.staging.stages.toArray()}
                onRangeChange={this.onRangeChange}
                stimulations={props.stimulations}
              />
            );
          case stagingSleepOnsetConfig.type:
            return (
              <HorigramPlot
                recordInfo={props.recordInfo}
                range={props.range}
                serie={props.staging.stages.toArray()}
                onRangeChange={this.onRangeChange}
              />
            );
          case stagingRelaxConfig.type:
            return (
              <ScoringPlot
                serie={props.staging.stages.toArray()}
                range={props.range}
                recordInfo={props.recordInfo}
                onRangeChange={this.onRangeChange}
                stagingDomain={stagingRelaxConfig.domain}
              />
            );
          default:
            return null;
        }
      case contentTypes.SPECTROGRAM:
        return (
          <SpectrogramPlot
            range={props.range}
            serie={element.data}
            onRangeChange={this.onRangeChange}
          />
        );
      case contentTypes.WINDOW_READ:
        return (
          <WindowReadPlot
            recordInfo={props.recordInfo}
            range={props.range}
            labels={props.labels}
            data={element.data}
            configuration={getConfig(props.profile)}
            onRangeChange={this.onRangeChange}
          />
        );
      case contentTypes.IMPORTED:
        return (
          <LinePlot
            recordInfo={props.recordInfo}
            range={props.range}
            serie={element.data.serie}
            serieType={element.data.serieType}
            yScale={element.data.yScale}
            onRangeChange={this.onRangeChange}
          />
        );
      case contentTypes.SLACA:
        return (
          <LinePlotSelector
            recordInfo={props.recordInfo}
            range={props.range}
            series={element.data}
            yScale={[0, 1]}
            onRangeChange={this.onRangeChange}
          />
        );
      default:
        return null;
    }
  }

  render() {
    return (
      <div className={styles.container}>
        {this.props.content.map((e) => (
          <Element
            key={e.id}
            elid={e.id}
            onClose={this.onClose}
            style={getStyleForContent(e)}
          >
            {this.renderElement(e)}
          </Element>
        ))}
      </div>
    );
  }
}

export default connect((state) => state.timeSeries)(TimeSeriesContent);
