/* eslint-disable import/prefer-default-export */
import {
  backendURL,
  jwt,
  getHeaders,
  responseHandler,
} from '../../../constants/globals';

export function openURL(content) {
  const u = content.data_url;
  if (u == null || !u.startsWith('http')) {
    throw new Error(`Invalid url ${u}`);
  }
  window.open(u, '_self');
}

export function generateFileName(record, end) {
  const user = record.user.username.replace('@dreem.com', '');
  const dateTime = record.report.endpoints.record_start_iso.replaceAll(':', '-');
  const timezone = `[${dateTime.slice(-6).replace('-', '')}]`;
  const date = dateTime.slice(0, -6) + timezone;
  return `${user}_${date + end}`;
}

export function downloadBlob(b, filename) {
  const url = window.URL.createObjectURL(b);
  const a = document.createElement('a');
  a.style.display = 'none';
  document.body.appendChild(a);
  a.setAttribute('download', filename);
  a.setAttribute('href', url);
  a.click();
  setTimeout(() => {
    document.body.removeChild(a);
    window.URL.revokeObjectURL(url);
  }, 1000);
}

export function downloadH5(record) {
  const headers = getHeaders(jwt());
  return fetch(
    `${backendURL()}/record/h5/${record.record}/`, {
      headers,
      method: 'GET',
      mode: 'cors',
    },
  ).then(responseHandler);
}

export function downloadRAW(record) {
  const headers = getHeaders(jwt());
  return fetch(
    `${backendURL()}/record/record/${record.record}/raw/`, {
      headers,
      method: 'GET',
      mode: 'cors',
    },
  ).then((r) => r.blob()).then((content) => {
    const b = new window.Blob([content], {
      type: 'application/x-tar',
    });
    downloadBlob(b, generateFileName(record, '.tar.gz'));
  });
}

export function downloadEDF(record) {
  const headers = getHeaders(jwt());
  return fetch(
    `${backendURL()}/record/record/${record.record}/get_edf/`, {
      headers,
      method: 'GET',
      mode: 'cors',
    },
  ).then((r) => r.blob()).then((content) => {
    const b = new window.Blob([content], {
      type: 'application/x-tar',
    });
    downloadBlob(b, generateFileName(record, '.edf'));
  });
}

export function downloadHypnogram(record) {
  const headers = getHeaders(jwt());
  return fetch(
    `${backendURL()}/record/record/${record.record}/latest_hypnogram_as_txt/`, {
      headers,
      method: 'GET',
      mode: 'cors',
    },
  ).then((r) => r.blob()).then((content) => {
    if (content.detail === 'Not found.') {
      alert('Hypnogram not found !');
      return;
    }
    const b = new window.Blob([content], {
      type: 'text/plain',
    });
    downloadBlob(b, generateFileName(record, '_hypnogram.txt'));
  });
}

export function downloadReportCSV(record) {
  const headers = getHeaders(jwt());
  return fetch(
    `${backendURL()}/record/record/${record.record}/latest_report_csv/`, {
      headers,
      method: 'POST',
      mode: 'cors',
      body: JSON.stringify({
        hardware: record.device ? record.device.hardware || null : null,
      }),
    },
  ).then((r) => r.blob()).then((content) => {
    const b = new window.Blob([content], {
      type: 'text/csv',
    });
    downloadBlob(b, generateFileName(record, '_report.csv'));
  });
}