import React from 'react';
import { useParams } from 'react-router-dom';
import GlobalStyle from '../../layout/GlobalStyle';
import { user, username } from '../../../constants/globals';
import TimeSeriesApp from './TimeseriesApp';

function TimeseriesContainer() {
  const { recordID } = useParams();

  const externalFeaturesConfig = {
    labelThumbnail: false,
    positionogram: false,
    spectrogram: false,
    stimulations: true,
    recordDetailsLink: false,
    algorythmOverview: false,
    seriesFilters: false,
    windowRead: false,
    physioMetrics: false,
  };

  const userData = {
    uuid: user(),
    username: username(),
  };

  return (
    <TimeSeriesApp
      recordID={recordID}
      externalFeaturesConfig={externalFeaturesConfig}
      user={userData}
    />
  );
}

export default function Timeseries() {
  return (
    <GlobalStyle content={<TimeseriesContainer />} />
  );
}