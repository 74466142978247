/* eslint-disable no-loop-func */
export const storageKey = {
  username: 'study-manager-username',
  token: 'study-manager-token',
  user: 'study-manager-user',
  role: 'study-manager-role',
};

export const adminRoles = ['team', 'study_admin'];

export const backendURL = () => window.env.REACT_APP_API_URI;
export const headbandURL = () => window.env.REACT_APP_HEADBAND_URI;
export const loginURL = () => window.env.REACT_APP_LOGIN_URI;

export const jwt = () => window.localStorage.getItem(storageKey.token);
export const user = () => window.localStorage.getItem(storageKey.user);
export const username = () => window.localStorage.getItem(storageKey.username);

export const subjectsEndPoint = `${backendURL()}/algorythm/restricted_list/${user()}/`;
export const recordEndpoint = `${backendURL()}/algorythm/record/`;

export const getHeaders = (jwtParam) => ({
  Accept: 'application/json',
  'Content-Type': 'application/json; charset=utf-8',
  ...(jwtParam ? {
    Authorization: `Bearer ${jwtParam}`,
  } : {}),
});

export function responseHandler(resp) {
  if (!resp.ok) {
    return resp.text().then((msg) => JSON.parse(msg));
  }
  return resp.json();
}

function formatResponseString(resp) {
  if (resp.status === 401) {
    return 'Your session has expired. Please reload the page and log in again.';
  }
  if (resp.status === 403) {
    return 'You cannot access this resource';
  }
  return `URL: ${resp.url}, Status: ${resp.status}`;
}

export function textResponseHandler(resp) {
  if (!resp.ok) {
    return resp.text().then((msg) => {
      throw new Error(`${formatResponseString(resp)}, Message : ${msg}`);
    });
  }
  return resp.text();
}

export function mapWithDefault(defaultValue) {
  const m = new Map();
  return {
    get(id) {
      const v = m.get(id);
      return v != null ? v : defaultValue;
    },

    set(id, value) {
      m.set(id, value);
    },

    has(id) {
      return m.has(id);
    },

    toArray() {
      return [...m.values()];
    },
  };
}
