/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable react/prop-types */
import React, { Component } from 'react';
import {
  Form, Button, Select, Input,
} from 'antd';
import moment from 'moment';
import Dialog from '../../../../shared/dialog/Dialog';

const { Option } = Select;
const FormItem = Form.Item;

const titleStyle = {
  fontSize: '1em',
  marginBottom: '1em',
  fontWeight: 'normal',
};

const durationRE = /^(\d+)([smh])$/;

function buildDuration(input) {
  const e = durationRE.exec(input.trim());
  if (e == null) {
    return null;
  }
  return moment.duration(+e[1], e[2]);
}

function validateDurationInput(rule, value, callback) {
  if (!durationRE.test(value)) {
    callback('Duration is invalid. Must be like 30s, 21m, 1h');
  }
  callback();
}

class WindowReadFormComponent extends Component {
  handleSubmit(e) {
    e.preventDefault();
    this.props.form.validateFields((err, values) => {
      if (!err) {
        const dur = buildDuration(values.window);
        if (dur != null) {
          this.props.apply({
            signals: values.signals,
            window: dur.asMilliseconds(),
            size: values.size,
          });
        }
      }
    });
  }

  render() {
    const {
      form: { getFieldDecorator },
      signals,
    } = this.props;
    return (
      <Form onSubmit={this.handleSubmit}>
        <FormItem>
          {getFieldDecorator('signals', {
            rules: [{ required: true, message: 'Please choose signals' }],
          })(
            <Select placeholder="Signals" mode="multiple">
              {signals.map((s) => (
                <Option key={s.path}>{s.path}</Option>
              ))}
            </Select>,
          )}
        </FormItem>
        <FormItem>
          {getFieldDecorator('window', {
            rules: [
              { required: true, message: 'Please input a window' },
              { validator: validateDurationInput },
            ],
          })(<Input placeholder="Window" />)}
        </FormItem>
        <FormItem>
          {getFieldDecorator('size', {
            rules: [
              { required: true, message: 'Please choose a size for the window' },
            ],
          })(
            <Select placeholder="Size">
              <Option value={200}>200px</Option>
              <Option value={250}>250px</Option>
              <Option value={300}>300px</Option>
              <Option value={350}>350px</Option>
              <Option value={400}>400px</Option>
              <Option value={450}>450px</Option>
              <Option value={500}>500px</Option>
            </Select>,
          )}
        </FormItem>
        <FormItem>
          <Button type="primary" htmlType="submit">
            Ok
          </Button>
        </FormItem>
      </Form>
    );
  }
}

const WindowReadForm = Form.create()(WindowReadFormComponent);

export default function WindowReadDialog(props) {
  return (
    <Dialog
      title="Add window read"
      display
      handleCloseDialog={props.close}
      content={(
        <>
          <h2 style={titleStyle}>Choose your signals and your window</h2>
          <WindowReadForm {...props} />
        </>
      )}
    />
  );
}
