import React, { useRef, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { v4 as uuidv4 } from 'uuid';
import moment from 'moment';
import styles from './MinMaxDurationPicker.module.scss';
import { ReactComponent as Filter } from '../../../assets/images/icons/filter.svg';
import { ReactComponent as Check } from '../../../assets/images/icons/check.svg';
import { ReactComponent as Close } from '../../../assets/images/icons/close.svg';
import { ReactComponent as Alarm } from '../../../assets/images/icons/alarm.svg';

function MinMaxDurationPicker(props) {
  const {
    disabled,
    minDuration,
    setMinDuration,
    maxDuration,
    setMaxDuration,
  } = props;
  const componentRef = useRef();
  const inputBarRef = useRef();
  const [focus, setFocus] = useState(false);
  const [rightIcon, setRightICon] = useState(<Alarm fill="#C0BAEE" width="16px" height="16px" />);
  const formatTime = 'H:mm';

  useEffect(() => {
    function handleClick(e) {
      if (componentRef && componentRef.current) {
        const ref = componentRef.current;
        if (!ref.contains(e.target)) {
          setFocus(false);
        }
      }
    }
    document.addEventListener('click', handleClick, { capture: true });
    return () => document.removeEventListener('click', handleClick);
  }, []);

  const contentWidth = () => {
    if (inputBarRef && inputBarRef.current && inputBarRef.current.offsetWidth) {
      return `calc(${inputBarRef.current.offsetWidth}px - 32px)`;
    }
    return 'unset';
  };

  function replaceColonByH(time) {
    const arr = Array.from(time);
    const index = arr.indexOf(':');
    arr.splice(index, 1, 'h');
    return arr.join('');
  }

  function makeDurationFilters() {
    const durationFilters = [];
    for (let hour = 0; hour < 23; hour += 1) {
      for (let minute = 0; minute < 60; minute += 5) {
        durationFilters.push(moment({ hour, minute }).format(formatTime));
      }
    }
    durationFilters.splice(1, 0, moment({ hour: 0, minute: 2 }).format(formatTime));
    durationFilters.push(moment({ hour: 23, minute: 0 }).format(formatTime));
    return durationFilters;
  }

  const itemsRender = (col) => {
    const durationsFilters = col === 'min' ? makeDurationFilters() : makeDurationFilters().slice(1);
    function onRowClick(id) {
      if (col === 'min') {
        // eslint-disable-next-line max-len
        if (moment(id, formatTime).format(formatTime) === moment(minDuration, formatTime).format(formatTime)) {
          setMinDuration(null);
        } else {
          setMinDuration(moment(id, formatTime));
        }
        if (maxDuration && moment(id, formatTime) >= moment(maxDuration, formatTime)) {
          setMaxDuration(null);
        }
      }
      if (col === 'max') {
        // eslint-disable-next-line max-len
        if (moment(id, formatTime).format(formatTime) === moment(maxDuration, formatTime).format(formatTime)) {
          setMaxDuration(null);
        } else {
          setMaxDuration(moment(id, formatTime));
        }
        if (minDuration && moment(id, formatTime) <= moment(minDuration, formatTime)) {
          setMinDuration(null);
        }
      }
    }
    function selected(id) {
      if ((col === 'min' && minDuration && minDuration.format(formatTime) === moment(id, formatTime).format(formatTime))
        || (col === 'max' && maxDuration && maxDuration.format(formatTime) === moment(id, formatTime).format(formatTime))) {
        return styles.selected;
      }
      return '';
    }

    return durationsFilters.map((filter) => {
      const id = filter;
      return (
        <div
          key={uuidv4()}
          className={`${styles.item} ${selected(id)}`}
          onClick={() => onRowClick(id)}
          onKeyPress={null}
          role="button"
          tabIndex={0}
          id={id}
        >
          {moment(filter, formatTime).format('H') >= 1 ? replaceColonByH(moment(filter, formatTime).format(formatTime)) : `${moment(filter, formatTime).format('m')} mins`}
          {selected(id) ? <Check fill="#fff" width="13px" /> : null}
        </div>
      );
    });
  };

  const titleRender = () => {
    let minRender;
    let maxRender;
    if (minDuration) {
      minRender = minDuration.format('H') >= 1 ? replaceColonByH(minDuration.format(formatTime)) : `${minDuration.format('m')} mins`;
    }
    if (maxDuration) {
      maxRender = maxDuration.format('H') >= 1 ? replaceColonByH(maxDuration.format(formatTime)) : `${maxDuration.format('m')} mins`;
    }
    return (
      <>
        <span className={minDuration ? styles.date : ''}>{minDuration ? minRender : 'Min duration'}</span>
        <span className={maxDuration ? styles.date : ''}>-</span>
        <span className={maxDuration ? styles.date : ''}>{maxDuration ? maxRender : 'Max duration'}</span>
      </>
    );
  };

  const updateRightICon = (mouseIsOver) => {
    if (mouseIsOver && (minDuration || maxDuration)) {
      setRightICon(<Close fill="#fff" width="10px" height="10px" style={{ background: '#C0BAEE', borderRadius: '100px', padding: '3px' }} />);
    } else {
      setRightICon(<Alarm fill="#C0BAEE" width="16px" height="16px" />);
    }
  };

  const OnRightIconClick = () => {
    if (minDuration || maxDuration) {
      setMinDuration(null);
      setMaxDuration(null);
    }
  };

  return (
    <div className={styles.container} ref={componentRef}>
      <div
        className={`${styles.inputBar} ${focus ? styles.focus : ''} ${disabled ? styles.disabled : ''}`}
        ref={inputBarRef}
        onClick={() => !disabled && setFocus(!focus)}
        onMouseOver={() => updateRightICon(true)}
        onMouseOut={() => updateRightICon(false)}
        onFocus={() => null}
        onBlur={() => null}
        onKeyPress={null}
        role="button"
        tabIndex={0}
      >
        <Filter height="15px" width="18px" fill="#C0BAEE" />
        {titleRender()}
        <div onClick={() => OnRightIconClick()} onKeyPress={null} role="button" tabIndex={0}>
          {rightIcon}
        </div>
      </div>
      {focus && (
        <div className={styles.select} style={{ width: contentWidth() }}>
          <div className={styles.column}>
            <h3>Min duration</h3>
            <div className={styles.itemList}>
              {itemsRender('min')}
            </div>
          </div>
          <div className={styles.column}>
            <h3>Max duration</h3>
            <div className={styles.itemList}>
              {itemsRender('max')}
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

MinMaxDurationPicker.defaultProps = {
  disabled: false,
  minDuration: null,
  setMinDuration: null,
  maxDuration: null,
  setMaxDuration: null,
};

MinMaxDurationPicker.propTypes = {
  disabled: PropTypes.bool,
  minDuration: PropTypes.instanceOf(Object),
  setMinDuration: PropTypes.instanceOf(Function),
  maxDuration: PropTypes.instanceOf(Object),
  setMaxDuration: PropTypes.instanceOf(Function),
};

export default MinMaxDurationPicker;