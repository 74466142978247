import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { login } from './actions';
import styles from './Login.module.scss';
import LoginForm from './LoginForm';

export default function Login() {
  const [loading, setLoading] = useState(false);
  const [msg, setMsg] = useState('');
  const history = useHistory();

  const handleSubmit = (values) => {
    setLoading(true);
    login(values).then((e) => {
      setLoading(false);
      if (e.status) {
        history.push('/');
      } else {
        setMsg(e.msg);
      }
    });
  };

  return (
    <div className={styles.container}>
      <LoginForm
        msg={msg}
        submit={(values) => handleSubmit(values)}
        loading={loading}
      />
    </div>
  );
}