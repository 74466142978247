import _ from 'lodash';
import { TIMESERIES } from '../constants/actionTypes';
import { reloadSeries } from './timeseries';
import { getConfig, resetFiltersConfig } from '../models/profile';
import { signalsToSeries } from '../models/serie';
import selectGroups from '../models/group';

// No need to reload series on profile creation. Profiles are created from the
// currently selected one so there should be no immediate change
export function createConfig(name) {
  return {
    type: TIMESERIES.CREATE_CONFIGURATION,
    name,
  };
}

function validateConfigSignals(signalsConfig, signals) {
  return (
    signalsConfig.length > 0
    && _.every(
      signalsConfig,
      (scfg) => _.find(signals, (s) => s.path === scfg) != null,
    )
  );
}

// Selecting a new config requires reloading series due to potential changes
// in filtering configurations
export function selectConfig(name) {
  return (dispatch, getState, api) => {
    const cfg = getConfig(getState, name);
    const args = {
      selectedProfile: name,
      postFilterOptions: {
        removeAll: true,
        added: resetFiltersConfig(cfg.filtersConfig),
      },
    };
    const { signals, recordInfo: { id: recordID } } = getState().timeSeries;
    if (validateConfigSignals(cfg.signalsConfig, signals)) {
      args.requestedSeries = signalsToSeries(
        recordID,
        _.map(cfg.signalsConfig, (scfg) => _.find(signals, (s) => s.path === scfg)),
      );
      args.groups = selectGroups();
      args.groups.addGroup(args.requestedSeries);
    }
    return reloadSeries({
      dispatch,
      getState,
      api,
      args,
    });
  };
}

export function deleteConfig(name) {
  return (dispatch, getState, api) => {
    if (name === 'default') {
      return Promise.resolve();
    }
    dispatch({
      type: TIMESERIES.DELETE_CONFIGURATION,
      name,
    });
    const profile = getConfig(getState);
    return reloadSeries({
      dispatch,
      getState,
      api,
      args: {
        postFilterOptions: {
          removeAll: true,
          added: resetFiltersConfig(profile.filtersConfig),
        },
      },
    });
  };
}

export function updateScaleAndColor(scaleConfig, colorConfig) {
  return {
    type: TIMESERIES.UPDATE_CONFIGURATION,
    updated: {
      scaleConfig,
      colorConfig,
    },
  };
}

export function editScale(path, newDomain) {
  return (dispatch, getState) => {
    const cfg = getConfig(getState);
    const { signals } = getState().timeSeries;
    const scaleConfig = { ...cfg.scaleConfig };
    scaleConfig[path] = {
      ..._.find(signals, (s) => s.path === path).domain,
      ...newDomain,
    };
    dispatch({
      type: TIMESERIES.UPDATE_CONFIGURATION,
      updated: {
        scaleConfig,
      },
    });
  };
}
