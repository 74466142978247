import React, { useState } from 'react';
import styles from './Subjects.module.scss';
import GlobalStyle from '../../layout/GlobalStyle';
import List from './list/List';
import Records from './records/Records';

function SubjectsContainer() {
  const [selectedId, setSelectedId] = useState('');

  function onRowClick(id) {
    const url = new URL(window.location);
    url.searchParams.set('id', id);
    window.history.pushState({}, '', url);
    setSelectedId(id);
  }

  return (
    <div className={styles.container}>
      <List
        style={{ gridArea: 'list' }}
        selected={selectedId}
        onRowClick={onRowClick}
      />
      <Records style={{ gridArea: 'records' }} selected={selectedId} />
    </div>
  );
}

export default function Subjects() {
  return (
    <GlobalStyle content={<SubjectsContainer />} />
  );
}