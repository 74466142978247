/* eslint-disable react/prop-types */
/* eslint-disable react/destructuring-assignment */
import React, { Component, Fragment } from 'react';
import _ from 'lodash';
import { compareSeries } from './utils';

const textStyle = {
  fill: 'black',
  fontSize: '10px',
};

const dotStyle = {
  fill: 'deeppink',
};

function generateNumbers(x, y, values, spacing) {
  if (values.length === 0) {
    return [];
  }
  const res = [];
  let current = values[0];
  const sr = y.range();
  const ty = sr[1] + 25;
  for (let i = 1; i < values.length; i += 1) {
    const v = values[i];
    if (v.time - current.time > spacing) {
      const px = x(v.time);
      const py = y(v.value);
      const vd = v.value > -1 && v.value < 1 ? _.round(v.value, 4) : _.round(v.value, 2);
      res.push(
        <Fragment key={v.time}>
          <text
            style={textStyle}
            textAnchor="middle"
            transform={`rotate(-20, ${px}, ${ty})`}
            dy="-1em"
            dx=".35em"
            x={px}
            y={ty}
          >
            {vd}
          </text>
          <circle style={dotStyle} r="2" cx={px} cy={py} />
        </Fragment>,
      );
      current = v;
    }
  }
  return res;
}

export default class NumberDisplay extends Component {
  shouldComponentUpdate(nextProps) {
    return (
      !compareSeries(this.props.series, nextProps.series)
      || this.props.enabled !== nextProps.enabled
    );
  }

  render() {
    const {
      xScale, yScales, series, enabled, window: w,
    } = this.props;
    const spacing = w / 30;
    return _.map(enabled, (e) => {
      const i = _.findIndex(series, (s) => s.signal.path === e);
      if (i < 0) {
        return null;
      }
      return (
        <g key={e}>
          {generateNumbers(xScale, yScales[i], series[i].values, spacing)}
        </g>
      );
    });
  }
}
