/* eslint-disable react/prop-types */
import React, { useState } from 'react';
import _ from 'lodash';
import styles from './ReferenceLinesDialog.module.scss';
import Dialog from '../../../../shared/dialog/Dialog';
import Button from '../../../../shared/button/Button';
import MultipleSelect from '../../../../shared/multipleSelect/MultipleSelect';

function newReferenceLine(value, signals) {
  return { value, signals };
}

export default function ReferenceLinesDialog(props) {
  const {
    applyReferenceLines,
    close,
    signals,
  } = props;
  const [value, setValue] = useState('');
  const [signalsSelected, setSignalsSelected] = useState([]);

  function clearLines() {
    applyReferenceLines([]);
  }

  function apply() {
    const references = _(value)
      .split(';')
      .map((v) => +v)
      .filter((v) => !_.isNaN(v))
      .map((v) => newReferenceLine(v, signalsSelected))
      .value();
    if (references.length > 0 && signalsSelected.length > 0) {
      applyReferenceLines(references);
    }
  }

  return (
    <Dialog
      display
      title="Set reference lines"
      handleCloseDialog={close}
      content={(
        <div className={styles.container}>
          <h5>Input your reference lines</h5>
          <input
            type="text"
            placeholder="50;-50"
            value={value}
            onChange={(e) => setValue(e.target.value)}
          />
          <MultipleSelect
            selected={signalsSelected}
            onSelect={setSignalsSelected}
            placeholder="Signals"
            options={signals}
          />
        </div>
      )}
      footer={[
        <Button
          type="button"
          onClick={clearLines}
          text="Clear"
          className={styles.clear}
        />,
        <Button
          type="button"
          onClick={apply}
          text="Ok"
          className={styles.apply}
        />,
      ]}
    />
  );
}