import React, { useRef, useState, useEffect, Fragment } from 'react';
import moment from 'moment-timezone';
import html2canvas from 'html2canvas';
import jsPDF from 'jspdf';
import { useParams } from 'react-router-dom';
import { getRecord, getHypnogram, getRespirogram } from './actions';

const styles = {
  container: {
    backgroundColor: '#FDFDFF',
    width: '210mm',
    height: '296mm',
    margin: 'auto',
    boxShadow: 'rgba(100, 100, 111, 0.2) 0px 7px 29px 0px',
    color: '#7A71A6',
    fontSize: '12px',
    lineHeight: '18px',
    fontWeight: '400',
    position: 'relative',
  },
  header: {
    padding: '10px 24px',
    backgroundColor: '#F7F7FC',
    display: 'flex',
    justifyContent: 'space-between',
    div: {
      display: 'flex',
      flexDirection: 'column',
    },
  },
  bloc: {
    padding: '10px 24px 0',
  },
  title: {
    display: 'flex',
    alignItems: 'center',
    gap: '12px',
    paddingBottom: '10px',
    text: {
      fontSize: '16px',
      color: '#3D366E',
      fontWeight: 'bold',
      whiteSpace: 'nowrap',
    },
    lineBar: {
      border: '1px solid #D8D5F1',
      width: '100%',
      height: '0',
    },
  },
  patientInformation: {
    display: 'grid',
    gridTemplateColumns: 'auto auto',
    gap: '3px',
  },
  recordInformation: {
    display: 'grid',
    gridTemplateColumns: '48% 52%',
    gap: '5%',
    stats: {
      display: 'grid',
      gap: '3px',
      padding: '16px',
      backgroundColor: '#F7F7FC',
      borderRadius: '8px',
    },
    qualityCheck: {
      marginTop: '24px',
      display: 'none', // flex
      alignItems: 'center',
      gap: '20px',
      indicatorLeft: {
        color: '#000',
        padding: '8px 14px',
        borderRadius: '22px 0 0 22px',
      },
      indicatorRight: {
        color: '#000',
        padding: '8px 14px',
        borderRadius: '0 22px 22px 0',
      },
    },
  },
  hypnogram: {
    stagesName: {
      fontSize: '11px',
      fontWeight: '700',
      fontFamily: 'Galano, Helvetica',
    },
    time: {
      fontSize: '11px',
      fontWeight: '400',
      fontFamily: 'Galano, Helvetica',
    },
  },
  respirogram: {
    stagesName: {
      fontSize: '11px',
      fontWeight: '400',
      textAlign: 'right',
      width: '100px',
      fontFamily: 'Galano, Helvetica',
    },
  },
  smLegends: {
    display: 'grid',
    gridTemplateColumns: 'auto auto',
    gap: '10px',
    titleCol: {
      color: '#3D366E',
      fontWeight: 'bold',
      fontSize: '11px',
      marginBottom: '4px',
    },
    item: {
      display: 'grid',
      gridTemplateColumns: '75px auto',
      marginBottom: '8px',
      value: {
        color: '#FF0064',
        fontWeight: '700',
        fontSize: '11px',
        marginRight: '8px',
      },
    },
  },
  footer: {
    backgroundColor: '#F7F7FC',
    padding: '8px 24px',
    fontWeight: '700',
    position: 'absolute',
    bottom: '0',
    left: '0',
    right: '0',
  },
};

export default function Report() {
  const ref = useRef();
  const params = useParams();
  const [record, setRecord] = useState({});
  const [hypnoStage, setHypnoStage] = useState(null);
  const [respirogramStage, setRespirogramStage] = useState(null);

  useEffect(() => getRecord(params.recordID).then((r) => setRecord(r || {})), [params]);
  useEffect(() => getHypnogram(params.recordID).then((h) => setHypnoStage(h.stages || [])), [params]);
  useEffect(() => getRespirogram(params.recordID).then(
    (r) => setRespirogramStage(r ? r.respiratory_rate : []),
  ), [params]);

  // useEffect(() => {
  //   if (record.record && hypnoStage && respirogramStage && ref.current) {
  //     html2pdf().from(ref.current).set(opt).save();
  //   }
  // }, [record, hypnoStage, respirogramStage]);

  const n1value = (record && record.endpoints && Math.round(record.endpoints.n1_percentage));
  const n1progress = n1value || null;

  const n2value = (record && record.endpoints && Math.round(record.endpoints.n2_percentage));
  const n2progress = n1value + n2value || null;

  const n3value = (record && record.endpoints && Math.round(record.endpoints.n3_percentage));
  const n3progress = n1value + n2value + n3value || null;

  const remvalue = 100 - n1value - n2value - n3value;
  const remprogress = (remvalue && 100) || null;
  const gradient = `conic-gradient(
    from 0deg,
    ${n1value ? `#C0BAEE 0% ${n1progress}%,` : ''}
    ${n2value ? `#777EFF ${n1progress + 5}% ${n2progress}%,` : ''}
    ${n3value ? `#4F3DC8 ${n2progress + 5}% ${n3progress}%,` : ''}
    ${remvalue ? `#4DBADA ${n3progress + 5}% 95%,` : ''}
    #C0BAEE 100%)`;

  const positions = [
    [95, 0],
    [130, 5],
    [160, 20],
    [180, 50],
    [185, 85],
    [180, 120],
    [160, 160],
    [130, 180],
    [95, 190],
    [65, 185],
    [35, 175],
    [20, 155],
    [0, 120],
    [0, 80],
    [10, 40],
    [45, 10],
    [95, 0],
  ];

  const getPosition = (v) => positions[Math.floor((v * positions.length) / 100)];

  const position = (title, pos, value) => {
    if (!pos || !value) return null;

    const position = getPosition(pos - Math.round(value / 3)) || [0,0]

    return (
      <foreignObject
        x={position[0]}
        y={position[1]}
        width="50"
        height="50"
      >
        <div
          style={{
            display: 'grid',
            fontFamily: 'Galano, Helvetica',
            fontSize: '12px',
            fontWeight: 400,
            lineHeight: '18px',
            color: '#7a71a6',
          }}
        >
          <span>{title}</span>
          <span>{`${value}%`}</span>
        </div>
      </foreignObject>
    );
  };

  // const dateOfBirth = (u) => {
  //   const day = u.day_of_birth < 10 ? `0${u.day_of_birth}` : u.day_of_birth;
  //   const month = u.month_of_birth < 10 ? `0${u.month_of_birth}` : u.month_of_birth;
  //   const year = u.year_of_birth;
  //   return `${year}/${month}/${day}`;
  // };

  const recordStartTimeIso = (startTime) => {
    const dateRender = moment(startTime.slice(0, -6)).format('DD MMMM YYYY HH:mm:ss');
    if (startTime.includes('+') || startTime.includes('-')) {
      return `${dateRender} ${startTime.slice(-6).replace(':', '')}`;
    }
    return dateRender;
  };

  const recordStopTimeIso = (startTime, duration) => {
    const dateRender = moment(
      new Date(startTime.slice(0, -6)).setTime(
        new Date(startTime.slice(0, -6)).getTime() + duration * 1000,
      ),
    ).format('DD MMMM YYYY HH:mm:ss');
    if (startTime.slice(-6)) {
      return `${dateRender} ${startTime.slice(-6).replace(':', '')}`;
    }
    return dateRender;
  };

  const hypnogramRender = () => {
    if (!hypnoStage || !hypnoStage.length) {
      return (
        <p style={{ color: 'orange', fontFamily: 'Galano, Helvetica' }}>No hypnogram available</p>
      );
    }
    const colors = {
      wake: '#FFB0CD',
      rem: '#4DBADA',
      n1: '#C0BAEE',
      n2: '#777EFF',
      n3: '#4F3DC8',
    };
    const positionsHypno = {
      wake: 10,
      rem: 40,
      n1: 70,
      n2: 100,
      n3: 130,
    };
    const stagesStartInPixels = 50;
    const stagesLengthInPixels = 745;
    const startTime = moment(hypnoStage[0].start);
    const stopTime = moment(hypnoStage[hypnoStage.length - 1].start);
    const durationInHours = moment.duration(stopTime.diff(startTime)).asHours();
    const timesMapped = Array(Math.floor(durationInHours)).fill(0).map((t, i) => (
      <text key={i} x={(stagesLengthInPixels / Math.round(durationInHours)) * i + 50 - 15} y="142" fill="#AAAAAA" style={styles.hypnogram.time}>
        {moment(startTime).add(i, 'hour').format('HH:mm')}
      </text>
    ));
    const stagesMapped = hypnoStage.map((s, i) => {
      let color;
      let posY;
      const posX = (i * (stagesLengthInPixels - 50)) / hypnoStage.length + 50;
      switch (s.stage) {
        case 'WAKE':
          color = colors.wake;
          posY = positionsHypno.wake;
          break;
        case 'N1':
          color = colors.n1;
          posY = positionsHypno.n1;
          break;
        case 'N2':
          color = colors.n2;
          posY = positionsHypno.n2;
          break;
        case 'DEEP':
          color = colors.n3;
          posY = positionsHypno.n3;
          break;
        default:
          color = colors.rem;
          posY = positionsHypno.rem;
          break;
      }
      const previousStage = hypnoStage[i - 1];
      let separator;
      if (previousStage && previousStage.stage !== s.stage) {
        let prevPos;
        switch (previousStage.stage) {
          case 'WAKE':
            prevPos = positionsHypno.wake;
            break;
          case 'N1':
            prevPos = positionsHypno.n1;
            break;
          case 'N2':
            prevPos = positionsHypno.n2;
            break;
          case 'DEEP':
            prevPos = positionsHypno.n3;
            break;
          default:
            prevPos = positionsHypno.rem;
            break;
        }
        let separatorPos = prevPos;
        let startPos = prevPos;
        let endPos = posY;
        if (posY < prevPos) {
          separatorPos = posY;
          startPos = posY;
          endPos = prevPos;
        }
        separator = (
          <g fill="none" stroke="#BABABA" strokeWidth="1">
            <path strokeDasharray="2,2" d={`M${posX} ${separatorPos + 2} l0 ${endPos - startPos - 4}`} />
          </g>
        );
      }
      return (
        <Fragment key={i}>
          {separator}
          <circle cx={posX} cy={posY} r="2" fill={color} />
        </Fragment>
      );
    });
    return (
      <svg height="150" width="745">
        {/* Left column */}
        <text x="0" y={positionsHypno.wake} fill={colors.wake} style={styles.hypnogram.stagesName}>Wake</text>
        <text x="0" y={positionsHypno.rem} fill={colors.rem} style={styles.hypnogram.stagesName}>Rem</text>
        <text x="0" y={positionsHypno.n1} fill={colors.n1} style={styles.hypnogram.stagesName}>N1</text>
        <text x="0" y={positionsHypno.n2} fill={colors.n2} style={styles.hypnogram.stagesName}>N2</text>
        <text x="0" y={positionsHypno.n3} fill={colors.n3} style={styles.hypnogram.stagesName}>N3</text>
        {/* Time bar */}
        <line x1={stagesStartInPixels} y1="132" x2={stagesLengthInPixels} y2="132" style={{ stroke: '#BABABA', strokeWidth: 0.5 }} />
        {timesMapped}
        <text x={stagesLengthInPixels - 32} y="142" fill="#AAAAAA" style={styles.hypnogram.time}>
          {stopTime.format('HH:mm')}
        </text>
        {/* stages */}
        {stagesMapped}
      </svg>
    );
  };

  const respirogramRender = () => {
    if (!respirogramStage || !respirogramStage.length) {
      return (
        <p style={{ color: 'orange' }}>No respirogram available</p>
      );
    }
    const stagesStartInPixels = 50;
    const stagesLengthInPixels = 745;
    const startTime = moment(respirogramStage[0].start);
    const stopTime = moment(respirogramStage[respirogramStage.length - 1].start);
    const durationInHours = moment.duration(stopTime.diff(startTime)).asHours();
    const timesMapped = Array(Math.floor(durationInHours)).fill(0).map((t, i) => (
      <Fragment key={i}>
        <g fill="none" stroke="#BABABA" strokeWidth="0.5">
          <path
            strokeDasharray="5,5"
            d={`M ${(stagesLengthInPixels / Math.round(durationInHours)) * i + 50},0 V 136`}
          />
        </g>
        <text x={(stagesLengthInPixels / Math.round(durationInHours)) * i + 50 - 15} y="150" fill="#AAAAAA" style={styles.hypnogram.time}>
          {moment(startTime).add(i, 'hour').format('HH:mm')}
        </text>
      </Fragment>
    ));
    const levels = [...new Set(respirogramStage.map((r) => r.respiratory_rate).sort())];
    const levelsMapped = levels.map((l, i) => (
      <Fragment key={i}>
        <text
          x="25"
          y={(Math.max(...levels) + 1 - l) * (136 / levels.length)}
          fill="#3D366E"
          style={styles.respirogram.stagesName}
        >
          {l}
        </text>
        <line
          x1={45}
          y1={(Math.max(...levels) - l) * (136 / levels.length) - 4}
          x2={50}
          y2={(Math.max(...levels) - l) * (136 / levels.length) - 4}
          style={{ stroke: '#DBDBDC', strokeWidth: 0.5 }}
        />
      </Fragment>
    ));
    const stagesMapped = respirogramStage.map((r, i) => {
      const posX = (i * (stagesLengthInPixels - 50)) / respirogramStage.length + 50;
      const posY = (Math.max(...levels) + 1 - r.respiratory_rate) * (136 / levels.length) - 4;
      const previousStage = respirogramStage[i - 1];
      let previousStagePosX = posX;
      let previousStagePosY = posY;
      if (previousStage) {
        previousStagePosX = (i * (stagesLengthInPixels - 50)) / respirogramStage.length + 50;
        previousStagePosY = (Math.max(...levels) + 1
          - previousStage.respiratory_rate) * (136 / levels.length) - 4;
      }
      return (
        <Fragment key={i}>
          <circle cx={posX + 0.5} cy={posY} r="1" fill="#7A71A6" />
          <line
            x1={posX}
            y1={posY}
            x2={previousStagePosX}
            y2={previousStagePosY}
            style={{ stroke: '#7A71A6', strokeWidth: 1 }}
          />
        </Fragment>
      );
    });
    return (
      <svg height="150" width="745">
        {/* Left column */}
        {levelsMapped}
        <line
          x1={50}
          y1="0"
          x2={50}
          y2="132"
          style={{ stroke: '#FF5151', strokeWidth: 0.5 }}
        />
        <line
          x1={50}
          y1="132"
          x2={50}
          y2="136"
          style={{ stroke: '#BABABA', strokeWidth: 0.5 }}
        />
        {/* Time bar */}
        <line
          x1={stagesStartInPixels}
          y1="132"
          x2={stagesLengthInPixels}
          y2="132"
          style={{ stroke: '#BABABA', strokeWidth: 0.5 }}
        />
        {timesMapped}
        <g fill="none" stroke="#BABABA" strokeWidth="0.5">
          <path
            strokeDasharray="5,5"
            d={`M ${stagesLengthInPixels - 16},0 V 136`}
          />
        </g>
        <text x={stagesLengthInPixels - 32} y="150" fill="#AAAAAA" style={styles.hypnogram.time}>
          {stopTime.format('HH:mm')}
        </text>
        {/* stages */}
        {stagesMapped}
      </svg>
    );
  };

  const goodQuality = () => {
    if (
      record
      && record.endpoints
      && record.endpoints.proportion_off_head
      && record.endpoints.proportion_scorable) {
      const offHead = Math.round(Number(record.endpoints.proportion_off_head) * 100);
      const scorability = Math.round(Number(record.endpoints.proportion_scorable) * 100);
      if (offHead < 10 || scorability > 85) {
        return true;
      }
    }
    return false;
  };

  const header = (
    <div style={styles.header}>
      <div style={styles.header.div}>
        <span style={{ fontWeight: '700' }}>Beacon Record Report</span>
        <span style={{ fontWeight: '700' }}>
          Reference:&nbsp;
          <span style={{ fontWeight: '400' }}>{record.record}</span>
        </span>
      </div>
      {/* <div>
        <span style={{ fontWeight: '700' }}>
          Study name:&nbsp;
          <span style={{ fontWeight: '400' }}>XXX000</span>
        </span>
      </div> */}
    </div>
  );

  const patientInformation = (
    <div style={styles.bloc}>
      <div style={styles.title}>
        <span style={styles.title.text}>Patient information</span>
        <div style={styles.title.lineBar} />
      </div>
      <div style={styles.patientInformation}>
        <span style={{ fontWeight: '700' }}>
          Patient ID:&nbsp;
          <span style={{ fontWeight: '400' }}>{(record && record.user && (record.user.email || record.user.pseudo)) || 'Unknown'}</span>
        </span>
        {/* <span style={{ fontWeight: '700' }}>
          Date of birth:&nbsp;
          <span style={{ fontWeight: '400' }}>
            {(record && record.user && dateOfBirth(record.user)) || 'Unknown'}
          </span>
        </span> */}
        {/* <span style={{ fontWeight: '700' }}>
          Hospital number:&nbsp;
          <span style={{ fontWeight: '400' }}>STUDYID_SITEID_SUBJECTID@dreem.com</span>
        </span>
        <span style={{ fontWeight: '700' }}>
          Physician:&nbsp;
          <span style={{ fontWeight: '400' }}>Unknown</span>
        </span>
        <span style={{ fontWeight: '700' }}>
          SS number:&nbsp;
          <span style={{ fontWeight: '400' }}>STUDYID_SITEID_SUBJECTID@dreem.com</span>
        </span>
        <span style={{ fontWeight: '700' }}>
          Hospital name:&nbsp;
          <span style={{ fontWeight: '400' }}>STUDYID_SITEID@dreem.com</span>
        </span> */}
      </div>
    </div>
  );

  const recordInformation = (
    <div style={styles.bloc}>
      <div style={styles.title}>
        <span style={styles.title.text}>Record information</span>
        <div style={styles.title.lineBar} />
      </div>
      <div style={styles.recordInformation}>
        <div>
          <div style={styles.recordInformation.stats}>
            <span style={{ fontWeight: '700' }}>
              Record ID:&nbsp;
              <span style={{ fontWeight: '400' }}>{(record && record.record) || 'Unknown'}</span>
            </span>
            <span style={{ fontWeight: '700' }}>
              User ID:&nbsp;
              <span style={{ fontWeight: '400' }}>{(record && record.user && record.user.dreemer) || 'Unknown'}</span>
            </span>
            <span style={{ fontWeight: '700' }}>
              Headband ID:&nbsp;
              <span style={{ fontWeight: '400' }}>{(record && record.device && record.device.id) || 'Unknown'}</span>
            </span>
            <span style={{ fontWeight: '700' }}>
              Record start time:&nbsp;
              <span style={{ fontWeight: '400' }}>{(record && record.endpoints && recordStartTimeIso(record.endpoints.record_start_iso)) || 'Unknown'}</span>
            </span>
            <span style={{ fontWeight: '700' }}>
              Record stop time:&nbsp;
              <span style={{ fontWeight: '400' }}>{(record && record.endpoints && recordStopTimeIso(record.endpoints.record_start_iso, record.endpoints.record_duration)) || 'Unknown'}</span>
            </span>
          </div>
          <div style={styles.recordInformation.qualityCheck}>
            <span style={{ fontWeight: '700' }}>
              Record Quality check
            </span>
            <div>
              <span style={{ ...styles.recordInformation.qualityCheck.indicatorLeft, background: goodQuality() ? '#3DF0CA' : '#F7F7FC' }}>Accept</span>
              <span style={{ ...styles.recordInformation.qualityCheck.indicatorRight, background: goodQuality() ? '#F7F7FC' : '#FF5151' }}>Reject</span>
            </div>
          </div>
        </div>
        <div style={{ display: 'flex' }}>
          <span style={{ fontWeight: '700' }}>Sleep summary:&nbsp;</span>
          <div>
            <svg width="225" height="225">
              <path
                d="M200 100C200 132.228 176.646 158.469 152.973 176.757C136.078 189.808 123 200 100 200C60.9038 200 31.578 177.023 15.1351 144.324C8.35032 130.832 0 116.132 0 100C0 68.5136 17.2546 43.1951 40 24.8649C57.151 11.0431 76.2579 0 100 0C130.477 0 152.469 18.4987 170.811 40C185.707 57.4621 200 75.2484 200 100Z"
                fill="#F7F7FC"
                transform="translate(5, 10)"
              />
              <foreignObject x="30" y="35" width="200" height="200">
                <div
                  style={{
                    width: '150px',
                    height: '150px',
                    borderRadius: '50%',
                    backgroundImage: gradient,
                  }}
                />
              </foreignObject>
              <circle cx="105" cy="110" r="60" fill="#F7F7FC" />
              {position('N1', n1progress, n1value)}
              {position('N2', n2progress, n2value)}
              {position('N3', n3progress, n3value)}
              {position('REM', remprogress, remvalue)}
              <foreignObject x="55" y="90" width="100" height="50">
                <div style={{
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center',
                  color: '#3D366E',
                }}
                >
                  <span style={{ fontWeight: '600', fontSize: '13px', fontFamily: 'Galano, Helvetica' }}>TST</span>
                  <span style={{ fontWeight: 'bold', fontSize: '22px', fontFamily: 'Galano, Helvetica' }}>
                    {(record && record.endpoints && record.endpoints.tst) || 'XX'}
                    min
                  </span>
                </div>
              </foreignObject>
            </svg>
          </div>
        </div>
      </div>
    </div>
  );

  const hypnogram = (
    <div style={{ ...styles.bloc, paddingTop: 0 }}>
      <div style={styles.title}>
        <span style={styles.title.text}>Hypnogram</span>
        <div style={styles.title.lineBar} />
      </div>
      <div style={{ display: 'flex', flexDirection: 'column' }}>
        <span>Sleep stages</span>
        {hypnogramRender()}
      </div>
    </div>
  );

  const respiration = (
    <div style={styles.bloc}>
      <div style={styles.title}>
        <span style={styles.title.text}>Respiration in bpm</span>
        <div style={styles.title.lineBar} />
      </div>
      {respirogramRender()}
    </div>
  );

  const smLegends = (
    <div style={styles.bloc}>
      <div style={styles.title}>
        <span style={styles.title.text}>Sleep Metrics Legend</span>
        <div style={styles.title.lineBar} />
      </div>
      <div style={styles.smLegends}>
        <div>
          <span style={styles.smLegends.titleCol}>Core Metrics</span>
          <div style={styles.smLegends.item}>
            <span style={styles.smLegends.item.value}>
              {(record && record.endpoints && record.endpoints.tst) || 'XX'}
              &nbsp;min
            </span>
            <span style={{ fontWeight: '700' }}>
              TST:&nbsp;
              <span style={{ fontWeight: '400' }}>Amount of sleep from &apos;Lights out&apos; to &apos;Lights on&apos; (total sleep time)</span>
            </span>
          </div>
          <div style={styles.smLegends.item}>
            <span style={styles.smLegends.item.value}>
              {(record && record.endpoints && record.endpoints.waso) || 'XX'}
              &nbsp;min
            </span>
            <span style={{ fontWeight: '700' }}>
              WASO:&nbsp;
              <span style={{ fontWeight: '400' }}>Wakefulness between SO and last epoch of sleep (wake after sleep onset)</span>
            </span>
          </div>
          <div style={styles.smLegends.item}>
            <span style={styles.smLegends.item.value}>
              {(record && record.endpoints && record.endpoints.n1_duration) || 'XX'}
              &nbsp;min
            </span>
            <span style={{ fontWeight: '700' }}>
              N1:&nbsp;
              <span style={{ fontWeight: '400' }}>Minutes of N1 sleep</span>
            </span>
          </div>
          <div style={styles.smLegends.item}>
            <span style={styles.smLegends.item.value}>
              {(record && record.endpoints && record.endpoints.n2_duration) || 'XX'}
              &nbsp;min
            </span>
            <span style={{ fontWeight: '700' }}>
              N2:&nbsp;
              <span style={{ fontWeight: '400' }}>N2: Minutes of N2 sleep</span>
            </span>
          </div>
          <div style={styles.smLegends.item}>
            <span style={styles.smLegends.item.value}>
              {(record && record.endpoints && record.endpoints.n3_duration) || 'XX'}
              &nbsp;min
            </span>
            <span style={{ fontWeight: '700' }}>
              N3:&nbsp;
              <span style={{ fontWeight: '400' }}>N3: Minutes of N3 sleep</span>
            </span>
          </div>
          <div style={styles.smLegends.item}>
            <span style={styles.smLegends.item.value}>
              {(record && record.endpoints && record.endpoints.rem_duration) || 'XX'}
              &nbsp;min
            </span>
            <span style={{ fontWeight: '700' }}>
              REM:&nbsp;
              <span style={{ fontWeight: '400' }}>Minutes of REM sleep</span>
            </span>
          </div>
        </div>
        <div>
          <span style={styles.smLegends.titleCol}>Derived Metrics</span>
          <div style={styles.smLegends.item}>
            <span style={styles.smLegends.item.value}>
              {(record && record.endpoints && Math.round(record.endpoints.sleep_efficiency)) || 'XX'}
              %
            </span>
            <span style={{ fontWeight: '700' }}>
              SE:&nbsp;
              <span style={{ fontWeight: '400' }}>Portion of the TRT spent asleep (sleep efficiency)</span>
            </span>
          </div>
          <div style={styles.smLegends.item}>
            <span style={styles.smLegends.item.value}>
              {(record && record.endpoints && record.endpoints.sol) || 'XX'}
              &nbsp;min
            </span>
            <span style={{ fontWeight: '700' }}>
              SOL:&nbsp;
              <span style={{ fontWeight: '400' }}>Time from &apos;Lights out&apos; to sleep onset (first of any epoch of sleep) (sleep onset latency)</span>
            </span>
          </div>
          <div style={styles.smLegends.item}>
            <span style={styles.smLegends.item.value}>
              {(record && record.endpoints && record.endpoints.nr_of_shifts) || 'Unknown'}
            </span>
            <span style={{ fontWeight: '700' }}>
              Nbr sleep stage shifts:&nbsp;
              <span style={{ fontWeight: '400' }}>number of sleep stage shift from SO to last epoch of sleep</span>
            </span>
          </div>
          <div style={styles.smLegends.item}>
            <span style={styles.smLegends.item.value}>
              {(record && record.endpoints && record.endpoints.last_sleep) || 'Unknown'}
            </span>
            <span style={{ fontWeight: '700' }}>
              FRT:&nbsp;
              <span style={{ fontWeight: '400' }}>Time of last of any epoch of sleep (final rise time)</span>
            </span>
          </div>
          <div style={styles.smLegends.item}>
            <span style={styles.smLegends.item.value}>
              {(record && record.endpoints && record.endpoints.lights_on.slice(-8)) || 'HH-M-s'}
            </span>
            <span style={{ fontWeight: '700' }}>
              Lights On:&nbsp;
              <span style={{ fontWeight: '400' }}>Time when record stops</span>
            </span>
          </div>
          <div style={styles.smLegends.item}>
            <span style={styles.smLegends.item.value}>
              {(record && record.endpoints && record.endpoints.average_respiration_rate && `${Number(record.endpoints.average_respiration_rate).toFixed(1)} bpm`) || 'Unknown'}
            </span>
            <span style={{ fontWeight: '700' }}>
              Avg RESPI:&nbsp;
              <span style={{ fontWeight: '400' }}>Average respiration rate over the whole recording</span>
            </span>
          </div>
        </div>
      </div>
    </div>
  );

  const footer = (
    <span style={styles.footer}>
      Report&nbsp;created&nbsp;at&nbsp;:&nbsp;
      <span style={{ fontWeight: '400' }}>{(record && moment(record.created).format('DD MMMM YYYY HH:mm:ss')) || 'Unknown'}</span>
    </span>
  );

  return (
    <div style={{ padding: '2rem', background: '#505050' }}>
      <div ref={ref} style={styles.container} id="report">
        {header}
        {patientInformation}
        {recordInformation}
        {hypnogram}
        {respiration}
        {smLegends}
        {footer}
      </div>
      <button
        type="button"
        onClick={() => {
          const w = document.getElementById('report').offsetWidth;
          const h = document.getElementById('report').offsetHeight;
          html2canvas(document.getElementById('report'), { dpi: 300, scale: 3 }).then((c) => {
            const img = c.toDataURL('image/jpeg', 1);
            // eslint-disable-next-line new-cap
            const doc = new jsPDF('P', 'px', [w, h]);
            doc.addImage(img, 'JPEG', 0, 0, w, h);
            doc.save(`${record.record}.pdf`);
          });
        }}
      >
        Click here to download PDF
      </button>
    </div>
  );
}
