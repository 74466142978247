import React from 'react';
import PropTypes from 'prop-types';
import styles from './Row.module.scss';
import { ReactComponent as Chevron } from '../../../../assets/images/icons/chevron.svg';

function Row(props) {
  const {
    row,
    selected,
    onRowClick,
  } = props;

  const selectedStyle = selected ? ` ${styles.selected}` : '';

  return (
    <div
      className={styles.container + selectedStyle}
      onClick={() => onRowClick(row.id)}
      onKeyPress={null}
      role="button"
      tabIndex={0}
    >
      <span className={styles.email}>{row.email ? row.email : row.pseudo}</span>
      <div className={styles.chevronContainer}>
        <Chevron fill={selected ? '#fff' : '#C0BAEE'} width="25px" transform="rotate(180)" />
      </div>
    </div>
  );
}

Row.defaultProps = {
  selected: {},
  onRowClick: null,
};

Row.propTypes = {
  row: PropTypes.instanceOf(Object).isRequired,
  selected: PropTypes.bool,
  onRowClick: PropTypes.instanceOf(Function),
};

export default Row;