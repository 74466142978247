import {
  TIMESERIES,
} from '../constants/actionTypes';
import {
  authFetch,
  fetchUsernames,
} from './utils';
import {
  updateProgress,
  timeseriesQueryParams,
} from './timeseries';
import labels, {
  lt as labelTypes,
} from '../models/label';
import {
  ensureDomain,
} from '../models/signal';
import {
  setErrorMessage,
  catchErrorMessage,
  toggleProgressBar,
} from './app';
import {
  generateThumbnail,
} from './exportPlot';

/** Sets the user for a new label */
function signLabel(recordID, getState, label) {
  return {
    ...label,
    record: recordID,
    user: getState().user.id,
    thumbnail: undefined,
  };
}

/** Creates the added label on the server */
function postNewLabel(dispatch, getState, api, l) {
  return authFetch(`${api.label}/`, getState, {
    method: 'POST',
    body: JSON.stringify(l),
  })
    .then((addedLabel) => {
      const currentUser = getState().user;
      dispatch({
        type: TIMESERIES.ADD_EDIT_LABEL,
        label: {
          ...addedLabel,
          userInfo: {
            username: currentUser.username,
            id: currentUser.id,
          },
        },
      });
      dispatch({
        type: TIMESERIES.SET_SELECTION,
        selection: {},
      });
    })
    .catch(catchErrorMessage(dispatch));
}

const labelsQueryParams = [{
  filter: 'labels_signals',
  property: 'signals',
},
{
  filter: 'labels_types',
  property: 'types',
},
{
  filter: 'labels_users',
  property: 'users',
},
{
  filter: 'labels_values',
  property: 'values',
},
];

function writeLabelQuery(filters) {
  labelsQueryParams.forEach((p) => {
    timeseriesQueryParams.updateQuery(
      p.filter,
      filters != null ? filters[p.property] : null,
    );
  });
  timeseriesQueryParams.writeHistory();
}

const labelLimit = 15000;

/**
 * Fetches all labels for display.
 * A hard limit is set to 15k labels since handling pagination
 * for labels would have been a little too complex
 */
export function fetchLabels(recordID, filters) {
  return (dispatch, getState, api) => {
    let la;
    return authFetch(`${api.label}/search/`, getState, {
      method: 'post',
      body: JSON.stringify({
        records: [recordID],
        signals: filters.signals,
        types: filters.types,
        users: filters.users,
        values: filters.values,
        properties: filters.properties,
        limit: labelLimit,
      }),
    })
      .then((resp) => {
        writeLabelQuery(filters);
        la = resp;
        if (la.length >= labelLimit) {
          console.warn(
            'Labels count matches the hard limit, some of the labels may not have been retrieved',
          );
        }
        return fetchUsernames(getState, api, resp, (r) => r.user);
      })
      .then((users) => {
        la.forEach((l) => {
          // eslint-disable-next-line no-param-reassign
          l.userInfo = users.get(l.user);
        });
        dispatch({
          type: TIMESERIES.SET_LABEL,
          labels: labels(la, filters),
        });
      })
      .catch(catchErrorMessage(dispatch));
  };
}

export function clearLabels() {
  writeLabelQuery(null);
  return {
    type: TIMESERIES.SET_LABEL,
    labels: labels(),
  };
}

export function getRecordLabelsUsers(recordID) {
  return (dispatch, getState, api) => authFetch(`${api.label}/users/`, getState, {
    method: 'POST',
    body: JSON.stringify({
      records: [recordID],
    }),
  })
    .then((ul) => fetchUsernames(getState, api, ul[recordID], ul.identity))
    .then((users) => users.toArray())
    .catch(catchErrorMessage(dispatch));
}

/**
 * Creates a new label.
 * If a thumbnail is required, that portion of signal is fetched
 * again from the server in order to have a constant sample count
 * in the thumbnail.
 */
export function addLabel(recordID, label, thumbnail) {
  return (dispatch, getState, api) => {
    const l = signLabel(recordID, getState, label);
    if (thumbnail) {
      const ts = getState().timeSeries;
      const seriesSignal = ts.series
        .map((s) => s.signal)
        .filter((sig) => label.signals.includes(sig))
        .join(',');
      return authFetch(
        `${api.sample}/series/${recordID}?from=${label.start}&to=${
          label.end
        }&threshold=1000&series=${seriesSignal}`,
        getState,
      )
        .then((resp) => {
          resp.forEach((r) => {
            // eslint-disable-next-line no-param-reassign
            r.signal = ensureDomain(r.signal);
          });
          l.thumbnail = generateThumbnail(resp, ts.groups, ts.fitMode, l);
          return postNewLabel(dispatch, getState, api, l);
        })
        .catch(catchErrorMessage(dispatch));
    }
    return postNewLabel(dispatch, getState, api, l);
  };
}

/**
 * Add the last added label again
 * This is useful for long labeling session where the
 * user labels the same event on the whole record
 */
export function copyLabel(recordID) {
  return (dispatch, getState, api) => {
    const selection = getState().timeSeries.selection.current;
    const {
      lastAdded,
    } = getState().timeSeries.labels;
    if (selection && lastAdded) {
      const l = signLabel(recordID, getState, lastAdded);
      l.start = selection.start;
      l.end = selection.end;
      return postNewLabel(dispatch, getState, api, l);
    }
    return Promise.resolve();
  };
}

const labelSelectionOffset = 2000;

/**
 * Sets the current range to the selected label.
 * This allows to center the viewing window when navigating
 * from label to label.
 */
function goToSelectedLabel(dispatch, getState, lab) {
  dispatch({
    type: TIMESERIES.SET_LABEL,
    lab,
  });
  const { range } = getState().timeSeries;
  dispatch(
    updateProgress(
      Math.max(range.start, labels.selectedLabel.start - labelSelectionOffset),
    ),
  );
}

export function selectNextLabel() {
  return (dispatch, getState) => {
    const prev = getState().timeSeries.labels;
    const next = prev.selectNext();
    if (prev !== next) {
      goToSelectedLabel(dispatch, getState, next);
    }
  };
}

/**
 * Copies an existing label while changing its state
 */
export function editLabelState(recordID, labelState) {
  return (dispatch, getState, api) => {
    const {
      selectedLabel,
    } = getState().timeSeries.labels;
    if (selectedLabel != null) {
      const l = signLabel(recordID, getState, selectedLabel);
      l.state = labelState;
      return postNewLabel(dispatch, getState, api, l).then(() => {
        dispatch(selectNextLabel());
      });
    }
    return Promise.reject(new Error('No selected label'));
  };
}

/**
 * Edits that alter the existing label are only allowed for
 * labels that belong to the current user.
 * This check should have been done on the server.
 */
function checkLabel(dispatch, getState, label) {
  if (label.user !== getState().user.id) {
    dispatch(
      setErrorMessage(
        'You cannot modify a label that does not belong to you, modifications will not be saved.',
      ),
    );
    return false;
  }
  return true;
}

/**
 * Modify an existing label instead of adding a new one.
 */
export function editLabel(recordID, label) {
  return (dispatch, getState, api) => {
    if (!checkLabel(dispatch, getState, label)) {
      return Promise.resolve();
    }
    return authFetch(`${api.label}/${label.id}/`, getState, {
      method: 'PATCH',
      // Never patch thumbnails
      body: JSON.stringify({ ...label, thumbnail: undefined }),
    })
      .then(() => {
        dispatch({
          type: TIMESERIES.ADD_EDIT_LABEL,
          label,
        });
      })
      .catch(catchErrorMessage(dispatch));
  };
}

export function deleteLabel(recordID, label) {
  return (dispatch, getState, api) => {
    if (!checkLabel(dispatch, getState, label)) {
      return Promise.resolve();
    }
    return authFetch(`${api.label}/${label.id}/`, getState, {
      method: 'delete',
      body: JSON.stringify(label),
    })
      .then(() => {
        dispatch({
          type: TIMESERIES.REMOVE_LABEL,
          label,
        });
      })
      .catch(catchErrorMessage(dispatch));
  };
}

export function selectPreviousLabel() {
  return (dispatch, getState) => {
    const prev = getState().timeSeries.labels;
    const next = prev.selectPrevious();
    if (prev !== next) {
      goToSelectedLabel(dispatch, getState, next);
    }
  };
}

export function selectLabel(id) {
  return {
    type: TIMESERIES.SELECT_LABEL,
    id,
  };
}

export function toggleLabelEdit() {
  return {
    type: TIMESERIES.TOGGLE_LABEL_EDIT_MODE,
  };
}

export function pushCommitLabel(l) {
  return {
    type: TIMESERIES.PUSH_COMMIT_LABEL,
    label: l,
  };
}

/**
 * Label edition is non-destructive by default.
 */
export function flushCommitLabel(recordID, save) {
  return (dispatch, getState, api) => {
    if (!save) {
      dispatch({
        type: TIMESERIES.FLUSH_COMMIT_LABEL,
        payload: [],
      });
      return Promise.resolve();
    }
    const cancel = toggleProgressBar(dispatch);
    const reqs = getState().timeSeries.labels.commit()
      .map((l) => signLabel(recordID, getState, l))
      .map((l) => authFetch(`${api.label}/`, getState, {
        method: 'post',
        body: JSON.stringify(l),
      }));
    return Promise.all(reqs)
      .then((addedLabels) => {
        const { user } = getState();
        dispatch({
          type: TIMESERIES.FLUSH_COMMIT_LABEL,
          payload: addedLabels.map((l) => ({
            ...l,
            userInfo: {
              username: user.username,
              id: user.id,
            },
          })),
        });
        cancel();
      })
      .catch(catchErrorMessage(dispatch, cancel));
  };
}

export function toggleLabelChannelLayout() {
  return {
    type: TIMESERIES.TOGGLE_LABEL_CHANNEL_LAYOUT,
  };
}

/**
 * Adds a label at the currently set marker.
 * A marker does not indicate a range but a specific
 * timestamp. Since labels only cover ranges, we set a
 * default range of 1s in order to depict an event.
 */
export function addMarkLabel(value) {
  return (dispatch, getState, api) => {
    const ts = getState().timeSeries;
    const m = ts.selection.mark;
    const r = ts.range;
    if (m.location == null || !r.inRange(m.location)) {
      return Promise.resolve();
    }
    const markLabel = signLabel(ts.recordInfo.id, getState, {
      signals: ts.series.map((s) => s.signal),
      value,
      type: labelTypes.MARKER,
      start: m.location,
      end: m.location + 1000,
      state: true,
      is_auto: false,
    });
    return postNewLabel(dispatch, getState, api, markLabel);
  };
}